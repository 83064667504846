<script setup>
import { useFooterSectionStore } from '@/stores/footer_sections';

const footerSectionStore = useFooterSectionStore();

</script>

<template>
	<template v-if="footerSectionStore.getPaymentSection && footerSectionStore.getPaymentSection.items.length > 0">
		<h6 class="payment-title">{{ $t('general.payments') }}</h6>
		<div class="payment-flex">
			<a v-for="item in footerSectionStore.getPaymentSection.items" :key="item.id" :href="item.redirect_url" target="_blank" class="payment-icon">
				<img :src="item.media_url" alt="Social Media Icon">
			</a>
		</div>
	</template>
</template>

<style scoped>
.payment-flex {
	display: flex;
	gap: 10px;
	justify-content: center;
	flex-wrap: wrap;
}

.payment-icon img {
	max-width: 150px;
}

.payment-title {
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	padding-bottom: 5px;
}

.payment-title {
	display: none;
}

@media screen and (max-width: 767px) {
	.payment-flex {
		justify-content: space-between;
	}
	.payment-title {
		display: block;
	}
}
</style>