<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';
import { useCountriesStore } from '../../stores/countries';
import { watch } from 'vue';
import _ from 'lodash';

const { t } = useI18n();
const registerStore = useRegisterStore();
const countriesStore = useCountriesStore();
const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';
const enabled_fields = registerStore.getEnabledRegistrationFields;

let default_mobile_id = null;

if (is_country_ghana) {
	if (enabled_fields) {
		const mobile_field = enabled_fields.find(field => field.slug == 'mobile_country_id');
		if (mobile_field) {
			default_mobile_id = mobile_field.default_value;
			registerStore.register_input_fields.mobile_country_id = mobile_field.default_value;
		}
	}
}

watch(() => registerStore.register_input_fields.mobile_country_id, (newValue, oldValue) => {
	if (newValue != null && registerStore.register_input_fields.country_id == null) {
		registerStore.register_input_fields.country_id = newValue;
	}
});

</script>

<template>
	<div class="register-form-field">
		<label>{{ $t('account.register.country') }} {{ $t('account.register.code') }}</label>
			<select @change="registerStore.validateMobilePrefix" name="" :style="{ cursor: is_country_ghana ? 'not-allowed' : 'pointer' }" v-model="registerStore.register_input_fields.mobile_country_id" class="form-input form-control" id="address-country" :disabled=is_country_ghana>
				<option :value="null" disabled>{{ $t('account.register.make_a_choice') }}</option>
				<option v-for="country in countriesStore.getCountries" :key="country.id" :value="country.id">+{{ country.mobile_prefix }}</option>
			</select>
		<div class="register-form-list" v-if="registerStore.register_errors.mobile_country_id.error">
			<div class="password-meter">
				<div class="password-meter-bar">
					<div class="password-meter-bar-inn"></div>
				</div>
			</div>
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.mobile_country_id.error == 'error_select_mobile_prefix'">
					{{ $t('account.register.error_select_mobile_prefix') }}
				</span>
				<span v-else-if="registerStore.register_errors.hasOwnProperty('mobile_country_id')">
					{{ registerStore.register_errors.mobile_country_id.error }}
				</span>
			</p>
		</div>
	</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .phone-field {
	display: block;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form select {
	background-position: 5%;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input {
	padding: 10px 15px 10px 15px;
}
</style>
