<script setup>
import { useFooterSectionStore } from '@/stores/footer_sections';

const footerSectionStore = useFooterSectionStore();

</script>

<template>
	<template v-if="footerSectionStore.getContactSection">
		<div class="contact-flex">
			<div class="mobile-width">
				<h6 class="contact-title">{{ $t('general.contact') }}</h6>
				<a href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});" class="btn-small btn-secondary" style="height: 39px; width: 140px;">{{ $t('general.live_chat') }}</a>
			</div>
			<div class="mobile-width">
				<h6></h6>
				<a href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});" class="btn-small" style="height: 39px; width: 140px;">{{ $t('general.request_a_free_call') }}</a>
			</div>

			<template v-if="footerSectionStore.getContactSection.items.length > 0">
				<template v-for="item in footerSectionStore.getContactSection.items" :key="item.id">
					<div v-if="item.type === 'reach_us_by_phone_call'" class="mobile-width">
						<h6 class="contact-title">{{ $t('general.reach_us_by_phone') }}</h6>
						<template v-for="lang in item.languages" :key="lang.id">
							<template v-if="lang.pivot">
								<a class="btn-small no-hover no-border" :href="'tel:' + lang.pivot.body" style="width: 140px;">{{ lang.pivot.body }}</a>
							</template>
						</template>
					</div>
					<div v-if="item.type === 'ussd_code'" class="mobile-width">
						<h6 class="contact-title">{{ $t('general.paybill/ussd') }}</h6>
						<template v-for="lang in item.languages" :key="lang.id">
							<template v-if="lang.pivot">
								<p class="btn-small no-hover" style="height: 39px; width: 140px;">{{ lang.pivot.body }}</p>
							</template>
						</template>
					</div>
				</template>
			</template>
		</div>
	</template>
</template>

<style scoped>
.contact-flex {
	display: flex;
	align-items: end;
	justify-items: start;
	flex-wrap: wrap;
	gap: 10px;
}
.contact-title {
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	padding-bottom: 5px;
}
.contact-number {
	color: #63a246;
	font-weight: 700;
	text-decoration: none;
}

.contact-number-ussd {
	color: #63a246;
	font-weight: 700;
}

.no-hover {
	color: #63a246;
	font-weight: 700;
	pointer-events: none;
	cursor: default;
}

.no-border {
	border: none;
	text-align: left;
}

@media screen and (max-width: 767px){
	.contact-flex {
		justify-content: space-between;
	}

	.btn-small {
		font-size: 13px;
		text-wrap: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.mobile-width {
		width: 44%;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.btn-small {
		font-size: 13px;
		padding: 7px;
	}
}
</style>