import { defineStore } from 'pinia';
import { ref } from 'vue'
import _ from 'lodash';

export const useDepositsStore = defineStore('deposits', {
	state: () => ({
		payment_gateways: ref({}),
		available_bonuses: ref({}),
	}),
	getters: {
		getPaymentGateways(state) {
			const allowed_payment_gateways = ['plisio', 'coinpayments', 'hubtel', '0xprocessing', 'iran-voucher', 'central-pay', 'perfect-money', 'perfect-money-voucher'];

			return _.filter(state.payment_gateways, (gateway) => {
				return _.includes(allowed_payment_gateways, gateway.slug);
			});
		},
		getAvailableBonuses(state) {
			return state.available_bonuses;
		},
	},
	actions: {
		fetchPaymentGateways() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/bank/deposit/payment_gateways').then((response) => {
				this.payment_gateways = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		fetchAvailableBonuses(payload) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/bank/deposit/payment_gateway/' + payload + '/bonus').then((response) => {
				this.available_bonuses = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		getPaymentGateway(slug) {
			return _.find(this.payment_gateways, { 'slug': slug });
		},
		// Plisio
		createDepositPlisio(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/plisio', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// CoinPayments
		createDepositCoinPayments(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/coinpayments', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// 0xprocessing
		createDeposit0xProcessing(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/0xprocessing', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Hubtel
		createDepositHubtelMobileMoney(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/hubtel/mobile-money', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		createDepositHubtelBankCard(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/hubtel/bank-card', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Iran Voucher
		createDepositIranVoucher(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/iran-voucher', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Central Pay
		createDepositCentralPay() {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/central-pay').then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Perfect Money
		createDepositPerfectMoney(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/V2/perfect-money', payload).then((response) => {

				let form = document.createElement('form');
				form.action = 'https://perfectmoney.com/api/step1.asp';
				form.method = 'post';
				form.style.display = 'none';
				document.body.appendChild(form);

				Object.entries(response.data.data).forEach(([name, value]) => {
					let input = document.createElement('input');
					input.setAttribute('name', name);
					input.setAttribute('value', value);
					form.appendChild(input);
				});

				form.submit();

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Perfect Money Voucher
		createDepositPerfectMoneyVoucher(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/deposit/perfect-money-voucher', payload).then((response) => {

				// let form = document.createElement('form');
				// form.action = 'https://perfectmoney.com/api/step1.asp';
				// form.method = 'post';
				// form.style.display = 'none';
				// document.body.appendChild(form);

				// Object.entries(response.data.data).forEach(([name, value]) => {
				// 	let input = document.createElement('input');
				// 	input.setAttribute('name', name);
				// 	input.setAttribute('value', value);
				// 	form.appendChild(input);
				// });

				// form.submit();

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		setPaymentGateways(data) {
			this.payment_gateways = data;
		}
	},
})