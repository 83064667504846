 /* eslint-disable */

import { defineStore } from "pinia";
import _ from "lodash";

import * as Combinatorics from "js-combinatorics";
import { useCustomerStore } from '@/stores/customer';
import { useSocketStore  } from '@/stores/socket';
import { useInitializeStore  } from "@/stores/initialize.js";
import { useFavouritesStore } from "./favourites";
import { useAccaBoostStore } from "./acca_boost";

import { applyPatch } from "rfc6902";
import BetslipComponent from "@/views/Bets/BetSlip/BetslipComponent.vue";
export const MyBets = defineStore({ id: "MyBets",
	state: () => ({
		my_bets: [],
	}),
	getters: {
		countMyBets(state) {
			return state.unsettledBetslips.length;
		},
		unsettledBetslips(state) {
			const favouritesStore = useFavouritesStore();
			let unsettled_betslips = [];

			state.my_bets.forEach((betslip) => {
			if (betslip.betslip_result == "unsettled") {
				if (betslip.freebet_id == null && betslip.bonus_can_cashout) {
				betslip.cashout_amount = state.betslipCashout(betslip);

				betslip.cashout_available = true;
				if (betslip.cashout_amount == null || betslip.cashout_amount == 0)
					betslip.cashout_available = false;
				}

				if (favouritesStore.favouriteBetslipIds.includes(String(betslip.betslip_id))) betslip.favourite = true;
				else betslip.favourite = false;

				unsettled_betslips.push(betslip);
			}
			});

			return _.orderBy(unsettled_betslips, ['favourite'], ['desc']);
		},
		cashoutBetslips(state) {
			return _.filter(state.unsettledBetslips, betslip => {
				return betslip.cashout_available === true && (betslip.mission_can_cashout === null || betslip.mission_can_cashout === true);
			});
		},
		settledBetslips(state) {
			const favouritesStore = useFavouritesStore();
			let settled_betslips = [];

			state.my_bets.forEach(betslip => {
				if (betslip.betslip_result == 'settled') {
					if (favouritesStore.favouriteBetslipIds.includes(String(betslip.betslip_id))) betslip.favourite = true;
					else betslip.favourite = false;

					settled_betslips.push(betslip);
				}
			});

			return _.orderBy(settled_betslips, ['favourite'], ['desc']);
		},
		betslipCashout: (state) => (betslip) => {
			let cashout_amount = null;

			// If at least one current_price is null, dont return cashout for this betslip
			if (betslip.bets.find(bet => bet.settlement == null && bet.current_price == null)) return null;

			// If at least one bet is suspended
			if (betslip.bets.find(bet => bet.status == 'suspended')) return null;

			switch (betslip.betslip_type) {
				case 'single':
					if (betslip.bets[0].settlement) return null;
					cashout_amount = (betslip.to_return / betslip.bets[0].current_price) * 0.9;
					return cashout_amount.toFixed(2);

				case 'multiple':
					if (betslip.bets.find(bet => bet.settlement == 'LOST')) return null;
					cashout_amount = Number(betslip.betslip_stake) + Number(betslip.bonus_stake);
					betslip.bets.forEach(bet => {
						if (bet.settlement == 'HALF_WON' || bet.settlement == 'HALF_LOST') {
							cashout_amount = cashout_amount * bet.current_price;
						} else {
							cashout_amount = cashout_amount * bet.odd_price;
						}
					});
					betslip.bets.forEach(bet => {
						if (bet.settlement != 'HALF_WON' && bet.settlement != 'HALF_LOST') {
							cashout_amount = cashout_amount / bet.current_price;
						}
					});
					cashout_amount = cashout_amount * 0.9;

					return cashout_amount.toFixed(2);

				case 'system':
					let combinations = Combinatorics.Combination.of(betslip.bets, betslip.betslip_system_number);

					cashout_amount = 0;
					[...combinations].forEach(combination => {
						let combination_return = betslip.betslip_stake_per_bet;

						combination.forEach(bet => {
							if (bet.settlement == 'LOST' || bet.settlement == 'HALF_WON' || bet.settlement == 'HALF_LOST') {
								combination_return = combination_return * bet.current_price;
							} else {
								combination_return = combination_return * bet.odd_price;
							}
						});

						if (combination_return > 0) {
							combination.forEach(bet => {
								if (bet.settlement != 'HALF_WON' && bet.settlement != 'HALF_LOST') {
									combination_return = combination_return / bet.current_price;
								}
							});

							cashout_amount = cashout_amount + combination_return;
						}
					});

					cashout_amount = cashout_amount * 0.9;
					if (cashout_amount > 0) return cashout_amount.toFixed(2);

				default:
					break;
			}
		},
		fixtureClockTime: (state, getters) => (statistics) => {
			if (statistics == null) return;
			const initializeStore = useInitializeStore();

			let seconds_diff = 0;
			let calculated_seconds = 0;

			// find diff between server_timestamp and fixture.clock_timestamp
			if (statistics.clock_status == 'running') {

				seconds_diff = Number(initializeStore.server_timestamp) - Number(statistics.clock_timestamp);
				if (statistics.clock_direction == 'up') {
					calculated_seconds = Number(statistics.clock_seconds) + seconds_diff;
				} else if (statistics.clock_direction == 'down') {
					calculated_seconds = Number(statistics.clock_seconds) - seconds_diff;
				}

			} else if (statistics.clock_status == 'stopped') {
				calculated_seconds = Number(statistics.clock_seconds);
			}

			if (calculated_seconds < 0) calculated_seconds = 0;

			if (calculated_seconds > 8400) calculated_seconds = 8434;

			// Calculate clock_time 'mm:ss'
			let mins = ~~(calculated_seconds / 3600) * 60 + ~~((calculated_seconds % 3600) / 60);
			let secs = ~~calculated_seconds % 60;
			let time = '';
			time += (mins < 10 ? '0' : '') + mins + ':' + (secs < 10 ? '0' : '');
			time += '' + secs;

			return time;
		},
		fixtureState: () => (statistics) => {
			if (statistics == null) return;

			if (statistics.game != null) return "G " + statistics.game;
			if (statistics.half != null) return statistics.half;
			if (statistics.period != null) return statistics.period;

			return null;
		},
		hasSPOdd: () => (betslip) => {
			let sp_odds = _.filter(betslip.bets, { sp: true });

			if (sp_odds.length > 0) return true;

			return false
		},
		getMyBetsScale: (state)=> (bets)=> {
			const accaBoostStore = useAccaBoostStore();
			const betScale = accaBoostStore.getAccaBoostScale.find((s)=> s.bet_selections === bets.length)

			return betScale;
		},
	},
	actions: {
		fetchMyBets() {
			const customerStore = useCustomerStore();
			if (!customerStore.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/account/mybets').then((response) => {
				this.my_bets = response.data.data;
				this.fetchMyBetsSnapshot();
				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},
		fetchMyBetsSnapshot() {
			const socketStore = useSocketStore();

			let my_bets = [];
			this.my_bets.forEach(betslip => {
				betslip.bets.forEach(bet => {
					my_bets.push({ fixture_id: bet.fixture_id, odd_uuid: bet.uuid })
					socketStore.getSocket.emit('join-fixtureInfo', bet.fixture_id);
				});
			});

			socketStore.getSocket.emit('join-myBetsSnapshot', my_bets);
		},
		intervalFetchMybets() {
			const customerStore = useCustomerStore();
			if (!customerStore.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/account/mybets').then((response) => {

				for (const betslip of response.data.data) {

					let my_bets_betslip_index = _.findIndex(this.my_bets, {betslip_id: betslip.betslip_id})

					if (my_bets_betslip_index == -1) continue;

					// Update betslip properties
					this.my_bets[my_bets_betslip_index].betslip_result = betslip.betslip_result;
					if (betslip.betslip_result == 'settled') this.my_bets[my_bets_betslip_index].cashout_available = false;

					this.my_bets[my_bets_betslip_index].settlement = betslip.settlement;
					this.my_bets[my_bets_betslip_index].returned = betslip.returned;

					// Update Bet properties
					for (const bet of betslip.bets) {
						let my_bets_betslip_bet_index = _.findIndex(this.my_bets[my_bets_betslip_index].bets, {bet_id: bet.bet_id});

						if (my_bets_betslip_bet_index == -1) continue;

						this.my_bets[my_bets_betslip_index].bets[my_bets_betslip_bet_index].settlement = bet.settlement;
						if (bet.settlement != null) {
							this.my_bets[my_bets_betslip_index].bets[my_bets_betslip_bet_index].current_price = bet.current_price;
						}
					}

				}
				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},
		setMyBetsSnapshot(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (payload[bet.uuid]) {
						this.my_bets[betslip_index].bets[bet_index].live = payload[bet.uuid].live;
						this.my_bets[betslip_index].bets[bet_index].status = payload[bet.uuid].status;
						this.my_bets[betslip_index].bets[bet_index].statistics = payload[bet.uuid].statistics;
						this.my_bets[betslip_index].bets[bet_index].virtual_live_id = payload[bet.uuid].virtual_live_id;
						if (!bet.settlement && !bet.current_price) {
							this.my_bets[betslip_index].bets[bet_index].current_price =  payload[bet.uuid].price;
						}
					} else {
						// Change price only if betslip is not settled
						// if (bet.settlement != null) this.my_bets[betslip_index].bets[bet_index].current_price = null;
					}
				});
			});
		},
		setFixtureOddInfo(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (bet.uuid == payload.uuid) {
						this.my_bets[betslip_index].bets[bet_index].status = payload.status;
						if (bet.settlement == null) {
							if (payload.status == 'available') {
								this.my_bets[betslip_index].bets[bet_index].current_price = payload.price;
							} else {
								this.my_bets[betslip_index].bets[bet_index].current_price = null;
							}
						}
					}
				});
			});
		},
		setFixtureOddInfoInsert(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (bet.uuid == payload.uuid) {
						this.my_bets[betslip_index].bets[bet_index].status = payload.status;
						if (bet.settlement == null) {
							if (payload.status == 'available') {
								this.my_bets[betslip_index].bets[bet_index].current_price = payload.price;
							} else {
								this.my_bets[betslip_index].bets[bet_index].current_price = null;
							}
						}
					}
				});
			});
		},
		setFixtureOddInfoUpdate(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (bet.uuid == payload.uuid) {
						// Apply changes from update patch (delta)
						payload.patch.forEach(patch => {
							if (patch.path.includes('price') && bet.settlement == null) {
								this.my_bets[betslip_index].bets[bet_index].current_price = patch.value;
							} else if (patch.path.includes('status')) {
								this.my_bets[betslip_index].bets[bet_index].status = patch.value;
							}
						});

						// Disable cashout when odd is not available
						if (bet.status != 'available' && bet.settlement == null) {
							this.my_bets[betslip_index].bets[bet_index].current_price = null;
						}
					}
				});
			});
		},
		setFixtureOddInfoDelete(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (bet.uuid == payload && bet.settlement == null) {
						this.my_bets[betslip_index].bets[bet_index].current_price = null;
					}
				});
			});
		},
		setFixtureInfo(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (bet.fixture_id == payload.id) {
						this.my_bets[betslip_index].bets[bet_index].statistics = payload.statistics;
						this.my_bets[betslip_index].bets[bet_index].live = payload.live;
					}
				});
			});
		},
		setFixtureInfoUpdate(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (bet.fixture_id == payload.id) {
						applyPatch(this.my_bets[betslip_index].bets[bet_index], payload.patch);
					}
				});
			});
		},
		setFixtureInfoDelete(payload) {
			this.my_bets.forEach((betslip, betslip_index) => {
				betslip.bets.forEach((bet, bet_index) => {
					if (bet.fixture_id == payload) {
						this.my_bets[betslip_index].bets[bet_index].live = false;
					}
				});
			});
		},
		cashoutBetslip(payload) {
			const customerStore = useCustomerStore();

			return global.axios.post(process.env.VUE_APP_API_URL + '/cashout', payload).then((response) => {
				customerStore.fetchBalance();
				this.fetchMyBets();
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},
		partialCashoutBetslip(payload) {
			const customerStore = useCustomerStore();

			return global.axios.post(process.env.VUE_APP_API_URL + '/cashout/partial', payload).then((response) => {
				customerStore.fetchBalance();
				this.fetchMyBets();
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},
		emptyMyBets() {
			this.my_bets = [];
		},
		toggleFavourite(betslip_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/favourite/betslip', { id: betslip_id }).then((response) => {
				useCustomerStore().customer.favourites = response.data.data

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
	},

  persist: true,
});
