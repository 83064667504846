 /* eslint-disable */
import { defineStore } from 'pinia';
import {applyPatch, createPatch} from 'rfc6902'
import _ from 'lodash';
import { useFavouritesStore } from './favourites';

export const useLiveSportsStore = defineStore('liveSports', {
	state: () => ({
		live_sports: null,
		selected_live_sport_id: null,
		favourites_selected: false
	}),
	getters: {
		liveSports(state) {
			return _.orderBy(state.live_sports, 'id');
		},
		getSelectedLiveSportName(state) {
			if (!state.selected_live_sport_id) return null;
			if (!state.live_sports) return null;
			if (!state.live_sports.hasOwnProperty(state.selected_live_sport_id)) return null;

			return state.live_sports[state.selected_live_sport_id].name;
		},
		liveSportName: (state) => (live_sport_id) => {
			let sport_names = {
				1: 'Soccer',
				2: 'Tennis',
				3: 'Basketball',
				4: 'Ice Hockey',
				5: 'Handball',
				6: 'Baseball',
				7: 'Water Polo',
				8: 'Volleyball',
				9: 'Badminton',
				10: 'Table Tennis',
				11: 'Futsal',
				12: 'Boxing',
				13: 'American Football',
				14: 'Horse Racing',
				15: 'Snooker',
				16: 'Counter Strike',
				17: 'MMA',
				18: 'Politics',
				19: 'Archery',
				20: 'Greyhounds',
				21: 'Dota 2',
				22: 'Cricket',
				23: 'Valorant',
				24: 'Rugby Union',
				25: 'Floorball',
				26: 'Curling',
				27: 'League of Legends',
				28: 'Chess',
				29: 'King of Glory',
				30: 'Hockey',
				31: 'Bowls',
				32: 'Pistol Shooting',
				33: 'Beach Volleyball',
				34: 'Trotting',
				35: 'Formula 1',
				36: 'Darts',
				37: 'Golf',
				38: 'Archery Shooting',
				39: 'Aussie Rules',
				40: 'Rugby League',
				41: 'Beach Soccer',
				42: 'Netball',
				43: 'Squash',
				44: 'Cycling',
				45: 'Gaelic Football',
				46: 'Shooting',
				47: 'Bandy',
				48: 'Lacrosse',
				49 :'Kabaddi',
				50 :'Biathlon',
				51 :'Gaelic Hurling',
				52 :'Entertainment',
			}

			return sport_names[live_sport_id];
		},
		liveSportWhiteIcon: (state) => (live_sport_id) => {
			return '/assets/white_sport_icons/' + state.liveSportName(live_sport_id) + '.svg'
		},
		liveSportGreenIcon: (state) => (live_sport_id) => {
			return '/assets/green_sport_icons/Sport icons Green_' + state.liveSportName(live_sport_id) + '.svg'
		},
		liveSportSlug: (state) => (live_sport_id) => {
			let sport_slugs = {
				1: 'soccer',
				2: 'tennis',
				3: 'basketball',
				4: 'ice_hockey',
				5: 'handball',
				6: 'baseball',
				7: 'waterpolo',
				8: 'volleyball',
				9: 'badminton',
				10: 'table_tennis',
				11: 'futsal',
				12: 'boxing',
				13: 'american_football',
				14: 'horse_racing',
				15: 'snooker',
				16: 'counter_strike',
				17: 'mma',
				18: 'politics',
				19: 'archery',
				20: 'greyhounds',
				21: 'dota_2',
				22: 'cricket',
				23: 'valorant',
				24: 'rugby_union',
				25: 'floorball',
				26: 'curling',
				27: 'league_of_legends',
				28: 'chess',
				29: 'king_of_glory',
				30: 'hockey',
				31: 'bowls',
				32: 'pistol_shooting',
				33: 'beach_volleyball',
				34: 'trotting',
				35: 'formula_1',
				36: 'darts',
				37: 'golf',
				38: 'archery_shooting',
				39: 'aussie_rules',
				40: 'rugby_league',
				41: 'beach_soccer',
				42: 'netball',
				43: 'squash',
				44: 'cycling',
				45: 'gaelic_football',
				46: 'shooting',
				47: 'bandy',
				48: 'lacrosse',
				49 :'kabaddi',
				50 :'biathlon',
				51 :'gaelic_hurling',
				52 :'entertainment',
			}

			return sport_slugs[live_sport_id];
		},
		liveSportIcons: (state, getters) => (live_sport_id) => {

			let sport_icons = {
				default : '/assets/icons/sports/white/' + state.liveSportSlug(live_sport_id) + '.svg',
				selected: '/assets/icons/sports/purple/' + state.liveSportSlug(live_sport_id) + '.svg',
			}

			return sport_icons;
		},
		hasFixtureClock: (state, getters) => (live_sport_id) => {
			return [
				1, // Soccer
				// 2, // Tennis
				3, // Basketball
				4, // Ice Hokey
				5, // Handball
				// 6, // Baseball
				7, // Water Polo
				// 8, // VolleyBall
				// 9, // Badminton
				// 10, // Table Tennis
				11, // Futsal
				12, // Boxing
				13, // American Football,
				// 14, // Horse Racing
				// 15, // Snooker
				// 16, // Counter Strike
				17, // MMA
				// 18, // Politics
				// 19, // Archery
				// 20, // Greyhounds
				// 21, // Dota 2
				// 22, // Cricket
				// 23, // Valorant
				// 24, // Rugby Union
				// 25, // Floorball
				// 26, // Curling
				// 27, // League of Legends
				// 28, // Chess
				// 29, // King of Glory
				// 30, // Hockey
				// 31, // Bowls
				// 32 // pistol_shooting
				// 33 // beach_volleyball
				// 34 // trotting
				// 35 // formula_1
				// 36 // darts
				// 37 // golf
				// 38 // archey_shooting
				39, // aussie_rules
				40, // rugby_league
				41 // beach_soccer
				// 42 // netball
				// 43 // squash
				// 44 // cycling

			].includes(live_sport_id);
		},
		hasTwoScores: (state, getters) => (live_sport_id) => {
			return [
				2, // Tennis
				8, // VolleyBall
				9, // Badminton
				10, // Table Tennis
				15, // Snooker
				16, // Counter Strike
				21, // Dota 2
				23, // Valorant
				27, // League of Legends
				29, // King of Glory
				33, // Beach Volleyball
				43, // Squash
			].includes(live_sport_id);
		},
		hasThreeScores: (state, getters) => (live_sport_id) => {
			return [
				2, // Tennis
			].includes(live_sport_id);
		},
		hasPossession: () => (live_sport_id) => {
			return [
				2, // Tennis,
				8, // VolleyBall
				9, // Badminton
				10, // Table Tennis
				// 13, // American Football
				// 24, // Rugby Union
				// 40, // Rubgy League
				33, // Beach Volleyball
				43, // Squash
			].includes(live_sport_id);
		},
		hasVirtualLive: (state) => (live_sport_id) => {
			return [
				1, // Soccer
				2, // Tennis
				3, // Basketball
				4, // Ice Hokey
				5, // Handball
				// 6, // Baseball
				7, // Water Polo
				8, // VolleyBall
				9, // Badminton
				10, // Table Tennis
				11, // Futsal
				// 12, // Boxing
				// 13, // American Football,
				// 14, // Horse Racing
				// 15, // Snooker
				// 16, // Counter Strike
				// 17 // MMA
			].includes(live_sport_id);
		},
		hasOneParticipant: (state) => (live_sport_id) => {
			return [
				32, // Pistol Shooting
				38, // Archery Shooting
				37, // Golf
				14, // Horse Racing
				20, // Greyhounds
				34, // Trotting
				35, // Formula 1
			].includes(live_sport_id);
		},
		hasOnlyParticipant: (state) => (live_sport_id) => {
			return [
				37, // Golf
				14, // Horse Racing
				20, // Greyhounds
				34, // Trotting
				35, // Formula 1
			].includes(live_sport_id);
		},
		virtualLiveIcon: (state) => (live_sport_id) =>{
			return '/assets/icons/vl_icons/' + state.liveSportSlug(live_sport_id) + '_field.svg';
		},
		favouritesSelected(state) {
			return state.favourites_selected;
		},
		hasLiveFavouriteFixtures() {
			const favouritesStore = useFavouritesStore();

			let live_fixtures = _.filter(favouritesStore.fixtureOverviews, { live: true });
			if (live_fixtures.length > 0) return true;

			return false;
		}
	},
	actions: {
		setLiveSports(data) {
			this.live_sports = data;
		},
		updateLiveSports(data) {
			applyPatch(this.live_sports, data.patch);
		},
		setSelectedLiveSportId(live_sport_id) {
			this.selected_live_sport_id = live_sport_id;
		},
		emptySelectedLiveSportId() {
			this.selected_live_sport_id = null
		},
		selectFavourites(data) {
			this.favourites_selected = data;
		}
	},
})