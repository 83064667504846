<!-- eslint-disable -->
<script setup>
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { onMounted, ref } from 'vue';

const svgs_data = commonSvgs
const cookie_accepted = ref(false)

const acceptCookieConsent = () => {
	let expiration_days = 30;
	let expiration_date = new Date();
	expiration_date.setTime(expiration_date.getTime() + (expiration_days * 24 * 60 * 60 * 1000));
	document.cookie = 'user_cookie_consent=true;expires=' + expiration_date.toUTCString() + ';path=/';

	cookie_accepted.value = true;
}

const closeCookieConsent = () => {
	cookie_accepted.value = true;
}

onMounted(() => {
	// Check if cookie is already accepted
	let cookies = decodeURIComponent(document.cookie).split(';');
	cookies.forEach(cookie => {
		if (cookie.includes('user_cookie_consent')) cookie_accepted.value = true;
	});
})

</script>
<template>
	<div v-if="!cookie_accepted" class="modal-outer">
		<div class="container">
			<button class="close" v-html="svgs_data.popup_cross" @click="closeCookieConsent"></button>
			<div class="password-reset-main">
				<div class="popup-message">
					<p>{{ $t('general.coockie_text') }}</p>
				</div>
				<button class="btn btn-secondary" type="button" @click="acceptCookieConsent">{{ $t('general.accept') }}</button>
			</div>
		</div>
	</div>
</template>
<style scoped>

.modal-outer .container {
	position: fixed;
	max-width: 450px;
	z-index: 100;
	padding: 24px 50px;
	background-color: #131C27;
	bottom: 0;
	left: 0;
	text-align: center;
	border-radius: 0px 5px 0px 0px;
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
}

html[dir="rtl"] .modal-outer .container {
	right: 0;
	border-radius: 5px 0px 0px 0px;
}

.modal-outer .container .password-reset-main .popup-message p {
	color: #fff;
	font-size: 14px;
	font-weight: 100;
}

.modal-outer .container .password-reset-main button {
	margin: 20px auto 0;
	padding: 7px 5px;
	width: 156px;
	font-size: 14px;
	cursor: pointer;
}

@media screen and (max-width: 991px) {
	.modal-outer .container {
		position: fixed;
		max-width: 100%;
		z-index: 9999;
		padding: 24px 50px;
		background-color: #131C27;
		bottom: 0;
		text-align: center;
		border-radius: 5px 5px 0px 0px !important;
	}
}
</style>