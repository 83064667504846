import { defineStore } from 'pinia';
import _ from 'lodash';

export const useRegionsStore = defineStore('regions', {
	state: () => ({
		regions_competitions: null,
		selected_region_id: null,
		dropdown_selector_opened: false,
		regions_order: 'asc',
		regions_order_popularity: 'desc'
	}),
	getters: {
		regionsCompetitions(state) {
			let regions = _.cloneDeep(state.regions_competitions);
			for (const key in regions) {
				if (Object.hasOwnProperty.call(regions, key)) {
					regions[key].competitions = _.orderBy(regions[key].competitions, ['weight', 'name'], ['desc', 'asc']);
				}
			}

			return _.orderBy(regions, ['name'], [state.regions_order]);
		},
		regionsCompetitionsPopularity(state) {
			let regions = _.cloneDeep(state.regions_competitions);
			for (const key in regions) {
				if (Object.hasOwnProperty.call(regions, key)) {
					regions[key].competitions = _.orderBy(regions[key].competitions, ['weight', 'name'], ['desc', 'asc']);
				}
			}

			return _.orderBy(regions, ['weight', 'name'], [state.regions_order_popularity, 'asc']);
		},
		regionsCompetitionsCoupons(state) {
			let regions = _.cloneDeep(state.regions_competitions);

			for (const key in regions) {
				if (Object.hasOwnProperty.call(regions, key)) {
					regions[key].competitions = _.orderBy(regions[key].competitions, ['weight', 'name'], ['desc', 'asc']);
				}
			}

			return _.orderBy(regions, ['weight', 'name'], ['desc', 'asc']);
		},
		selectedRegionId(state) {
			return state.selected_region_id;
		},
		dropdownSelectorOpened(state) {
			return state.dropdown_selector_opened;
		}
	},
	actions: {
		fetchRegions(sport_id) {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/regions/competitions/sport/' + sport_id, { withCredentials: false }).then((response) => {
				this.regions_competitions = response.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		setSelectedRegionId(data) {
			this.selected_region_id = data;
		},
		toggleDropdownSelector() {
			this.dropdown_selector_opened = !this.dropdown_selector_opened;
		},
		setRegionsOrder() {
			if (this.regions_order == 'asc') {
				this.regions_order = 'desc';
				return;
			}

			if (this.regions_order == 'desc') {
				this.regions_order = 'asc';
				return;
			}

		},
		setRegionsPopularityOrder() {
			if (this.regions_order_popularity == 'asc') {
				this.regions_order_popularity = 'desc';
				return;
			}

			if (this.regions_order_popularity == 'desc') {
				this.regions_order_popularity = 'asc';
				return;
			}

		}
	},
})