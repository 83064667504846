/* eslint-disable */
import { defineStore } from 'pinia';
import _ from 'lodash';
import { useFavouritesStore } from './favourites';
import { applyPatch } from 'rfc6902';

export const useSportPopularStore = defineStore('sportPopularStore', {
	state: () => ({
		popular_fixtures: {},
	}),
	getters: {
		getTopFeaturedFixtures(state) {
			return _.chain(state.popular_fixtures).orderBy(['sort','competition_weight', 'start_datetime', 'competition_region_name'], ['asc','desc', 'asc', 'asc']).take(5).value();
		},
		popularFixtures(state){
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.popular_fixtures);
			let favourite_fixtures = [];
			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {

					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(Number(fixture_id))) {
						fixtures[fixture_id].favourite = true;
						favourite_fixtures.push(fixtures[fixture_id]);

						fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name + '-fav';

						delete fixtures[fixture_id];
					}
				}
			}

			fixtures = _.chain(fixtures).orderBy(['favourite', 'sort', 'competition_weight', 'competition_region_name'], ['asc', 'asc', 'desc', 'asc']).groupBy('competition_region_name').value();

			favourite_fixtures = _.groupBy(favourite_fixtures, 'competition_region_name');

			if (Object.keys(favourite_fixtures).length > 0) return {...favourite_fixtures, ...fixtures}

			return fixtures;
		},
	},
	actions: {
		setPopularFixtures(data) {
			this.popular_fixtures = data;
		},
		updatePopularFixtures(data) {
			applyPatch(this.popular_fixtures, data.patch)
		}
	}
})