<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';
import { ref } from 'vue';

const { t } = useI18n();
const registerStore = useRegisterStore();
const password_visibility = ref(false);

const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';

const togglePasswordVisibility = () => {
	if (password_visibility.value == false) password_visibility.value = true
	else password_visibility.value = false
}

const passwordProgress = () => {
	var counter = 0;

	if (registerStore.register_password.has_letter == true) counter++;
	if (registerStore.register_password.has_number == true) counter++;
	if (registerStore.register_password.has_minimum_length == true) counter++;

	if (counter == 0) return 'password-meter-bar-inn-99'
	else if (counter == 1) return 'password-meter-bar-inn-66'
	else if (counter == 2) return 'password-meter-bar-inn-33'
	else return 'password-meter-bar-inn-100'
}
</script>
<template>
	<div class="register-form-field">
		<label>{{$t('account.login.password')}}</label>
		<div class="pass-field">
			<input :type="password_visibility ? 'text' : 'password'" :placeholder="$t('account.login.password')" class="form-input form-input-password" name="password" autocomplete="current-password" @keyup="registerStore.validatePassword" v-model="registerStore.register_input_fields.password">
			<a class="togglePassword" @click="togglePasswordVisibility()">
				<img v-if="password_visibility" src="@/assets/svgs/eye.svg" alt="">
				<img v-else src="@/assets/svgs/eyeCross.svg" alt="">
			</a>
				
		</div>
		<div class="register-form-list"  v-if="registerStore.register_errors.password.error == true">
			<div class="password-meter">
				<div class="password-meter-bar">
					<p :class="passwordProgress()"></p>
				</div>
			</div> 
			<template v-if="is_country_ghana">
				<p>
					<img v-if="registerStore.register_password.has_minimum_length == false" src="@/assets/svgs/cross.svg" alt=""/>
					<img v-else src="@/assets/svgs/check.svg" alt="">
						&nbsp;{{ $t('account.login.password_must_contain_at_least') }} {{ $t('account.login.least_four_characters') }}
				</p>
				<p>
					<img v-if="registerStore.register_password.required == false" src="@/assets/svgs/cross.svg" alt="">
					<img v-else src="@/assets/svgs/check.svg" alt=""/>
						&nbsp;{{ $t('account.login.password_required') }}
				</p>
			</template>
			<template v-else>
				<p>
					<img v-if="registerStore.register_password.has_minimum_length == false" src="@/assets/svgs/cross.svg" alt=""/>
					<img v-else src="@/assets/svgs/check.svg" alt="">
						&nbsp;{{ $t('account.login.password_must_contain_at_least') }} {{ $t('account.login.least_characters') }}
				</p>
				<p>
					<img v-if="registerStore.register_password.has_number == false" src="@/assets/svgs/cross.svg" alt=""/>
					<img v-else src="@/assets/svgs/check.svg" alt="">
						&nbsp;{{ $t('account.login.password_must_contain_at_least') }} {{ $t('account.login.one_number') }}
				</p>
				<p>
					<img v-if="registerStore.register_password.has_letter == false" src="@/assets/svgs/cross.svg" alt="">
					<img v-else src="@/assets/svgs/check.svg" alt=""/>
						&nbsp;{{ $t('account.login.password_must_contain_at_least') }} {{ $t('account.login.one_uppercase_letter') }}
				</p>
				<p>
					<img v-if="registerStore.register_password.required == false" src="@/assets/svgs/cross.svg" alt="">
					<img v-else src="@/assets/svgs/check.svg" alt=""/>
						&nbsp;{{ $t('account.login.password_required') }}
				</p>
			</template>
		</div>
	</div>

	<!-- Password Confirmation -->
	<div class="register-form-field">
		<label>{{$t('account.register.confirm')}}</label>
		<div class="pass-field">
			<input :type="password_visibility ? 'text' : 'password'" :placeholder="$t('account.register.confirm')" @keyup="registerStore.validatePasswordConfirmation" v-model="registerStore.register_input_fields.password_confirmation" class="form-input"
			name="password" autocomplete="current-password" required=""
			id="id_password">
			<a class="togglePassword" @click="togglePasswordVisibility()">
				<img v-if="password_visibility" src="@/assets/svgs/eye.svg" alt="">
				<img v-else src="@/assets/svgs/eyeCross.svg" alt="">
			</a>
		</div>
		<div class="register-form-list" v-if="registerStore.register_errors.password_confirmation.error">
			<div class="password-meter">
				<div class="password-meter-bar">
					<p class="password-meter-bar-inn-99"></p>
				</div>
			</div> 
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.password_confirmation.error == 'required'">
					{{ $t('account.register.error_required_confirmation') }}
				</span>
				<span v-if="registerStore.register_errors.password_confirmation.error == 'not_matching'">
					{{ $t('account.register.error_password_confirmation') }}
				</span>
				<span v-if="registerStore.register_errors.password_confirmation.error == 'username_password_same'">
					{{ $t('account.register.error_username_and_password_cant_be_came') }}
				</span>
			</p>
		</div>
	</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-99{
	width: 100%;
	background-color:#FF6B73;
	padding:1px;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-33{
	width: 33%;
	background-color:#FF6B73;
	padding:1px;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-66{
	width: 66%;
	background-color:#FF6B73;
	padding:1px;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-100{
	width: 100%;
	background-color: green;
	padding:1px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .pass-field .togglePassword {
	right: unset;
	left: 25px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input { 
	padding: 10px 15px 10px 15px;
}
</style>