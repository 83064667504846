<script setup>
import { ref } from 'vue';

// Props
const props = defineProps({
	image_url: String,
	dealer_name: String
});

// Reactive state to control the visibility of the image
const is_visible = ref(true);

// Method to handle the error event
function onError() {
	is_visible.value = false; // Hide the image on error
}

// Check if dealers avatar is a valid URL
const isTableImageUrlValid = (url) => {
	let url_valid = true;

	// Check if the URL is empty or null
	if (!url || url == '') {
		url_valid = false;
	} else {
		// Try to create a new URL object to verify URL format
		try {
			new URL(url);
		} catch (e) {
			url_valid = false; // URL is not valid
		}

		// Extract the filename and validate it
		if (url_valid) {
			const fileName = url.split('/').pop();
			if (!fileName || fileName.includes('..')) {
				url_valid = false; // Filename is not valid
			}

			// Regular expression to check for valid image file extensions
			const validImageRegex = /\.(jpeg|jpg|png)$/i;
			if (!validImageRegex.test(fileName)) {
				url_valid = false; // File extension is not valid
			}
		}
	}

	if (!url_valid) {

		return false;
	}

	return true;
}
</script>
<template>
	<div v-if="isTableImageUrlValid(props.image_url) && is_visible" class="table-dealer">
		<img class="table-dealer-img" :src="props.image_url" @error="onError" :alt="props.dealer_name" />
		<span class="table-dealer-name">{{ props.dealer_name }}</span>
	</div>
</template>
<style scoped>
.table-dealer {
	position: absolute;
	top: 0;
	left: 0;
	color: white;
	padding: 5px 10px;
	border-radius: 0 0 5px 5px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	z-index: 9999;
}

.table-dealer-img {
	height: 50px !important;
	width: auto !important;
	max-width: 40px !important;
	border-radius: 50%;
	display: block;
	object-fit: cover;
	overflow: hidden;
	object-position: left !important;
}

.table-dealer-name {
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border-radius: 0 0 5px 5px;
	font-size: 10px;
	display: block;
	width: auto;
}

</style>