<!-- eslint-disable -->
<script setup>
import { useFooterSectionStore } from '@/stores/footer_sections';

const footerSectionStore = useFooterSectionStore();

</script>
<template>
	<template v-if="footerSectionStore.getSocialSection && footerSectionStore.getSocialSection.items.length > 0">
		<div class="social-flex" >
			<a v-for="item in footerSectionStore.getSocialSection.items" :key="item.id" :href="item.redirect_url" target="_blank">
				<img :src="item.media_url" alt="Social Media Icon" width="30">
			</a>
		</div>
	</template>
</template>
<style scoped>
.social-flex {
	display: flex;
	gap: 10px;
	align-items: center;
	flex-wrap: wrap;
}
</style>