<script setup>
/* eslint-disable */
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router';
import { useDatesStore } from '@/stores/dates';
import { useFavouritesStore } from '@/stores/favourites';


const router = useRouter();
const datesStore = useDatesStore();
const favouritesStore = useFavouritesStore();

import commonSvgs from '@/assets/svgs/commonSvgs.json'
const svg_data = ref(commonSvgs);

const props = defineProps({
	primary_overview_market: {
		required: true,
	},
	secondary_overview_market: {
		required: true,
	},
	fixture: {
		type: Object,
		required: true,
	}
});

const toggleFavourite = () => {
	favouritesStore.toggleFixture(props.fixture.id);
}

const goToFixture = () => {
	router.push({ name: 'pre-sports-fixturebook', params: { sport_id: props.fixture.sport_id, region_id: props.fixture.region_id, competition_id: props.fixture.competition_id, fixture_id: props.fixture.id } } )
}

const primaryOverviewMarketOdds = computed(() => {
	if (!props.fixture.overview_markets.hasOwnProperty(props.primary_overview_market.slug)) return [];

	return props.fixture.overview_markets[props.primary_overview_market.slug];
});

const secondaryOverviewMarketOdds = computed(() => {
	if (!props.fixture.overview_markets.hasOwnProperty(props.secondary_overview_market.slug)) return [];

	return props.fixture.overview_markets[props.secondary_overview_market.slug];
});

</script>
<template>

	<div class="live-info-inner upcomimg_sport" @click="goToFixture">
		<div class="live-teams" >
			<div class="star-ico" @click="toggleFavourite">
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
					<path :style="{ fill: props.fixture.favourite ? '#63A246' : '' }" id="Icon_ionic-ios-star" :class="`fixture-${fixture?.id}`" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d" />
				</svg>
			</div>
			<div class="team-name">
				<div class="team-inner">
					<span>{{ datesStore.preFixtureBookDate(props.fixture.start_datetime) }}</span>
				</div>
				<div class="team-inner">
					<span>{{ datesStore.preFixtureBookTime(props.fixture.start_datetime) }}</span>
				</div>
			</div>

			<div class="name-name">
				<p>{{ props.fixture.participants ? fixture.participants[0] : '' }}</p>
			</div>
		</div>

		<div class="match-score_info" v-if="secondary_overview_market">
			<div class="total-bet">
				<div class="total-bet-main">
					<h6>{{ fixture.odds_count }} {{$t('account.affiliate.bets')}}</h6>
				</div>
			</div>
		</div>

		<!-- mobile-live icon start -->
		<div class="mobile-top-live">
			<div class="star-ico" @click="toggleFavourite">
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
					<path :style="{ fill: props.fixture.favourite ? '#63A246' : '' }" id="Icon_ionic-ios-star" :class="`fixture-${fixture?.id}`" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d" />
				</svg>
			</div>
			<div class="mobile-name">
				<p>{{ props.fixture.participants ? fixture.participants[0] : '' }}</p>
			</div>
		</div>
		<div class="mobile-bottom-live">
			<div class="info-inner" style="margin-left: 22px;">
				<div class="upcoming-date-mobile">
					<span>{{ datesStore.preFixtureBookDate(props.fixture.start_datetime) }}</span>
					<span>{{ datesStore.preFixtureBookTime(props.fixture.start_datetime) }}</span>
				</div>
			</div>
			<div @click="goToFixture" class="betting-total-mobile">
				<p><img src="/assets/icons/plus-bets.svg" alt="" />&nbsp;{{ props.fixture.odds_count }} {{$t('account.affiliate.bets')}}</p>
			</div>
		</div>
		<!-- mobile-live icon start -->

	</div>
</template>
<style scoped>
@media screen and (min-width: 768px) {
	.live-bet-dashboard.round-games-main .live-tab-info .live-info-outer .live-info-inner .live-teams .team-name .team-inner p {
		max-width: 20ch;
	}
	.live-bet-dashboard .live-tab-info .live-info-outer .live-info-inner .match-score_info {
		justify-content: flex-end;
	}
	.live-bet-dashboard.round-games-main .live-tab-info .live-info-outer .live-info-inner .live-teams {
		grid-template-columns: 18px 100px auto;
	}
	.live-bet-dashboard .live-tab-info .live-info-outer .live-info-inner .live-teams .team-name {
		justify-content: start;
	}
	.live-bet-dashboard.round-games-main .live-tab-info .live-info-outer .live-info-inner {
		display: grid;
		grid-template-columns: 5fr 1fr;
	}

	.live-bet-dashboard.round-games-main .live-tab-info .live-info-outer .live-info-inner.upcomimg_sport .live-teams .team-name .team-inner {
		display: block;
	}

	.name-name p {
		color: #fff;
		white-space: nowrap;
		font-weight: 100;
		max-width: -moz-fit-content;
		max-width: fit-content;
		font-size: 14px;
	}

	.mobile-top-live {
		display: none;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.live-bet-dashboard.round-games-main .live-tab-info .live-info-outer .live-info-inner .live-teams {
		display: none;
	}

	.name-name {
		display: none;
	}

	.mobile-name {
		width: 100%;
	}

	.mobile-name p{
		font-size: 12px;
		color: #ffffff;
		font-weight: 100;
		text-overflow: ellipsis;
		overflow: hidden;
		text-wrap: unset;
		width: 300px;
	}

	.live-bet-dashboard.round-games-main .live-tab-info .live-info-outer .live-info-inner .mobile-bottom-live {
		display: grid;
		grid-template-columns: 22px auto 1fr auto;
		grid-column-start: 2;
		grid-column: span 3;
		justify-content: center;
		align-items: center;
		justify-items: center;
	}

	.live-bet-dashboard.round-game-outer .live-tab-info .live-info-outer .live-info-inner .live-teams {
		display: none;
	}

	.mobile-bottom-live {
		display: flex !important;
		justify-content: space-between !important;
	}

	.mobile-top-live {
		display: grid;
		grid-template-columns: 22px auto;
	}

	.live-bet-dashboard.round-games-main .live-tab-info .live-info-outer .live-info-inner {
		display: block;
	}
}

</style>