<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { useMarketsStore } from '@/stores/markets';
import { usePreSportsStore } from '@/stores/pre_sports';

import LeftSidebarFavouriteFixture from './LeftSidebarFavouriteFixture.vue';

const marketsStore = useMarketsStore();
const preSportsStore = usePreSportsStore();

const props = defineProps({
	competition: {
		required: true,
	},
});

const primary_overview_market = ref(marketsStore.sportOverviewMarkets(props.competition[0].sport_id)[0])

onMounted(() => {
	primary_overview_market.value = marketsStore.sportOverviewMarkets(props.competition[0].sport_id)[0]
});

</script>
<template>
<div class="accordion-main-content">
	<div class="accordion-main-header">
		<div class="league-title">
			<div class="sports-icon">
				<img :src="preSportsStore.preSportWhiteIcon(props.competition[0].sport_id)" alt="icon">
			</div>
			<h3>{{ props.competition[0].competition_region_name }}</h3>
		</div>
		<div class="total-leagues">
			<p>({{ props.competition.length }})</p>
		</div>
	</div>
	<div class="inner-accordion">
		<div class="inner-accordion-content">
			<LeftSidebarFavouriteFixture v-for="fixture in competition" :key="fixture.id" :fixture="fixture" :primary_overview_market="primary_overview_market"/>
		</div>
	</div>
</div>
</template>