<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { useSportPopularStore } from '@/stores/sport_popular';
import FeaturedFixture from './FeaturedFixture.vue';
import FeaturedMissions from './FeaturedMissions.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { useMissionsStore } from '@/stores/missions';
import '@splidejs/vue-splide/css';
import { openModal } from '@kolirt/vue-modal'
import SportsbookMissions from '@/components/modals/missions/SportsbookMissions.vue';

const sportPopularStore = useSportPopularStore();
const missionsStore = useMissionsStore();
const direction = ref(document.dir);
const options = ref({
	direction: direction,
	rewind: false,
	pagination: false,
	type: 'slide',
	perPage: 3,
	perMove: 1,
	arrows: true,
	autoWidth: true,
	breakpoints: {
		499: {
			perPage: 1,
			pagination: true,
		},
		767: {
			perPage: 2,
			pagination: true,
		},
		1400: {
			perPage: 2,
			pagination: false,
		},
		2560: {
			perPage: 3,
		}
	},
});


const openMissions = () => {
	openModal(SportsbookMissions).then((data) => {}).catch(() =>{});
}

</script>
<template>
	<div class="league-silder league-slider-desktop">
		<div id="league_Slider" class="league-slider-main">
			<div class="wrapper">
				<div id="league_slides" class="league_slides-inner">
					<!-- slider start -->
					<Splide :options="options">
						<SplideSlide v-if="missionsStore.sportsbook_missions.length > 0" @click="openMissions">
							<FeaturedMissions/>
						</SplideSlide>
						<SplideSlide v-for="(fixture, index) in sportPopularStore.getTopFeaturedFixtures" :key="index">
							<FeaturedFixture :fixture="fixture"/>
						</SplideSlide>
					</Splide>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.league-slider-main .wrapper .league_slides-inner :deep(.splide__arrow:disabled) {
	display: none !important;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
	rotate: 180deg;
	right: -10px;
	top: 35%;
	display: flex;
	left: unset;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
	rotate: 180deg;
	left: -10px;
	top: 35%;
	display: flex;
	right: unset;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
	.league-silder {
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
		top: 30%;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px;
		top: 30%;
	}
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px
	}
}

@media screen and (min-width: 320px) and (max-width: 425px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 93vw;
	}
}

@media screen and (min-width: 426px) and (max-width: 499px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 94vw;
	}
}

@media screen and (min-width: 500px) and (max-width: 530px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 46vw;
	}
}

@media screen and (min-width: 531px) and (max-width: 708px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 47vw;
	}
}

@media screen and (min-width: 709px) and (max-width: 766px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 47.5vw;
	}
}

@media screen and (min-width: 767px) and (max-width: 991px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 48vw;
	}
}

@media screen and (min-width: 992px) and (max-width: 1018px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 37.5vw;
	}
}

@media screen and (min-width: 1019px) and (max-width: 1024px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 38vw;
	}
}

@media screen and (min-width: 1025px) and (max-width: 1041px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 35.5vw;
	}
}

@media screen and (min-width: 1042px) and (max-width: 1075px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 36vw;
	}
}

@media screen and (min-width: 1076px) and (max-width: 1119px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 36.5vw;
	}
}

@media screen and (min-width: 1120px) and (max-width: 1163px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 37vw;
	}
}

@media screen and (min-width: 1164px) and (max-width: 1210px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 37.5vw;
	}
}

@media screen and (min-width: 1211px) and (max-width: 1260px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 38vw;
	}
}

@media screen and (min-width: 1261px) and (max-width: 1270px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 38vw;
	}
}

@media screen and (min-width: 1271px) and (max-width: 1280px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 38.5vw;
	}
}

@media screen and (min-width: 1281px) and (max-width: 1281px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 28vw;
	}
}

@media screen and (min-width: 1282px) and (max-width: 1366px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 27vw;
	}
}

@media screen and (min-width: 1367px) and (max-width: 1400px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 27.5vw;
	}
}

@media screen and (min-width: 1401px) and (max-width: 1420px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 18.4vw;
	}
}

@media screen and (min-width: 1421px) and (max-width: 1430px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 18.4vw;
	}
}

@media screen and (min-width: 1431px) and (max-width: 1460px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 18.8vw;
	}
}

@media screen and (min-width: 1461px) and (max-width: 1500px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 19.2vw;
	}
}

@media screen and (min-width: 1501px) and (max-width: 1540px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 19.5vw;
	}
}

@media screen and (min-width: 1541px) and (max-width: 1570px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 19.8vw;
	}
}

@media screen and (min-width: 1571px) and (max-width: 1599px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 20.1vw;
	}
}

@media screen and (min-width: 1600px) and (max-width: 1620px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 18.8vw;
	}
}

@media screen and (min-width: 1621px) and (max-width: 1645px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 19vw;
	}
}

@media screen and (min-width: 1646px) and (max-width: 1679px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 19.3vw;
	}
}

@media screen and (min-width: 1680px) and (max-width: 1717px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 19.6vw;
	}
}

@media screen and (min-width: 1718px) and (max-width: 1755px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 19.9vw;
	}
}

@media screen and (min-width: 1756px) and (max-width: 1795px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 20.2vw;
	}
}

@media screen and (min-width: 1796) and (max-width: 1837px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 20.5vw;
	}
}

@media screen and (min-width: 1838px) and (max-width: 1880px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 20.8vw;
	}
}

@media screen and (min-width: 1881px) and (max-width: 1912px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 21vw;
	}
}

@media screen and (min-width: 1913px) and (max-width: 1930px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 21.2vw;
	}
}

@media screen and (min-width: 1931px) and (max-width: 1940px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 21vw;
	}
}

@media screen and (min-width: 1941px) and (max-width: 1999px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 21vw;
	}
}

@media screen and (min-width: 2000px) and (max-width: 2560px) {
	.league-slider-main .wrapper .league-slide-item {
		width: 15.8vw;
	}
}
</style>