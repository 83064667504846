<script setup>
/* eslint-disable */
import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
import { useVirtualCourtStore } from '@/stores/virtual_court';
import { useInitializeStore } from '@/stores/initialize';
import { BetSlip } from '@/stores/BetSlip';

const props = defineProps({
	mobile: {
		type: Boolean,
	}
});

const virtualCourtStore = useVirtualCourtStore();
const initializeStore = useInitializeStore();
const mounted = ref(false);
const betSlipStore = BetSlip();

const getSourceLink = () => {
	if (initializeStore.screenWidth < 992) return process.env.VUE_APP_VIRTUAL_COURT_URL + 'vl/mobile/frame';

	return process.env.VUE_APP_VIRTUAL_COURT_URL + 'vl/frame';
}

onBeforeUnmount( () => {
	virtualCourtStore.emptyVirtualCourt();
});

onMounted(() => {
	window.addEventListener('message', (message) => {
		if (typeof message.data == 'object') return;
		mounted.value = true;

		let virtual_live_element = document.getElementById('VirtualLiveDesktop');
		if (message.data.startsWith('out::size::')) {
			let message_parts = message.data.split('::');
			virtual_live_element.style.width = '100%';
			virtual_live_element.style.height = message_parts[3] + 'px';

			if (message_parts[3] == 0) {
				if (virtualCourtStore.fixture_in_queue != null) {
					virtualCourtStore.selectFixture(virtualCourtStore.fixture_in_queue);
				}
			}
		}
	}, false);
})

watch(() => virtualCourtStore.fixture_in_queue, (newFixture, oldFixture) => {
	// if oldFixture == null it means that virtual live is still initializing
	if (oldFixture == null) return;

	if (mounted.value) virtualCourtStore.selectFixture(newFixture);
})

</script>

<template>
	<div style="margin-bottom: 15px;">
		<iframe id="VirtualLiveDesktop" class="svm-StickyVideoManager_Video svm-StickyVideoManager_Video-sticky" :class="['virtual-live-frame', betSlipStore.betSlipModel=='' ? '' : 'pointer-events-none'] " style="width: 0px; height: 0px; border: unset" :src="getSourceLink()"></iframe>
	</div>
</template>

<style scoped>
.pointer-events-none {
	pointer-events: none;
}
</style>