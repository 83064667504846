import { defineStore } from 'pinia';
import _ from "lodash";

export const useCountriesStore = defineStore('countries', {
	state: () => ({
		countries: null
	}),
	getters: {
		getCountries(state){
			return _.sortBy(state.countries, 'name');
		},
		findCountryById: (state) => (id) => {
			return _.find(state.countries, {'id': Number(id) });
		}
	},
	actions: {
		setCountries(data) {
			this.countries = data;
		}
	},
})