
<script setup>
/* eslint-disable */
import { onMounted, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useFixtureStore } from '@/stores/fixture';
import LiveFixtureDoubleMarket from './LiveFixtureDoubleMarket.vue';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
const svg_data = ref(commonSvgs);

const router = useRouter();
const liveSportsStore = useLiveSportsStore();
const liveSportOverviewStore = useLiveSportOverview();
const fixtureStore = useFixtureStore();

const props = defineProps({
	primary_overview_market: {
		type: Object,
		required: true,
	},
	secondary_overview_market: {
		type: Object,
		required: true,
	},
});


</script>
<template>
	<div id="live" class="tab-content highlight-live">
		<div class="live-info-outer-main" v-for="(competition, index) in liveSportOverviewStore.highlightFixtures" :key="index">

			<div class="live-info-outer">
				<div class="live-info-inner">
					<div class="live-info-team">
						<div class="live-match">
							<div class="team-flag">
								<img :src="'/assets' + competition[0].region_icon_url" alt="">
							</div>
							<div class="live-match-name">
								<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }}</h3>
							</div>
						</div>
					</div>

					<div class="full-time-top top-title-dash">
						<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
							<span v-if="odd != ''">{{ odd }}</span>
						</template>
					</div>

					<div class="match-goal-top top-title-dash">
						<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
							<span v-if="odd != ''">{{ odd }}</span>
						</template>
						<span class="bet-column-empty"></span>

					</div>
				</div>

				<LiveFixtureDoubleMarket v-for="(fixture, index) in competition"
					:key="index"
					:primary_overview_market="primary_overview_market"
					:secondary_overview_market="secondary_overview_market"
					:fixture="fixture"
				/>
			</div>
		</div>
	</div>
</template>