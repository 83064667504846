 /* eslint-disable */
import { defineStore } from 'pinia';
import { useFavouritesStore } from './favourites';
import _ from 'lodash';

export const useCouponsStore = defineStore('coupons', {
	state: () => ({
		competition_ids: [],
		all_expanded: true,
		next_hour_fixtures: null,
		fixtures_loading: false,
	}),
	getters: {
		selectedCompetitionIds(state) {
			return state.competition_ids;
		},
		isRegionChecked: (state) => (region) => {
			for (const competition_key in region.competitions) {
				const competition = region.competitions[competition_key];
				if (!state.competition_ids.includes(competition.id)) return false;
			}

			return true;
		},
		isCompetitionChecked: (state, getters) => (competition_id) => {
			if (state.competition_ids.includes(competition_id)) return true;

			return false;
		},
		nextHourFixturesPerHour(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.next_hour_fixtures);
			for (const key in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, key)) {
					fixtures[key]['competition_region_name'] = fixtures[key].region_name + ' - ' + fixtures[key].competition_name;
					fixtures[key]['favourite'] = false;

					if (favouritesStore.favouriteFixtureIds.includes(fixtures[key].id)) {
						fixtures[key]['favourite'] = true;
					}
				}
			}

			return _.orderBy(fixtures, ['start_datetime', 'asc']);
		},
		nextHourFixturesPerCompetition(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.next_hour_fixtures);

			for (const key in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, key)) {
					fixtures[key]['competition_region_name'] = fixtures[key].region_name + ' - ' + fixtures[key].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(fixtures[key].id)) {
						fixtures[key]['favourite'] = true;
					}
				}
			}

			fixtures = _.chain(fixtures).orderBy(['competition_weight', 'competition_region_name'], ['desc', 'asc']).groupBy('competition_region_name').value();

			return fixtures;
		}
	},
	actions: {
		fetchNextHourFixtures(payload) {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/sport/' + payload.sport_id + '/fixtures-upcoming/' + payload.hours, { withCredentials: false }).then((response) => {
				this.next_hour_fixtures = response.data;
				this.fixtures_loading = false;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error)
			});
		},
		toggleCheckboxCompetition(competition_id) {
			if (this.competition_ids.includes(competition_id)) {
				this.competition_ids = _.remove(this.competition_ids, function(array_competition_id) {
					return array_competition_id != competition_id
				});

				return;
			}

			this.competition_ids.push(competition_id);
		},
		emptyNextHourFixtures() {
			this.next_hour_fixtures = null;
		},
		setFixturesLoading(data) {
			this.fixtures_loading = data;
		},
		toggleAllExpanded() {
			this.all_expanded = !this.all_expanded;
		}
	}
})