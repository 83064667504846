<script setup>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { useLiveCasinoStore } from '@/stores/live_casino'
import { ref, watch } from 'vue';
import _ from 'lodash';

// Initialize Store
const liveCasinoStore = useLiveCasinoStore();

// Initialize variables
const direction = ref(document.dir);

const options = ref({
	direction: direction,
	rewind: false,
	pagination: false,
	type:false,
	perMove: 1,
	autoWidth: true,
	breakpoints: {
		375: { perPage: 2 },
		550: { perPage: 2 },
		768: { perPage: 6 },
		1024: { perPage: 6 },
		1680: { perPage: 8 },
		2560: { perPage: 11 },
	},
});

const selectCategory = (category) => {
	liveCasinoStore.selectCategory(category);
}

const handleProviderSelectChange = (event) => {
	let provider = _.find(liveCasinoStore.getProviders, { id: parseInt(event.target.value, 10) });

	liveCasinoStore.selectProvider(provider);
}

watch(() => liveCasinoStore.getFavouriteTables, (newValue, oldValue) => {
	// Go to all categories if favourites are empty
	if (newValue != oldValue && Object.keys(oldValue).length > 0 && Object.keys(newValue).length == 0 && liveCasinoStore.getSelectedCategoryId ==  'favourites') {
		liveCasinoStore.selectCategory(null);
	}
});
</script>

<template>
	<div class="container-lg casino-container-lg">
		<div class="casino-top-filters">
			<div class="casino-game_tab-outer live-casino-game-tab">
				<div class="casino-games-tab">
					<Splide :options="options">
						<div @click="selectCategory(null)"
							:class="liveCasinoStore.getSelectedCategoryId == null ? 'active' : ''"
							class="game-filter all-filter">
							<div class="icon-box">
								<svg xmlns="http://www.w3.org/2000/svg" width="16.091" height="18.503"
									viewBox="0 0 16.091 18.503">
									<g id="Group_489" data-name="Group 489" transform="translate(0.25 0.25)">
										<g id="dice" transform="translate(0)">
											<path id="Path_529" data-name="Path 529"
												d="M55.081,15l-7.8,4.5v9l7.8,4.5,7.8-4.5v-9Z"
												transform="translate(-47.286 -15)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<path id="Path_530" data-name="Path 530"
												d="M62.877,19.5l-7.8,4.5-7.8-4.5,7.8-4.5Z"
												transform="translate(-47.286 -15)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_11" data-name="Line 11" y2="9.001"
												transform="translate(7.795 9.001)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_12" data-name="Line 12" x2="0.6"
												transform="translate(7.495 4.501)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_13" data-name="Line 13" y2="0.6"
												transform="translate(4.995 12.802)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_14" data-name="Line 14" y2="0.6"
												transform="translate(2.795 9.401)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_15" data-name="Line 15" y2="0.6"
												transform="translate(13.346 8.551)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_16" data-name="Line 16" y2="0.6"
												transform="translate(10.046 13.652)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_17" data-name="Line 17" y2="0.6"
												transform="translate(11.696 11.102)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
										</g>
									</g>
								</svg>
							</div>
							<p class="nowrap">{{ $t('general.all_games') }}</p>
						</div>
						<div v-if="Object.keys(liveCasinoStore.getFavouriteTables).length > 0 && Object.keys(liveCasinoStore.getTables).length > 0" @click="selectCategory('favourites')" :class="liveCasinoStore.getSelectedCategoryId == 'favourites' ? 'active' : ''" class="game-filter all-filter">
							<div class="icon-box">
								<svg xmlns="http://www.w3.org/2000/svg"
									width="12"
									height="11.143"
									viewBox="0 0 12 11.143">
									<path class="favourite-svg"
										id="Icon_ionic-ios-star"
										data-name="Icon ionic-ios-star"
										d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z"
										transform="translate(-1.125 -1.688)"
										fill="#fff"
										opacity="0.403"></path>
								</svg>

							</div>
							<p class="nowrap">{{ $t('general.favourites') }}</p>
						</div>

						<SplideSlide v-for="category in liveCasinoStore.getCategories" :key="category">
							<div @click="selectCategory(category)" class="game-filter"
								:class="liveCasinoStore.getSelectedCategoryId == category.id ? 'active' : ''">
								<div class="icon-box">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
										width="18" height="18" viewBox="0 0 18 18">
										<defs>
											<clipPath id="clip-path">
												<path id="path2055" d="M0-682.665H18v18H0Z"
													transform="translate(0 682.665)" />
											</clipPath>
										</defs>
										<g id="Group_526" data-name="Group 526" transform="translate(-465 -583)">
											<g id="g2049" transform="translate(465 583)">
												<g id="g2051">
													<g id="g2053" clip-path="url(#clip-path)">
														<g id="g2059" transform="translate(0.527 0.527)">
															<path id="path2061"
																d="M0-312.86a8.5,8.5,0,0,1,8.473-8.473,8.5,8.5,0,0,1,8.473,8.473,8.5,8.5,0,0,1-8.473,8.473A8.5,8.5,0,0,1,0-312.86Z"
																transform="translate(0 321.332)" fill="none"
																stroke="#fff" stroke-miterlimit="10"
																stroke-width="0.5" />
														</g>
														<g id="g2063" transform="translate(5.489 5.309)">
															<path id="path2065"
																d="M-129.637,0l1.24,1.985,2.271.566-1.5,1.793.164,2.335-2.17-.877-2.17.877.164-2.335-1.5-1.793,2.271-.566Z"
																transform="translate(133.148)" fill="none" stroke="#fff"
																stroke-miterlimit="10" stroke-width="0.5" />
														</g>
														<g id="g2067" transform="translate(2.672 2.672)">
															<path id="path2069"
																d="M0-233.671A6.328,6.328,0,0,1,6.328-240a6.328,6.328,0,0,1,6.328,6.328,6.328,6.328,0,0,1-6.328,6.328A6.328,6.328,0,0,1,0-233.671Z"
																transform="translate(0 239.999)" fill="none"
																stroke="#fff" stroke-miterlimit="10"
																stroke-width="0.5" />
														</g>
													</g>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<p class="nowrap">{{ category.name }}</p>
							</div>
						</SplideSlide>
					</Splide>
				</div>
			</div>

			<div class="provider-option">
				<div class="provider-form-field">
					<select @change="handleProviderSelectChange" class="form-input">
						<option class="form-input" value="" selected>{{ $t('general.select_your_provider') }}</option>
						<template v-for="(provider) in liveCasinoStore.getProviders" :key="provider">
							<option class="form-input" :value="provider.id" :selected="provider.id == liveCasinoStore.getSelectedProviderId">{{ provider.name }}</option>
						</template>
					</select>

				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
li {
	margin-right: 5px;
}

.nowrap {
	white-space: nowrap;
}

.casino-game-area .casino-top-filters .casino-game_tab-outer.live-casino-game-tab {
	width: 100% !important;
	padding-left: 0 !important;
}

@media (min-width: 992px) {
	.casino-game-area .casino-top-filters {
		gap: 20px !important;
	}
}

html[dir=rtl] .provider-option .provider-form-field select {
	background-position: 3% !important;
}
</style>