<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from "vue"
import { BetSlip } from '@/stores/BetSlip';
import { useOddsStore } from '@/stores/odds';
import { useFixtureStore } from '@/stores/fixture';
import menuIcons from '@/assets/svgs/NotificationMenu.json'

const oddsStore = useOddsStore();
const betslipStore = BetSlip();
const fixtureStore = useFixtureStore();

const menu_icons = ref(menuIcons);

const props = defineProps({
	odd: {
		type: Object,
		required: true,
	},
});

const participant_info = ref({});

onMounted(() => {
	participant_info.value = _.find(fixtureStore.fixtureBook.participants_info, { trapNumber: String(props.odd.number) });
});

const addToBetslip = () => {
	if (!props.odd || props.odd.status == 'suspended') return;

	betslipStore.toggleToBetslip({ odd_uuid: props.odd.uuid, fixture_id: fixtureStore.fixtureBook.id })
}

const historyOdds = computed(() => {
	if (!props.odd.history) return;
	let last_three_odds = props.odd.history.slice(-3);

	return last_three_odds.join(' > ');
});

</script>
<template>
	<div class="racing-detail-tab-items racing-detail-tab-main">
		<div class="tabing_racing-left">
			<div class="star-icon">
				<!--  -->
			</div>

			<div class="race_number">
				<p>{{ odd.number }} </p>
				<!-- <div class="racer_jersey">
					<img src="../../assets/images/racing-jearsy.png" alt="">
				</div> -->
			</div>

			<div class="racer_info">
				<p v-if="participant_info && participant_info.horseName">{{ participant_info.horseName }} <span></span></p>
				<p v-else> {{ odd.name }}</p>
			</div>

			<div class="racer_trainer">
				<p v-if="participant_info && participant_info.jockeyName"><span>{{ $t('racing.jockey_short') }}:</span>{{ participant_info.jockeyName }}</p>
				<p v-if="participant_info && participant_info.trainerName"><span>{{ $t('racing.trainer_short') }}:</span>{{ participant_info.trainerName }}</p>
			</div>

			<div class="racer_body-info">
				<p v-if="participant_info && participant_info.weight">{{ $t('racing.weight') }}:<span>{{ participant_info.weight }}</span></p>
				<p v-if="participant_info && participant_info.age">{{ $t('racing.age') }}:<span>{{ participant_info.age }}</span></p>
			</div>

		</div>

		<div class="tabing_racing-right">
			<div class="previous_odds">
				<p><span>{{ $t('racing.previous_odds') }}:</span>{{ historyOdds }}</p>
			</div>

			<template v-if="odd && !oddsStore.isOddSuspended(odd)">
				<div @click="addToBetslip" class="odds-sec odd-selector" :class="{'active': odd && oddsStore.isOddInBetslip(odd.uuid) }">
					<p>{{ odd.sp ? odd.placeholder : oddsStore.oddsFormat(odd.price) }}</p>
				</div>
			</template>
			<template v-else>
				<div class="odds-sec odd-selector">
					<p v-html="menu_icons.lock"></p>
				</div>
			</template>

			<!-- <div class="sp-sec odd-selector">
				<p>SP</p>
			</div> -->
		</div>
	</div>
</template>
<style scoped>
.active{
	color: #ffff !important;
}

.racing-main .sports-main-outer.live_Sports-main-outer.racing-outer-main .racing-outer-left .race-main-area .racing-area-outer .racing-odds-main.racing-center-detail .racing-outer-odds .racing-tab_area .racing-tab-content .tabing_racing-left {
	min-height: 44px;
}
</style>