<script setup>
/* eslint-disable */
import { watch, onMounted, ref, computed } from "vue";
import { usePreSportsStore } from '@/stores/pre_sports';
import { useRegionsStore } from '@/stores/regions';
import { useRouter, useRoute } from 'vue-router';
import EsportRegion from './EsportRegion.vue';
const preSportsStore = usePreSportsStore();
const regionsStore = useRegionsStore();

const route = useRoute();
const router = useRouter();

const regions_opened = ref(true);

const regions_popularity = ref(true);

const sortRegions = () => {
	regions_popularity.value = false;
	regionsStore.setRegionsOrder();
}

const sortRegionsByPopularity = () => {
	regions_popularity.value = true;
	regionsStore.setRegionsPopularityOrder();
}

onMounted(() => {
	if (router.currentRoute.value.params.region_id && router.currentRoute.value.params.region_id != '') {
		regionsStore.setSelectedRegionId(router.currentRoute.value.params.region_id);
	} else {
		preSportsStore.fetchPreSports().then((response) => {
			regionsStore.fetchRegions(preSportsStore.esports[0].id);
		});
	}
})

const regionCompetitions = computed(() => {
	if (regions_popularity.value == true) return regionsStore.regionsCompetitionsPopularity;
	else return regionsStore.regionsCompetitions;
});

watch(() => preSportsStore.selected_esport_id, (newSportId) => {
	if (newSportId > 0) {
		// Fetch regions of this sport
		regionsStore.fetchRegions(newSportId);
	}
})

watch(route, (to) => {
	if (!to.params.hasOwnProperty('region_id')) return;
	if (to.params.region_id == regionsStore.setSelectedRegionId) return;

	regionsStore.setSelectedRegionId(to.params.region_id);
});

</script>
<template>
	<div class="accordion-item regions-tab" :class="regions_opened ? 'active' : ''">
		<div class="accordion-header" @click="regions_opened = !regions_opened">{{$t('general.regions')}}
			<div class="acoordion-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
					<g id="Group_776" data-name="Group 776" transform="translate(-272 -6)">
						<g id="minimize" transform="translate(272 10)">
							<rect id="Rectangle_408" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
						<g id="minimize-2" class="hide-i" data-name="minimize" transform="translate(277 6) rotate(90)">
							<rect id="Rectangle_408-2" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
					</g>
				</svg>
			</div>
		</div>
		<div class="accordion-content" v-if="regions_opened">
			<!-- sort -->
			<div class="accordion-filter" id="filter-acc">

				<div class="filter-right filters active" @click="sortRegionsByPopularity">
					{{ $t('general.sort_by_popularity')}}
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="4" height="7.5" viewBox="0 0 4 7.5">
							<g id="Group_39" data-name="Group 39" transform="translate(-134 -563.5)">
							<path id="Polygon_1" data-name="Polygon 1" d="M2,0,4,3H0Z" transform="translate(134 563.5)" fill="#fff"/>
							<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff"/>
							</g>
						</svg>
					</span>
				</div>

				<div class="filter-right filters active" @click="sortRegions">
					{{ regionsStore.regions_order == 'desc' ? $t('general.sort_z_a') : $t('general.sort_a_z')}}
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="4" height="7.5" viewBox="0 0 4 7.5">
							<g id="Group_39" data-name="Group 39" transform="translate(-134 -563.5)">
							<path id="Polygon_1" data-name="Polygon 1" d="M2,0,4,3H0Z" transform="translate(134 563.5)" fill="#fff"/>
							<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff"/>
							</g>
						</svg>
					</span>
				</div>
			</div>
			<!-- sort -->

			<div class="country-info">
				<EsportRegion v-for="region in regionCompetitions" :key="region.id" :region="region"/>
			</div>
		</div>

	</div>
</template>
<style scoped>
.sports-main .sports-main-outer .sports-left-sidebar .accordion-outer .accordion-item .accordion-content .accordion-filter {
	grid-template-columns: 1fr 1fr;
}
</style>