<!-- eslint-disable -->
<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { closeModal, confirmModal } from '@kolirt/vue-modal'

const { t } = useI18n();

const share_url_text = ref(t('general.copy_url'));
const share_id_text = ref(t('general.copy_code'));

const loading = ref(false);


const {share_url,share_id} = defineProps({
	share_url: {
		type: String,
		required: true
	},
	share_id: {
		type: String,
		required: true
	}
});

const copyToClipboard = () => {
	navigator.clipboard.writeText(share_url);
	share_url_text.value = t('general.copied')

	setTimeout(() => {
		share_url_text.value = t('general.copy_url');
	}, 2000);
}

const copyIdToClipboard = () => {
	navigator.clipboard.writeText(share_id);
	share_id_text.value = t('general.copied')

	setTimeout(() => {
		share_id_text.value = t('general.copy_code');
	}, 2000);
}


</script>
<template name="ShareBetModal" class="ShareBetModal">
	<section class="share-modal-main">
		<div class="share-modal-inner">
			<!-- <span class="form-cross" >
				<a @click="closeModal" style="cursor: pointer;">
					<object v-html="svg_data.form_cross"></object>
				</a>
			</span> -->
			<div class="title">
				<h5>Share Bet</h5>
				<h6>Share this code with your friends and you can bet on the same ticket</h6>
			</div>
			<!-- barcode start -->
			<!-- <div class="bar-code">
				<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 512 512"><path d="M0 448V64h18v384H0zm26.857-.273V64H36v383.727h-9.143zm27.143 0V64h8.857v383.727H54zm44.857 0V64h8.857v383.727h-8.857zm36 0V64h17.714v383.727h-17.714zm44.857 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm35.715 0V64h18v383.727h-18zm44.857 0V64h18v383.727h-18zm35.999 0V64h18.001v383.727h-18.001zm36.001 0V64h18.001v383.727h-18.001zm26.857 0V64h18v383.727h-18zm45.143 0V64h26.857v383.727h-26.857zm35.714 0V64h9.143v383.727H476zm18 .273V64h18v384h-18z"/></svg>
			</div> -->
			<!-- barcode end -->

			<!-- bet share code start -->
			<div class="share-bet-form">
				<div class="share-field">
					<div class="fieldset">
						<p>{{share_id}}</p>
					</div>
					<button v-if="share_id" class="btn-small btn-secondary ShareBtns" @click="copyIdToClipboard()">{{ share_id_text }}</button>
				</div>

				<div class="share-field">
					<div class="fieldset">
						<p>{{share_url}}</p>
					</div>
					<button v-if="share_url" class="btn-small btn-secondary ShareBtns" @click="copyToClipboard()">{{ share_url_text }}</button>
				</div>
			</div>
			<!-- bet share code end -->

			<!-- share bet social start -->
			<!-- <div class="share-social">
				<h5>Share On Social</h5>
				<div class="share-socal-inner">
					<div class="share-socal-item facebook">
						<span v-html="commonSvgs.Facebook_ico" class="ico-white"></span>
						<span v-html="commonSvgs.Facebook_Green_ico" class="ico-green"></span>
					</div>

					<div class="share-socal-item twitter">
						<span v-html="commonSvgs.Insta_ico" class="ico-white"></span>
						<span v-html="commonSvgs.Insta_Green_ico" class="ico-green"></span>
					</div>

				</div>
			</div> -->
			<!-- share bet social end -->
			<div class="share-bet-btn">
				<button @click="closeModal"  class="share-btn btn-small btn-secondary" type="button">Close</button>
			</div>
		</div>
	</section>

</template>
