import { defineStore } from 'pinia';
import { useCustomerStore } from './customer';
import _ from 'lodash';

export const useLiveCasinoStore = defineStore('liveCasinoStore', {
	state: () => ({
		tables: {},
		live_tables: {},
		providers: {},
		categories: {},
		last_played_tables: {},
		favourites: {},
		search_term: null,
		search_is_provider: false,
		search_is_category: false,
		title: null,
		selected_category: null,
		selected_provider: null,
		tables_loading: false,
		show_favorites: false,
		show_more_category: [],
		more_tables_fetched: {},
		total_tables_per_category: {},
		all_tables_load_more: 10
	}),
	getters: {
		getProviders(state) {
			if (!state.providers) return [];

			return state.providers;
		},
		getCategories(state) {
			if (!state.categories) return [];

			return state.categories;
		},
		getPageTitle(state) {
			if (!state.title) return null;

			return state.title;
		},
		getSelectedCategoryId(state) {
			if (state.selected_category === 'favourites') return 'favourites';

			if (state.selected_category && state.selected_category.id) {
				let category_exists = _.find(state.categories, { 'id': state.selected_category.id });

				if (!category_exists) {
					this.selected_category = null;
					this.fetchLiveCasino();
					return null;
				}
			}

			return state.selected_category ? state.selected_category.id : null;
		},
		getSelectedProviderId(state) {
			return state.selected_provider ? state.selected_provider.id : null;
		},
		getTables(state) {
			if (!state.tables) return {};

			let tables = _.cloneDeep(state.tables);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.tables.length > 0) ? useCustomerStore().customer.favourites.tables : null;

			tables = _.map(tables, table => {

				let favourite_record = _.find(favourites, { 'table_id': String(table.id) });
				return {
					...table,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_tables = _.filter(tables, { 'favourite': true });
			favourite_tables = _.orderBy(favourite_tables, ['favourite_timestamp'], ['desc']);

			let not_favourite_tables = _.filter(tables, { 'favourite': false });

			tables = [...favourite_tables, ...not_favourite_tables];

			let result = {};
			let searchTerm = state.search_term ? state.search_term.toString().toLowerCase() : null;

			if (!searchTerm) {
				tables = _.sortBy(tables, 'category');
				tables = _.groupBy(tables, 'category');

				// Ensure the "Lobby" category is on top
				if (tables['Lobby']) {
					const lobbyTables = { Lobby: tables['Lobby'] };
					tables = { ...lobbyTables, ..._.omit(tables, 'Lobby') };
				}

				_.forIn(tables, (value, key) => {
					const index = this.show_more_category.indexOf(key);
					if (index === -1) {
						tables[key] = value.slice(0, 10);
					}
				});

				// Get the first 10 brands with their games
				tables = _.fromPairs(_.take(_.toPairs(tables), state.all_tables_load_more));

				return tables;
			}

			result = _.filter(tables, table => {
				return searchTerm && table.category_brand_table_name.toLowerCase().includes(searchTerm);
			});

			result = _.sortBy(result, 'category');
			result = _.groupBy(result, 'category');

			_.forIn(result, (value, key) => {
				const index = this.show_more_category.indexOf(key);
				if (index === -1) {
					result[key] = value.slice(0, 10);
				}
			});

			// Get the first 10 brands with their games
			result = _.fromPairs(_.take(_.toPairs(result), state.all_tables_load_more));

			return result;
		},
		liveCasinoTablesCount() {
			return Object.keys(this.tables).length;

		},
		getLiveTables(state) {
			if (!state.live_tables) return {};

			return state.live_tables;
		},
		getFilterOptions(state) {
			return {
				'is_category': state.search_is_category,
				'is_provider': state.search_is_provider,
			}
		},
		getFavouriteTables(state) {
			if (!state.favourites) return {};

			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.tables.length > 0) ? useCustomerStore().customer.favourites.tables : null;
			let tables = _.map(state.favourites, table => {
				let favourite_record = _.find(favourites, {'table_id': String(table.id)});

				return {
					...table,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_tables = _.filter(tables, {'favourite': true});
			favourite_tables = _.orderBy(favourite_tables, ['favourite_timestamp'], ['desc']);

			favourite_tables = _.sortBy(favourite_tables, 'category');
			favourite_tables = _.groupBy(favourite_tables, 'category');

			_.forIn(favourite_tables, (value, key) => {
				const index = this.show_more_category.indexOf(key);
				if (index === -1) {
					favourite_tables[key] = value.slice(0, 10);
				}
			});

			// Get the first 10 brands with their games
			favourite_tables = _.fromPairs(_.take(_.toPairs(favourite_tables), state.all_tables_load_more));

			return favourite_tables;
		},

		getLastPlayedTables(state) {
			if (!state.last_played_tables) return {};

			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.tables.length > 0) ? useCustomerStore().customer.favourites.tables : null;

			let tables = state.last_played_tables;

			if (state.selected_category && state.selected_category != 'favourites' && state.selected_category.id) {
				tables = _.filter(tables, { 'live_casino_category_id': state.selected_category.id });
			}

			tables = _.map(tables, table => {
				let favourite_record = _.find(favourites, {'table_id': String(table.id)});

				return {
					...table,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_tables = _.orderBy(tables, ['favourite_timestamp'], ['desc']);

			return favourite_tables;
		},
		featuredLiveCasinoTables(getters) {
			if (getters.getTables.length == 0) return [];

			return _.chain(getters.getTables).shuffle().take(5).value();
		},
		// Check later
		searchTerm(state) {
			return state.search_term;
		},
		categoryLogo() {
			return {
				'tv_games': '/assets/icons/live_casino/categories/live_dealers.svg',
				'poker': '/assets/icons/live_casino/categories/fast_games_icon.svg',
				'blackjack': '/assets/icons/live_casino/categories/fast_games_icon.svg',
			}
		},
		categoryLogoMobile() {
			return {
				'tv_games': '/assets/icons/live_casino/categories/live_dealers_white.svg',
				'poker': '/assets/icons/live_casino/categories/fast_games_icon_white.svg',
				'blackjack': '/assets/icons/live_casino/categories/fast_games_icon_white.svg',
			}
		},
		showFavouritesTables(state) {
			return state.show_favorites;
		},
		getShowMoreCategory(state) {
			return state.show_more_category;
		},
		getTotalTablesPerCategory(state) {
			return state.total_tables_per_category;
		}
	},
	actions: {
		fetchLiveCasino() {
			this.show_more_category = [];
			this.more_tables_fetched = {};
			this.tables_loading = true;

			let params = {}

			if (this.selected_provider) {
				params.provider_id = this.selected_provider.id;
			}

			if (this.selected_category) {
				params.category_id = this.selected_category.id;
			}

			if (this.search_term) {
				params.search_term = this.search_term;
			}

			params.device = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				params.device = 'mobile';
			}

			return global.axios.get(process.env.VUE_APP_API_URL + '/live-casino', { params: params }, { headers: { 'Version': 'v3' } }).then((response) => {
				this.tables_loading = false;
				this.tables = response.data.tables;
				this.live_tables = response.data.live_tables;
				this.providers = response.data.providers;
				this.categories = response.data.categories;
				this.total_tables_per_category = response.data.total_tables_per_category;
				this.favourites = response.data.favourites;

				return Promise.resolve(response.data);
			}).catch((error) => {
				this.tables_loading = false;

				return Promise.reject(error);
			})

		},
		fetchMoreCategoryGames(category) {
			let payload = {
				category: category,
				device: 'desktop'
			};

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload.device = 'mobile';
			}

			if (this.selected_provider) payload['provider_id'] = this.selected_provider.id;
			if (this.selected_category) payload['category_id'] = this.selected_category.id;

			// Fetch only if not fetched before
			if (!Object.hasOwnProperty.call(this.more_tables_fetched, category)) {
				return global.axios.post(process.env.VUE_APP_API_URL + '/live-casino/more', payload, { headers: { 'Version': 'v3' } }).then((response) => {
					this.more_tables_fetched[category] = true;
					this.tables = [...this.tables, ...response.data['tables']];

					return Promise.resolve(response.data.data);
				}).catch((error) => {
					this.tables_loading = false;
					console.log(error.response);

					return Promise.reject(error);
				})
			} else {
				return Promise.resolve();
			}
		},
		fetchLastPlayedGames() {
			let payload = {
				device: 'desktop'
			};

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload.device = 'mobile';
			}

			return global.axios.post(process.env.VUE_APP_API_URL + '/live-casino/last-played', payload ,{ headers: { 'Version': 'v3' } }).then((response) => {
				this.last_played_tables = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error);

				return Promise.reject(error);
			})
		},
		selectProvider(provider_id) {
			this.selected_provider = provider_id;

			this.fetchLiveCasino();
		},
		selectCategory(category_id) {
			this.selected_category = category_id;

			if (category_id != 'favourites') this.fetchLiveCasino();
		},
		setSearchTerm(term) {
			this.search_term = term;

			this.fetchLiveCasino();
		},
		// For darka live casino tables
		openGame(game_id) {
			let protocol = window.location.protocol;
			let hostname = window.location.hostname;
			let port = window.location.port ? window.location.port : '';
			let close_url = protocol + '//' + hostname + (port ? ':' + port : '') + '/casino/close';

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/opengame', { game_id: game_id, close_url: close_url }).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchTable(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/live-casino/table', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		toggleFavourite(table_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/favourite/table', { id: table_id }).then((response) => {
				useCustomerStore().customer.favourites = response.data.data;

				let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.tables.length > 0) ? useCustomerStore().customer.favourites.tables : null;

				let is_favourite = _.find(favourites, { 'table_id': String(table_id) });

				if (is_favourite) {
					let table = _.find(this.tables, { 'id': table_id });
					this.favourites = [...this.favourites, table];
				} else {
					this.favourites = _.filter(this.favourites, table => table.id != table_id);
				}

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		showMoreToggle(category_name) {
			const index = this.show_more_category.indexOf(category_name);

			if (index !== -1) {
				// If the category_name is already in the array, remove it
				this.show_more_category.splice(index, 1);

				return Promise.resolve();
			} else {
				if (category_name == 'show_last_played_tables' || category_name == 'favourites') {
					this.show_more_category.push(category_name);

					return Promise.resolve();
				}

				return this.fetchMoreCategoryGames(category_name).then(() => {
					this.show_more_category.push(category_name);

					return Promise.resolve();
				}).catch(() => {
					return Promise.reject();
				});
			}

			// const index = this.show_more_category.indexOf(category_name);

			// if (index !== -1) {
			// 	// If the category is already in the array, remove it
			// 	this.show_more_category.splice(index, 1);
			// } else {
			// 	// If the category is not in the array, add it
			// 	this.fetchMoreCategoryGames(category_name);
			// 	this.show_more_category.push(category_name);
			// }
		},
		loadMoreTables() {
			let tables_count = Object.keys(_.groupBy(this.tables, 'live_casino_category_id')).length;

			if (tables_count > this.all_tables_load_more) this.all_tables_load_more += 10;
		},
	},
})