<script setup>
/* eslint-disable */
import { useCustomerStore } from '@/stores/customer';

const customerStore = useCustomerStore();

const props = defineProps({
	notification: {
		type: Object,
		required: true,
	},
});

const closeNotification = (notification_id) => {
	customerStore.dismissNotification(notification_id);
}

</script>
<template>
	<div class="notification-container">
		<div class="notification-icon">
			<img src="../../assets/svgs/icon-info.svg" alt="notification-icon" width="14">
		</div>
		<div class="notification-text">
			<p>{{ notification.message }}</p>
		</div>
		<div class="close-btn" @click="closeNotification(notification.id)">
			<p>X</p>
		</div>
	</div>
</template>
<style scoped>
.notification-container {
	display: grid;
	width: 100%;
	height: auto;
	background-color: #FFBB02;
	grid-template-columns: 22px auto 22px;
	padding: 0px 20px 0px 20px;
	align-items: center;
	border: 1px solid #4E545D;
    border-top: unset;
}

.notification-text{
	color: #000;
	font-size: 12px;
	font-weight: 500;
}

.close-btn {
	color: #000;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
}

@media screen and (max-width: 1280px) {
	.notification-container {
		padding: 0px 12px 5px 12px;
	}
}
</style>