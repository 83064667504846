/* eslint-disable */
import { defineStore } from 'pinia';
import _ from 'lodash';
import { useFavouritesStore } from './favourites';

export const useSportUpcomingStore = defineStore('sportUpcomingStore', {
	state: () => ({
		fixures: null,
	}),
	getters: {
		upcomingFixtures(state){
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.fixures);
			let favourite_fixtures = [];
			let regular_fixtures = [];

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {

					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(fixtures[fixture_id]['id'])) {
						fixtures[fixture_id].favourite = true;
						favourite_fixtures.push(fixtures[fixture_id]);

						fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name + '-fav';
					} else {
						regular_fixtures.push(fixtures[fixture_id]);
					}
				}
			}

			regular_fixtures = _.chain(regular_fixtures).orderBy(['favourite', 'sort', 'competition_weight', 'competition_region_name'], ['asc', 'asc', 'desc', 'asc']).groupBy('competition_region_name').value();
			favourite_fixtures = _.groupBy(favourite_fixtures, 'competition_region_name');

			if (Object.keys(favourite_fixtures).length > 0) return {...favourite_fixtures, ...regular_fixtures}

			return regular_fixtures;
		},
	},
	actions: {
		getUpcomingFixtures(sport_id) {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/competitions/fixtures/highlights/sport/' + sport_id, { withCredentials: false }).then((response) => {
				this.fixures = response.data;
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
	}
})