<!-- eslint-disable -->
<script setup>
import { useCurrenciesStore } from '../../stores/currencies';
import { useRegisterStore } from '../../stores/register';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import _ from 'lodash';

const { t } = useI18n();
const registerStore = useRegisterStore();
const currenciesStore = useCurrenciesStore();
const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';
const enabled_fields = registerStore.getEnabledRegistrationFields;

let default_currency_id = null;

if (is_country_ghana) {
	if (enabled_fields) {
		const currency_field = enabled_fields.find(field => field.slug == 'currency_id');
		if (currency_field) {
			default_currency_id = currency_field.default_value;
			registerStore.register_input_fields.currency_id = currency_field.default_value;
		}
	}
}
</script>

<template>
	<div class="register-form-field">
		<label>{{$t('account.register.currency')}}</label>
		<select @change="registerStore.validateCurrency" :style="{ cursor: is_country_ghana ? 'not-allowed' : 'pointer' }" name="" v-model="registerStore.register_input_fields.currency_id" class="form-input form-control" id="address-country" :disabled="is_country_ghana">
			<option :value="null" disabled>{{ $t('account.register.choose_currency') }}</option>
			<option v-for="currency in currenciesStore.getCurrencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
		</select>
		<div class="register-form-list" v-if="registerStore.register_errors.currency_id.error">
			<div class="password-meter">
				<div class="password-meter-bar">
					<div class="password-meter-bar-inn"></div>
				</div>
			</div> 
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.currency_id.error === 'error_select_currency'">
					{{ $t('account.register.error_select_currency') }}
				</span>
				<span v-else-if="registerStore.register_errors.hasOwnProperty('currency_id')">
					{{ registerStore.register_errors.currency_id.error }}
				</span>
			</p>
		</div>
	</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form select {
	background-position: 5%;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input { 
	padding: 10px 15px 10px 15px;
}
</style>
