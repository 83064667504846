<script setup>
import { onMounted } from 'vue';
import BrandsSlider from '@/components/live_casino/BrandsSlider.vue';
import CategorySlider from '@/components/live_casino/CategorySlider.vue';
import TablesContainer from '@/components/live_casino/TablesContainer.vue';
import TablesContainerLastPlayed from '@/components/live_casino/TablesContainerLastPlayed.vue';
import Loader from '@/components/live_casino/LoaderComponent.vue';
import { useLiveCasinoStore } from '@/stores/live_casino'
import { useI18n } from 'vue-i18n';

const liveCasinoStore = useLiveCasinoStore();
const { t } = useI18n();

onMounted(() => {
	document.body.style.overflow = 'auto';
});

const getTitle = () => {
	let filter = liveCasinoStore.searchTerm;
	let is_provider = liveCasinoStore.getFilterOptions.is_provider;
	let is_category = liveCasinoStore.getFilterOptions.is_category;
	if (filter != null && is_provider == false && is_category == false) return t('general.search_for') + ' ' + liveCasinoStore.searchTerm;
	else if (filter != null && (is_provider != false || is_category != false)) return liveCasinoStore.searchTerm;
	else return t('general.all_tables');
}
</script>
<template>
	<div class="casino-main-outer live_casino-main-outer">
		<!-- Brands Slider Start -->
		<BrandsSlider></BrandsSlider>

		<div class="casino-game-area">
			<!-- Categories Slider Start -->
			<CategorySlider/>

			<!-- Loader -->
			<Loader v-if="liveCasinoStore.tables_loading" />

			<template v-else>
				<!-- Last Played Tables -->
				<template v-if="liveCasinoStore.getSelectedProviderId == null && liveCasinoStore.getSelectedCategoryId == null && liveCasinoStore.getLastPlayedTables.length > 0 && Object.keys(liveCasinoStore.getTables).length > 0 && liveCasinoStore.searchTerm == null">
					<TablesContainerLastPlayed :title="$t('general.last_played_games')" :tables="liveCasinoStore.getLastPlayedTables" type="last_played"/>
				</template>

				<!-- Favourite Tables -->
				<template v-if="liveCasinoStore.getSelectedCategoryId == 'favourites' && Object.keys(liveCasinoStore.getFavouriteTables).length > 0">
					<TablesContainer :title="$t('general.favourites')" :tables="liveCasinoStore.getFavouriteTables" :live_tables="[]" type="favourites"/>
				</template>

				<!-- All Tables -->
				<template v-else-if="liveCasinoStore.getSelectedCategoryId != 'favourites'">
					<TablesContainer :title="getTitle()" :tables="liveCasinoStore.getTables" :live_tables="liveCasinoStore.getLiveTables" type="all_tables"/>
				</template>
			</template>
		</div>

	</div>
</template>