import { defineStore } from 'pinia';

export const useRegistrationDocumentTypesStore = defineStore('registration_document_types', {
	state: () => ({
		registration_document_types: null
	}),
	getters: {
		getRegistrationDocumentTypes(state) {
			return state.registration_document_types;
		}
	},
	actions: {
		setRegistrationDocumentType(data) {
			this.registration_document_types = data;
		}
	},
})