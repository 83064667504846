<script setup>
/* eslint-disable */
import { onMounted, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRegionsStore } from '@/stores/regions';
import { useCouponsStore } from '@/stores/coupons';
import { useGeneralStore } from '@/stores/general';
import { usePreSportsStore } from '@/stores/pre_sports';

const router = useRouter();
const regionsStore = useRegionsStore();
const couponsStore = useCouponsStore();
const preSportsStore = usePreSportsStore();
const generalStore = useGeneralStore();

const props = defineProps({
	region: {
		type: Object,
		required: true,
	}
});

const expanded = ref(null);
const checked = ref(false);

onMounted(() => {
	expanded.value = couponsStore.all_expanded;
});

const toggleRegionCompetitions = (region) => {
	for (const competition_key in region.competitions) {
		const competition = region.competitions[competition_key];
		couponsStore.toggleCheckboxCompetition(competition.id);
	}
}

const toggleCompetition = (competition) => {
	couponsStore.toggleCheckboxCompetition(competition.id);
}

watch(() => couponsStore.all_expanded, (newValue, oldValue) => {
	expanded.value = newValue;
})

</script>
<template>
	<div class="accordion-item-coupon" :class="{'active': expanded}" :style="expanded ? 'padding-bottom:20px' : ''">
		<div class="accordion-header-coupon">
			<div class="coupon-country coupon-checkbox-outer" >
				<input @click="toggleRegionCompetitions(region)" type="checkbox" :id="'checkbox' + region.id" :checked="couponsStore.isRegionChecked(region)"/>
				<label :for="'checkbox' + region.id">
					<div class="country-flag">
						<img :src="'/assets' + region.icon" alt="flag-country">
					</div>
					<h6>{{ region.name }}</h6>
				</label>
			</div>
			<div class="acoordion-icon-coupon" @click="expanded = !expanded">
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
					<g id="Group_1063" data-name="Group 1063" transform="translate(-272 -6)">
						<g id="minimize" transform="translate(272 10)">
						<rect id="Rectangle_408" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff"/>
						</g>
						<g id="minimize-2" class="hide-icon" data-name="minimize" transform="translate(277 6) rotate(90)">
						<rect id="Rectangle_408-2" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff"/>
						</g>
					</g>
				</svg>

			</div>
		</div>

		<div v-if="expanded" class="accordion-content-coupon">
			<div class="coupon-section-content">
				<div class="coupon-section-inner">
					<div class="coupon-checkboxes">
						<div class="coupon-checkbox-outer" v-for="competition in region.competitions" :key="competition.id" :class="{'active': false }" >
							<input type="checkbox" class="checkbox" :id="'competition-checkbox-' + competition.id" :checked="couponsStore.isCompetitionChecked(competition.id)" @click="toggleCompetition(competition)">
							<label :for="'competition-checkbox-' + competition.id">
								<p> <span> {{ competition.name }}</span> </p>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<style scoped>
.coupons-section-outer .accordion-outer-coupon .accordion-coupon .accordion-item-coupon .accordion-header-coupon .acoordion-icon-coupon svg {
	display: block;
}

@media screen and (min-width: 767px) {
	.coupons-section-outer .accordion-outer-coupon .accordion-coupon .accordion-item-coupon {
		padding-bottom: 0px;
	}
}
html[dir="rtl"] .coupons-section-outer .accordion-outer-coupon .accordion-coupon .accordion-item-coupon .accordion-header-coupon .acoordion-icon-coupon {
	width: 15px;
	position: absolute;
	left: 0;
	top: 42%;
	transform: translateY(-50%);
	right: unset;
}

html[dir="rtl"] .coupons-section-outer .accordion-outer-coupon .accordion-coupon .accordion-item-coupon .accordion-header-coupon .coupon-country.coupon-checkbox-outer input:checked + label:after {
	content: "";
	position: absolute;
	right: 6px;
	left: unset;
	top: 34%;
	display: inline-block;
	transform: rotate(225deg);
	height: 11px;
	width: 4px;
	border-top: 1px solid #202833;
	border-bottom: unset;
	border-left: 1px solid #202833;
	border-right: unset;
}
html[dir="rtl"] .coupons-section-outer .coupon-section-content .coupon-section-inner .coupon-checkboxes .coupon-checkbox-outer input:checked + label:after {
	content: "";
	position: absolute;
	right: 6px;
	top: 4px;
	display: inline-block;
	transform: rotate(45deg);
	height: 11px;
	width: 4px;
	border-bottom: 1px solid #63A246;
	border-right: 1px solid #63A246;
}
.coupons-section-outer .accordion-outer-coupon .accordion-coupon .accordion-item-coupon .accordion-header-coupon .coupon-country h6 {
	color: #ffffff;
	padding-left: 3px;
}
</style>
