<script setup>
/* eslint-disable */
import { useRouter, useRoute } from 'vue-router';
import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
import { useSocketStore } from '@/stores/socket';
import { useGeneralStore } from '@/stores/general';
import { useCompetitionsStore } from '@/stores/competitions';
import CompetitionFixtures from '@/components/esports/CompetitionFixtures.vue';
import _ from 'lodash';

const socketStore = useSocketStore();
const competitionsStore = useCompetitionsStore();
const generalStore = useGeneralStore();

const router = useRouter();
const route = useRoute();

socketStore.getSocket.on('competitionFixtures', (data) => {
	if (_.isEmpty(data.fixtures)) {
		router.push({ name: 'not-found' });
	}

	competitionsStore.setCompetitionFixtures(data);
	setPageTitle(data);
});

socketStore.getSocket.on('competitionFixturesUpdate', (data) => {
	competitionsStore.updateCompetitionFixture(data);
});

socketStore.getSocket.on('competitionFixturesDelete', (data) => {
	competitionsStore.deleteCompetitionFixture(data);
});

const setPageTitle = (data) => {
	if (Object.keys(data.fixtures).length == 0) return;
	let first_fixture = data.fixtures[Object.keys(data.fixtures)[0]];

	if (router.currentRoute.value.name == 'esports-coupons-competitions') return;
	document.title = generalStore.getGeneral.site_name + ' - ' + first_fixture.region_name + ' - ' + first_fixture.competition_name;
}

onMounted( () => {
	competitionsStore.emptyCompetitions();

	if (router.currentRoute.value.params.competition_id != '') {
		socketStore.getSocket.emit('join-competitionFixtures', router.currentRoute.value.params.competition_id)
		competitionsStore.setSelectedCompetitionId(router.currentRoute.value.params.competition_id);
	}
})

onBeforeUnmount(() => {
	socketStore.getSocket.emit('leave-competitionFixtures', competitionsStore.selectedCompetitionId);
	competitionsStore.emptyCompetitions();
})

watch(route, (to, from) => {

	if (!to.params.hasOwnProperty('competition_id')) return;
	if (to.params.competition_id == competitionsStore.selectedCompetitionId) return;

	// Check If I have selected competition and leave
	if (competitionsStore.selectedCompetitionId != null) {
		socketStore.getSocket.emit('leave-competitionFixtures', competitionsStore.selectedCompetitionId);
		competitionsStore.emptyCompetitions();
	}

	socketStore.getSocket.emit('join-competitionFixtures', to.params.competition_id);
	competitionsStore.setSelectedCompetitionId(to.params.competition_id);
});

</script>

<template>
	<div id="Competitions" class="competetion-section">
		<CompetitionFixtures v-for="(competition) in competitionsStore.competitionFixturesByDate" :key="competition.competition_id" :competition="competition"/>
	</div>

</template>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}

}


</style>