<script setup>
/* eslint-disable */
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router';
import { useMarketsStore } from '@/stores/markets';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useFixtureStore } from '@/stores/fixture';
import { useFavouritesStore } from '@/stores/favourites';

import LiveFixtureDoubleMarketOverviewOdd from '@/components/esports/highlights/LiveFixtureDoubleMarketOverviewOdd.vue';
import LiveFixtureDoubleSecondMarketOverviewOdd from '@/components/esports/highlights/LiveFixtureDoubleSecondMarketOverviewOdd.vue';

const router = useRouter();
const marketsStore = useMarketsStore();
const liveSportsStore = useLiveSportsStore();
const fixtureStore = useFixtureStore();
const favouritesStore = useFavouritesStore();

import commonSvgs from '@/assets/svgs/commonSvgs.json'
const svg_data = ref(commonSvgs);

const props = defineProps({
	primary_overview_market: {
		required: true,
	},
	secondary_overview_market: {
		required: true,
	},
	fixture: {
		type: Object,
		required: true,
	}
});

const is_hidden = ref(false);

const visibilityChanged = (isVisible, entry) => {
	is_hidden.value = !isVisible;
}

const toggleFavourite = () => {
	favouritesStore.toggleFixture(props.fixture.id);
}

const goToFixture = () => {
	router.push({ name: 'live-sports-eventview', params: { fixture_id: props.fixture.id } })
}

const primaryOverviewMarketOdds = computed(() => {
	if (!props.fixture.overview_markets.hasOwnProperty(props.primary_overview_market.slug)) return [];

	return props.fixture.overview_markets[props.primary_overview_market.slug];
});

const secondaryOverviewMarketOdds = computed(() => {
	if (!props.fixture.overview_markets.hasOwnProperty(props.secondary_overview_market.slug)) return [];

	return props.fixture.overview_markets[props.secondary_overview_market.slug];
});

</script>

<template>
	<div class="live-info-inner" v-observe-visibility="visibilityChanged" :style="is_hidden ? 'visibility:hidden;' : ''">
		<!-- live team info start -->
		<div class="live-teams">
			<div class="star-ico" @click="toggleFavourite">
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
					<path :style="{ fill: fixture.favourite ? '#63A246' : '' }" id="Icon_ionic-ios-star" :class="`fixture-${fixture?.id}`" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d"></path>
				</svg>
			</div>
			<div class="info-inner">
				<div class="live-badge" v-if="fixture.live" v-html="svg_data.live_badge"></div>
				<div class="live-data-desktop" v-if="liveSportsStore.hasFixtureClock(props.fixture.sport_id)" @click="goToFixture">
					<h3>
						<dl>
							{{ fixtureStore.fixtureClockTime(props.fixture) }}
							<dt> {{ fixtureStore.fixtureState(props.fixture) }}</dt>
						</dl>

						<span></span>
					</h3>
				</div>
				<div class="live-data-desktop" v-else>
					<h3> {{ fixtureStore.fixtureState(props.fixture) }}</h3>
					<span></span>
				</div>
			</div>

			<div class="team-name" @click="goToFixture">
				<div class="info-inner">
					<div class="live-badge" v-html="svg_data.live_badge"></div>

					<div class="live-data-mobile" v-if="liveSportsStore.hasFixtureClock(props.fixture.sport_id)">
						<h3>
							<dl>
							{{ fixtureStore.fixtureClockTime(props.fixture) }}
							<dt> {{ fixtureStore.fixtureState(props.fixture) }}</dt>
							</dl>

							<span></span>
						</h3>
					</div>
					<div class="live-data-mobile" v-else>
						<h3> {{ fixtureStore.fixtureState(props.fixture) }}</h3>
						<span></span>
					</div>
				</div>
				<div class="team-inner">
					<p>
						<span class="playing-team" v-if="props.fixture.statistics?.possession && props.fixture.statistics?.possession == 1"></span>
						{{ props.fixture.participants ? props.fixture.participants[0] : '' }}
					</p>
					<div class="live-round-score">
						<span>{{ props.fixture.statistics?.p1_score }}</span>
						<span v-if="liveSportsStore.hasThreeScores(props.fixture.sport_id)">{{ props.fixture.statistics?.p1_games }}</span>
						<span v-if="liveSportsStore.hasTwoScores(props.fixture.sport_id)">{{ props.fixture.statistics?.p1_points }}</span>
					</div>
				</div>
				<div class="team-inner" v-if="!liveSportsStore.hasOneParticipant(props.fixture.sport_id)">
					<p>
						<span class="playing-team" v-if="props.fixture.statistics?.possession && props.fixture.statistics?.possession == 2"></span>
						{{ props.fixture.participants ? props.fixture.participants[1] : '' }}
					</p>
					<div class="live-round-score">
						<span>{{ props.fixture.statistics?.p2_score }}</span>
						<span v-if="liveSportsStore.hasThreeScores(props.fixture.sport_id)">{{
								props.fixture.statistics?.p2_games }}</span>
						<span v-if="liveSportsStore.hasTwoScores(props.fixture.sport_id)">{{ props.fixture.statistics?.p2_points }}</span>

					</div>
				</div>
			</div>
			<div @click="goToFixture" class="betting-total-mobile">
				<p><img src="/assets/icons/plus-bets.svg" alt="" />&nbsp;{{ props.fixture.odds_count }} {{$t('account.affiliate.bets')}}</p>
			</div>
		</div>
		<!-- live team info start -->
		<!-- full bet result start -->
		<div class="outer-betting-info info-left" v-if="primary_overview_market">
			<div class="betting_info">
				<LiveFixtureDoubleMarketOverviewOdd
					:overview_market_header_name="primary_overview_market.odd_names[0]"
					:overview_odd="primaryOverviewMarketOdds[0]"
					:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
					:fixture_id="props.fixture.id"
				/>

				<LiveFixtureDoubleMarketOverviewOdd v-if="primary_overview_market.three_way"
					:overview_market_header_name="primary_overview_market.odd_names[1]"
					:overview_odd="primaryOverviewMarketOdds[1]"
					:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
					:fixture_id="props.fixture.id"
				/>

				<LiveFixtureDoubleMarketOverviewOdd
					:overview_market_header_name="primary_overview_market.odd_names[2]"
					:overview_odd="primary_overview_market.three_way ? primaryOverviewMarketOdds[2] : primaryOverviewMarketOdds[1]"
					:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
					:fixture_id="props.fixture.id"
				/>
			</div>
		</div>
		<!-- full bet result end -->
		<!-- match goals start -->

		<div class="outer-betting-info info-right" v-if="secondary_overview_market">

			<div class="match-score_info">
				<LiveFixtureDoubleSecondMarketOverviewOdd
					:overview_market_header_name="secondary_overview_market.odd_names[0]"
					:overview_odd="secondaryOverviewMarketOdds[0]"
					:needs_indicator="marketsStore.overviewMarketNeedsIndicator(secondary_overview_market)"
					:fixture_id="props.fixture.id"
				/>

				<LiveFixtureDoubleSecondMarketOverviewOdd v-if="secondary_overview_market.three_way"
					:overview_market_header_name="secondary_overview_market.odd_names[1]"
					:overview_odd="secondaryOverviewMarketOdds[1]"
					:needs_indicator="marketsStore.overviewMarketNeedsIndicator(secondary_overview_market)"
					:fixture_id="props.fixture.id"
				/>

				<LiveFixtureDoubleSecondMarketOverviewOdd
					:overview_market_header_name="secondary_overview_market.odd_names[2]"
					:overview_odd="secondary_overview_market.three_way ? secondaryOverviewMarketOdds[2] : secondaryOverviewMarketOdds[1]"
					:needs_indicator="marketsStore.overviewMarketNeedsIndicator(secondary_overview_market)"
					:fixture_id="props.fixture.id"
				/>

				<div class="total-bet" @click="goToFixture">
					<div class="total-bet-main">
						<h6>{{ fixture.odds_count }} {{$t('account.affiliate.bets')}}</h6>
					</div>
				</div>
			</div>
		</div>

		<!-- match goals end -->
	</div>
</template>
<style scoped>
.live-info-inner {
	/* Force hardware acceleration */
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}
</style>
