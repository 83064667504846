<script setup>
/* eslint-disable */
import { onMounted, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRegionsStore } from '@/stores/regions';
import { useGeneralStore } from '@/stores/general';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
import RegionCoupon from './RegionCoupon.vue';

const router = useRouter();
const regionsStore = useRegionsStore();
const preSportsStore = usePreSportsStore();
const generalStore = useGeneralStore();


onMounted(() => {
	let selected_pre_sport = _.find(preSportsStore.preSports, {'id': Number(router.currentRoute.value.params.sport_id) });
	if (selected_pre_sport) document.title = generalStore.getGeneral.site_name + ' - ' + selected_pre_sport.name + ' ' + t('general.coupons');
})

watch(() => preSportsStore.preSports, (newSports, oldSports) => {
	if (oldSports.length == 0 && newSports.length > 0) {
		let selected_pre_sport = _.find(preSportsStore.preSports, {'id': Number(router.currentRoute.value.params.sport_id) });
		if (!selected_pre_sport) return;
		document.title = generalStore.getGeneral.site_name + ' - ' + selected_pre_sport.name + ' ' + t('general.coupons');
	}
})

</script>
<template>

	<div class="accordion-outer-coupon">
		<div class="accordion-coupon">
			<!-- iteration on country data -->
			<RegionCoupon v-for="region in regionsStore.regionsCompetitionsCoupons" :key="region.id" :region="region"/>
		</div>
	</div>
</template>
<style scoped>
 @media screen and (min-width: 320px) and (max-width: 991px) {
	.accordion-coupon{
		max-height:unset!important;
	}
}
</style>