<script setup>
/* eslint-disable */
import { onMounted, onUnmounted, inject, watch, ref, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useSocketStore } from '@/stores/socket';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useFixtureStore } from '@/stores/fixture';
import { useDatesStore } from '@/stores/dates';
import { useGeneralStore } from '@/stores/general';
import { useCompetitionsStore } from '@/stores/competitions';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import headerIcons from '@/assets/svgs/Header.json'

import FixtureBookMarket from '@/components/Common/FixtureBookMarket.vue';

const markets_closed = ref(false);
const show_scroll_to_top_button = ref(false);
const header_icons = ref(headerIcons);

const router = useRouter();
const route = useRoute();

const socketStore = useSocketStore();
const preSportsStore = usePreSportsStore();
const liveSportsStore = useLiveSportsStore();
const fixtureStore = useFixtureStore();
const datesStore = useDatesStore();
const generalStore = useGeneralStore();
const competitionsStore = useCompetitionsStore();

const splideRef = ref(null);
const direction = ref(document.dir);
const options = ref({
	direction: direction.value,
	pagination: false,
	// arrows: false,
	// focus: 'center',
	autoWidth: true,
	gap: 20,
	trimSpace: 'move'
});

const checkArrowsVisibility = () => {
	if (splideRef.value && splideRef.value.splide) {
		const slider = splideRef.value.splide;
		let totalWidth = 0;
		slider.Components.Elements.slides.forEach(slide => {
			const marginRight = parseFloat(window.getComputedStyle(slide).marginRight);
			totalWidth += slide.offsetWidth + marginRight;
		});

		const sliderWidth = slider.Components.Elements.track.clientWidth;

		// Update arrow visibility
		slider.options = {
			...slider.options,
			arrows: totalWidth > sliderWidth
		};

		slider.refresh();
	}
};

watch(() => fixtureStore.marketGroups, (newValue, oldValue) => {
	if (newValue.length > 0) {
		nextTick().then(() => {
			checkArrowsVisibility();
		});
	}
});

socketStore.getSocket.on('liveFixtureBook', (data) => {
	fixtureStore.setFixture(data);
	setPageTitle();
	competitionsStore.setSelectedCompetitionId(data.competition_id);
	fixtureStore.setFixtureLoading(false);
});

socketStore.getSocket.on('liveFixtureBookNotFound', (data) => {
	fixtureStore.setFixtureLoading(false);
	router.push({ name: 'not-found' });
})

socketStore.getSocket.on('liveFixtureBookUpdate', (data) => {
	fixtureStore.updateFixture(data);
})

socketStore.getSocket.on('liveFixtureBookDelete', (data) => {
	fixtureStore.emptyFixture(data);
})

const changeMarketGroup = (market_group) => {
	fixtureStore.setSelectedMarketGroup(market_group);
}

const setPageTitle = () => {
	if (liveSportsStore.hasOneParticipant(fixtureStore.fixture.sport_id) || fixtureStore.fixture.outright == true) {
		document.title = generalStore.getGeneral.site_name + ' - ' + fixtureStore.fixture.participants[0];
		return;
	}

	document.title = generalStore.getGeneral.site_name + ' - ' + fixtureStore.fixture.participants[0] + ' - ' + fixtureStore.fixture.participants[1];
};

onMounted(() => {
	window.scrollTo(0, 0);

	if (router.currentRoute.value.params.fixture_id) {
		fixtureStore.setFixtureLoading(true);
		socketStore.getSocket.emit('join-liveFixtureBook', router.currentRoute.value.params.fixture_id);
	}

	nextTick().then(() => {
		checkArrowsVisibility();
	});
	window.addEventListener('resize', checkArrowsVisibility);

	window.addEventListener('scroll', handleScroll);
	const container = document.querySelector('.live-tab-info');

	if (container) {
		container.addEventListener('scroll', handleElementScroll);
	}

})

const scrollToTop = () => {
	const container = document.querySelector('.live-tab-info');
	window.scrollTo({ top: 0 });

	if (container) {
		container.scrollTo({ top: 0 });
	}
};

const handleScroll = () => {
	const scrollTop = window.scrollY;
	show_scroll_to_top_button.value = scrollTop > 100;
};

const handleElementScroll = () => {
	const container = document.querySelector('.live-tab-info');

	if (container) {
		const scrollTop = container.scrollTop;
		show_scroll_to_top_button.value = scrollTop > 100;
	}
};

onUnmounted(() => {
	socketStore.getSocket.emit('leave-liveFixtureBook', fixtureStore.fixtureBook.id);
	fixtureStore.emptyFixture();

	window.removeEventListener('resize', checkArrowsVisibility);
})

watch(() => fixtureStore.fixtureBook, (newFixturebook, oldFixtureBook) => {
	if (newFixturebook.live == true && oldFixtureBook.live == false) {
		router.push({ name: 'live-sports-eventview', params: { fixture_id: newFixturebook.id } })
	}
})

watch(route, (to, from) => {
	if (!to.params.hasOwnProperty('fixture_id')) return;

	if (fixtureStore.fixtureBook.id == to.params.fixture_id) return;

	socketStore.getSocket.emit('leave-liveFixtureBook', fixtureStore.fixtureBook.id);
	fixtureStore.emptyFixture();

	fixtureStore.setFixtureLoading(true);
	socketStore.getSocket.emit('join-liveFixtureBook', to.params.fixture_id);
});

const goToCompetition = (fixture) => {
	router.push({ name: 'esports-competition', params: { sport_id: fixture.sport_id, region_id: Number(fixture.region_id), competition_id: fixture.competition_id } } )
}

</script>
<template>
	<LoaderComponent v-if="fixtureStore.fixture_loading" />
	<div class="live-sports-center" v-else>
		<div class="competetion-back-btn">
			<a @click="router.back()" class="">
				<svg xmlns="http://www.w3.org/2000/svg" width="4.414" height="7.414" viewBox="0 0 4.414 7.414">
					<g id="Group_232" data-name="Group 232" transform="translate(0.707 0.525)">
						<line id="Line_7" data-name="Line 7" x1="3" y2="3" transform="translate(0 0.182)" fill="none"
							stroke="#fff" stroke-linecap="round" stroke-width="1"></line>
						<line id="Line_8" data-name="Line 8" x1="3" y1="3" transform="translate(0 3.182)" fill="none"
							stroke="#fff" stroke-linecap="round" stroke-width="1">
						</line>
					</g>
				</svg>&nbsp; {{ $t('general.back') }}
			</a>
		</div>
		<div class="eventview-game-banner upcoming-scorecard">
			<div class="eventview-banner-image">
				<img :src="'/assets/scoreboards/' + preSportsStore.preSportName(fixtureStore.fixtureBook.sport_id) + '.png'"
					alt="">
			</div>
			<div class="live-game-outer">
				<div class="live_score_Box">
					<div class="league-title">
						<div class="title">
							<img :src="preSportsStore.preSportWhiteIcon(fixtureStore.fixtureBook.sport_id)" alt="">
							<h6 @click="goToCompetition(fixtureStore.fixtureBook)">{{ fixtureStore.fixtureBook.region_name + ' - ' + fixtureStore.fixtureBook.competition_name
							}}</h6>
						</div>
						<div class="upcoming-match-date">
							<p>{{ datesStore.preFixtureBookDate(fixtureStore.fixtureBook.start_datetime) }}</p>
							<p>{{ datesStore.preFixtureBookTime(fixtureStore.fixtureBook.start_datetime) }}</p>
						</div>
					</div>
					<!-- score panel start-->
					<div class="league-score">

						<div class="eventview-playing-info">
							<div class="playing-team-inner">
								<div class="playing-team">
									<h6 v-if="fixtureStore.fixtureBook.participants">{{
										fixtureStore.fixtureBook.participants[0] }}</h6>
									<!-- <img src="@/assets/images/leeds.png" alt=""> -->
								</div>
								<div class="playing-score">
									<span>{{ datesStore.preFixtureBookDate(fixtureStore.fixtureBook.start_datetime)
									}}</span>
								</div>
							</div>
							<div class="divider"> - </div>
							<div class="playing-team-inner">
								<div class="playing-team"
									v-if="!liveSportsStore.hasOneParticipant(fixtureStore.fixtureBook.sport_id) && !fixtureStore.fixtureBook.outright">
									<!-- <img src="@/assets/images/liverpool.png" alt=""> -->
									<h6 v-if="fixtureStore.fixtureBook.participants">{{
										fixtureStore.fixtureBook.participants[1] }}</h6>
								</div>
								<div class="playing-score">
									<span>{{ datesStore.preFixtureBookTime(fixtureStore.fixtureBook.start_datetime)
									}}</span>
								</div>
							</div>
						</div>

					</div>
					<!-- score panel end-->
				</div>

			</div>
		</div>

		<!----- info tab start ------>
		<div class="live-bet-dashboard">
			<div class="top-search-bar">
				<div></div>
				<div class="header-search-field">
					<div class="header-search-inner">
						<input type="search" :placeholder="$t('general.search_for_specific_markets')" v-model="fixtureStore.market_search">
						<button type="submit">
							<div>
								<object v-html="header_icons.search"></object>
							</div>
						</button>
					</div>
				</div>
			</div>

			<div class="all_detail">
				<div class="slider-grid">
					<Splide ref="splideRef" :options="options" direction:direction>
						<SplideSlide v-for="(market_group) in fixtureStore.sortedMarketGroups(fixtureStore.marketGroups)" :key="market_group">
							<button class="tablinks" :class="{ 'active': market_group == fixtureStore.selectedMarketGroup }"
								@click="changeMarketGroup(market_group)">{{ market_group }} ({{fixtureStore.marketGroups[market_group]}})</button>
						</SplideSlide>
					</Splide>
				</div>
				<span class="toggle-markets">
					<div @click="markets_closed = !markets_closed" class="markets-toggle-fill">
						<svg v-if="!markets_closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline=""
							title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink"
							class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_close.svg#markets_close"></use>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle"
							role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink"
							class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_open.svg#markets_open"></use>
						</svg>
					</div>
				</span>
			</div>

			<div class="live-tab-info"
				v-if="fixtureStore.fixtureBook && fixtureStore.fixtureBook.markets && fixtureStore.fixtureBook.markets.length > 0">
				<div id="all_event" class="eventview-center-panel">
					<div class="live-info-outer" style="display:unset;">
						<FixtureBookMarket v-for="market in fixtureStore.fixtureBook.markets" :key="market.id"
							:market="market" :markets_closed="markets_closed" />
					</div>
				</div>

			</div>
			<div class="live-tab-info" v-else>
				<p class="no-bet-message"> {{ $t('general.no_markets') }}</p>
			</div>
			<!----- info tab start ------>

		</div>
		<!-- center grid end -->
	</div>
	<button @click="scrollToTop" type="button" class="btn scroll-to-top" id="btn-back-to-top" v-show="show_scroll_to_top_button">
		<i class="fas fa-arrow-up"></i>
	</button>
</template>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 991px) {
	.live-bet-dashboard .live-tab-info {
		max-height: unset;
	}
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	button#btn-back-to-top {
		display:none;
	}
}
@media screen and (min-width: 320px) and (max-width: 992px) {
	button#btn-back-to-top {
		bottom: 70px;
		left: 20px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	svg.svg-inline--fa.fa-arrow-up.fa-w-14 {
		margin-bottom: 7px!important;
	}
	button#btn-back-to-top {
		width: 30px!important;
		height: 30px!important;
		font-size: 14px!important;
	}
}
button#btn-back-to-top {
	background: #63A246;
	width: 38px;
	height: 38px;
	border: none;
	border-radius: 50%;
	position: fixed;
	z-index: 99;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	cursor: pointer;
}
html[dir="rtl"] button#btn-back-to-top {
	left: unset;
	right:20px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	button#btn-back-to-top {
		left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] button#btn-back-to-top {
		left: unset;
		right:10px;
	}
}
.all_detail {
	margin-bottom: 22px;
}

.slider-grid :deep(.splide__arrow:disabled) {
	display: none;
}
html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--next:after ) {
	rotate: 180deg;
}

html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--next) {
	left: 0px !important;
}

html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--prev:after ) {
	rotate: 180deg;
}
.slider-grid {
	display: grid;
	grid-auto-columns: minmax(142px, auto);
	grid-gap: 15px;
	grid-auto-flow: column;
	padding: 0px;
	width: 100%;
	height: 100%;
	transform: translateX(0px);
	transition: all 1s ease;
	border-bottom: 1px solid #707070;
}

.slider-grid .tablinks {
	color: #878A90;
	font-size: 13px;
	padding-bottom: 6px;
	text-transform: capitalize;
	cursor: pointer;
}

.slider-grid .active {
	color: #fff;
	position: relative;
}

.all_detail {
	position: relative;
	display: grid;
	grid-template-columns: 97% 3%;
	overflow: hidden;
}

.tab {
	overflow-y: hidden;
	overflow-x: overlay;
}


::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.all_detail::-webkit-scrollbar-thumb {
	background-color: #8f54a0;
	border-radius: 2px;
	border: 3px solid linear-gradient(#e382bc 0%, #8d54eb 100%);
}

.tablinks {
	white-space: nowrap;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.all_detail {
		grid-template-columns: 93% 5%;
	}
}

.live-info-outer {
	gap: 0px !important;
}

html[dir="rtl"] .top-search-bar .header-search-field button {
	left: 14px;
	right: unset;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.live-bet-dashboard .live-tab-info {
		min-height: 100%;
		max-height: unset;
		height: 100vh;
	}

	.sports-main .sports-center-content .eventview-center-panel .live-info-outer {
		border: none;
		overflow-y: scroll;
	}
}
</style>