<script setup>
import { closeModal } from '@kolirt/vue-modal'
import Register from '@/components/modals/Register.vue';
import Login from '@/components/modals/Login.vue';
import VerificationEmail from '@/components/modals/VerificationEmail.vue';
import { openModal } from '@kolirt/vue-modal';
import { useCustomerStore } from '@/stores/customer';
import { useRouter } from 'vue-router';

const customerStore = useCustomerStore();
const router = useRouter();

const props = defineProps({
	table: Object,
	image_url: {
		type: String,
		default: '/assets/images/brand_logo.svg'
	},
})

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() => { });
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() => { });
};

// Show Verification Mail Modal
const showVerificationEmailModal = () => {
	openModal(VerificationEmail).catch(() => { });
};

// Go To Table
const goToTable = (table_id, lobby_table_id) => {
	let router_object = { name: 'live-casino-table', params: { table_id: table_id, lobby_table_id: lobby_table_id } };

	// Check if Customer is logged in
	if (!customerStore.isLoggedIn) {
		useCustomerStore().redirect_after_login = router_object;

		showLoginModal();

		return;
	}

	// Check if Customer has verified email
	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	// Go to Table's view
	router.push(router_object);

	closeModal();
}
</script>
<template>
	<div id="customModal" class="custom-modal-detail">
		<div class="modal-content">

			<!-- Close Button Container -->
			<div class="close-container">
				<span id="closeModalBtn" class="close" @click="closeModal()">
					<svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="13.414" viewBox="0 0 13.414 13.414">
						<g id="close" transform="translate(0.707 0.707)">
							<line id="Line_2" data-name="Line 2" x2="12" y2="12" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
							<line id="Line_3" data-name="Line 3" x1="12" y2="12" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
						</g>
					</svg>
				</span>
			</div>

			<div class="slot-thumbnail default-image-container">
				<img v-if="props.image_url == '/assets/images/brand_logo.svg'" class="default-image" :src="props.image_url" alt="logo" />
				<img v-else :src="props.image_url" alt="logo" />
			</div>

			<div class="content">
				<p class="title-game-brand ellipsis">Lucky Streak</p>
				<p class="title-game-name ellipsis">{{ props.table.name }}</p>
			</div>

			<div class="button-container">
				<div v-if="customerStore.isLoggedIn" @click="goToTable(props.table.id)" class="btn-play">{{ $t('general.play_now') }}</div>
				<div v-else @click="goToTable(props.table.id)" class="btn-play">{{ $t('general.login_and_play') }}</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.custom-modal-detail .modal-content {
	background-color: #0C131A;
	padding: 0 0 15px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
	text-align: center;
	position: relative;
	height: max-content;
	min-width: 300px;
	max-width: 100vw;
	margin: 0 auto;
	border: 1px solid #363D47;
}

.custom-modal-detail .modal-content .close-container {
	height: 25px;
	width: 25px;
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 5px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border: 1px solid #363D47;
}

.custom-modal-detail .modal-content .close-container:hover {
	background-color: rgba(0, 0, 0, 0.2);
	transform: scale(1.05);
}

.custom-modal-detail .modal-content .close {
	padding-bottom: 3px;
}

.custom-modal-detail .modal-content .close svg {
	width: 11px;
	height: 11px;
}

.title-game-name {
	font-size: 15px;
	color: #F2F2F7;
	font-weight: bold;
	margin-bottom: 5px;
}

.title-game-brand {
	font-size: 17px;
	color: #848484;
}

.custom-modal-detail .modal-content .slot-thumbnail img {
	width: auto;
	padding: 10px 10px 3px 10px;
	border-radius: 15px;
	max-height: 200px;
	object-fit: fill;
}

.custom-modal-detail .modal-content .content {
	font-size: 14px;
	color: #F2F2F7;
	margin-bottom: 10px;
	padding-left: 12px;
	padding-right: 12px;
}

.custom-modal-detail .modal-content .close-button:after {
	content: " ";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	margin: -1px;
	border-radius: inherit;
	background-image: linear-gradient(50deg, #e382bc 0%, #8d54eb 100%);
}

.default-image-container {
	background-color: rgb(0 0 0 / 20%);
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.default-image {
	width: auto !important;
	height: 200px !important;
	max-height: 185px;
	opacity: 0.3;
}

.button-container {
	display: inline-flex;
	gap: 5px;
	height: 33px;
}

.custom-modal-detail .modal-content .btn-play {
	position: relative;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	padding: 4px 33px;
	font-size: 14px;
	font-weight: 600;
	background: #63A246;
	color: #fff;
	border: 1px solid transparent;
	transition: 0.3s ease-in-out;
	margin-left: 10px;
}

.custom-modal-detail .modal-content .btn-play:hover {
	background: #4e8234;
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	transform: translateY(-1px);
	border: 1px solid #4e8234;
}

.custom-modal-detail .modal-content .btn-favourite {
	position: relative;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	padding: 4px 10px;
	font-size: 14px;
	font-weight: 600;
	background: #202833;
	color: #fff;
	border: 1px solid transparent;
	transition: 0.3s ease-in-out;
}

.custom-modal-detail .modal-content .btn-favourite:hover {
	background: #2c3b4a;
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	transform: translateY(-1px);
	border: 1px solid #2c3b4a;
}

.custom-modal-detail .modal-content .btn-favourite:hover svg {
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(155, 59, 59, 0.1);
	transform: translateY(-1px);
}

.custom-modal-detail .modal-content .btn-demo {
	position: relative;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	padding: 4px 15px;
	font-size: 14px;
	font-weight: 600;
	background: #202833;
	color: #fff;
	border: 1px solid transparent;
	transition: 0.3s ease-in-out;
	margin-right: 10px;
}

.custom-modal-detail .modal-content .btn-demo:hover {
	background: #2c3b4a;
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	transform: translateY(-1px);
	border: 1px solid #2c3b4a;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	/* max-width: 270px; */
}
</style>
