
<script setup>
import Header from '@/components/Common/header.vue';
import MobileMenu from '@/components/Common/mobileMenu.vue';
import Footer from "@/components/Common/Footer.vue";
import Banner from "@/components/live_casino/Banner.vue"
import LiveCasinoContainer from '@/components/live_casino/LiveCasinoContainer.vue';
import { useLiveCasinoStore } from '@/stores/live_casino';
import { useCustomerStore } from '@/stores/customer';
import { useGeneralStore } from '@/stores/general';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const liveCasinoStore = useLiveCasinoStore();
const customer = useCustomerStore();
const { t } = useI18n();

onMounted(() => {
	document.title = useGeneralStore().getGeneral.site_name + ' - ' + t('routing.live_casino');

	if (liveCasinoStore.liveCasinoTablesCount == 0) {
		liveCasinoStore.fetchLiveCasino();
		if (customer.isLoggedIn) liveCasinoStore.fetchLastPlayedGames();
	}
});
</script>

<template>
	<div class="main_div">
		<!-- Header Start -->
		<Header></Header>
		<!-- Header End -->

		<!-- Mobile Menu Start -->
		<div class="fixed-btm-main">
			<div class="container-full fix-padding">
				<MobileMenu></MobileMenu>
			</div>
		</div>
		<!-- Mobile Menu End -->

		<!-- Slider Start -->
		<Banner></Banner>
		<!-- Slider End -->

		<!-- Live Casino Container Start -->
		<LiveCasinoContainer></LiveCasinoContainer>
		<!-- Live Casino Container End -->

		<!-- Footer Start -->
		<Footer></Footer>
		<!-- Footer End -->
	</div>
</template>
<style scoped>
.main_div {
	background-color: #202833;
}
html[dir="rtl"] .fix-padding {
	padding-left: unset;
	padding-right: unset;
}
</style>