<!-- eslint-disable -->
<script setup>
import NavigateTimeLimits from './NavigateTimeLimits.vue';
import { ref, onMounted, watch, nextTick } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';

// Define store
const customerStore = useCustomerStore();
const { t } = useI18n();

// Define variables
const time_out_id = ref(customerStore.customerProtectionSectionChoices('time_out')[0]?.id || null);
const form_edited = ref(false);
const loading = ref(false);
const success_message = ref(null);
const errors = ref({});
const alert_message = ref(null);

const update = () => {
	if (form_edited.value == false) return;

	errors.value = {};
	success_message.value = null;

	if (time_out_id.value === null || customerStore.getCustomer.time_out?.customer_protection_configuration_choice_id === time_out_id.value) {
		return;
	}

	loading.value = true;

	// Create payload
	let body = {
		id: time_out_id.value,
	}

	// Send request
	customerStore.updateCustomerProtectionTimeOut(body).then((response) => {
		success_message.value = response.data.message;
		fireSwal('success', response.data.message);

		// Fetch customer
		customerStore.fetchCustomer();

		loading.value = false;
		form_edited.value = false;
	}).catch((error) => {
		success_message.value = null;
		loading.value = false;

		if (!error.data.errors) return;

		let error_messages = Object.keys(error.data?.errors).map(key => error.data?.errors[key]);
		let message = error_messages.length > 0 ? error_messages.join(', ') : error.data?.message;
		alert_message.value = message;
		errors.value = error.data.errors;
		form_edited.value = false;
		fireSwal('error', message);
	});
}

const resetForm = () => {
	time_out_id.value = customerStore.getCustomer.time_out?.customer_protection_configuration_choice_id || null;
	form_edited.value = false;
}

const formatValue = (option) => {
	if (option['type'] == 'infinite' || option['type'] == 'no limit')
		return option['type'].charAt(0).toUpperCase() + option['type'].slice(1);

	return option['value'] + ' ' + option['type'].charAt(0).toUpperCase() + option['type'].slice(1);
}

watch(success_message, (newValue, oldValue) => {
	if (newValue != null) {
		setTimeout(() => {
			success_message.value = null;
		}, 3000);
	}
});

watch(errors, (newValue, oldValue) => {
	if (Object.keys(newValue).length > 0) {
		setTimeout(() => {
			errors.value = {};
		}, 3000);
	}
});

watch(time_out_id, (newValue, oldValue) => {
	if (newValue != oldValue) {
		form_edited.value = true;
	}
})

const fireSwal = (alert_type, alert_message) => {
	Swal.fire({
		text: alert_message,
		icon: alert_type,
		confirmButtonText: alert_type == 'success' ? t('account.register.close') : t('general.try_again'),
	});
}

onMounted(() => {
	// Set selector to current limit id or default
	time_out_id.value = customerStore.getCustomer.time_out?.customer_protection_configuration_choice_id || null;

	nextTick().then(() => {
		form_edited.value = false;
	});
})
</script>
<template>
	<div class="dash-content-main player-protection-main bcom-time-out-limits" id="prefrences">
		<div class="dash-content-outer">
			<div class="dash-main-content">
				<div class="title">
					<h3>{{$t('account.customer_protection.time_out_title')+' + ' + $t('account.customer_protection.limits')}}</h3>
				</div>

				<div class="player-protection-outer">
					<NavigateTimeLimits></NavigateTimeLimits>
					<div class="content-outer single-column time-out" id="login-limit">
						<div class="content-inner">
							<div class="list-content">
								<div class="dashboard-form register-inner-form no-column">
									<div class="desc-content">
										<p>{{$t('account.customer_protection.time_out_description_text_1')}}</p>
									</div>
									<div class="register-form-field select-field">

										<select class="form-input" id="address-country" v-model="time_out_id">
											<option :value="null" disabled>{{ $t('account.customer_protection.no_timeout') }}</option>
											<option class="form-input"
												v-for="option in customerStore.customerProtectionSectionChoices('time_out')"
												:key="option.id" :value="option.id"
												:selected="option.id == time_out_id">
												{{ formatValue(option) }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="list-instructions">
								<ul>
									<li>
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
											<g id="Group_223" data-name="Group 223" transform="translate(-566 -572.276)">
												<path id="Icon_awesome-info-circle" data-name="Icon awesome-info-circle" d="M6.562.563a6,6,0,1,0,6,6A6,6,0,0,0,6.562.563Zm0,2.661A1.016,1.016,0,1,1,5.546,4.24,1.016,1.016,0,0,1,6.562,3.224ZM7.917,9.369a.29.29,0,0,1-.29.29H5.5a.29.29,0,0,1-.29-.29V8.788A.29.29,0,0,1,5.5,8.5h.29V6.95H5.5a.29.29,0,0,1-.29-.29V6.079a.29.29,0,0,1,.29-.29H7.046a.29.29,0,0,1,.29.29V8.5h.29a.29.29,0,0,1,.29.29Z" transform="translate(565.438 571.713)" fill="#fff"></path>
											</g>
										</svg>
										<p>{{$t('account.customer_protection.time_out_description_text_2')}}</p>
									</li>
								</ul>

							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="dashboard-footer">
				<div class="dash-btns">
					<button type="button" class="btn"  @click="resetForm()">{{$t('general.cancel')}}</button>
				</div>
				<div class="dash-btns">
					<button type="button" class="btn btn-secondary" v-if="loading">
						<div class="loader-main"></div>
					</button>
					<button type="button" class="btn btn-secondary" v-if="!loading" @click="update()" :class="{'disabled-button': form_edited == false }">{{$t('general.update')}}</button>
				</div>
			</div>
		</div>
		<div class="close-btn" @click="goToPreviousPage(accountStore.getPreviousRoute)">
			<a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="21.414" viewBox="0 0 21.414 21.414">
					<g id="close" transform="translate(0.707 0.707)">
						<line id="Line_2" data-name="Line 2" x2="20" y2="20" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1"></line>
						<line id="Line_3" data-name="Line 3" x1="20" y2="20" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1"></line>
					</g>
				</svg>
			</a>
		</div>
	</div>
</template>

<style scoped>
.disabled-button {
	pointer-events: none;
	background: transparent;
	color: #4E545D;
	border: 1px solid #4E545D;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dashboard-main .dashboard-outer .dash-mobile-title {
		padding:unset;
		padding-bottom: 10px;
	}
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .dashboard-footer .dash-btns:last-child {
	text-align: left;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .player-protection-main .dash-content-outer .dash-main-content .player-protection-outer .content-outer.single-column .content-inner:nth-child(1) {
	padding-right: unset;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .close-btn {
	right: unset;
	left: 50px;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .register-inner-form select {
	background-position: 5%;
}
</style>

