<script setup>
/* eslint-disable */
import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
import { useMarketsStore } from '@/stores/markets';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useCouponsStore } from '@/stores/coupons';
import { useRouter, useRoute } from 'vue-router';
import NextFixturesPerHour from './NextHoursPerHour.vue';
import NextFixturesPerCompetition from './NextHoursPerCompetition.vue';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';
import { SportsData } from '@/stores/Sports';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const marketsStore = useMarketsStore();
const couponsStore = useCouponsStore();
const preSportsStore = usePreSportsStore();
const SportsDataStore = SportsData();
const isDropActive = ref(false);
const selectedText = ref(t('general.per_hour'));

const route = useRoute();
const router = useRouter();

const show_primary_overview_market = ref(false);
const show_secondary_overview_market = ref(false);

const primary_overview_market = ref(null);
const secondary_overview_market = ref(null);

const active_tab = ref('per_hour');

const changeActiveTab = (tab) => {
	active_tab.value = tab;
}

const toggleDrop = () => {
	isDropActive.value = !isDropActive.value;
};
const selectOption = (text) => {
	selectedText.value = text;
	isDropActive.value = false;
};


const selectPrimaryOverviewMarket = (overview_market) => {
	primary_overview_market.value = overview_market;
	show_primary_overview_market.value = false;
}

const selectSecondaryOverviewMarket = (overview_market) => {
	secondary_overview_market.value = overview_market;
	show_secondary_overview_market.value = false;
}

const closePrimaryOverviewMarketSelector = () => {
	show_primary_overview_market.value = false;
}

const closeSecondaryOverviewMarketSelector = () => {
	show_secondary_overview_market.value = false;
}

onMounted( () => {
	let sport_overview_markets = marketsStore.sportOverviewMarkets(router.currentRoute.value.params.sport_id);

	if (sport_overview_markets.length > 1) {

		primary_overview_market.value = sport_overview_markets[0];

		secondary_overview_market.value = sport_overview_markets[1]
	} else {
		primary_overview_market.value = sport_overview_markets[0];
		secondary_overview_market.value = sport_overview_markets[0]
	}

	if (router.currentRoute.value.params.hours && router.currentRoute.value.params.sport_id) {
		couponsStore.setFixturesLoading(true);
		couponsStore.fetchNextHourFixtures({ sport_id: router.currentRoute.value.params.sport_id, hours: router.currentRoute.value.params.hours });
	}
})

watch(route, (to, from) => {
	if (to.params.hours) {
		couponsStore.setFixturesLoading(true);
		couponsStore.emptyNextHourFixtures();
		couponsStore.fetchNextHourFixtures({ sport_id: to.params.sport_id, hours: to.params.hours });
	}
});

</script>
<template>

<div class="live-bet-dashboard round-games-main set_game-outer">
	<div class="bet-dashboard-top">
		<div class="tabing-dashboard">
			<div class="tab-pills">
				<button class="tab-link" :class="{'active': active_tab == 'per_hour'}" data-tab-id="live" @click="changeActiveTab('per_hour')">{{ $t('general.per_hour') }} </button>
				<button class="tab-link" :class="{'active': active_tab == 'per_competition'}" data-tab-id="upcoming" @click="changeActiveTab('per_competition')">{{ $t('general.per_competition') }}</button>
			</div>
		</div>

		<div class="dashboard-info-label mobile-only-dropdown">
			<div class="select-option">
				<dl class="drop" :class="{'active': isDropActive}">
					<span class="tabIco" @click="toggleDrop">
						<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
							<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
								<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff"/>
							</g>
						</svg>
					</span>
					<dt @click="toggleDrop">
						<button class="tablinks">{{ selectedText }}</button>
					</dt>
					<dd v-if="isDropActive">
						<ul>
							<li>
								<button class="tablinks" @click="changeActiveTab('per_hour'); selectOption($t('general.per_hour'))" :class="{'active': active_tab == 'per_hour'}">{{ $t('general.per_hour') }}</button>
							</li>
							<li>
								<button class="tablinks" @click="changeActiveTab('per_competition'); selectOption($t('general.per_competition'))" :class="{'active': active_tab == 'per_competition'}">{{ $t('general.per_competition') }}</button>
							</li>
						</ul>
					</dd>
				</dl>
			</div>
		</div>


		<div class="dashboard-info-label dropdown-option-top" v-if="primary_overview_market">
			<div class="select-option">
				<dl class="drop" :class="show_primary_overview_market ? 'active' : ''">
					<span class="tabIco">
						<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
							<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
								<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
							</g>
						</svg>
					</span>
					<dt><button class="tablinks" @click="show_primary_overview_market = !show_primary_overview_market"  v-click-outside-element="closePrimaryOverviewMarketSelector">{{ primary_overview_market.name.toUpperCase() }}</button>
					</dt>
					<dd>
						<ul>
							<li v-for="overview_market in marketsStore.sportOverviewMarkets(preSportsStore.selected_esport_id)" :key="overview_market.slug" @click="selectPrimaryOverviewMarket(overview_market)" :class="primary_overview_market.slug == overview_market.slug ? 'active' : ''">
								<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
							</li>
						</ul>
					</dd>
				</dl>
			</div>
		</div>

		<div class="dashboard-info-label dropdown-option-top dropdown-option-top-2" v-if="secondary_overview_market">
			<div class="select-option">
				<dl class="drop" :class="show_secondary_overview_market ? 'active' : ''">
					<span class="tabIco">
						<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
							<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
								<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
							</g>
						</svg>
					</span>
					<dt><button class="tablinks" @click="show_secondary_overview_market = !show_secondary_overview_market"  v-click-outside-element="closeSecondaryOverviewMarketSelector">{{ secondary_overview_market.name.toUpperCase() }}</button>
					</dt>
					<dd>
						<ul>
							<li v-for="overview_market in marketsStore.sportOverviewMarkets(preSportsStore.selected_esport_id)" :key="overview_market.slug" @click="selectSecondaryOverviewMarket(overview_market)" :class="secondary_overview_market.slug == overview_market.slug ? 'active' : ''">
								<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
							</li>
						</ul>
					</dd>
				</dl>
			</div>
		</div>

		<div class="mobile-odds-heading">
			<div class="mobile-odds-heading-inner">
				<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
						<span v-if="odd != ''">{{ odd }}</span>
				</template>
			</div>

		</div>

	</div>

	<LoaderComponent v-if="couponsStore.fixtures_loading"/>
	<template v-else>
		<template v-if="active_tab == 'per_hour'">
			<NextFixturesPerHour :primary_overview_market="primary_overview_market" :secondary_overview_market="secondary_overview_market"/>
		</template>
		<template v-else>
			<NextFixturesPerCompetition v-for="competition in couponsStore.nextHourFixturesPerCompetition" :key="competition" :competition="competition" :primary_overview_market="primary_overview_market" :secondary_overview_market="secondary_overview_market"/>
		</template>
	</template>

</div>

</template>

<style scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}

}
</style>
