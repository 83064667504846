<script setup>
/* eslint-disable */
import { useCouponsStore } from '@/stores/coupons';
import EsportFixtureDoubleMarket from '@/components/esports/highlights/EsportFixtureDoubleMarket.vue'

const couponsStore = useCouponsStore();
const props = defineProps({
	primary_overview_market: {
		type: Object,
		required: true,
	},
	secondary_overview_market: {
		type: Object,
		required: true,
	},
});

</script>

<template>
	<div class="live-tab-info competition-tab-main set_game-outer">
		<div id="competition" class="tab-content competetion-outer-main">

			<div class="live-info-outer">
				<div class="live-info-inner">
					<div class="live-info-team"></div>

					<div class="full-time-top top-title-dash">
						<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
							<span v-if="odd != ''">{{ odd }}</span>
						</template>
					</div>

					<div class="match-goal-top top-title-dash">
						<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
							<span v-if="odd != ''">{{ odd }}</span>
						</template>
						<span class="bet-column-empty"></span>

					</div>

				</div>

				<EsportFixtureDoubleMarket  v-for="fixture in couponsStore.nextHourFixturesPerHour" :key="fixture.id"
					:primary_overview_market="primary_overview_market"
					:secondary_overview_market="secondary_overview_market"
					:fixture="fixture"
				/>
			</div>

		</div>
	</div>
</template>