<script setup>
/* eslint-disable */
import { ref, computed } from "vue";
import { useFavouritesStore } from '@/stores/favourites';

import LeftSidebarFavouriteCompetition from './LeftSidebarFavouriteCompetition.vue';

const favouritesStore = useFavouritesStore();
const tab_opened = ref(true);

const favouritesCount = computed(() => {
	let count = 0;
	for (const key in favouritesStore.preLeftSidebarFavourites) {
		count += favouritesStore.preLeftSidebarFavourites[key].length;
	}

	return count;
});

</script>
<template>
	<div class="accordion-item" :class="{'active': tab_opened}">
		<div class="accordion-header" @click="tab_opened = !tab_opened">{{ $t('general.favourites') }} ({{ favouritesCount }})
			<div class="acoordion-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
					<g id="Group_776" data-name="Group 776" transform="translate(-272 -6)">
						<g id="minimize" transform="translate(272 10)">
							<rect id="Rectangle_408" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
						<g id="minimize-2" class="hide-i" data-name="minimize" transform="translate(277 6) rotate(90)">
							<rect id="Rectangle_408-2" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
					</g>
				</svg>
			</div>
		</div>
		<div class="accordion-content favrouites-content">
			<LeftSidebarFavouriteCompetition v-for="(competition, index) in favouritesStore.preLeftSidebarFavourites" :key="index" :competition="competition"/>
		</div>

	</div>
</template>