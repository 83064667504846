import { defineStore } from 'pinia';
import { useCustomerStore } from './customer';
import _ from 'lodash';

export const useCasinoStore = defineStore('casinoStore', {
	state: () => ({
		// Main data
		categories: {},
		providers: {},
		slots: [],
		favourites: {},
		has_favourites: false,
		last_played_games: {},
		has_last_played_games: false,
		featured_slot_games: {},
		slots_count: 0,

		games_count_per_category: {},

		active_page: 'landing', // Values: landing, category, provider, favourites, last_played, search

		// Filters
		selected_category_id: null,
		selected_provider_id: null,
		selected_provider_ids: [],
		sort_type: 'popular',
		search_term: null,

		// Paginator
		current_page: 1,
		total_pages: 1,

		// Loading
		games_loading: false,
		games_loading_more: true,
	}),
	getters: {
		getGames: (state) => {
			if (!state.slots) return {};

			let customerStore = useCustomerStore();
			let favourites = (customerStore.customer && customerStore.customer.favourites.slots.length > 0) ? customerStore.customer.favourites.slots : [];

			if (state.selected_category_id == null) {
				let categories = _.cloneDeep(state.slots);

				// Process each category
				_.each(categories, (category_slots) => {
					let games = _.map(category_slots.slots, game => {
						let favourite_record = _.find(favourites, { game_id: String(game.id) });
						let category_pivot = _.find(game.categories_ids, { id: category_slots.id });

						return {
							...game,
							favourite: favourite_record ? true : false,
							favourite_timestamp: favourite_record ? favourite_record.created_at : null,
							category_order: category_pivot ? category_pivot.order : 999999
						};
					});

					let favourite_games = _.filter(games, { 'favourite': true });
					favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

					let not_favourite_games = _.filter(games, { 'favourite': false });
					if (state.sort_type === 'popular') {
						not_favourite_games = _.orderBy(not_favourite_games, ['category_order', 'name'], ['asc', 'asc']);
					} else if (state.sort_type === 'alphabetical') {
						not_favourite_games = _.orderBy(not_favourite_games, ['name'], ['asc']);
					}

					// Reassign processed games back to the category
					category_slots.slots = [...favourite_games, ...not_favourite_games];
				});

				return categories;
			} else {
				let slots = _.cloneDeep(state.slots);

				// Process the selected category
				let games = _.map(slots, game => {
					let favourite_record = _.find(favourites, { game_id: String(game.id) });
					let category_pivot = _.find(game.categories_ids, { id: state.selected_category_id });

					return {
						...game,
						favourite: favourite_record ? true : false,
						favourite_timestamp: favourite_record ? favourite_record.created_at : null,
						category_order: category_pivot ? category_pivot.order : 999999
					};
				});

				let favourite_games = _.filter(games, { 'favourite': true });
				favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

				let not_favourite_games = _.filter(games, { 'favourite': false });

				if (state.sort_type === 'popular') {
					not_favourite_games = _.orderBy(not_favourite_games, ['category_order', 'name'], ['asc', 'asc']);
				} else if (state.sort_type === 'alphabetical') {
					not_favourite_games = _.orderBy(not_favourite_games, ['name'], ['asc']);
				}

				// Reassign processed games back to the category
				slots = [...favourite_games, ...not_favourite_games];

				return slots;

			}
		},
		getLastPlayedGames(state) {
			// Add favourite flag to the games
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			let last_played_games = _.map(state.last_played_games, game => {
				let favourite_record = null;
				favourite_record = _.find(favourites, { 'game_id': String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			return _.orderBy(last_played_games, ['favourite_timestamp', 'name'], ['asc', 'asc']);
		},
		getFavoriteGames(state) {
			// Add favourite flag to the games
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			let favourite_games = _.map(state.favourites, game => {
				let favourite_record = null;
				favourite_record = _.find(favourites, { 'game_id': String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			return _.orderBy(favourite_games, ['favourite_timestamp', 'name'], ['asc', 'asc']);
		},
		hasFavourites(state) {
			return state.has_favourites;
		},
		hasLastPlayedGames(state) {
			return state.has_last_played_games;
		},
		getSlotsCount(state) {
			if (!state.slots_count) return 0;

			return state.slots_count;
		},
		getProviders(state) {
			return state.providers;
		},
		getCategories(state) {
			return state.categories;
		},
		getSelectedCategoryId(state) {
			return state.selected_category_id;
		},
		getSelectedCategory(state) {
			if (!state.selected_category_id) return null;

			return _.find(state.categories, { id: state.selected_category_id });
		},
		getSelectedProviderIds(state) {
			if (state.selected_provider_ids.length == 0) {
				return [];
			}

			return state.selected_provider_ids;
		},
		getSelectedProviderId(state) {
			if (!state.selected_provider_id) return null;

			return state.selected_provider_id;
		},
		getSelectedProvider(state) {
			if (!state.selected_provider_id) return null;

			return _.find(state.providers, { id: state.selected_provider_id });
		},
		getStorageUrl() {
			if (!process.env.VUE_APP_STORAGE_URL) return '';

			return process.env.VUE_APP_STORAGE_URL;
		},
		getCurrentPage(state) {
			return state.current_page;
		},
		getTotalPages(state) {
			return state.total_pages;
		},
		getGamesCountPerCategory(state) {
			return state.games_count_per_category;
		},
		getSelectedSlotProviderIds(state) {
			return state.selected_provider_ids;
		},
		getSlotProvidersFormatted: (state) => (sort_mode) => {
			if (!state.providers) return [];

			let providers = null

			// Sorting logic based on the sort_mode
			if (sort_mode === 'alphabetical') {
				// Sort brands alphabetically by name
				providers = _.sortBy(state.providers, ['name']);
			} else if (sort_mode === 'popular') {
				// Sort providers by the 'order' property for popularity
				providers = _.sortBy(state.providers, ['order']);
			}

			return providers;
		},
		getGamesLoading(state) {
			return state.games_loading;
		},
		getGamesLoadingMore(state) {
			return state.games_loading_more;
		},
		hasNextPage(state) {
			return state.current_page < state.total_pages ? true : false;
		},
		getSortType(state) {
			return state.sort_type;
		},
		getActivePage(state) {
			return state.active_page;
		},
		getSearchTerm(state) {
			return state.search_term;
		},
		featuredSlotGames(state) {
			if (!state.slots) return [];

			let games = _.cloneDeep(state.featured_slot_games);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });
			games = [...favourite_games, ...not_favourite_games];

			return _.filter(games, game => game.image_url !== null);
		},
		featuredSlotGamesFooter(state) {
			if (!state.slots) return [];

			let games = _.cloneDeep(state.featured_slot_games);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });
			games = [...favourite_games, ...not_favourite_games];

			return _.chain(games).cloneDeep().filter(game => game.image_url !== null).take(8).value();
		},
	},
	actions: {
		fetchGames() {
			if (this.current_page == 1) this.slots = [];
			this.games_loading_more = true;
			let device = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				device = 'mobile';
			}

			let payload = {
				category_id: this.selected_category_id,
				provider_ids: this.selected_provider_ids,
				page: this.current_page,
				device: device,
				sort_type: this.sort_type
			};

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/index', payload, { headers: { 'Version': 'v4' } }).then((response) => {
				this.categories = response.data['categories'],
				this.providers = response.data['providers'];

				if (this.current_page == 1) {
					// On page 1, reset the games list
					this.slots = response.data['games'];
				} else {
					// For next pages, push new games to the existing list if they don't already exist
					this.slots.push(...response.data['games']);
				}

				this.games_count_per_category = response.data['games_count_per_category'] ?? 10;

				this.games_loading = false;
				this.games_loading_more = false;
				this.total_pages = response.data['total_pages'];

				this.has_favourites = response.data['favourites'];
				this.has_last_played_games = response.data['last_played'];

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				this.games_loading = false;
				this.games_loading_more = false;
				console.log(error.response);

				return Promise.reject(error);
			});
		},
		fetchProviderGames() {
			if (this.current_page == 1) this.slots = [];
			this.games_loading_more = true;
			let device = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				device = 'mobile';
			}

			let payload = {
				provider_id: this.selected_provider_id,
				page: this.current_page,
				device: device,
				sort_type: this.sort_type
			};

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/provider', payload, { headers: { 'Version': 'v4' } }).then((response) => {
				this.categories = response.data['categories'],
				this.providers = response.data['providers'];

				if (this.current_page == 1) {
					// On page 1, reset the games list
					this.slots = response.data['games'];
				} else {
					// For next pages, push new games to the existing list if they don't already exist
					this.slots.push(...response.data['games']);
				}

				this.games_count_per_category = response.data['games_count_per_provider'] ?? 10;

				this.games_loading = false;
				this.games_loading_more = false;
				this.total_pages = response.data['total_pages'];

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				this.games_loading = false;
				this.games_loading_more = false;
				console.log(error.response);

				return Promise.reject(error);
			});
		},
		fetchLastPlayedGames() {
			if (this.current_page == 1) this.last_played_games = [];
			this.games_loading_more = true;
			this.slots_count = 0;

			let device = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				device = 'mobile';
			}

			let payload = {
				provider_ids: this.selected_provider_ids,
				page: this.current_page,
				device: device,
				sort_type: this.sort_type
			};

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload['device'] = 'mobile';
			}

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/last-played', payload, { headers: { 'Version': 'v4' } }).then((response) => {
				if (this.current_page == 1) {
					// On page 1, reset the games list
					this.last_played_games = response.data['games'];
				} else {
					// For next pages, push new games to the existing list if they don't already exist
					this.last_played_games.push(...response.data['games']);
				}

				this.providers = response.data['providers'];
				this.slots_count = response.data['slots_count'];
				this.total_pages = response.data['total_pages'];

				this.games_loading = false;
				this.games_loading_more = false;

				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error);

				this.games_loading = false;
				this.games_loading_more = false;

				return Promise.reject(error);
			})
		},
		fetchFavouriteGames() {
			if (this.current_page == 1) this.favourites = [];
			this.games_loading_more = true;
			this.slots_count = 0;

			let device = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				device = 'mobile';
			}

			let payload = {
				provider_ids: this.selected_provider_ids,
				page: this.current_page,
				device: device,
				sort_type: this.sort_type
			};

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload['device'] = 'mobile';
			}

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/favourites', payload, { headers: { 'Version': 'v4' } }).then((response) => {
				if (this.current_page == 1) {
					// On page 1, reset the games list
					this.favourites = response.data['games'];
				} else {
					// For next pages, push new games to the existing list if they don't already exist
					this.favourites.push(...response.data['games']);
				}

				this.providers = response.data['providers'];
				this.slots_count = response.data['slots_count'];
				this.total_pages = response.data['total_pages'];

				this.games_loading = false;
				this.games_loading_more = false;

				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error);

				this.games_loading = false;
				this.games_loading_more = false;

				return Promise.reject(error);
			})
		},
		searchGames() {
			if (this.current_page == 1) this.slots = [];
			this.games_loading_more = true;
			let device = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				device = 'mobile';
			}

			let payload = {
				provider_ids: this.selected_provider_ids,
				page: this.current_page,
				device: device,
				sort_type: this.sort_type,
				search_term: this.search_term
			};

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/search', payload, { headers: { 'Version': 'v4' } }).then((response) => {
				this.categories = response.data['categories'],
				this.providers = response.data['providers'];

				if (this.current_page == 1) {
					// On page 1, reset the games list
					this.slots = response.data['games'];
				} else {
					// For next pages, push new games to the existing list if they don't already exist
					this.slots.push(...response.data['games']);
				}

				this.slots_count = response.data['slots_count'];

				this.games_loading = false;
				this.games_loading_more = false;
				this.total_pages = response.data['total_pages'];

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				this.games_loading = false;
				this.games_loading_more = false;
				console.log(error.response);

				return Promise.reject(error);
			});
		},
		setSelectedCategoryId(category_id) {
			this.selected_category_id = category_id;
			this.selected_provider_ids = [];
			this.current_page = 1;
			this.slots = [];
			this.search_term = null;

			if (category_id == null) this.active_page = 'landing';
			else if (category_id == 'favourites') this.active_page = 'favourites';
			else if (category_id == 'last_played_games') this.active_page = 'last_played_games';
			else if (category_id == 'provider') this.active_page = 'provider';
			else this.active_page = 'category';

			if (this.active_page == 'landing' || this.active_page == 'category') this.fetchGames();
			else if (this.active_page == 'favourites') this.fetchFavouriteGames();
			else if (this.active_page == 'last_played_games') this.fetchLastPlayedGames();
			else if (this.active_page == 'provider') this.fetchProviderGames();
		},

		toggleSelectedSlotProviderId(slot_provider_id) {
			const index = this.selected_provider_ids.indexOf(slot_provider_id);

			if (slot_provider_id === null) {
				// When 'all' is clicked, set the list to only contain 'all'
				this.selected_provider_ids = [];
			} else {
				// Remove 'all' if it is currently selected and another provider is toggled
				const allIndex = this.selected_provider_ids.indexOf('all');
				if (allIndex !== -1) {
					this.selected_provider_ids.splice(allIndex, 1);
				}

				// Toggle the provider ID
				if (index === -1) {
					// Add the provider ID if it's not in the list
					this.selected_provider_ids.push(slot_provider_id);
				} else {
					// Remove the provider ID if it's already in the list
					this.selected_provider_ids.splice(index, 1);
				}
			}

			this.current_page = 1;

			if (this.active_page == 'landing' || this.active_page == 'category') {
				this.slots = [];
				this.fetchGames();
			} else if (this.active_page == 'favourites') {
				this.favourites = [];
				this.fetchFavouriteGames();
			} else if (this.active_page == 'last_played_games') {
				this.last_played_games = [];
				this.fetchLastPlayedGames();
			} else if (this.active_page == 'search') {
				this.slots = [];
				this.searchGames();
			}
		},
		setSelectedSlotProviderIds(slot_provider_ids) {
			this.selected_provider_ids = slot_provider_ids.value;

			this.current_page = 1;

			if (this.active_page == 'landing' || this.active_page == 'category') {
				this.slots = [];
				this.fetchGames();

			} else if (this.active_page == 'favourites') {
				this.favourites = [];
				this.fetchFavouriteGames();

			} else if (this.active_page == 'last_played_games') {
				this.last_played_games = [];
				this.fetchLastPlayedGames();
			} else if (this.active_page == 'search') {
				this.slots = [];
				this.searchGames();
			}

		},
		setSelectedProviderId(provider_id) {
			if (provider_id == null) {
				this.active_page = 'landing';
				this.selected_category_id = null;
				this.selected_provider_ids = [];
				this.current_page = 1;
				this.slots = [];

				this.fetchGames();
			} else {
				this.active_page = 'provider';
				this.selected_provider_id = provider_id;
				this.selected_provider_ids = [provider_id];
				this.current_page = 1;
				this.slots = [];

				this.fetchProviderGames();
			}
		},
		loadMoreGames() {
			this.current_page++;
			// console.log('current page', this.current_page);
			if (this.active_page == 'landing' || this.active_page == 'category') this.fetchGames();
			else if (this.active_page == 'favourites') this.fetchFavouriteGames();
			else if (this.active_page == 'last_played_games') this.fetchLastPlayedGames();
			else if (this.active_page == 'provider') this.fetchProviderGames();
			else if (this.active_page == 'search') this.searchGames();
		},
		setSortType(sort_type) {
			this.sort_type = sort_type;
			this.current_page = 1;
		},
		toggleFavourite(game_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/favourite/slot', { id: game_id }).then((response) => {
				const customerStore = useCustomerStore();
				customerStore.customer.favourites = response.data.data;

				let favourites = customerStore.customer && customerStore.customer.favourites.slots.length > 0 ? customerStore.customer.favourites.slots : [];

				let is_favourite = _.find(favourites, { game_id: String(game_id) });

				// Loop through each category in slots and update the game within those categories
				this.slots.forEach(category => {
					let game = _.find(category.slots, { 'id': game_id });
					if (game) {
						if (is_favourite) {
							game.favourite = true;
							game.favourite_timestamp = is_favourite.created_at;
						} else {
							game.favourite = false;
							game.favourite_timestamp = null;
						}
					}
				});

				// Update the favourites array directly from the filtered slots
				if (this.selected_category_id == null) {
					this.favourites = _.flatMap(this.slots, category => category.slots).filter(game => game.favourite);
				} else {
					this.favourites = this.slots.filter(game => game.favourite);
				}

				if (Object.entries(customerStore.customer.favourites.slots).length == 0) {
					this.has_favourites = false;
					this.slots = [];

					this.favourites = {};
					this.last_played_games = {};
					this.slots_count = 0;
					this.games_count_per_category = {};

					this.active_page = 'landing';
					this.selected_category_id = null;
					this.selected_provider_id = null;
					this.selected_provider_ids = [];
					this.sort_type = 'popular';
					this.search_term = null;

					this.current_page = 1;

					this.fetchGames();
				} else {
					if (this.has_favourites == false) this.has_favourites = true;
				}

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},
		setSearchTerm(search_term) {
			if (search_term != '' && search_term != null) {
				this.active_page = 'search';
				this.search_term = search_term;
				this.selected_provider_ids = [];
				this.current_page = 1;
				this.slots = [];
				this.selected_category_id = null;
				this.slots_count = 0;

				this.searchGames();
			} else {
				this.slots = [];
				this.favourites = {};
				this.last_played_games = {};
				this.slots_count = 0;
				this.games_count_per_category = {};

				this.active_page = 'landing';
				this.selected_category_id = null;
				this.selected_provider_id = null;
				this.selected_provider_ids = [];
				this.sort_type = 'popular';
				this.search_term = null;

				this.current_page = 1;

				this.fetchGames();
			}
		},
		goBack() {
			this.slots = [];
			this.favourites = {};
			this.last_played_games = {};
			this.slots_count = 0;
			this.games_count_per_category = {};

			this.active_page = 'landing';
			this.selected_category_id = null;
			this.selected_provider_id = null;
			this.selected_provider_ids = [];
			this.sort_type = 'popular';
			this.search_term = null;

			this.current_page = 1;

			this.fetchGames();
		},
		openGame(game_id, demo = false) {
			let protocol = window.location.protocol;
			let hostname = window.location.hostname;
			let port = window.location.port ? window.location.port : '';
			let close_url = protocol + '//' + hostname + (port ? ':' + port : '') + '/casino/close';

			let payload = {
				game_id: game_id,
				close_url: close_url
			};

			if (demo) payload['demo'] = true;

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/opengame', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchFeaturedSlotGames(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/featured', payload, { headers: { 'Version': 'v4' } }).then((response) => {
				this.featured_slot_games = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		claimFreespinsAndPlay(freespin_id, slot_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/account/freespins/claim', { freespin_id: freespin_id, slot_id: slot_id }).then(response => {

				return Promise.resolve(response.data);
			}).catch(error => {
				return Promise.reject(error);
			});
		},
		cancelFreespin(freespin_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/account/freespins/cancel', { freespin_id: freespin_id }).then(response => {

				return Promise.resolve(response.data);
			}).catch(error => {
				return Promise.reject(error);
			});
		},
	},
})