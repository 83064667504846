// socketStore.js
import { defineStore } from 'pinia';
import io from 'socket.io-client';

export const useSocketStore = defineStore('socket', {
	state: () => ({
		socket: null,
		locale: 'en'
	}),
	getters: {
		getSocket() {
			return this.socket;
		},
	},
	actions: {
		initializeSocket(locale) {
			this.socket = io(process.env.VUE_APP_SOCKET_IO_URL + 'sb-' + locale, {
				path: process.env.VUE_APP_SOCKET_IO_PATH,
				transports: ['websocket'],
			})
		},
	},
});