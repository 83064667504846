import { defineStore } from 'pinia';
import { useLanguagesStore } from './languages';
import { useCurrenciesStore } from './currencies';
import { useRegisterStore } from './register';

import { useCountriesStore } from './countries';
import { useTimezonesStore } from './timezones';
import { useGeneralStore } from './general';
import { useRegistrationDocumentTypesStore } from './registration_documents_types';
import { useMenuStore } from './menus';
import { BetSlip } from './BetSlip';
import { usePromotionsStore } from '@/stores/promotions';
import { useAccaBoostStore } from './acca_boost';
import _ from 'lodash';

export const useInitializeStore = defineStore('initialize', {
	state: () => ({
		server_timestamp: 0,
		screen_width: window.innerWidth,
		product_settings: null
	}),
	getters: {
		screenWidth(state) {
			return state.screen_width;
		},
		isMobile() {
			let is_mobile = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

			if (is_mobile) {
				// Code for mobile devices
				return true;
			}

			return false;
		},
		// see if the user is using an iPhone or iPod
		isIphoneOrIpod() {
			const userAgent = window.navigator.userAgent;
			const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
			const webkit = !!userAgent.match(/WebKit/i);
			const iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i);
			if (iOSSafari) return true;
			else return false;
		},
		betcomCourtEnabled() {
			if (process.env.VUE_APP_VIRTUAL_COURT_BETCOM_ENABLED) return true;
			return false;
		},
		isSportsbookEnabled(state) {
			let product = _.find(state.product_settings, {'product': 'sportsbook'});
			if (product.enabled == true) return true;

			return false;
		},
		isCasinoEnabled(state) {
			let product = _.find(state.product_settings, {'product': 'casino'});
			if (product.enabled == true) return true;

			return false;
		},
		isLiveCasinoEnabled(state) {
			let product = _.find(state.product_settings, {'product': 'live-casino'});
			if (product.enabled == true) return true;

			return false;
		}
	},
	actions: {
		initialize() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/initialize').then((response) => {
				useLanguagesStore().setLanguages(response.data.data.languages);
				useCurrenciesStore().setCurrencies(response.data.data.currencies);
				useCountriesStore().setCountries(response.data.data.countries);
				useTimezonesStore().setTimezones(response.data.data.timezones);
				useGeneralStore().setGeneral(response.data.data.general);
				useRegisterStore().setEnabledRegistrationFields(response.data.data.registration_fields);
				BetSlip().setBetslipTax(response.data.data.betslip_tax);
				useMenuStore().setFooterDescriptions(response.data.data.footer_descriptions);
				useRegistrationDocumentTypesStore().setRegistrationDocumentType(response.data.data.registration_document_types);
				this.product_settings = response.data.data.product_settings;
				useAccaBoostStore().setAccaBoostScale(response.data.data.acca_boost_settings);
				useAccaBoostStore().setAvailableMarkets(response.data.data.acca_boost_enabled_markets);

				usePromotionsStore().fetchPromotionCategories().then(() => {
					usePromotionsStore().selectPromotionCategory('all');
				});

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		setServerTimestamp(data) {
			this.server_timestamp = data;
		},
		tickServerTimestamp() {
			this.server_timestamp++;
		},
		updateScreenWidth() {
			this.screen_width = window.innerWidth;
		},
		setProductSettings(data) {
			this.product_settings = data;
		}
	},
})