<script setup>
/* eslint-disable */
import { onMounted, ref, watch } from "vue"

import { useRouter } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useRegionsStore } from '@/stores/regions';
import { useCompetitionsStore } from '@/stores/competitions';

const props = defineProps({
	region: {
		type: Object,
		required: true,
	},
});

const region_opened = ref(false);

const router = useRouter();
const regionsStore = useRegionsStore();
const preSportsStore = usePreSportsStore();
const competitionsStore = useCompetitionsStore();

const goToCompetition = (competition) => {
	regionsStore.setSelectedRegionId(props.region.id);
	router.push( { name: 'pre-sports-competition', params: {sport_id: preSportsStore.selectedPreSportId, region_id: props.region.id, competition_id: competition.id } } )
}

onMounted( () => {
	if (regionsStore.selectedRegionId == props.region.id) region_opened.value = true;
});


watch(() => regionsStore.selectedRegionId, (newRegionId, oldRegionId) => {
	if (newRegionId == props.region.id) region_opened.value = true;
})

</script>

<template>
	<a style="cursor:pointer;">
		<div class="inner-accordion" :class="region_opened ? 'active': ''" :id="regionsStore.selectedRegionId == props.region.id ? 'thechosenone' : 'region'">
			<div class="inner-accordion-header"  @click="region_opened = !region_opened">
				<div class="country-info-inner">
					<div class="country-left">
						<div class="country-flag">
							<img :src="'/assets/' + region.icon" alt="flag-country">
						</div>
						<div class="country-name text-capitalize">
							<h3>{{ region.name }}</h3>
						</div>
					</div>
					<div class="country-right">
						<p>({{ region.fixtures_count }})</p>
					</div>
				</div>
			</div>

			<div class="inner-accordion-content">
				<div class="inner-content" v-for="competition in region.competitions" :key="competition.id" @click="goToCompetition(competition)" :class="competitionsStore.selectedCompetitionId == competition.id ? 'active' : '' ">
					<div class="icon"></div>
					<p>{{ competition.name }}</p>
				</div>
			</div>
		</div>
	</a>
</template>