<script setup>
/* eslint-disable */

import { usePromotionsStore } from '@/stores/promotions';
import { useRouter } from 'vue-router';
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useGeneralStore } from '../../stores/general';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

// Define stores
const router = useRouter();
const promotionsStore = usePromotionsStore();

// Slider start
const current_promotion = ref(0);
const promotion_refs = ref([]);
const slider_container_ref = ref(null);
const direction = ref(document.dir);
const screenWidth = ref(window.innerWidth);

const options = ref({
	direction: direction.value,
	rewind: false,
	pagination: true,
	perMove: 1,
	autoWidth: true,
	gap: 20,
	arrows: false
});
// Scroll slider
// const scrollToPromotion = (index) => {
// 		current_promotion.value = index;

// 		const promotion_element = promotion_refs.value[index];
// 		if (promotion_element && slider_container_ref.value) {
// 				const scroll_position = promotion_element.offsetLeft;
// 				slider_container_ref.value.scroll({
// 						left: scroll_position,
// 						behavior: 'smooth'
// 					});
// 				}
// 			};

const updateScreenWidth = () => {
	screenWidth.value = window.innerWidth;
}

// Handle manual scroll
const handleScroll = () => {
	let currentIndex = 0;
	let maxOverlap = 0;

	for (let i = 0; i < promotion_refs.value.length; i++) {
		const slide = promotion_refs.value[i];
		const start = slide.offsetLeft;
		const end = start + slide.offsetWidth;
		const view_start = slider_container_ref.value.scrollLeft;
		const view_end = view_start + slider_container_ref.value.offsetWidth;

		// Determine overlap of slide with view
		const overlap = Math.min(end, view_end) - Math.max(start, view_start);

		if (overlap > maxOverlap) {
			maxOverlap = overlap;
			currentIndex = i;
		}
	}

	current_promotion.value = currentIndex;
};
// Slider end

const goToPromotion = (promotion_slug) => {
	if (promotion_slug == router.currentRoute.value.params.promotion_slug) return;
	promotionsStore.fetchPromotion(promotion_slug).then(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		router.push({ name: 'promotion', params: { promotion_slug: promotion_slug } });
	});
}

watch(() => router.currentRoute.value.params.promotion_slug, (new_promotion_slug) => {
	if (!new_promotion_slug) return;
	if (new_promotion_slug) {
		promotionsStore.fetchPromotion(new_promotion_slug).then((response) => {
			document.title = useGeneralStore().getGeneral.site_name + ' - ' + response.title;
			window.scrollTo({ top: 0, behavior: 'smooth' });
			router.push({ name: 'promotion', params: { promotion_slug: new_promotion_slug } });
		});
	}
});

onMounted(() => {
	window.addEventListener("resize", () => {
		updateScreenWidth();
	});

	if (screenWidth > 1280) {
		slider_container_ref.value.addEventListener('scroll', handleScroll);
	}


	promotionsStore.selectPromotionCategory('all');
});

onUnmounted(() => {
	window.removeEventListener("resize", null);
});
</script>
<template>
	<div class=" promotion-card-slider">
		<div class="league-slider-main promotion-card-slider loaded">
			<div class="wrapper">

				<div ref="slider_container_ref" class="promotion-card-main">
					<div v-if="screenWidth > 1280" @click="goToPromotion(promotion.slug)"
						v-for="(promotion, index) in promotionsStore.getPromotionsSidebar" :key="index"
						:ref="el => promotion_refs[index] = el" class="league-slide-item">
						<div class="promotion-card-outer">
							<div class="promotion-image">
								<img class="img" :src="promotion.media_url">
							</div>
							<div class="promotion-content">
								<h5 class="text-size">{{ promotion.title }}</h5>
								<p class="text-size-p">{{ promotion.subtitle }}</p>
							</div>
						</div>
					</div>

					<Splide v-if="screenWidth <= 1280" :options="options">
						<SplideSlide v-for="(promotion, index) in promotionsStore.getPromotionsSidebar" :key="index" >
							<a @click="goToPromotion(promotion.slug)">
								<div class="promotion-card-outer">
								<div class="promotion-image">
									<img class="img" :src="promotion.media_url">
								</div>
								<div class="promotion-content">
									<h5 class="text-size">{{ promotion.title }}</h5>
									<p class="text-size-p">{{ promotion.subtitle }}</p>
								</div>
							</div>
						</a>
						</SplideSlide>
					</Splide>
				</div>

				<!-- <div v-if="screenWidth > 1280" class="dots-p">
					<i v-for="(promotion, index) in promotionsStore.getPromotions" :key="index"
						:class="{ active: current_promotion === index }" :data-id="index"
						@click="scrollToPromotion(index)"></i>
				</div> -->


			</div>
		</div>
	</div>
</template>
<style scoped>
.main_div {
	background-color: #202833;
}

.casino-detail .casino-detail-main.promotion-detail-main {
	margin-bottom: 80px;
}

.promotion-card-slider .promotion-card-main .league-slide-item {
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: visible;
	position: relative;
	transition: all 1s;
	width: 100%;
}

.sports-outer-right {
	border-bottom: unset;
	border-radius: 0 0 0 0;
}

.promotion-detail .promotion-detail-right {
	padding-top: 20px;
}

.back-btn {
	cursor: pointer;
}

.casino_wrap .promotions_detail_wrap {
	display: grid;
	gap: 0px;
	grid-template-columns: 5fr 320px;
	padding-bottom: 0;
	padding-top: 55px;
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail {
	margin: 0px auto;
	position: relative;
	width: 90%;
}

.casino_wrap .promotions_detail_wrap .detail .back {
	margin-top: 50px;
	padding-bottom: 35px;
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail .back a {
	align-items: center;
	color: #f2f2f7;
	cursor: pointer;
	display: flex;
	font-size: 13px;
	gap: 3px;
	justify-content: flex-start;
	text-decoration: none;
	width: 50px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail {
	background: #4e545d;
	border-radius: 5px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	overflow: hidden;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .image {
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .image img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content {
	padding: 50px 32px 50px 50px;
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .cont_detail {
	max-height: 488px;
	overflow: auto;
	padding-right: 21px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .cont_detail::-webkit-scrollbar {
	width: 3px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .cont_detail::-webkit-scrollbar-track {
	background: rgba(255,255,255,0.274);
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .cont_detail::-webkit-scrollbar-thumb {
	background-color: #8f54a0;
	border-radius: 2px;
	border: 3px solid linear-gradient(#e382bc 0%,#63A246 100%);
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .title {
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .title h1 {
	color: #f2f2f7;
	font-size: 30px;
	font-weight: 600;
	line-height: normal;
	padding-bottom: 16px;
	padding-right: 21px;
	text-transform: capitalize;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .sub_detail {
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .sub_detail p {
	color: #fff;
	font-size: 14px;
	font-weight: 100;
	line-height: 1.6;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer {
	align-items: flex-start;
	display: flex;
	gap: 8px;
	padding-bottom: 20px;
	padding-top: 38px;
	padding: 15px 0px;
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer input {
	-webkit-appearance: none;
	appearance: checkbox !important;
	appearance: none;
	cursor: pointer;
	margin-top: 4px;
	width: 100%;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .checkbox-custom {
	opacity: 0;
	position: absolute;
	z-index: 10;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .checkbox-custom+label {
	display: flex !important;
	flex-direction: row !important;
	gap: 0 !important;
	padding: 0;
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .checkbox-custom+label:before {
	background: transparent;
	border-radius: 3px;
	border: 1px solid #878a90;
	content: "";
	cursor: pointer;
	display: inline-block;
	height: 16px;
	margin-right: 10px;
	margin-top: 2px;
	vertical-align: text-top;
	width: 16px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .checkbox-custom:hover+label:before {
	background: transparent;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .checkbox-custom:focus+label:before {
	box-shadow: 0 0 0 3px rgba(0,0,0,0.12);
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .checkbox-custom:checked+label:before {
	background: #63A246;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .checkbox-custom:checked+label:after {
	box-shadow: 2px 0 0 #f2f2f7, 4px 0 0 #f2f2f7, 4px -2px 0 #f2f2f7, 4px -4px 0 #f2f2f7, 4px -6px 0 #f2f2f7, 4px -8px 0 #f2f2f7;
	content: "";
	height: 2px;
	left: 3px;
	position: absolute;
	top: 10px;
	transform: rotate(45deg);
	width: 2px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer p {
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	line-height: 1.6;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .checkbox-custom-outer .text-grey {
	color: #888a8f;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .btn-box {
	text-align: right;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .btn-box a {
	position: relative;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content {
	padding-top: 30px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content p {
	color: #f2f2f7;
	font-size: 14px;
	font-weight: 100;
	line-height: 1.6;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content h4 {
	color: #f2f2f7;
	font-size: 16px;
	font-weight: 500;
	padding-bottom: 10px;
	padding-top: 40px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content h4:first-child {
	padding-top: 0;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content ul {
	list-style: disc;
	padding-left: 20px;
	padding-top: 8px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content ul li {
	color: #f2f2f7;
	font-size: 14px;
	font-weight: 100;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content ol {
	list-style: decimal;
	padding-left: 20px;
	padding-top: 8px;
}

.casino_wrap .promotions_detail_wrap .detail .main_detail .content .inner-content ol li {
	color: #f2f2f7;
	font-size: 14px;
	font-weight: 100;
}

.casino_wrap .promotions_detail_wrap .promotion-detail-right {
	border-left: 1px solid #4e545d;
	border-radius: 0;
	padding: 25px 20px 20px 20px;
}

.casino_wrap .promotions_detail_wrap .promotion-detail-right .sports-banner-outer {
	position: relative;
}

.casino_wrap .promotions_detail_wrap .promotion-detail-right .sports-banner-outer .promotion-header {
	border-bottom: 1px solid #4e545d;
	margin-bottom: 22px;
	padding-bottom: 8px;
	position: relative;
}

.casino_wrap .promotions_detail_wrap .promotion-detail-right .sports-banner-outer .promotion-header h2 {
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: normal;
}

.promotion-card-slider {
	margin-top: 0px;
	overflow: hidden;
}

.promotion-card-slider .promotion-card-main {
	display: flex;
	flex-direction: column;
	gap: 10px;
	left: 0;
	max-height: 665px;
	overflow-y: auto;
	position: relative;
	top: 0;
	width: 100%;
}

.promotion-card-main::-webkit-scrollbar {
	display: none;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-card-outer {
	position: relative;
	width: 100%;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-image {
	border-radius: 5px;
	overflow: hidden;
	position: relative;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-image:before {
	background: linear-gradient(359deg, #63A246, #131C27);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-image img {
	height: 100%;
	mask-position: initial;
	object-fit: cover;
	vertical-align: bottom;
	width: 100%;
	z-index: 1;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-image:after {
	/* background-blend-mode: multiply; */
	background: linear-gradient(359deg, #63A246, #131C27);
	content: "";
	height: 100%;
	left: 0;
	opacity: 50%;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content {
	left: 50%;
	padding: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 100%;
	z-index: 5;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h2 {
	color: #fff;
	font-size: 60px;
	font-weight: bold;
	line-height: 1.2;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h3 {
	color: #fff;
	font-size: 22px;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h4 {
	color: #fff;
	font-size: 34px;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h4 span {
	font-size: 16px;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h5 {
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
}

.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content p {
	color: #fff;
	font-size: 14px;
	font-weight: 300;
}

.promotion-card-slider .control {
	display: none !important;
}

.promotion-card-slider .dots-p {
	display: none;
	gap: 6px;
	height: 100%;
	justify-content: center;
	padding-top: 15px;
	position: relative;
	width: 100%;
	z-index: 50;
}

@media screen and (min-width: 1191px) and (max-width: 1279px) {
	.casino-detail .sports-main-outer {
		grid-template-columns: 5fr 25%;
	}
}

@media screen and (min-width: 1191px) and (max-width: 1280px) {
	.dots-p {
		display: none !important;
	}
}

@media screen and (min-width: 1280px) and (max-width: 1359px) {
	.casino_wrap .promotions_detail_wrap .detail .main_detail .content {
		padding: 25px 30px 30px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .title h1 {
		font-size: 21px;
		padding-bottom: 10px;
		padding-right: 0;
	}
}

@media screen and (min-width: 1360px) and (max-width: 1599px) {
	.casino_wrap .promotions_detail_wrap .detail .main_detail .content {
		padding: 45px 35px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .title h1 {
		font-size: 22px;
		padding-bottom: 12px;
		padding-right: 0;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.casino_wrap .promotions_detail_wrap {
		display: block;
	}

	.casino_wrap .promotions_detail_wrap .detail {
		width: 100%;
	}

	.casino_wrap .promotions_detail_wrap .detail .back {
		padding-bottom: 18px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail {
		display: block;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content {
		padding: 30px 20px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .cont_detail {
		max-height: 100%;
		padding-right: 0px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .title h1 {
		font-size: 20px;
		padding-bottom: 10px;
		padding-right: 0;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .btn-box a {
		font-size: 13px;
		font-weight: 400;
	}

	.casino_wrap .promotions_detail_wrap .promotion-detail-right {
		border-left: 0px;
		margin-bottom: 50px;
		margin-top: 50px;
		padding: 0;
	}

	.promotion-card-slider .promotion-card-main {
		display: grid;
		gap: 20px;
		grid-auto-columns: minmax(300px,auto);
		grid-auto-flow: column;
		grid-gap: 15px;
		overflow-x: auto;
		overflow-y: auto;
		width: 100%;
	}

	.promotion-card-slider .dots-p {
		display: flex;
	}

	.promotion-card-slider .dots-p i {
		background-color: #4e545d;
		border-radius: 3px;
		height: 2px;
		width: 21px;
	}

	.promotion-card-slider .dots-p .active {
		background-color: #63A246;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.casino_wrap .promotions_detail_wrap {
		display: block;
	}

	.casino_wrap .promotions_detail_wrap .detail {
		width: 100%;
	}

	.casino_wrap .promotions_detail_wrap .detail .back {
		padding-bottom: 20px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail {
		display: block;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .image {
		height: 450px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content {
		padding: 40px 20px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .cont_detail {
		max-height: 100%;
		padding-right: 0px;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .title h1 {
		font-size: 22px;
		padding-bottom: 10px;
		padding-right: 0;
	}

	.casino_wrap .promotions_detail_wrap .promotion-detail-right {
		border-left: 0px;
		margin-bottom: 50px;
		margin-top: 50px;
		padding: 0;
	}

	.promotion-card-slider .promotion-card-main {
		display: grid;
		gap: 20px;
		grid-auto-columns: minmax(300px,auto);
		grid-auto-flow: column;
		grid-gap: 15px;
		overflow-x: auto;
		overflow-y: auto;
		width: 100%;
	}

	.promotion-card-slider .dots-p {
		display: flex;
	}

	.promotion-card-slider .dots-p i {
		background-color: #4e545d;
		border-radius: 3px;
		height: 2px;
		width: 21px;
	}

	.promotion-card-slider .dots-p .active {
		background-color: #63A246;
	}
}

@media screen and (min-width: 992px) and (max-width: 1190px) {
	.promotion-card-main {
		display: grid !important;
		gap: 20px !important;
		grid-auto-columns: minmax(300px,auto) !important;
		grid-auto-flow: column !important;
		grid-gap: 15px !important;
		overflow-x: auto !important;
		overflow-y: auto !important;
		width: 100% !important;
	}
}

@media screen and (min-width: 992px) and (max-width: 1279px) {
	.casino_wrap .promotions_detail_wrap {
		display: block;
	}

	.casino_wrap .promotions_detail_wrap .detail {
		width: 100%;
	}

	.casino_wrap .promotions_detail_wrap .detail .main_detail .content .title h1 {
		font-size: 20px;
		padding-bottom: 10px;
		padding-right: 0;
	}

	.casino_wrap .promotions_detail_wrap .promotion-detail-right {
		border-left: 0px;
		margin-bottom: 50px;
		margin-top: 50px;
		padding: 0;
	}

	.promotion-card-slider .dots-p {
		display: flex;
	}

	.promotion-card-slider .dots-p i {
		background-color: #4e545d;
		border-radius: 3px;
		height: 2px;
		width: 21px;
	}

	.promotion-card-slider .dots-p .active {
		background-color: #63A246;
	}
}
@media screen and (max-width: 1600px) {
	.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h5 {
		font-size: 12px;
	}
	.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content p {
		font-size: 11px;
		font-weight: 400;
	}
}
@media screen and (min-width: 992px) and (max-width: 1291px) {
	.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h5 {
		font-size: 15px !important;
	}
}

@media screen and (min-width: 1190px) and (max-width: 1278px) {
	.promotion-card-main[data-v-6c660c2f] {
		display: grid !important;
		gap: 20px !important;
		grid-auto-columns: minmax(300px,auto) !important;
		grid-auto-flow: column !important;
		grid-gap: 15px !important;
		overflow-x: auto !important;
		overflow-y: auto !important;
		width: 100% !important;
	}
}

@media screen and (min-width: 1278px) {
	.promotion-card-main {
		display: grid !important;
		grid-template-columns: 48% 48% !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 470px) {
	.promotion-card-main {
		grid-auto-columns: minmax(48%, auto) !important;
	}

	.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h5 {
		color: #fff;
		font-size: 13px;
		font-weight: bold;
	}

}

@media screen and (min-width: 471px) and (max-width: 767px) {
	.promotion-card-main {
		grid-auto-columns: minmax(33%, 100px) !important;
	}

	.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h5 {
		color: #fff;
		font-size: 14px;
		font-weight: bold;
	}
}

@media screen and (min-width: 1191px) and (max-width: 1278px) {
	.promotion-card-main {
		display: grid !important;
		grid-auto-columns: minmax(33%, auto) !important;
		grid-auto-flow: column !important;
	}

	.promotion-card-slider .promotion-card-main .league-slide-item .promotion-content h5 {
		color: #fff;
		font-size: 14px;
		font-weight: bold;
	}
}

.promotion-card-slider {
	max-height: 310px !important;
}

.promotion-card-main {
	scrollbar-width: none !important;
}

.league-slider-main {
	scrollbar-width: none !important;
}
</style>