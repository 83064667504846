<template>
	<div class="loading">
		<div class="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>
<style scoped>
.lds-ring {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 8px;
	border: 4px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	padding-bottom: 50px;
}

html[dir="rtl"] .loading {
	justify-content: center;
}
</style>
