<template>
	<button v-show="visible" @click="scrollToTop" class="scroll-to-top">
		<i class="fa fa-arrow-up"></i>
	</button>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const visible = ref(false);

const checkVisibility = () => {
	visible.value = window.scrollY > 2000;
};

const scrollToTop = () => {
	// Get element
	const provider_element = document.getElementById('main-container');

	// Get the element's position relative to the viewport
	const rect = provider_element.getBoundingClientRect();

	// Calculate the new scroll position with a 10-pixel offset at the top
	const scroll_top_offset = window.scrollY + rect.top - 60;

	// Scroll to the new position smoothly
	window.scrollTo({
		top: scroll_top_offset,
		behavior: "smooth"
	});
};

onMounted(() => {
	window.addEventListener('scroll', checkVisibility);
});

onUnmounted(() => {
	window.removeEventListener('scroll', checkVisibility);
});
</script>

<style scoped>
.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	padding: 10px 15px;
	background-color: #4CAF50;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: opacity 0.4s ease;
	opacity: 0.7;
}

.scroll-to-top:hover {
	opacity: 1;
	transform: scale(1.02);
	background: linear-gradient(145deg, #4CAF50, #47a24a);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
</style>