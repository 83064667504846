<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';

const { t } = useI18n();
const registerStore = useRegisterStore();

const dobProgress = () => {
	var counter = 0;

	if (registerStore.register_input_fields.dob_day != null) counter++;
	if (registerStore.register_input_fields.dob_month != null) counter++;
	if (registerStore.register_input_fields.dob_year != null) counter++;

	if (counter == 0) return 'password-meter-bar-inn-99'
	else if (counter == 1) return 'password-meter-bar-inn-66'
	else if (counter == 2) return 'password-meter-bar-inn-33'
	else return 'password-meter-bar-inn-100'
}
</script>

<template>
<div class="register-form-field">
	<div class="row">
		<label>{{ $t('account.register.dob') }}</label>
		<select name="" @change="registerStore.validateDobDay" v-model="registerStore.register_input_fields.dob_day" class="form-input form-control day" id="address-country">
			<option value="null" disabled>{{ $t('account.register.day') }}</option>
			<option value="1">1</option>
			<option value="2">2</option>
			<option value="3">3</option>
			<option value="4">4</option>
			<option value="5">5</option>
			<option value="6">6</option>
			<option value="7">7</option>
			<option value="8">8</option>
			<option value="9">9</option>
			<option value="10">10</option>
			<option value="11">11</option>
			<option value="12">12</option>
			<option value="13">13</option>
			<option value="14">14</option>
			<option value="15">15</option>
			<option value="16">16</option>
			<option value="17">17</option>
			<option value="18">18</option>
			<option value="19">19</option>
			<option value="20">20</option>
			<option value="21">21</option>
			<option value="22">22</option>
			<option value="23">23</option>
			<option value="24">24</option>
			<option value="25">25</option>
			<option value="26">26</option>
			<option value="27">27</option>
			<option value="28">28</option>
			<option value="29">29</option>
			<option value="30">30</option>
			<option value="31">31</option>
		</select>
		<select name="" @change="registerStore.validateDobMonth" v-model="registerStore.register_input_fields.dob_month" class="form-input form-control month" id="address-country">
			<option value="null" disabled>{{ $t('account.register.month') }}</option>
			<option value="1">{{ $t('general.january') }}</option>
			<option value="2">{{ $t('general.february') }}</option>
			<option value="3">{{ $t('general.march') }}</option>
			<option value="4">{{ $t('general.april') }}</option>
			<option value="5">{{ $t('general.may') }}</option>
			<option value="6">{{ $t('general.june') }}</option>
			<option value="7">{{ $t('general.july') }}</option>
			<option value="8">{{ $t('general.august') }}</option>
			<option value="9">{{ $t('general.september') }}</option>
			<option value="10">{{ $t('general.october') }}</option>
			<option value="11">{{ $t('general.november') }}</option>
			<option value="12">{{ $t('general.december') }}</option>
		</select>
		<select name="" @change="registerStore.validateDobYear" v-model="registerStore.register_input_fields.dob_year" class="form-input form-control year" id="address-country">
			<option value="null" disabled>{{ $t('account.register.year') }}</option>
			<option value="2004">2004</option>
			<option value="2003">2003</option>
			<option value="2002">2002</option>
			<option value="2001">2001</option>
			<option value="2000">2000</option>

			<option value="1999">1999</option>
			<option value="1998">1998</option>
			<option value="1997">1997</option>
			<option value="1996">1996</option>
			<option value="1995">1995</option>
			<option value="1994">1994</option>
			<option value="1993">1993</option>
			<option value="1992">1992</option>
			<option value="1991">1991</option>
			<option value="1990">1990</option>
			<option value="1989">1989</option>
			<option value="1988">1988</option>
			<option value="1987">1987</option>
			<option value="1986">1986</option>
			<option value="1985">1985</option>
			<option value="1984">1984</option>
			<option value="1983">1983</option>
			<option value="1982">1982</option>
			<option value="1981">1981</option>
			<option value="1980">1980</option>
			<option value="1979">1979</option>
			<option value="1978">1978</option>
			<option value="1977">1977</option>
			<option value="1976">1976</option>
			<option value="1975">1975</option>
			<option value="1974">1974</option>
			<option value="1973">1973</option>
			<option value="1972">1972</option>
			<option value="1971">1971</option>
			<option value="1970">1970</option>
			<option value="1969">1969</option>
			<option value="1968">1968</option>
			<option value="1967">1967</option>
			<option value="1966">1966</option>
			<option value="1965">1965</option>
			<option value="1964">1964</option>
			<option value="1963">1963</option>
			<option value="1962">1962</option>
			<option value="1961">1961</option>
			<option value="1960">1960</option>
			<option value="1959">1959</option>
			<option value="1958">1958</option>
			<option value="1957">1957</option>
			<option value="1956">1956</option>
			<option value="1955">1955</option>
			<option value="1954">1954</option>
			<option value="1953">1953</option>
			<option value="1952">1952</option>
			<option value="1951">1951</option>
			<option value="1950">1950</option>
			<option value="1949">1949</option>
			<option value="1948">1948</option>
			<option value="1947">1947</option>
			<option value="1946">1946</option>
			<option value="1945">1945</option>
			<option value="1944">1944</option>
			<option value="1943">1943</option>
			<option value="1942">1942</option>
			<option value="1941">1941</option>
			<option value="1940">1940</option>
			<option value="1939">1939</option>
			<option value="1938">1938</option>
			<option value="1937">1937</option>
			<option value="1936">1936</option>
			<option value="1935">1935</option>
			<option value="1934">1934</option>
			<option value="1933">1933</option>
			<option value="1932">1932</option>
			<option value="1931">1931</option>
			<option value="1930">1930</option>
			<option value="1929">1929</option>
			<option value="1928">1928</option>
			<option value="1927">1927</option>
			<option value="1926">1926</option>
			<option value="1925">1925</option>
			<option value="1924">1924</option>
			<option value="1923">1923</option>
			<option value="1922">1922</option>
			<option value="1921">1921</option>
			<option value="1920">1920</option>
			<option value="1919">1919</option>
			<option value="1918">1918</option>
			<option value="1917">1917</option>
			<option value="1916">1916</option>
			<option value="1915">1915</option>
			<option value="1914">1914</option>
			<option value="1913">1913</option>
			<option value="1912">1912</option>
			<option value="1911">1911</option>
			<option value="1910">1910</option>
			<option value="1909">1909</option>
			<option value="1908">1908</option>
			<option value="1907">1907</option>
			<option value="1906">1906</option>
			<option value="1905">1905</option>
			<option value="1904">1904</option>
			<option value="1903">1903</option>
			<option value="1902">1902</option>
			<option value="1901">1901</option>
			<option value="1900">1900</option>
		</select>

		<div class="register-form-list" v-if="registerStore.register_errors.dob_day.error != null || registerStore.register_errors.dob_month.error != null || registerStore.register_errors.dob_year.error != null">
			<div class="password-meter">
				<div class="password-meter-bar">
					<p :class="dobProgress()"></p>
				</div>
			</div>
			<p v-if="registerStore.register_errors.dob_day.error != null">
			<img src="@/assets/svgs/cross.svg" alt=""/>&nbsp;
			<span>
				{{ $t('account.register.error_select_dob_day') }}
			</span>
			</p>
			<p v-if="registerStore.register_errors.dob_month.error != null">
			<img src="@/assets/svgs/cross.svg" alt=""/>&nbsp;
			<span>
				{{ $t('account.register.error_select_dob_month') }}
			</span>
			</p>
			<p v-if="registerStore.register_errors.dob_year.error != null">
			<img src="@/assets/svgs/cross.svg" alt=""/>&nbsp;
			<span>
				{{ $t('account.register.error_select_dob_year') }}
			</span>
			</p>

		</div>
	</div>
</div>
</template>

<style scoped>
.form-control {
	height: 40px;
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

.form-control::-webkit-scrollbar {
	width:0em;
}

.form-control::-webkit-scrollbar-thumb {
	background-color: transparent;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-99{
	width: 100%;
	background-color:#FF6B73;
	padding:1px;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-33{
	width: 33%;
	background-color:#FF6B73;
	padding:1px;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-66{
	width: 66%;
	background-color:#FF6B73;
	padding:1px;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar p.password-meter-bar-inn-100{
	width: 100%;
	background-color: green;
	padding:1px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}

.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
	width: 30%;
	margin-right: 10px;
}

.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
	width: 30%;
	margin-right: 10px;
}

.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
	width: 31%;
}

html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
	width: 30%;
	margin-left: 10px;
	margin-right: unset;
}

html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
	width: 30%;
	margin-left: 10px;
	margin-right: unset;
}

html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
	width: 32%;
}
@media screen and (min-width: 1332px) and (max-width: 1782px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year{
		width: 31%;
	}
}
@media screen and (min-width: 1281px) and (max-width: 1331px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year{
		width: 30%;
	}
}
@media screen and (min-width: 1083px) and (max-width: 1280px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 31%;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}
}

@media screen and (min-width: 1000px) and (max-width: 1082px) {
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 30%;
		margin-right: 7px;
	}
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 30%;
		margin-right: 10px;
	}
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32%;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31%;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}
}
@media screen and (min-width: 985px) and (max-width: 999px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 33%;
	}
}
@media screen and (min-width: 829px) and (max-width: 984px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 31%;
	}
}
@media screen and (min-width: 768px) and (max-width: 828px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 31%;
	}
}

@media screen and (min-width: 601px) and (max-width: 767px) {
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-right: 10px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-right: 10px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 31.5%;
	}
}
@media screen and (min-width: 756px) and (max-width: 767px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-left: 11px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.6%
	}
}
@media screen and (min-width: 702px) and (max-width: 757px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.5%
	}
}
@media screen and (min-width: 683px) and (max-width: 701px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.9%
	}
}
@media screen and (min-width: 618px) and (max-width: 682px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.7%
	}
}
/* @media screen and (min-width: 618px) and (max-width: 648px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.5%
	}
} */

@media screen and (min-width: 553px) and (max-width: 618px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 33%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 30%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.5%
	}
}
@media screen and (min-width: 501px) and (max-width: 552px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 30.5%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.5%
	}
}
@media screen and (min-width: 474px) and (max-width: 500px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 30.5%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32.2%
	}
}
@media screen and (min-width: 454px) and (max-width: 473px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 30.2%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32%
	}
}



@media screen and (min-width: 530px) and (max-width: 600px) {
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31%;
		margin-right: 10px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-right: 7px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 33%;
	}
}
@media screen and (min-width: 454px) and (max-width: 529px) {
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31%;
		margin-right: 10px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31%;
		margin-right: 7px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 31.5%;
	}

	/* html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31%;
		margin-left: 10px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-left: 7px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 33%;
	} */
}

@media screen and (min-width: 379px) and (max-width: 453px) {
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 30%;
		margin-right: 7px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 30%;
		margin-left: 5px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 30%;
		margin-left: 7px;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 32%;
		margin-left: 3px;
		margin-right: 2px;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-left: 3px;
		margin-right: 2px
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32%;
		margin-left: -5px;
		margin-right: 2px;
	}
}
@media screen and (min-width: 344px) and (max-width: 378px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31.5%;
		margin-left: 6px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-left: 6px;
		margin-right: unset;
	}

	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32%;
	}
}
@media screen and (max-width: 343px) {
	html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 31.5%;
	}
}
@media screen and (max-width: 378px) {
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31.5%;
		margin-right: 6px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 32%;
		margin-right: 6px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 32%;
	}
}
@media screen and (max-width: 343px) {
	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .day {
		width: 31.5%;
		margin-right: 6px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .month {
		width: 31.5%;
		margin-right: 6px;
	}

	.register-form-main .register-outer-form .register-inner-form .register-form-field .row .year {
		width: 31.5%;
	}
}

@media screen and (min-width: 926px) and (max-width: 991px) {
	.register-form-main .form-register-horizontal li:not(:last-child):after {
		width: 270px;
	}
}

@media screen and (min-width: 830px) and (max-width: 925px) {
	.register-form-main .form-register-horizontal li:not(:last-child):after {
		width: 230px;
	}
}
@media screen and (max-width: 350px) {
	.register-form-main .form-register-horizontal li {
		flex-direction: column-reverse;
	}
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form select {
	background-position: 5%;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input {
	padding: 10px 15px 10px 15px;
}
</style>