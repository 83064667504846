import { defineStore } from 'pinia';
import _ from 'lodash';

export const useTodaysCouponsStore = defineStore('todaysCoupons', {
	state: () => ({
		sports: null
	}),
	getters: {
		todaysSports(state) {
			return _.orderBy(state.sports, 'id');
		},
	},
	actions: {
		fetchTodaysCoupons() {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/sports/today', { withCredentials: false }).then((response) => {
				this.sports = response.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		}
	},
})