<script setup>
/* eslint-disable */
import { onMounted, ref, watch } from 'vue';

import { useFixtureStore } from '@/stores/fixture';
import { useLiveSportsStore } from '@/stores/live_sports';

import SetGames from './ScoreboardGrid/SetGames.vue'
import FrameGames from './ScoreboardGrid/FrameGames.vue'
import HalfGames from './ScoreboardGrid/HalfGames.vue'
import InningGames from './ScoreboardGrid/InningGames.vue'
import MapGames from './ScoreboardGrid/MapGames.vue'
import MultipleGames from './ScoreboardGrid/MultipleGames.vue'
import PeriodGames from './ScoreboardGrid/PeriodGames.vue'
import QuarterGames from './ScoreboardGrid/QuarterGames.vue'
import CricketGrid from './ScoreboardGrid/CricketGrid.vue'
import { useRouter } from 'vue-router';

import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svg_data = ref(commonSvgs);

const router = useRouter();
const fixtureStore = useFixtureStore();
const liveSportsStore = useLiveSportsStore();

const set_games = ref(['Archery', 'Tennis',  'Volleyball', 'Beach Volleyball', 'Squash', 'Table Tennis']);
const frame_games = ref(['Snooker']);
const half_games = ref(['Rugby Union', 'Rugby League']);
const inning_games = ref(['Baseball']);
const map_games = ref(['Counter Strike', 'Dota 2', 'Valorant', 'League of Legends']);
const multiple_games = ref(['King of Glory ']);
const period_games = ref(['Futsal', 'Floorball', 'Beach Soccer', 'Badminton', 'Ice Hockey', 'Handball', 'Field Hockey', 'Water Polo']);
const quarter_games = ref(['American Football', 'Aussie Rules']);

const goToCompetition = (fixture) => {
	router.push({ name: 'pre-sports-competition', params: { sport_id: fixture.sport_id, region_id: Number(fixture.region_id), competition_id: fixture.competition_id } } )
}

onMounted(() => {
	checkImageExists();
});

const checkImageExists = () => {
	let imageUrl = '/assets/scoreboards/' + liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) + '.png'

	// Create a new image object
	const img = new Image();

	// When image loads successfully, update the src
	img.onload = function () {
		if (!document.getElementById('scoreboardImage')) return;
		document.getElementById('scoreboardImage').style = 'display:block;'
		document.getElementById('scoreboardImage').src = imageUrl;
	};

	// If image doesn't load, fall back to a default image
	img.onerror = function () {
		if (!document.getElementById('scoreboardImage')) return;
		document.getElementById('scoreboardImage').style = 'display:none;'
	};

	// Try to load the generated image URL
	img.src = imageUrl;
}

watch(() => fixtureStore.fixtureBook, (newFixtureBook, oldFixtureBook) => {
	if (newFixtureBook.id != oldFixtureBook.id) checkImageExists();
});

</script>

<template>
<!-- scorecard for set games start -->
<div class="eventview-game-banner eventview-set_game-banner" v-if="fixtureStore.fixture != null">
	<div class="eventview-banner-image">
		<img id="scoreboardImage" alt="">
	</div>
	<div class="live-game-outer">
		<div class="live_score_Box">
			<div class="league-title">
				<img :src="liveSportsStore.liveSportWhiteIcon(fixtureStore.fixtureBook.sport_id)" alt="">
				<h6 @click="goToCompetition(fixtureStore.fixtureBook)">{{ fixtureStore.fixtureBook.region_name + ' - ' + fixtureStore.fixtureBook.competition_name }}</h6>
			</div>
			<!-- score panel start-->
			<div class="league-score">
				<div class="set-top-info">
					<div class="live-badge" v-html="svg_data.live_badge"></div>
					<div class="playing-time" v-if="liveSportsStore.hasFixtureClock(fixtureStore.fixtureBook.sport_id)">
						<p>
							{{ fixtureStore.fixtureClockTime(fixtureStore.fixtureBook) }}
								<abbr>{{ fixtureStore.fixtureState(fixtureStore.fixtureBook) }}</abbr>
						</p>
						<span></span>
					</div>
					<div class="playing-time" v-else>
						<p> {{ fixtureStore.fixtureState(fixtureStore.fixtureBook) }}</p>
						<span></span>
					</div>
				</div>

				<div class="eventview-playing-info">
					<div class="playing-team-inner">
						<div class="playing-team" :class="fixtureStore.fixtureBook.scoreboard?.servis==1?'active':''">
							<h6>{{fixtureStore.fixtureBook.participants[0]}}</h6>
						</div>
						<div class="playing-score">
							<h3 v-if="fixtureStore.fixtureBook.scoreboard?.points">{{ fixtureStore.fixtureBook.scoreboard.points.p1}}</h3>
							<h3 v-else>{{ fixtureStore.fixtureBook.statistics.p1_score }}</h3>
						</div>
					</div>

					<div class="playing-team-inner">
						<div class="playing-team" :class="fixtureStore.fixtureBook.scoreboard?.servis==2?'active':''">
							<h6>{{fixtureStore.fixtureBook.participants[1]}}</h6>
						</div>
						<div class="playing-score">
							<h3 v-if="fixtureStore.fixtureBook.scoreboard?.points">{{ fixtureStore.fixtureBook.scoreboard.points.p2}}</h3>
							<h3 v-else>{{ fixtureStore.fixtureBook.statistics.p2_score }}</h3>
						</div>
					</div>
				</div>
					<div class="scorecard-outer" v-if="Object.keys(fixtureStore.fixtureBook.scoreboard)?.length>0" :class="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Cricket' || map_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id)) ? 'cricket-scorecard' : ''">
						<set-games :fixture_data="fixtureStore.fixtureBook" v-if="set_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></set-games>
						<frame-games :fixture_data="fixtureStore.fixtureBook" v-if="frame_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></frame-games>
						<half-games :fixture_data="fixtureStore.fixtureBook" v-if="half_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></half-games>
						<inning-games :fixture_data="fixtureStore.fixtureBook" v-if="inning_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></inning-games>
						<cricket-grid :fixture_data="fixtureStore.fixtureBook" v-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id)=='Cricket'"></cricket-grid>
						<map-games :fixture_data="fixtureStore.fixtureBook" v-if="map_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></map-games>
						<multiple-games :fixture_data="fixtureStore.fixtureBook" v-if="multiple_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></multiple-games>
						<period-games :fixture_data="fixtureStore.fixtureBook" v-if="period_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></period-games>
						<quarter-games :fixture_data="fixtureStore.fixtureBook" v-if="quarter_games.includes(liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id))"></quarter-games>
					</div>
				</div>
				</div>

			</div>
</div>

<!-- scorecard for set games end -->

</template>
<style>
	svg.svg-inline--fa.fa-volume-up.fa-w-18 {
		position: absolute;
		right: 10px;
		bottom: 163px;
		color: #fff;
	}
	svg.svg-inline--fa.fa-volume-mute.fa-w-16  {
		position: absolute;
		right: 10px;
		bottom: 163px;
		color: #fff;
	}
	html[dir="rtl"] .sports-main .eventview-pannel .sports-center-content .eventview-game-banner .live-game-outer .live_score_Box .league-title {
		right: 20px;
		left: unset;
	}

	.active {
		color: #63A246 !important;
	}

	@media screen and (min-width: 320px) and (max-width: 767px) {
		.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview-set_game-banner .live-game-outer .live_score_Box .league-score .set-top-info {
			display: grid !important;
		}

		.sports-main .eventview-pannel .sports-center-content .eventview-game-banner .live-game-outer .live_score_Box .league-score .playing-time {
			display: flex !important;
		}
	}
</style>