<script setup>
import { onMounted } from 'vue';
import BrandsSlider from '@/components/casino_v2/BrandsSlider.vue';
import GamesContainer from '@/components/casino_v2/GamesContainer.vue';
import CategorySlider from '@/components/casino_v2/CategorySlider.vue';
// import { useCustomerStore } from '@/stores/customer';
import { useCasinoStore } from '@/stores/casino_v2';
import { useGeneralStore } from '@/stores/general';
import { useI18n } from 'vue-i18n';

// Inialize store
const casinoStore = useCasinoStore();
// const customer = useCustomerStore();
const generalStore = useGeneralStore();
const { t } = useI18n();

onMounted(() => {
	document.title = generalStore.getGeneral.site_name + ' - ' + t('routing.casino');

	document.body.style.overflow = 'auto';

	casinoStore.fetchGames();
});
</script>
<template>
	<!-------- Categories Slider Start --------->
	<div class="casino-tabing-header">
		<div class="container-lg casino-container-lg">

			<CategorySlider v-if="generalStore.isLicenseEnabled == 'true'"></CategorySlider>
		</div>
	</div>
	<!-------- Brand Slider End--------->

	<!---- Games Section Start ---->
	<div class="casino-game-area">
		<!-- Brand Slider Start -->
		<BrandsSlider></BrandsSlider>

		<div class="casino-game-section">
			<GamesContainer></GamesContainer>
		</div>
	</div>
	<!---- Games Section End ---->
</template>
<style scoped>
.casino-game-area {
	padding-bottom: 0;
}
</style>