<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';
import { ref } from 'vue';
import { onMounted } from 'vue';

const { t } = useI18n();
const registerStore = useRegisterStore();

const verify_by = ref(process.env.VUE_APP_CUSTOMER_VERIFY_BY ? process.env.VUE_APP_CUSTOMER_VERIFY_BY : 'email');

onMounted(() => {
	if (verify_by.value == 'sms') {
		registerStore.register_input_fields.verify_by = 'sms';
	} else {
		registerStore.register_input_fields.verify_by = 'email';
	}
});
</script>

<template>
	<div class="register-form-field">
		<label>{{$t('account.register.verify_by')}}</label>
		<select name="" @change="registerStore.validateVerifyBy" v-model="registerStore.register_input_fields.verify_by" class="form-input form-control" id="verify_by" :disabled="verify_by == 'sms' || verify_by == 'email'">
			<option :value="null" disabled>{{$t('account.register.verify_by')}}</option>
			<option value="sms">{{ $t('general.sms') }}</option>
			<option value="email">{{ $t('general.email') }}</option>
		</select>
	</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form select {
	background-position: 5%;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input {
	padding: 10px 15px 10px 15px;
}
</style>