<script setup>
// eslint-disable-next-line no-unused-vars
import { closeModal } from '@kolirt/vue-modal'
import { onMounted, ref } from 'vue'
import { useLoginStore } from '@/stores/login'
import Loader from '@/components/Common/Loader.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { useCustomerStore } from '@/stores/customer'

const loginStore = useLoginStore()
const customerStore = useCustomerStore();
const router = useRouter()
const { t } = useI18n()
const token = ref(null)
const email = ref(null)
const loading = ref(true)
const title = ref(null)
const svgs_data = commonSvgs

const verifyEmail = () => {
	let payload = {
		token: token.value,
		email: email.value
	};
	loginStore.emailVerification(payload).then(() => {

		title.value = t('email_verification.success_verification')
		customerStore.fetchCustomer();
		loading.value = false

	}).catch((error) => {
		title.value = error.data.error;

		loading.value = false
	})
}

const goToLanding = () => {
	router.push({ name: 'landing' })
	closeModal()
}

onMounted(() =>{

	setTimeout(() => {
		token.value = router.currentRoute.value.query.token
		email.value = router.currentRoute.value.query.email

		if (token.value && email.value) verifyEmail()
		else {
			title.value = t('email_verification.error')
			loading.value = false
		}
	}, 1500)
})

</script>
<template>
	<div class="modal-outer verify-email-popup" ref="modal">
		<!-- overlay -->
		<div class="overlay"></div>
		<!-- modal -->
		<div class="modal">
			<button class="close" @click="goToLanding()"  v-html="svgs_data.popup_cross"></button>

			<Loader v-if="loading" style="padding-top: 80px;"></Loader>

			<template v-if="!loading">
				<div class="modal-header">
					<h3>{{ title }}</h3>
				</div>
				<div class="password-reset-main">
					<div class="popup-message">
						<p></p>
					</div>
					<button class="btn btn-secondary" type="button" @click="goToLanding()">{{ $t('general.close') }}</button>
				</div>
			</template>
		</div>
	</div>
</template>