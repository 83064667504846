<script setup>
/* eslint-disable */
import { onMounted, computed, watch, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useFixtureStore } from '@/stores/fixture';
import { useSportUpcomingStore } from '@/stores/sport_upcoming';
import { useCouponsStore } from '@/stores/coupons';

import PreFixtureDoubleMarket from './PreFixtureDoubleMarket.vue';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';

const router = useRouter();
const route = useRoute();

const liveSportsStore = useLiveSportsStore();
const preSportsStore = usePreSportsStore();
const liveSportOverviewStore = useLiveSportOverview();
const fixtureStore = useFixtureStore();
const sportUpcomingStore = useSportUpcomingStore();
const couponsStore = useCouponsStore();

const props = defineProps({
	primary_overview_market: {
		type: Object,
		required: true,
	},
	secondary_overview_market: {
		type: Object,
		required: true,
	},
});

onMounted(() => {
	let payload =  {
		sport_id: router.currentRoute.value.params.sport_id,
		hours: 3
	}

	if (payload.sport_id == '' || payload.sport_id == null) return;

	couponsStore.setFixturesLoading(true);
	couponsStore.fetchNextHourFixtures(payload);
});

watch(route, (to, from) => {
	if (!to.params.hasOwnProperty('sport_id') || to.params.sport_id == '') return;

	let payload =  {
		sport_id: to.params.sport_id,
		hours: 3
	}

	couponsStore.setFixturesLoading(true);
	couponsStore.fetchNextHourFixtures(payload);
});
</script>

<template>
	<LoaderComponent v-if="couponsStore.fixtures_loading"/>
	<div id="upcoming" class="tab-content" v-else>
		<div v-if="Object.keys(couponsStore.nextHourFixturesPerCompetition).length == 0">
			<div style="margin-top: 20px;">
				<p style="text-align: center; color: white">{{ $t('general.there_are_no_upcoming_events_available') }}</p>
			</div>
		</div>
		<div class="live-info-outer" v-for="(competition, index) in couponsStore.nextHourFixturesPerCompetition" :key="index">

			<div class="live-info-inner">
				<div class="live-info-team">
					<div class="live-match">
						<div class="team-flag">
							<img :src="'/assets' + competition[0].region_icon_url" alt="">
						</div>
						<div class="live-match-name">
							<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }}</h3>
						</div>
					</div>
				</div>

				<div class="full-time-top top-title-dash">
					<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
						<span v-if="odd != ''">{{ odd }}</span>
					</template>
				</div>

				<div class="match-goal-top top-title-dash">
					<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
						<span v-if="odd != ''">{{ odd }}</span>
					</template>
					<span class="bet-column-empty"></span>

				</div>
			</div>

			<PreFixtureDoubleMarket v-for="(fixture, index) in competition"
				:key="index"
				:primary_overview_market="primary_overview_market"
				:secondary_overview_market="secondary_overview_market"
				:fixture="fixture"
			/>
		</div>
	</div>
</template>