<script setup>
/* eslint-disable */

import { onMounted, onUnmounted, inject, watch, ref, computed, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useSocketStore } from '@/stores/socket';
import { useDatesStore } from '@/stores/dates';
import { useFixtureStore } from '@/stores/fixture';
import { useGeneralStore } from '@/stores/general';
import { useCompetitionsStore } from '@/stores/competitions';
import { useRacingSportsStore } from '@/stores/racing_sports';

import _ from 'lodash';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';
import RacingFixtureBookOdd from '@/components/racing_sports/RacingFixtureBookOdd.vue';

const router = useRouter();
const route = useRoute();

const socketStore = useSocketStore();
const fixtureStore = useFixtureStore();
const datesStore = useDatesStore();
const competitionsStore = useCompetitionsStore();
const racingSportsStore = useRacingSportsStore();

const splideRef = ref(null);
const direction = ref(document.dir);
const options = ref({
	direction: direction.value,
	pagination: false,
	// arrows: false,
	// focus: 'center',
	autoWidth: true,
	gap: 20,
	trimSpace: 'move'
});

socketStore.getSocket.on('liveFixtureBook', (data) => {
	fixtureStore.setFixture(data);

	// Get first market and set it
	if (fixtureStore.fixtureBook.markets.length > 0) {
		setActiveMarket(fixtureStore.fixtureBook.markets[0].id);
	}

	setPageTitle();
	fixtureStore.setFixtureLoading(false);
});

socketStore.getSocket.on('liveFixtureBookNotFound', (data) => {
	fixtureStore.setFixtureLoading(false);
	router.push({ name: 'not-found' });
});

socketStore.getSocket.on('liveFixtureBookUpdate', (data) => {
	fixtureStore.updateFixture(data);
});

socketStore.getSocket.on('liveFixtureBookDelete', (data) => {
	let sport_id = fixtureStore.fixtureBook.sport_id;
	fixtureStore.emptyFixture(data);
	router.push({ name: 'racing-coupons', params: { sport_id: sport_id } });
});

socketStore.getSocket.on('competitionFixtures', (data) => {
	competitionsStore.setCompetitionFixtures(data);
});

socketStore.getSocket.on('competitionFixturesUpdate', (data) => {
	competitionsStore.updateCompetitionFixture(data);
});

socketStore.getSocket.on('competitionFixturesDelete', (data) => {
	competitionsStore.deleteCompetitionFixture(data);
});

const active_market = ref(null);

const setActiveMarket = (market_id) => {
	active_market.value = market_id;
}

const goBack = () => {
	router.push({ name: 'racing-coupons', params: {sport_id: fixtureStore.fixtureBook.sport_id }});
}

const changeFixture = (fixture) => {
	router.push({ name: 'racing-fixture-book', params: { sport_id: fixture.sport_id, region_id: fixture.region_id, competition_id: fixture.competition_id, fixture_id: fixture.id } } )
}

const setPageTitle = () => {
	document.title = useGeneralStore().getGeneral.site_name + ' - ' + fixtureStore.fixtureBook.competition_name;
}

onMounted(() => {
	window.scrollTo(0, 0);

	if (router.currentRoute.value.params.fixture_id) {
		fixtureStore.setFixtureLoading(true);
		socketStore.getSocket.emit('join-liveFixtureBook', router.currentRoute.value.params.fixture_id);
	}

	if (router.currentRoute.value.params.competition_id) {
		socketStore.getSocket.emit('join-competitionFixtures', router.currentRoute.value.params.competition_id);
		competitionsStore.setSelectedCompetitionId(router.currentRoute.value.params.competition_id);

	}
})

const selectedMarketOdds = computed(() => {
	let market = _.find(fixtureStore.fixtureBook.markets, {'id': active_market.value});
	if (market) return market.odds;
})

onUnmounted(() => {
	socketStore.getSocket.emit('leave-liveFixtureBook', fixtureStore.fixtureBook.id);
	socketStore.getSocket.emit('leave-competitionFixtures', competitionsStore.selectedCompetitionId);
	fixtureStore.emptyFixture();
	competitionsStore.emptyCompetitions();
})

watch(route, (to, from) => {

	if (!to.params.hasOwnProperty('competition_id')) return;
	if (to.params.competition_id == competitionsStore.selectedCompetitionId) return;

	// Check If I have selected competition and leave
	if (competitionsStore.selectedCompetitionId != null) {
		socketStore.getSocket.emit('leave-competitionFixtures', competitionsStore.selectedCompetitionId);
		competitionsStore.emptyCompetitions();
	}

	socketStore.getSocket.emit('join-competitionFixtures', to.params.competition_id);
	competitionsStore.setSelectedCompetitionId(to.params.competition_id);


});

watch(route, (to, from) => {
	if (!to.params.hasOwnProperty('fixture_id')) return;
	if (to.params.fixture_id == fixtureStore.fixtureBook.id) return;

	socketStore.getSocket.emit('leave-liveFixtureBook', fixtureStore.fixtureBook.id);
	fixtureStore.emptyFixture();
	fixtureStore.setFixtureLoading(true);
	socketStore.getSocket.emit('join-liveFixtureBook', to.params.fixture_id);
});

</script>

<template>
	<LoaderComponent v-if="fixtureStore.fixture_loading" />
	<div v-else class="racing-odds-main racing-center-detail">
		<div class="racing-outer-odds">
			<div class="racing-center-header">
				<div class="racing-category-info">
					<div class="title">
						<div class="country-flag">
							<img style="width: 20px;" :src="'/assets' + fixtureStore.fixtureBook.region_icon_url" alt="">
						</div>
						<h6>{{ fixtureStore.fixtureBook.competition_name }} </h6>
					</div>
				</div>

				<div class="detail-main-odds">
					<div class="odds-column">
						<div @click="goBack" class="odds-items">
							<p>All Races</p>
						</div>
						<div v-for="fixture in competitionsStore.todaysCompetitionFixtures(fixtureStore.fixtureBook.start_datetime)" :key="fixture.id" class="odds-items" @click="changeFixture(fixture)" :class="{'active': fixture.id == fixtureStore.fixtureBook.id}">
							<p>{{ datesStore.preFixtureBookTime(fixture.start_datetime) }}</p>
						</div>
					</div>
				</div>

				<div class="racing-category-content">
						<div class="racing-category-date">
							<p>{{ datesStore.competitionFixtureDate(fixtureStore.fixtureBook.start_datetime) }}</p>
							<p>{{ datesStore.preFixtureBookTime(fixtureStore.fixtureBook.start_datetime) }}</p>
						</div>
						<div class="racing-category-text">
							<p>{{ fixtureStore.fixtureBook.venue_title }}</p>
						</div>
					</div>
			</div>

			<div class="racing-tab_area">
				<div class="racing-odds-tabs">
					<div class="slider-grid">
						<Splide ref="splideRef" :options="options" direction:direction>
							<SplideSlide v-for="market in fixtureStore.fixtureBook.markets" :key="market.id" class="tab desktop-tab-left">
								<button class="tablinks" :class="{ 'active': market.id == active_market }"
									@click="setActiveMarket(market.id)">{{ market.name }}</button>
							</SplideSlide>
						</Splide>
					</div>
				</div>

				<div class="tab-content racing-tab-content">

					<div class="tab-content-column">
						<!-- tab content heading start -->
						<div class="tabing-title racing-detail-tab-main">
							<div class="tabing_racing-left">
								<p></p>
								<p>{{ $t('racing.number') }}</p>
								<p>{{ racingSportsStore.racingSportName(fixtureStore.fixtureBook.sport_id) }}</p>
								<p>{{ $t('racing.jockey_trainer') }}</p>
								<p>{{ $t('racing.weight_age') }}</p>
							</div>
							<div class="tabing_racing-right">
								<p>{{ $t('racing.previous_odds') }}</p>
								<!-- <p></p> -->
								<p class="odd-column">{{ $t('racing.odds') }}</p>
								<!-- <p class="sp-column">SP</p> -->
							</div>

						</div>
					<!-- tab content heading end -->
						<div class="racing-tab-inner">
							<RacingFixtureBookOdd v-for="odd in selectedMarketOdds" :key="odd.id" :odd="odd"></RacingFixtureBookOdd>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
@media screen and (max-width: 1599px) {
	.racing-main .sports-main-outer.live_Sports-main-outer.racing-outer-main .racing-outer-left .race-main-area .racing-area-outer .racing-odds-main.racing-center-detail .racing-outer-odds .racing-tab_area .racing-tab-content .tab-content-column {
		width: 100%;
	}
}

.slider-grid :deep(.splide__arrow:disabled) {
	display: none;
}
html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--next:after ) {
	rotate: 180deg;
}

html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--next) {
	left: 0px !important;
}

html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--prev:after ) {
	rotate: 180deg;
}

.tab {
	border-bottom: 0px !important;
}

.slider-grid {
	display: grid;
	grid-auto-columns: minmax(142px, auto);
	grid-gap: 15px;
	grid-auto-flow: column;
	padding: 0px;
	width: 100%;
	height: 100%;
	transform: translateX(0px);
	transition: all 1s ease;
	border-bottom: 1px solid #707070;
}


.slider-grid .tablinks {
	color: #878A90;
	font-size: 13px;
	padding-bottom: 6px;
	text-transform: capitalize;
	cursor: pointer;
}

.slider-grid .active {
	color: #fff;
	position: relative;
}


</style>