
<script setup>
 /* eslint-disable */
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { closeModal } from '@kolirt/vue-modal'
import { useCustomerStore } from '@/stores/customer';

import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svgs_data = ref(commonSvgs);

// Define store
const customerStore = useCustomerStore();

// Define variables
const loading = ref(false);
const success_message = ref(null);
const errors = ref({});

const show_deposit_info = ref(false);
const show_loss_info = ref(false);
const show_time_info = ref(false);

const show_deposit_limits_input = ref(false);
const deposit_limits_selected_period = ref('daily');
const deposit_limits_selected_choice = ref(null);
const deposit_limits_input = ref('');

const show_loss_limits_input = ref(false);
const loss_limits_selected_period = ref('daily');
const loss_limits_selected_choice = ref(null);
const loss_limits_input = ref('');

const show_time_limits_input = ref(false);
const time_limits_selected_period = ref('daily');
const time_limits_selected_choice = ref(null);
const time_limits_input = ref('');

// Handle dropdowns changes
const depositLimitsChange = (event) => {
	removeError('deposit_limits.value');

	if (event.target.value === 'other') {
		show_deposit_limits_input.value = true;
	} else {
		show_deposit_limits_input.value = false;
		deposit_limits_input.value = '';
	}

	deposit_limits_selected_choice.value = event.target.value;
};

const lossLimitsChange = (event) => {
	removeError('loss_limits.value');

	if (event.target.value === 'other') {
		show_loss_limits_input.value = true;
	} else {
		show_loss_limits_input.value = false;
		loss_limits_input.value = '';
	}

	loss_limits_selected_choice.value = event.target.value;
};

const timeLimitsChange = (event) => {
	removeError('time_limits.value');

	if (event.target.value === 'other') {
		show_time_limits_input.value = true;
	} else {
		show_time_limits_input.value = false;
		time_limits_input.value = '';
	}

	time_limits_selected_choice.value = event.target.value;
};

// Validate inputs
const validateDepositLimitsInput = async (event) => {
	removeError('deposit_limits.value');

	let input = event.target.value.replace(/[^0-9]/g, '');

	// Convert string to a number
	const numericValue = parseInt(input, 10);

	// Ensure the number is greater than 0, if not, reset the input to an empty string
	if (isNaN(numericValue) || numericValue <= 0) {
		input = '';
	} else {
		// If valid, and number is less than or equal to the maximum length of 7 digits
		input = input.slice(0, 7);
	}

	deposit_limits_input.value = input;
};

const validateLossLimitsInput = async (event) => {
	removeError('loss_limits.value');

	let input = event.target.value.replace(/[^0-9]/g, '');

	// Convert string to a number
	const numericValue = parseInt(input, 10);

	// Ensure the number is greater than 0, if not, reset the input to an empty string
	if (isNaN(numericValue) || numericValue <= 0) {
		input = '';
	} else {
		// If valid, and number is less than or equal to the maximum length of 7 digits
		input = input.slice(0, 7);
	}

	loss_limits_input.value = input;
};

const validateTimeLimitsInput = async (event) => {
	removeError('time_limits.value');

	let input = event.target.value.replace(/[^0-9]/g, '');

	// Convert string to a number
	const numericValue = parseInt(input, 10);

	// Ensure the number is greater than 0, if not, reset the input to an empty string
	if (isNaN(numericValue) || numericValue <= 0) {
		input = '';
	} else {
		// If valid, and number is less than or equal to the maximum length of 7 digits
		input = input.slice(0, 7);
	}

	if (Number(input) > 18) input = '18';

	time_limits_input.value = input;
};

const removeError = (slug) => {
	if (customerStore.getProtectionLimitsErrors.errors) {
		delete customerStore.getProtectionLimitsErrors.errors[slug];
	}
};

// Watch - Popovers visibility
watch(show_deposit_info, (newValue) => {
	if (newValue === true) {
		show_loss_info.value = false;
		show_time_info.value = false;
	}
});

watch(show_loss_info, (newValue) => {
	if (newValue === true) {
		show_deposit_info.value = false;
		show_time_info.value = false;
	}
});

watch(show_time_info, (newValue) => {
	if (newValue === true) {
		show_deposit_info.value = false;
		show_loss_info.value = false;
	}
});

// Watch - Success and error messages
watch(success_message, (newValue, oldValue) => {
	if (newValue != null) {
		setTimeout(() => {
			success_message.value = null;
		}, 4000);
	}
});

watch(errors, (newValue, oldValue) => {
	if (Object.keys(newValue).length > 0) {
		setTimeout(() => {
			errors.value = {};
		}, 4000);
	}
});

// Update Limits
const setCustomerProtectionLimits = () => {
	loading.value = true;
	// errors.value = {};

	// Create payload
	let payload = {
		deposit_limits: {
			period: deposit_limits_selected_period.value,
			value: deposit_limits_selected_choice.value == 'other' ? deposit_limits_input.value : deposit_limits_selected_choice.value
		},
		loss_limits: {
			period: loss_limits_selected_period.value,
			value: loss_limits_selected_choice.value == 'other' ? loss_limits_input.value : loss_limits_selected_choice.value
		},
		time_limits: {
			period: time_limits_selected_period.value,
			value: time_limits_selected_choice.value == 'other' ? time_limits_input.value : time_limits_selected_choice.value
		},
	}

	customerStore.updateCustomerDefaultLimits(payload).then((response) => {
		loading.value = false;

		closeModal();
	}).catch((error) => {
		success_message.value = null;
		loading.value = false;

		// Set errors
		if (error && error.errors) errors.value = error.errors;
		else closeModal();
	})
}

onMounted(() => {
	// Disable background scrolling
	document.body.style.overflow = 'hidden';

	deposit_limits_selected_choice.value = customerStore.customerProtectionSectionChoices('deposit_limits')[0].value;
	loss_limits_selected_choice.value = customerStore.customerProtectionSectionChoices('loss_limits')[0].value;
	time_limits_selected_choice.value = customerStore.customerProtectionSectionChoices('time_limits')[0].value;
});

onUnmounted(() => {
	// Enable background scrolling
	document.body.style.overflow = '';
});
</script>
<template>
<div class="modal-outer message-popup responsible-betting-popup">
	<div class="modal">
		<div class="modal-header">
			<h3>{{ $t('account.customer_protection.responsible_betting') }}</h3>
			<p>{{ $t('account.customer_protection.legal_framework') }}</p>
		</div>
		<div class="betting-form">

				<div class="register-inner-form">
					<div class="register-form-field">
						<label>{{ $t('account.customer_protection.deposit_limits') }}
							<div @click="show_deposit_info = !show_deposit_info"><span  class="tooltip tool-tip-icon" v-html="svgs_data.tool_tip_icon"></span></div>

							<div v-if="show_deposit_info" class="popover">
								<!-- Popover Header -->
								<div class="popover-header">
									<span class="popover-title">{{ $t('account.customer_protection.deposit_limits') }}</span>
									<a class="close-btn" @click="show_deposit_info = !show_deposit_info">✖</a>
								</div>

								<!-- Popover Content -->
								<div class="popover-content">
									{{ $t('account.customer_protection.deposit_limits_info') }}
								</div>
							</div>

						</label>
						<div class="inner-betting-fields">
							<select name="" id="address-country" class="form-input form-control" v-model="deposit_limits_selected_period">
								<option value="daily">{{ $t('account.customer_protection.daily') }}</option>
								<option value="weekly">{{ $t('account.customer_protection.weekly') }}</option>
								<option value="monthly">{{ $t('account.customer_protection.monthly') }}</option>
							</select>

							<select name="" id="address-country" class="form-input form-control" @change="depositLimitsChange">
								<option v-for="(option, index) in customerStore.customerProtectionSectionChoices('deposit_limits')" :key="index" :value="option.value">{{ Number(option.value).toLocaleString() }} {{ customerStore.customer.currency.symbol }}</option>
								<option value="other">{{ $t('account.customer_protection.other_amount') }}</option>
							</select>

						</div>

					</div>

					<input v-if="show_deposit_limits_input" v-model="deposit_limits_input" type="text" @input="validateDepositLimitsInput"  id="address-country" class="form-input form-control" :placeholder="$t('general.enter_amount')"/>
					<!-- <span style="color: red;" v-if="v$.deposit_limit_input_value?.$errors">{{ v$.deposit_limit_input_value?.$errors[0]?.$message }}</span> -->

					<div class="register-form-field">
						<label>{{ $t('account.customer_protection.loss_limits') }}
							<div @click="show_loss_info = !show_loss_info"><span  class="tooltip tool-tip-icon" v-html="svgs_data.tool_tip_icon"></span></div>
							<div v-if="show_loss_info" class="popover">
								<!-- Popover Header -->
								<div class="popover-header">
									<span class="popover-title">{{ $t('account.customer_protection.loss_limits') }}</span>
									<a class="close-btn" @click="show_loss_info = !show_loss_info">✖</a>
								</div>

								<!-- Popover Content -->
								<div class="popover-content">
									{{ $t('account.customer_protection.loss_limits_info') }}
								</div>
							</div>
						</label>


						<div class="inner-betting-fields">
							<select name="" id="address-country" class="form-input form-control" v-model="loss_limits_selected_period">
								<option value="daily">{{ $t('account.customer_protection.daily') }}</option>
								<option value="weekly">{{ $t('account.customer_protection.weekly') }}</option>
								<option value="monthly">{{ $t('account.customer_protection.monthly') }}</option>
							</select>

							<select name="" id="address-country" class="form-input form-control" @change="lossLimitsChange">
								<option v-for="option in customerStore.customerProtectionSectionChoices('loss_limits')" :value="option.value">{{ Number(option.value).toLocaleString() }} {{ customerStore.customer.currency.symbol }}</option>
								<option value="other">{{ $t('account.customer_protection.other_amount') }}</option>
							</select>
						</div>

					</div>
					<input type="text" v-if="show_loss_limits_input" v-model="loss_limits_input" @input="validateLossLimitsInput"  id="address-country" class="form-input form-control" :placeholder="$t('general.enter_amount')"/>
					<!-- <span style="color: red;" v-if="v$.loss_limit_input_value?.$errors">{{ v$.loss_limit_input_value?.$errors[0]?.$message }}</span> -->
					<div class="register-form-field">
						<label>{{ $t('account.customer_protection.time_limits') }}
							<div @click="show_time_info = !show_time_info"><span  class="tooltip tool-tip-icon" v-html="svgs_data.tool_tip_icon"></span></div>
							<div v-if="show_time_info" class="popover">
								<!-- Popover Header -->
								<div class="popover-header">
									<span class="popover-title">{{ $t('account.customer_protection.time_limits') }}</span>
									<a class="close-btn" @click="show_time_info = !show_time_info">✖</a>
								</div>

								<!-- Popover Content -->
								<div class="popover-content">
									{{ $t('account.customer_protection.time_limits_info') }}
								</div>
							</div>
						</label>
						<p>{{ $t('account.customer_protection.maximum_playing_time_per_day') }}</p>
						<div class="inner-betting-fields">
							<select name="" id="address-country" class="form-input form-control" v-model="time_limits_selected_period">
								<option value="daily">{{ $t('account.customer_protection.daily') }}</option>
								<option value="weekly">{{ $t('account.customer_protection.weekly') }}</option>
								<option value="monthly">{{ $t('account.customer_protection.monthly') }}</option>
							</select>

							<select name="" id="address-country" class="form-input form-control" @change="timeLimitsChange">
							  <option v-for="option in customerStore.customerProtectionSectionChoices('time_limits')"
							:value="option.value">{{ option.value }} {{ $t('account.customer_protection.hours') }}</option>
						<option value="other">{{ $t('account.customer_protection.other_amount') }}</option>
							</select>

						</div>

					</div>
					<input v-if="show_time_limits_input" v-model="time_limits_input" type="text" @input="validateTimeLimitsInput" id="address-country" class="form-input form-control" :placeholder="$t('general.enter_amount_in_hours')"/>
					<!-- <span style="color: red;" v-if="v$.time_limit_input_value?.$errors">{{ v$.time_limit_input_value?.$errors[0]?.$message }}</span> -->

				</div>
				<div class="form-footer">
					<p>{{ $t('account.customer_protection.time_limits_applies') }}</p>
					<div class="submit-btn">
						<button type="button" class="btn btn-secondary" @click="setCustomerProtectionLimits" v-if="!loading">{{ $t('account.customer_protection.submit_limits') }}</button>
						<button class="btn btn-secondary " type="button" v-else><div class="loader-main"></div></button>
					</div>
				</div>


		</div>
		<!-- end -->
	</div>
</div>
</template>
<style scoped>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
}

.popover {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	width: 345px;
	background-color: #0C131A;
	padding: 10px;
	box-sizing: border-box;
	border-left: 1px solid var(--linear-gradient-primary-color);
	border-bottom: 1px solid var(--linear-gradient-secondary-color);
	border-right: 1px solid var(--linear-gradient-secondary-color);
	border-top: 1px solid var(--linear-gradient-primary-color);
	border-radius: 6px;
	font-family: Arial, sans-serif;
	font-size: 0.8rem;
	text-align: center;
}

.popover-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	padding-bottom: 5px;
	margin-bottom: 10px;
}

.popover-title {
	font-weight: bold;
	color: var(--text-primary-color);
	width: 100%;
	text-align: center;
	font-weight: bold;
	margin-top: 5px;
	margin-bottom: 5px;
}

.close-btn {
	background-color: transparent;
	border: none;
	font-family: Arial, sans-serif;
	font-size: 1rem;
	cursor: pointer;
	color: var(--linear-gradient-primary-color);
	padding: 5px;
	border-radius: 50%;
	transition: background-color 0.3s;
	outline: none;
	box-shadow: none;
	line-height: 1;
}

.close-btn:hover {
	background-color: rgba(227, 130, 188, 0.1);
}

.popover-content {
	text-align: center;
	color: #eee;
}

.blocks {
	display: flex;
	gap: 10px;
	background-color: #191F28;
}

.dropdown-menu {
	width: calc(50% - 5px);
}

.error-message {
	color: red;
	font-size: 12px;
	margin-top: 5px;
}

select {
	background-image: url('@/assets/images/arrow-select.svg');
	background-repeat: no-repeat;
	background-position: 95%;
}

html[dir="rtl"] .select {
	background-position: 5%;
}

.notice {
	margin-top: 10px;
	font-family: Arial, sans-serif;
	font-size: 0.8rem;
	background-color: red;
	height: 40px;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	color: var(--text-primary-color);
}

html[dir="rtl"] .modal-outer.message-popup.responsible-betting-popup .betting-form .register-inner-form select {
	background-position: center left 14px;
}
</style>