<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { useTodaysCouponsStore } from '@/stores/todays_coupons';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useInitializeStore } from '@/stores/initialize';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { useRouter } from 'vue-router';

const router = useRouter();

const direction = ref(document.dir);
const breakpoints = ref({
	360: {
		perPage:4,
		gap: '3px',
	},
	414: {
		perPage:4,
		gap: '10px',
	},
	767: {
		perPage:4,
		gap:'10px',
	},
	991: {
		perPage:6,
		gap: '10px',
	},
	1024: {
		perPage: 7,
	},
	1680: {
		perPage: 8,
		 gap: '10px',
	},
	1919: {
		perPage: 8,
		 gap: '10px',
	},
	1920: {
		perPage: 10,
		gap:'10px',
	},
});

const todayCouponsStore = useTodaysCouponsStore();
const liveSportsStore = useLiveSportsStore();
const initializeStore = useInitializeStore();

const goToSport = (sport_id) => {
	router.push({ name: 'pre-sports-highlights', params: { sport_id: sport_id }});
}

onMounted(() => {
	todayCouponsStore.fetchTodaysCoupons();
});

</script>
<template>
	<div class="coupon-slider-wrapper">
		<div class="coupon-slider-overflow">
			<Splide :options="{ rewind: false, breakpoints:breakpoints, perPage: 10, pagination:false, perMove: 1, autoplay:false, type:'false', direction:direction}">
				<SplideSlide v-for="sport in todayCouponsStore.todaysSports" :key="sport.id">
					<div class="coupon-slider-item carousel__item">
						<a @click="goToSport(sport.id)" :to="{ name: 'pre-sports-highlights', params: { sport_id: sport.id } }">
							<div class="coupon-slider-content">
								<img :src="liveSportsStore.liveSportWhiteIcon(sport.id)" alt="">
								<p> {{ sport.name }} </p>
							</div>
						</a>
					</div>
				</SplideSlide>
			</Splide>
		</div>
	</div>
</template>
<style scoped>
.coupon-slider .splide :deep(.splide__arrow:disabled) {
	display: none;
}
html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--prev) {
	right: -20px;
	left: unset;
	rotate: 180deg;
	top: 35px;
}

html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--next) {
	left: -20px;
	right: unset;
	rotate: 180deg;
	top: 35px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.coupon-slider .splide :deep(.splide__arrows .splide__arrow--prev) {
		left: -8px;
	}
	.coupon-slider .splide :deep(.splide__arrows .splide__arrow--next) {
		right: -8px;
	}
	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--prev) {
		right: -10px;
		left: unset;
		top: 20px;
	}

	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--next) {
		left: -10px;
		right: unset;
		top: 20px;
	}
}

@media screen and (min-width: 1360px) and (max-width: 1599px) {
	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--next) {
		left: -3.5%;
	}

	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--prev) {
		right: -3.5%;
	}
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--next) {
		top: 20px;
	}

	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--prev) {
		top: 20px;
	}
}

@media screen and (min-width: 768px) and (max-width: 992px) {
	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--next) {
		top: 22px;
	}

	html[dir="rtl"] .coupon-slider .splide :deep(.splide__arrows .splide__arrow--prev) {
		top: 22px;
	}
}
</style>
