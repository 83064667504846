<script setup>
/* eslint-disable */
import { usePromotionsStore } from '@/stores/promotions';
import { onMounted } from 'vue'
import { useRouter } from 'vue-router';

// Define stores
const router = useRouter();
const promotionsStore = usePromotionsStore();

const goToPromotion = (promotion_slug) => {
	router.push({ name: 'promotion', params: { promotion_slug: promotion_slug } });
}

onMounted(() => {
	if (Object.keys(promotionsStore.getPromotions).length == 0) promotionsStore.fetchPromotionCategories();
});
</script>

<template>
		<div class="promo-card-main grid-column-main">
			<div @click="goToPromotion(promotion.slug)" class="promo-card-inner" v-for="(promotion, index) in promotionsStore.getPromotionsLanding" :key="index" style="cursor: pointer;">
				<div class="promo-image">
					<img :src="getImage(promotion.media_url)" @error="getImage('')" alt="">
				</div>
				<div class="promo-content">
					<h2>{{promotion.title}}</h2>
					<p>{{ promotion.subtitle }}</p>
					<router-link class="cursor-hand" :to="'/promotion/' + promotion.slug"><img src="@/assets/images/arrow-ico.svg" alt="arrow-btn">&nbsp;{{ promotion.button_text }}
					</router-link>
				</div>
			</div>
		</div>
</template>
<style scoped>
html[dir="rtl"] .promo-card-main .promo-card-inner .promo-content img {
	margin-left: 5px;
	margin-right: unset;
	rotate: 180deg;
}

.promo-card-main {
	scrollbar-width: none !important;
}
</style>