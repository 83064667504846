import { defineStore } from 'pinia';
import _ from 'lodash';

export const useBannersStore = defineStore('bannersStore', {
	state: () => ({
		hero_banners: []
	}),
	getters: {
		getLiveCasinoHeroBanners() {
			let live_casino = this.hero_banners.filter(banner => banner.banner_slot_slug === 'live_casino');
			return live_casino
		},
		homepage_banners() {
			const hero_banners = this.hero_banners.filter(banner => banner.banner_slot_slug === 'homepage');
			return _.take(_.sortBy(hero_banners, 'sort'), 3);
		},
		getCasinoHeroBanners(state) {
			return _.filter(state.hero_banners, { 'banner_slot_slug': 'casino' });
		},
		is_mobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true;
			}

			return false;
		}
	},
	actions: {
		fetchHeroBanners() {
			const device_type = this.is_mobile ? 'mobile' : 'desktop';

			return global.axios.get(process.env.VUE_APP_API_URL + '/banners/hero/' + device_type).then((response) => {
				this.hero_banners = response.data.data
				return Promise.resolve(response.data.data)
			}).catch((error) => {
				return Promise.reject(error);
			})

		},
	},
})