<script setup>
/* eslint-disable */
import EsportRegions from './EsportRegions.vue';
import EsportTopCompetitions from './EsportTopCompetitions.vue';
import LeftSidebarFavourites from './LeftSidebarFavourites.vue';
import { useFavouritesStore } from '@/stores/favourites';

const favouritesStore = useFavouritesStore();

</script>
<template>
	<LeftSidebarFavourites v-if="Object.keys(favouritesStore.esportsLeftSidebarFavourites).length > 0"/>
	<EsportTopCompetitions/>
	<!-- Regions -->
	<EsportRegions/>
</template>