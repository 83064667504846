<!-- eslint-disable -->
<script setup>
import { computed } from 'vue';
const props = defineProps({
	fixture_data: {
		type: Object,
		required: true
	}
})

const correctInnings = computed(() => {
	let innings = _.cloneDeep(props.fixture_data.scoreboard.innings)
	for (const key in innings) {
		if (Object.prototype.hasOwnProperty.call(innings, key)) {
			const inning = innings[key];
			if (inning.p1 == -1 && inning.p2 == -1) delete innings[key];
		}
	}

	return innings;
})

</script>
<template>
	<div class="scorecard-inner-main">
		<div class="scorecard-inner">
			<div class="team-name">
			</div>

			<div class="scorecard-score-outer">
				<div class="scorecard-score-inner" v-for="(inning, index) in correctInnings" :key="index">
					<Popper arrow hover openDelay="100" closeDelay="100"  placement="top">
						<p> {{ index }}</p>
						<template #content>
							<div class="tooltip">{{ $t('scoreboard.innings') }} {{ index }}</div>
						</template>
					</Popper>
				</div>
			</div>
		</div>

		<div class="scorecard-inner">
			<div class="team-name">
				<h5>{{fixture_data.participants[0]}}</h5>
			</div>
			<div class="scorecard-score-outer">
				<div class="scorecard-score-inner" v-for="(inning, index) in correctInnings" :key="index">
					<h6 v-if="inning.p1!=-1">{{ inning.p1 }}</h6>
				</div>
			</div>
		</div>

		<div class="scorecard-inner">
			<div class="team-name">
				<h5>{{fixture_data.participants[1]}}</h5>
			</div>
			<div class="scorecard-score-outer">
				<div class="scorecard-score-inner" v-for="(inning, index) in correctInnings" :key="index">
					<h6 v-if="inning.p2!=-1">{{ inning.p2 }}</h6>
				</div>
			</div>
		</div>

	</div>
</template>
<style scoped>
.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview-set_game-banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner-main .scorecard-inner .scorecard-score-outer .scorecard-score-inner:first-child h6 {
	color: unset;
}

.tooltip {
	width: 50px;
	background-color: #63A246;
	padding: 5px 0;
	text-align: center;
	border: 1px solid #63A246;
	border-radius: 50px !important;
	font-size: 10px;
	color: #ffffff;
}
:deep(.popper) {
	background-color: #63A246;
	padding: 20px;
	border-radius: 20px;
	font-weight: bold;
	margin-right: 30px;
}

:deep(.popper #arrow::before) {
	background: #63A246;
	left: 0px;
}
:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
	background: #63A246;
}
</style>