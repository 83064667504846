<!-- eslint-disable -->
<script setup>
import { onMounted, computed, ref } from 'vue';
import { useBannersStore } from '@/stores/banners';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

// Define Stores
const bannersStore = useBannersStore();
const is_rtl = ref(null);

const hasBanners = computed(() => {
	return bannersStore.getLiveCasinoHeroBanners.length > 0;
});

onMounted(() => {
	is_rtl.value = document.documentElement.getAttribute('dir') === 'rtl';
})
</script>

<template>
	<section v-if="hasBanners">
		<div class="banner-full">
			<div class="slider">
				<div class="slides">
					<Splide :options="{perPage: 1, drag:true, arrows:false, pagination:false, autoplay:true, interval:5000, type:'loop', direction: is_rtl ? 'rtl' : 'ltr'}">
						<SplideSlide v-for="slide in bannersStore.getLiveCasinoHeroBanners" :key="slide.id">
							<div class="slide carousel__item">
								<div class="banner-main-image">
									<img :src="slide.media_url" alt="Image 1">
								</div>
								<div class="banner-content" id="banner-item">
									<h1>{{ slide.title }}</h1>
									<h5>{{ slide.sub_title }}</h5>
									<div class="banner-btn">
									<a v-if="slide.button_text && slide.button_url"
										:href="slide.button_url" class="btn bc-btn-lg btn-secondary">{{ slide.button_text }}
									</a>
								</div>
								</div>
							</div>
						</SplideSlide>
					</Splide>
				</div>
			</div>
		</div>
	</section>
</template>
<style scoped>
html[dir="rtl"] .slider :deep(.splide__pagination) {
	width: 50%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.banner-content .banner-btn {
		position: relative;
		font-weight: 400;
		text-align: left;
		margin-top: 12px;
	}

	.banner-content:has(a) h5 {
		width: 80%;
	}
}

.slider {
	padding-top: 0px !important;
}
</style>