import { defineStore } from 'pinia';

export const useAccountStore = defineStore('account', {
	state: () => ({
		previous_route: null
	}),
	getters: {
		getPreviousRoute(state){
			return state.previous_route
		},
	},
	actions: {
		setPreviousRoute(data) {
			this.previous_route = data;
		}
	},
})