<script setup>
/* eslint-disable */
import { onMounted, onBeforeUnmount, ref, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useGeneralStore } from '@/stores/general';
import { useSocketStore } from '@/stores/socket';
import { useCouponsStore } from '@/stores/coupons';
import { useCompetitionsStore } from '@/stores/competitions';
import CompetitionFixtures from '@/components/pre_sports/CompetitionFixtures.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const router = useRouter();
const competition_ids = ref([]);
const socketStore = useSocketStore();
const competitionsStore = useCompetitionsStore();
const couponsStore = useCouponsStore();
const preSportsStore = usePreSportsStore();
const generalStore = useGeneralStore();

socketStore.getSocket.on('competitionFixtures', (data) => {
	competitionsStore.setCompetitionFixtures(data);
});

socketStore.getSocket.on('competitionFixturesUpdate', (data) => {
	competitionsStore.updateCompetitionFixture(data);
});

socketStore.getSocket.on('competitionFixturesDelete', (data) => {
	competitionsStore.deleteCompetitionFixture(data);
});

onMounted(() => {
	if (router.currentRoute.value.params.competition_ids) {
		competition_ids.value = router.currentRoute.value.params.competition_ids.split(',');
		competition_ids.value.forEach(competition_id => {
			socketStore.getSocket.emit('join-competitionFixtures', competition_id)
		});
	}
});

onBeforeUnmount(() => {
	competition_ids.value.forEach(competition_id => {
		socketStore.getSocket.emit('leave-competitionFixtures', competition_id);
	});

	competitionsStore.emptyCompetitions();
})

watch(() => preSportsStore.preSports, (newSports, oldSports) => {
	if (oldSports.length == 0 && newSports.length > 0) {
		let selected_pre_sport = _.find(preSportsStore.preSports, {'id': Number(router.currentRoute.value.params.sport_id) });
		if (!selected_pre_sport) return;
		document.title = generalStore.getGeneral.site_name + ' - ' + selected_pre_sport.name + ' ' + t('general.competitions');
	}
})


</script>
<template>
	<div id="Competitions" class="competetion-section">
		<CompetitionFixtures v-for="(competition) in competitionsStore.competitionFixturesByDate" :key="competition.competition_id" :competition="competition"/>
	</div>

</template>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}
}

.competetion-section {
	scrollbar-width: none !important;
}
</style>