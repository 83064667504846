<script setup>
/* eslint-disable */
import { useRouter, useRoute } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import { useCouponsStore } from '@/stores/coupons';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useInitializeStore } from '@/stores/initialize';

const router = useRouter();
const route = useRoute();
const couponsStore = useCouponsStore();
const preSportsStore = usePreSportsStore();
const initializeStore = useInitializeStore();
const popup_message = ref(false);
const show_scroll_to_top_button = ref(false);

const expand = () => {
	couponsStore.toggleAllExpanded();
}

const goToAllCoupons = () => {
	router.push({ name: 'esports-coupons', params: { sport_id: preSportsStore.selectedEsportId }});
}

const scrollToTop = () => {
	window.scrollTo({ top: 0 });
};

const handleScroll = () => {
	const scrollTop = window.scrollY;
	show_scroll_to_top_button.value = scrollTop > 100;
};

onMounted(() => {
	if (initializeStore.screen_width < 768) couponsStore.all_expanded = false;
	window.addEventListener('scroll', handleScroll);
});

const goToNextHours = (hours) => {
	router.push({ name: 'esports-coupons-next-hours', params: { sport_id: preSportsStore.selectedEsportId, hours: hours }});
}

const goToCompetitions = () => {
	if (couponsStore.selectedCompetitionIds.length == 0) return;
	let competition_ids = couponsStore.selectedCompetitionIds.toString();

	router.push({ name: 'esports-coupons-competitions', params: { sport_id: preSportsStore.selectedEsportId, competition_ids: competition_ids}});
}

const isShowButtonDisabled = computed(() => {
	if (couponsStore.selectedCompetitionIds.length == 0) return true;
	if (couponsStore.selectedCompetitionIds.length > 40) return true;

	return false;
});

watch(() => couponsStore.selectedCompetitionIds.length, (newValue) => {
	popup_message.value = false;
	if (newValue > 40) popup_message.value = true;
	else popup_message.value = false;
})

watch(() => preSportsStore.selectedEsportId, (newValue, oldValue) => {
	if (newValue != null && oldValue == null) {
		if (router.currentRoute.value.params.sport_id == '') {
			router.push( { name: 'esports-coupons', params: { sport_id: preSportsStore.selectedEsportId } } )
		}
	}
});


</script>

<template>
<div id="Coupons" class="">
	<div class="coupons-section-outer">

		<div class="coupon-section-header">
			<div class="tab">
				<button class="tablinks" @click="goToAllCoupons" :class="{'active': router.currentRoute.value.name == 'esports-coupons'}">{{$t('general.all')}}</button>
				<button class="tablinks" @click="goToNextHours('3')" :class="{'active': router.currentRoute.value.name == 'esports-coupons-next-hours' && router.currentRoute.value.params.hours == '3'}">3 {{$t('general.hours')}}</button>
				<button class="tablinks" @click="goToNextHours('12')" :class="{'active': router.currentRoute.value.name == 'esports-coupons-next-hours' && router.currentRoute.value.params.hours == '12'}">12 {{$t('general.hours')}}</button>
				<button class="tablinks" @click="goToNextHours('24')" :class="{'active': router.currentRoute.value.name == 'esports-coupons-next-hours' && router.currentRoute.value.params.hours == '24'}">24 {{$t('general.hours')}}</button>
			</div>

			<div class="show-coupons-btn" v-if="router.currentRoute.value.name == 'esports-coupons'">
				<button class="btn-small" @click="goToCompetitions" :disabled="isShowButtonDisabled">{{$t('general.show_lowercase')}}</button>
				<div v-if="popup_message" class="tooltip-main left">
					<div class="tooltip-inner">
						<p>{{$t('general.coupon_tooltip')}}</p>
					</div>
				</div>
			</div>

			<span class="toggle-markets" v-if="router.currentRoute.value.name == 'esports-coupons'">
				<div @click="expand" class="markets-toggle-fill">
					<svg v-if="couponsStore.all_expanded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
						<use xlink:href="/assets/icons/markets_close.svg#markets_close"></use>
					</svg>
					<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
						<use xlink:href="/assets/icons/markets_open.svg#markets_open"></use>
					</svg>
				</div>
			</span>
		</div>

		<router-view></router-view>

	</div>
</div>
<button @click="scrollToTop" type="button" class="btn scroll-to-top" id="btn-back-to-top" v-show="show_scroll_to_top_button">
	<i class="fas fa-arrow-up"></i>
</button>
</template>
<style scoped>
.tooltip-main.left {
	left: -120px;
}

@media screen and (min-width: 992px) and (max-width: 3000px) {
	button#btn-back-to-top {
		display:none;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		bottom: 70px;
		left: 20px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	svg.svg-inline--fa.fa-arrow-up.fa-w-14 {
		margin-bottom: 7px!important;
	}
	button#btn-back-to-top {
		width: 30px!important;
		height: 30px!important;
		font-size: 14px!important;
	}
}
button#btn-back-to-top {
	background: #63A246;
	width: 38px;
	height: 38px;
	border: none;
	border-radius: 50%;
	position: fixed;
	z-index: 99;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	cursor: pointer;
}
html[dir="rtl"] button#btn-back-to-top {
	left: unset;
	right:20px;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] button#btn-back-to-top {
		left: unset;
		right:10px;
	}
}

html[dir="rtl"] .coupons-section-outer .coupon-section-header .show-coupons-btn {
	text-align: left;
	position: relative;
}

html[dir="rtl"] .tooltip-main.left {
	right: -120px;
	left: unset;
}

html[dir="rtl"] .tooltip-main.left:after {
	content: "";
	position: absolute;
	left: -3px;
	top: 50%;
	transform: translateY(-50%) rotate(44deg);
	width: 6px;
	height: 6px;
	background: #363D47;
	border-left: 1px solid #4E545D;
	border-right: unset;
	border-bottom: 1px solid #4E545D;
	border-top: unset;
	right: unset;
}



.tooltip-main.left {
	left: -90px;
}

@media screen and (min-width: 320px) and (max-width: 320px) {
	.tooltip-main.left {
		left: -120px;
		top: 40%;
	}
	html[dir="rtl"] .tooltip-main.left {
		left: -130px;
		top: 40%;
	}
}

@media screen and (min-width: 375px) and (max-width: 500px) {
	.tooltip-main.left {
		left: -120px;
	}
}

@media screen and (min-width: 501px) and (max-width: 630px) {
	.tooltip-main.left {
		left: -100px;
	}
	html[dir="rtl"] .tooltip-main.left {
		left: unset;
		right: -100px;
	}
}

@media screen and (min-width: 631px) and (max-width: 767px) {
	.tooltip-main.left {
		left: -80px;
	}
}
@media screen and (min-width: 631px) and (max-width: 700px) {
	html[dir="rtl"] .tooltip-main.left {
		right: -80px;
		left: unset;
	}
}

@media screen and (min-width: 700px) and (max-width: 771px) {
	html[dir="rtl"] .tooltip-main.left {
		right: -70px;
		left: unset;
	}
}

@media screen and (min-width: 768px) and (max-width: 771px) {
	.tooltip-main.left {
		left: -60px;
	}
}

@media screen and (min-width: 772px) and (max-width: 1500px) {
	.tooltip-main.left {
		left: -130px;
	}
}

@media screen and (min-width: 1500px) and (max-width: 1700px) {
	.tooltip-main.left {
		left: -120px;
	}
	html[dir="rtl"] .tooltip-main.left {
		right: -120px;
		left: unset;
	}
}

@media screen and (min-width: 1701px) {
	.tooltip-main.left {
		left: -110px;
	}
	html[dir="rtl"] .tooltip-main.left {
		right: -110px;
		left: unset;
	}
}
</style>