<script setup>
/* eslint-disable */
import { onMounted, ref, computed, watch } from 'vue';
import { useFixtureStore } from '@/stores/fixture';
import { useMarketsStore } from '@/stores/markets';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useDatesStore } from '@/stores/dates';
import { useRouter } from 'vue-router';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import FeaturedFixtureOverviewOdd from './FeaturedFixtureOverviewOdd.vue';

const fixtureStore = useFixtureStore();
const marketsStore = useMarketsStore();
const preSportsStore = usePreSportsStore();
const datesStore = useDatesStore();
const liveSportsStore = useLiveSportsStore();

const svg_data = ref(commonSvgs);

const router = useRouter();

const props = defineProps({
	fixture: {
		type: Object,
		required: true,
	},
});

const goToFixture = () => {
	if (!props.fixture.live) router.push({ name: 'pre-sports-fixturebook', params: { sport_id: props.fixture.sport_id, region_id: props.fixture.region_id, competition_id: props.fixture.competition_id, fixture_id: props.fixture.id } } )
	else router.push( { name: 'live-sports-eventview', params: { fixture_id: props.fixture.id } } );
}

const primary_overview_market = ref(marketsStore.sportOverviewMarkets(props.fixture.sport_id)[0]);

const primaryOverviewMarketOdds = computed(() => {
	if (!props.fixture.overview_markets.hasOwnProperty(primary_overview_market.value.slug)) return [];

	return props.fixture.overview_markets[primary_overview_market.value.slug];
});

const getImageUrl = computed(() => {
	if (props.fixture.highlight_image_url) {
		return process.env.VUE_APP_STORAGE_URL + 'highlights/' + props.fixture.highlight_image_url;
	}

	if (props.fixture.competition_image_url) {
		return process.env.VUE_APP_STORAGE_URL + 'competitions_images/' + props.fixture.competition_image_url;
	}

	return '/assets/default_competition_images/comp-' + preSportsStore.preSportName(props.fixture.sport_id) + '.jpg';
});

watch(() => props.fixture.sport_id, (newSportId) => {
	primary_overview_market.value = marketsStore.sportOverviewMarkets(newSportId)[0]
})

</script>
<template>
	<div class="league-slide-item">
		<div class="live-legaue-card" :class="props.fixture.live ? 'live-league-match' : '' ">
			<div class="live-card-inner">
				<div class="live-card-image">
					<img :src="getImageUrl" alt="promo-card-1">
				</div>
				<div class="live-card-content">
					<div class="league-title" @click="goToFixture">
						<div class="title">
							<img :src="preSportsStore.preSportWhiteIcon(props.fixture.sport_id)" alt="">
							<h6>{{ props.fixture.competition_name }}</h6>
						</div>
						<div class="score_time">
							<span>{{ datesStore.preFixtureBookDate(props.fixture.start_datetime) }}</span> - <span> {{ datesStore.preFixtureBookTime(props.fixture.start_datetime) }}</span>
						</div>
						<div class="live-info-data">
							<div class="live-badge" v-html="svg_data.live_badge"></div>
							<div class="live-data-desktop" v-if="liveSportsStore.hasFixtureClock(props.fixture.sport_id)">
								<h3>
									<dl>
										{{ fixtureStore.fixtureClockTime(props.fixture) }}
										<dt> {{ fixtureStore.fixtureState(props.fixture) }}</dt>
									</dl>

									<span></span>
								</h3>
							</div>
							<div class="live-data-desktop" v-else>
								<h3 >{{ fixtureStore.fixtureState(props.fixture) }}</h3>
								<span></span>
							</div>
						</div>
					</div>

					<div class="team-info" @click="goToFixture">
						<div class="team-info-inner">
							<div class="team-title">
								<h4>{{ props.fixture.participants? props.fixture.participants[0] : ''}}</h4>
							</div>
							<!-- <div class="team-shirt">
								<img src="@/assets/images/team-left.png" alt="">
							</div> -->
						</div>

						<div class="divider">
							-
						</div>

						<div class="team-info-inner" v-if="!liveSportsStore.hasOneParticipant(props.fixture.sport_id)">

							<!-- <div class="team-shirt">
								<img src="@/assets/images/team-right.png" alt="">
							</div> -->
							<div class="team-title">
								<h4>{{ props.fixture.participants? props.fixture.participants[1] : ''}}</h4>
							</div>
						</div>

					</div>

					<div class="betting_info">
						<FeaturedFixtureOverviewOdd
							:overview_market_header_name="primary_overview_market.odd_names[0]"
							:overview_odd="primaryOverviewMarketOdds[0]"
							:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
							:fixture_id="props.fixture.id"
						/>

						<FeaturedFixtureOverviewOdd v-if="primary_overview_market.three_way"
							:overview_market_header_name="primary_overview_market.odd_names[1]"
							:overview_odd="primaryOverviewMarketOdds[1]"
							:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
							:fixture_id="props.fixture.id"
						/>

						<FeaturedFixtureOverviewOdd
							:overview_market_header_name="primary_overview_market.odd_names[2]"
							:overview_odd="primary_overview_market.three_way ? primaryOverviewMarketOdds[2] : primaryOverviewMarketOdds[1]"
							:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
							:fixture_id="props.fixture.id"
						/>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>

<style scoped>
 .live-legaue-card .live-card-inner .live-card-image img {
	mix-blend-mode: unset !important;
}
</style>