<script setup>
/* eslint-disable */
import { ref, watch } from 'vue';
import { useCurrenciesStore } from '@/stores/currencies';
import { MyBets } from '@/stores/MyBets';

const currenciesStore = useCurrenciesStore();
const myBetsStore = MyBets();

const partial_cashout = ref(0);
const show_cash_out_settings = ref(false);
const cashout_verification = ref(false);
const cashout_processing = ref(null);

const props = defineProps({
	betslip: {
		type: Object,
		required: true
	}
})

// update background of cashout range
watch(partial_cashout, (newValue) => {
	const inputElement = document.querySelector('.cashout-range');
	updateBackground({ target: inputElement });
});

// update background of cashout range
const updateBackground = (event) => {
	const min = event.target.min;
	const max = event.target.max;
	const val = event.target.value;

	const percentage = ((val - min) / (max - min)) * 100;

	event.target.style.background = `linear-gradient(to right, #FFBB02 ${percentage}%, #363D47 ${percentage}%)`;
};

const calculatePartialCashout = (amount) => {
	amount = amount * 0.1;
	return amount.toFixed(2);
};

const calculatePartialCashoutStake = () => {
	if (props.betslip.betslip_stake != 0) return ( Number(props.betslip.betslip_stake) * partial_cashout.value ) / props.betslip.cashout_amount;
	else return ( Number(props.betslip.bonus_stake) * partial_cashout.value ) / props.betslip.cashout_amount;
};

const openPartialCashout = () => {
	show_cash_out_settings.value = !show_cash_out_settings.value;
	partial_cashout.value = 0;
};

const cashoutBetslip = (betSlip) => {
	if (betSlip.cashout_available == false && betSlip.bonus_can_cashout == false) return;

	if (betSlip.is_mission_betslip && betSlip.mission_can_cashout == false) return;

	if (cashout_processing.value == betSlip.betslip_id) return;

	cashout_processing.value = betSlip.betslip_id;

	if (partial_cashout.value > 0 && partial_cashout.value < Number(betSlip.cashout_amount)) {
		myBetsStore.partialCashoutBetslip({ betslip_id:betSlip.betslip_id, partial_stake: currenciesStore.format_number(calculatePartialCashoutStake()), partial_cashout_amount: partial_cashout.value }).then(() => {
			cashout_processing.value = null;
		}).catch(() => {
			cashout_processing.value = null;
		});
	} else {
		myBetsStore.cashoutBetslip({ betslip_id:betSlip.betslip_id, cashout_amount: betSlip.cashout_amount }).then(() => {
			cashout_processing.value = null;
		}).catch(() => {
			cashout_processing.value = null;
		});
	}
}

</script>
<template>
	<template v-if="cashout_verification != betslip.betslip_id && betslip.freebet_id != null">
		<a href="javascript:void(0);"  @click="cashout_verification = betslip.betslip_id" class="btn-secondary cashout-btn betslip-button" style="cursor:pointer;">{{ $t('general.cash_out') }}: {{ currenciesStore.format_currency(betslip.cashout_amount)  }}</a>
	</template>
	<template v-else-if="cashout_verification != betslip.betslip_id">
		<div class="cashout-container">
			<div class="cashout-buttons">
				<a href="javascript:void(0);"  @click="cashout_verification = betslip.betslip_id" class="btn-secondary cashout-btn betslip-button" style="cursor:pointer; width:85%;">
					<template v-if="partial_cashout == 0 || Number(partial_cashout) == Number(betslip.cashout_amount)">
						{{ $t('general.cash_out') }}: {{ currenciesStore.format_currency(betslip.cashout_amount) }}
					</template>
					<template v-else>
						<p class ="partial-cashout-title">{{ $t('general.cash_out') }}: {{ currenciesStore.format_currency(partial_cashout) }}</p>
						<p class="partial-cashout-info">{{ $t('betslip.balance_will_be_used_from_stake', { stake: currenciesStore.format_currency(calculatePartialCashoutStake()) }) }}</p>
					</template>
				</a>

				<input type="checkbox" class="btn-secondary cashout-btn betslip-button img-button" @click="openPartialCashout" style="width: 15%;" />
			</div>

			<div v-if="show_cash_out_settings" class="cashout-settings">
				<div class="cashout-money">
					<p class="cashout-text">{{ calculatePartialCashout(betslip.cashout_amount) }}</p>
					<p class="cashout-text">{{ $t('betslip.choose_cashout_range') }}</p>
					<p class="cashout-text">{{ betslip.cashout_amount }}</p>
				</div>

				<input class="cashout-range" type="range" step="0.01" :min="calculatePartialCashout(betslip.cashout_amount)" :max="betslip.cashout_amount" v-model="partial_cashout" @input="updateBackground"/>

				<p class="cashout-text">{{ currenciesStore.format_currency(partial_cashout) }}</p>
			</div>
		</div>
	</template>
	<template v-else>
		<p v-if="betslip.mission_can_cashout == true" style="font-size: 13px; font-weight: 500; color: #F2F2F7; margin-bottom: 10px; text-align:center;">{{ $t('missions.mission_cashout_warning_text') }}</p>
		<p v-else style="font-size: 13px; font-weight: 500; color: #F2F2F7; margin-bottom: 10px; text-align:center;">{{ $t('general.are_you_sure') }}</p>
		<div class="cashout-btn-inner">
			<a v-if="cashout_processing==betslip.betslip_id" href="javascript:void(0);" class="btn-small btn-secondary" >
				<div class="loader-main"></div>
			</a>
			<a @click="cashoutBetslip(betslip)" href="javascript:void(0);" class="btn-small btn-secondary betslip-button" v-else>{{ $t('general.yes') }}</a>

			<a @click="cashout_verification = null"  class="btn-small btn-secondary betslip-button">{{ $t('general.no') }}</a>

		</div>
	</template>
</template>
<style scoped>
.cashout-settings {
	margin-top: 5px;
	padding-bottom: 10px;
}

.cashout-buttons {
	display: flex;
	gap: 10px;
}

.cashout-text {
	font-size: 12px;
	color: #fff;
	line-height: 1.6;
	display: grid;
	font-weight: 300;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.partial-cashout-title {
	font-size: 13px;
	font-weight: 600;
}

.partial-cashout-info {
	font-size: 12px;
	font-weight: 400;
	margin-top: -5px;
}

.cashout-money {
	display: flex;
	justify-content: space-between;
}

.cashout-range {
	width: 100%;
	background: #363D47;
	height: 3px;
	border-radius: 12px;
	accent-color: #FFBB02;
}

.cashout-range::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	background: #FFBB02;
	cursor: pointer;
}

.cashout-range::-moz-range-thumb {
	border-radius: 50%;
	width: 12px;
	height: 12px;
	background: #FFBB02;
	cursor: pointer;
}

.cashout-range::-moz-range-track {
	height: 3px;
	border-radius: 12px;
}

.img-button {
	background: url('../../../assets/svgs/settings_black.svg') no-repeat center;
	background-size: 20px;
	background-color: #FFBB02;
	transition: all 0.5s !important;
}

.img-button:hover {
	background: url('../../../assets/svgs/settings_white.svg') no-repeat center !important;
	background-size: 20px !important;
	background-color: #131C27;
	transition: all 0.5s !important;
}

.img-button:checked {
	background: url('../../../assets/svgs/settings_white.svg') no-repeat center !important;
	background-size: 20px !important;
	background-color: #131C27;
	transition: all 0.5s !important;
	border: 1px solid #FFFFFF !important;
}
</style>