<script setup>
/* eslint-disable */
import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useMarketsStore } from '@/stores/markets';
import { useInitializeStore } from '@/stores/initialize';
import EsportFixtureDoubleMarket from '@/components/esports/highlights/EsportFixtureDoubleMarket.vue';
import LiveFixtureDoubleMarket from '@/components/esports/highlights/LiveFixtureDoubleMarket.vue';
import { SportsData } from '@/stores/Sports';

const router = useRouter();
const preSportsStore = usePreSportsStore();
const liveSportsStore = useLiveSportsStore();
const initializeStore = useInitializeStore();
const marketsStore = useMarketsStore();
const SportsDataStore = SportsData();

const show_primary_overview_market = ref(false);
const show_secondary_overview_market = ref(false);

const primary_overview_market = ref(null);
const secondary_overview_market = ref(null);

const props = defineProps({
	competition: {
		type: Object,
		required: true,
	},
});

const selectPrimaryOverviewMarket = (overview_market) => {
	primary_overview_market.value = overview_market;
	show_primary_overview_market.value = false;
}

const selectSecondaryOverviewMarket = (overview_market) => {
	secondary_overview_market.value = overview_market;
	show_secondary_overview_market.value = false;
}

const closePrimaryOverviewMarketSelector = () => {
	show_primary_overview_market.value = false;
}

const closeSecondaryOverviewMarketSelector = () => {
	show_secondary_overview_market.value = false;
}

onMounted( () => {
	let sport_overview_markets = marketsStore.sportOverviewMarkets(router.currentRoute.value.params.sport_id);
	if (sport_overview_markets.length > 1) {

		primary_overview_market.value = sport_overview_markets[0];

		secondary_overview_market.value = sport_overview_markets[1]
	} else {
		primary_overview_market.value = sport_overview_markets[0];
		secondary_overview_market.value = sport_overview_markets[0]
	}
})

</script>

<template>
	<div class="live-bet-dashboard competetion-main round-games-main set_game-outer">
		<div class="bet-dashboard-top">
			<div class="tabing-dashboard">
				<div class="competition-tab-title">
					<div class="country-flag">
						<img :src="'/assets' + competition.region_icon_url" alt="country-flag">
					</div>
					<h6> {{ competition.region_name }} - {{ competition.competition_name }} </h6>
				</div>
			</div>

			<div class="dashboard-info-label dropdown-option-top" v-if="primary_overview_market">
				<div class="select-option">
					<dl class="drop" :class="show_primary_overview_market ? 'active' : ''">
						<span class="tabIco">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
								<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
									<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
								</g>
							</svg>
						</span>
						<dt><button class="tablinks" @click="show_primary_overview_market = !show_primary_overview_market"  v-click-outside-element="closePrimaryOverviewMarketSelector">{{ primary_overview_market.name.toUpperCase() }}</button>
						</dt>
						<dd>
							<ul>
								<li v-for="overview_market in marketsStore.sportOverviewMarkets(preSportsStore.selected_esport_id)" :key="overview_market.slug" @click="selectPrimaryOverviewMarket(overview_market)" :class="primary_overview_market.slug == overview_market.slug ? 'active' : ''">
									<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
								</li>
							</ul>
						</dd>
					</dl>
				</div>
			</div>

			<div class="dashboard-info-label dropdown-option-top dropdown-option-top-2" v-if="secondary_overview_market">
				<div class="select-option">
					<dl class="drop" :class="show_secondary_overview_market ? 'active' : ''">
						<span class="tabIco">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
								<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
									<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
								</g>
							</svg>
						</span>
						<dt><button class="tablinks" @click="show_secondary_overview_market = !show_secondary_overview_market"  v-click-outside-element="closeSecondaryOverviewMarketSelector">{{ secondary_overview_market.name.toUpperCase() }}</button>
						</dt>
						<dd>
							<ul>
								<li v-for="overview_market in marketsStore.sportOverviewMarkets(preSportsStore.selected_esport_id)" :key="overview_market.slug" @click="selectSecondaryOverviewMarket(overview_market)" :class="secondary_overview_market.slug == overview_market.slug ? 'active' : ''">
									<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
								</li>
							</ul>
						</dd>
					</dl>
				</div>
			</div>
		</div>

		<div class="live-tab-info competition-tab-main">
			<div id="competition" class="tab-content competetion-outer-main">

				<div class="live-info-outer" v-for="(date, date_index) in competition.dates" :key="date_index">
					<div class="live-info-inner">
						<div class="live-info-team">

							<div class="live-match competetion-date">
								<div class="live-match-name">
									<h3>{{ date_index }}</h3>
								</div>
							</div>

						</div>

						<div class="full-time-top top-title-dash">
							<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
								<span v-if="odd != ''">{{ odd }}</span>
							</template>
						</div>

						<div class="match-goal-top top-title-dash">
							<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
								<span v-if="odd != ''">{{ odd }}</span>
							</template>
							<span class="bet-column-empty"></span>

						</div>

					</div>

					<!-- Fixtures -->
					<template v-for="fixture in date" :key="fixture.id">
						<template v-if=" initializeStore.screenWidth > 767">
							<div class="live-info-grid">
								<LiveFixtureDoubleMarket v-if="fixture.live"
									:primary_overview_market="primary_overview_market"
									:secondary_overview_market="secondary_overview_market"
									:fixture="fixture"
								/>

								<EsportFixtureDoubleMarket v-if="!fixture.live"
									:primary_overview_market="primary_overview_market"
									:secondary_overview_market="secondary_overview_market"
									:fixture="fixture"
								/>
							</div>
						</template>
						<template v-else>
							<LiveFixtureDoubleMarket v-if="fixture.live"
								:primary_overview_market="primary_overview_market"
								:secondary_overview_market="secondary_overview_market"
								:fixture="fixture"
							/>

							<EsportFixtureDoubleMarket v-if="!fixture.live"
								:primary_overview_market="primary_overview_market"
								:secondary_overview_market="secondary_overview_market"
								:fixture="fixture"
							/>
						</template>
					</template>
				</div>

			</div>

		</div>
		<!----- info tab start ------>

	</div>
</template>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}

}
</style>