<script setup>
/* eslint-disable */
import { useRouter, useRoute } from 'vue-router';
import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
import { useSocketStore } from '@/stores/socket';
import { useGeneralStore } from '@/stores/general';
import { useCompetitionsStore } from '@/stores/competitions';
import CompetitionFixtures from '@/components/pre_sports/CompetitionFixtures.vue';
import _ from 'lodash';

const socketStore = useSocketStore();
const competitionsStore = useCompetitionsStore();
const generalStore = useGeneralStore();

const show_scroll_to_top_button = ref(false);

const router = useRouter();
const route = useRoute();

socketStore.getSocket.on('competitionFixtures', (data) => {
	if (_.isEmpty(data.fixtures)) {
		router.push({ name: 'not-found' });
	}

	competitionsStore.setCompetitionFixtures(data);
	setPageTitle(data);
});

socketStore.getSocket.on('competitionFixturesUpdate', (data) => {
	competitionsStore.updateCompetitionFixture(data);
});

socketStore.getSocket.on('competitionFixturesDelete', (data) => {
	competitionsStore.deleteCompetitionFixture(data);
});

const setPageTitle = (data) => {
	if (Object.keys(data.fixtures).length == 0) return;
	let first_fixture = data.fixtures[Object.keys(data.fixtures)[0]];

	if (router.currentRoute.value.name == 'pre-sports-coupons-competitions') return;
	document.title = generalStore.getGeneral.site_name + ' - ' + first_fixture.region_name + ' - ' + first_fixture.competition_name;
}

const handleScroll = () => {
	const scrollTop = window.scrollY;
	show_scroll_to_top_button.value = scrollTop > 100;
};

onMounted( () => {
	competitionsStore.emptyCompetitions();

	if (router.currentRoute.value.params.competition_id != '') {
		socketStore.getSocket.emit('join-competitionFixtures', router.currentRoute.value.params.competition_id)
		competitionsStore.setSelectedCompetitionId(router.currentRoute.value.params.competition_id);
	}
	window.addEventListener('scroll', handleScroll);

})

onBeforeUnmount(() => {
	socketStore.getSocket.emit('leave-competitionFixtures', competitionsStore.selectedCompetitionId);
	competitionsStore.emptyCompetitions();
})

const scrollToTop = () => {
	window.scrollTo({ top: 0 });
};

watch(route, (to, from) => {

	if (!to.params.hasOwnProperty('competition_id')) return;
	if (to.params.competition_id == competitionsStore.selectedCompetitionId) return;

	// Check If I have selected competition and leave
	if (competitionsStore.selectedCompetitionId != null) {
		socketStore.getSocket.emit('leave-competitionFixtures', competitionsStore.selectedCompetitionId);
		competitionsStore.emptyCompetitions();
	}

	socketStore.getSocket.emit('join-competitionFixtures', to.params.competition_id);
	competitionsStore.setSelectedCompetitionId(to.params.competition_id);
});

</script>

<template>
	<div id="Competitions" class="competetion-section">
		<CompetitionFixtures v-for="(competition) in competitionsStore.competitionFixturesByDate" :key="competition.competition_id" :competition="competition"/>
	</div>
	<button @click="scrollToTop" type="button" class="btn scroll-to-top" id="btn-back-to-top" v-show="show_scroll_to_top_button">
		<i class="fas fa-arrow-up"></i>
	</button>
</template>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}

}
.competetion-section {
	scrollbar-width: none !important;
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	button#btn-back-to-top {
		display:none;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		bottom: 70px;
		left: 20px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	svg.svg-inline--fa.fa-arrow-up.fa-w-14 {
		margin-bottom: 7px!important;
	}
	button#btn-back-to-top {
		width: 30px!important;
		height: 30px!important;
		font-size: 14px!important;
	}
}
button#btn-back-to-top {
	background: #63A246;
	width: 38px;
	height: 38px;
	border: none;
	border-radius: 50%;
	position: fixed;
	z-index: 99;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	cursor: pointer;
}
html[dir="rtl"] button#btn-back-to-top {
	left: unset;
	right:20px;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	html[dir="rtl"] button#btn-back-to-top {
		left: unset;
		right:10px;
	}
}
</style>