<script setup>
/* eslint-disable */
import { ref } from 'vue';

import { useFavouritesStore } from '@/stores/favourites';
import LeftSidebarFavouriteCompetition from '@/components/live_sports/left_sidebar/LeftSidebarFavouriteCompetition.vue';

const favouritesStore = useFavouritesStore();
const favourites_opened = ref(true);

const props = defineProps({
	competitions_opened: {
		required: true,
	}
});

</script>
<template>
	<div class="accordion-item favrouite-items" :class="{'active': favourites_opened}">
		<div class="accordion-header" @click="favourites_opened = !favourites_opened">{{$t('general.favourites')}}
			<div class="acoordion-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
					<g id="Group_776" data-name="Group 776" transform="translate(-272 -6)">
						<g id="minimize" transform="translate(272 10)">
							<rect id="Rectangle_408" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
						<g id="minimize-2" class="hide-i" data-name="minimize" transform="translate(277 6) rotate(90)">
							<rect id="Rectangle_408-2" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
					</g>
				</svg>

			</div>
		</div>
		<div class="accordion-content favrouites-content">
			<div v-for="(competition, index) in favouritesStore.liveLeftSidebarFavourites" :key="index">
				<LeftSidebarFavouriteCompetition :competition="competition" :competitions_opened="props.competitions_opened"/>
			</div>
		</div>
	</div>
</template>