<script setup>
/* eslint-disable */
import Regions from './PreRegions.vue';
import PreSportTopCompetitions from './PreSportTopCompetitions.vue';
import LeftSidebarFavourites from './LeftSidebarFavourites.vue';
import { useFavouritesStore } from '@/stores/favourites';

const favouritesStore = useFavouritesStore();

</script>
<template>
	<LeftSidebarFavourites v-if="Object.keys(favouritesStore.preLeftSidebarFavourites).length > 0"/>
	<PreSportTopCompetitions/>
	<!-- Regions -->
	<Regions/>
</template>