<!-- eslint-disable -->
<script setup>
import { onMounted, ref } from 'vue';
import { useLanguagesStore } from '@/stores/languages';
import { useOddsStore } from '@/stores/odds';
import { useCustomerStore } from '@/stores/customer';
import { useAccountDetailsStore } from '@/stores/account_details'

const languagesStore = useLanguagesStore();
const oddsStore = useOddsStore();
const customerStore = useCustomerStore();
const accountDetailsStore = useAccountDetailsStore();
const language_name = ref(null);
const language = ref({ language_id: null });
const select_lang = ref(false);
const select_odds = ref(false);
const betslip_changes = ref('');

const changeLanguage = (id) => {
	language.value.language_id = id;
	languagesStore.changeLanguage(language.value);
}

const toggleBetslipChangesPreferences = (betslip_changes_option) => {
	const payload = { betslip_changes: 'always_ask'};
	if (betslip_changes.value != betslip_changes_option) payload.betslip_changes = betslip_changes_option;

	accountDetailsStore.updateCustomerOdds(payload).then( () => {
		betslip_changes.value = payload.betslip_changes
	})
}

onMounted(() => {
	language.value.language_id = languagesStore.selectedLanguage.id;
	language_name.value = languagesStore.selectedLanguage.name;
	if (customerStore.isLoggedIn) betslip_changes.value = customerStore.getCustomer.customer_settings.betslip_changes;
	else betslip_changes.value = 'always_ask'
})

</script>
<template>
	<div class="setting-dropdown-inner">
		<div class="accordion-item" :class="select_lang ? 'active':''" style="cursor: pointer;">
			<div class="accordion-header" @click="select_lang = !select_lang">
				<p>{{ $t('account.account_preferences.language') }}: <span>{{ language_name }}</span></p>
				<svg :style="{ transform: select_lang ? 'rotate(0deg)' : 'rotate(180deg)' }" xmlns="http://www.w3.org/2000/svg" width="9.414" height="5.414" viewBox="0 0 9.414 5.414">
					<g id="Group_1815" data-name="Group 1815" transform="translate(0.707 4.707) rotate(-90)">
						<line id="Line_7" data-name="Line 7" y1="4" x2="4" transform="translate(0 4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
						<line id="Line_8" data-name="Line 8" x2="4" y2="4" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
					</g>
				</svg>
			</div>
			<div class="accordion-content">
				<form>
					<div class="lang-options">
						<div class="radio-btn" v-for="(language,index) in languagesStore.getLanguages" :key="index">
							<input type="radio" name="lang" :value="language.id" :checked="language.id == languagesStore.selectedLanguage.id" @change="changeLanguage(language.id)">
							<label>{{language.name}}</label>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="accordion-item" :class="select_odds ? 'active' : ''" style="cursor: pointer;">
			<div class="accordion-header" @click="select_odds=!select_odds">
				<p>{{ $t('general.odds_format') }}:
					<span v-if="oddsStore.getOddsPreview == 'decimal' || oddsStore.getOddsPreview == null">{{ $t('general.decimal') }}</span>
					<span v-if="oddsStore.getOddsPreview == 'american'">{{ $t('general.american') }}</span>
					<span v-if="oddsStore.getOddsPreview == 'fractional'">{{ $t('general.fractional') }}</span>
					<!-- <span v-if="oddsStore.getOddsPreview == 'hong_kong'">{{ $t('general.hong_kong') }}</span>
					<span v-if="oddsStore.getOddsPreview == 'malay'">{{ $t('general.malay') }}</span>
					<span v-if="oddsStore.getOddsPreview == 'indonesian'">{{ $t('general.indonesian') }}</span>
					<span v-if="oddsStore.getOddsPreview == 'implied'">{{ $t('general.implied') }}</span> -->
				</p>
				<svg :style="{ transform: select_odds ? 'rotate(0deg)' : 'rotate(180deg)' }" xmlns="http://www.w3.org/2000/svg" width="9.414" height="5.414" viewBox="0 0 9.414 5.414">
					<g id="Group_1815" data-name="Group 1815" transform="translate(0.707 4.707) rotate(-90)">
						<line id="Line_7" data-name="Line 7" y1="4" x2="4" transform="translate(0 4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
						<line id="Line_8" data-name="Line 8" x2="4" y2="4" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
					</g>
				</svg>
			</div>
			<div class="accordion-content">
				<form>
					<div class="lang-options">
						<div class="radio-btn">
							<input @click="oddsStore.changeOddsPreview('decimal')" :checked="'decimal' == oddsStore.getOddsPreview || oddsStore.getOddsPreview == null" type="radio" name="odds" value="decimal">
							<label>{{ $t('general.decimal') }}</label>
						</div>
						<div class="radio-btn">
							<input @click="oddsStore.changeOddsPreview('american')" :checked="'american' == oddsStore.getOddsPreview" type="radio" name="odds" value="american">
							<label>{{ $t('general.american') }}</label>
						</div>
						<!-- <div class="radio-btn">
							<input @click="oddsStore.changeOddsPreview('fractional')" :checked="'fractional' == oddsStore.getOddsPreview" type="radio" name="odds" value="fractional">
							<label>{{ $t('general.fractional') }}</label>
						</div> -->
						<!-- <div class="radio-btn">
							<input @click="oddsStore.changeOddsPreview('hong_kong')" :checked="'hong_kong' == oddsStore.getOddsPreview" type="radio" name="odds" value="hong_kong">
							<label>{{ $t('general.hong_kong') }}</label>
						</div>
						<div class="radio-btn">
							<input @click="oddsStore.changeOddsPreview('malay')" :checked="'malay' == oddsStore.getOddsPreview" type="radio" name="odds" value="malay">
							<label>{{ $t('general.malay') }}</label>
						</div>
						<div class="radio-btn">
							<input @click="oddsStore.changeOddsPreview('indonesian')" :checked="'indonesian' == oddsStore.getOddsPreview" type="radio" name="odds" value="indonesian">
							<label>{{ $t('general.indonesian') }}</label>
						</div>
						<div class="radio-btn">
							<input @click="oddsStore.changeOddsPreview('implied')" :checked="'implied' == oddsStore.getOddsPreview" type="radio" name="odds" value="implied">
							<label>{{ $t('general.implied') }}</label>
						</div> -->
					</div>
				</form>
			</div>
		</div>

		<div class="bottom-options" v-if="customerStore.isLoggedIn">
			<p>{{ $t('account.account_preferences.accept_any_odds') }}</p>
			<form>
				<div class="switch-outer">
					<label class="switch" @click="toggleBetslipChangesPreferences('accept_any_odds')">
						<input :checked="betslip_changes == 'accept_any_odds'" type="checkbox">
						<span class="switch-slider round"></span>
					</label>
				</div>
			</form>
		</div>

		<div class="bottom-options" v-if="customerStore.isLoggedIn">
			<p>{{ $t('account.account_preferences.accept_higher_odds') }}</p>
			<form>
				<div class="switch-outer">
					<label class="switch" @click="toggleBetslipChangesPreferences('accept_higher_odds')">
						<input :checked="betslip_changes == 'accept_higher_odds'" type="checkbox">
						<span class="switch-slider round"></span>
					</label>
				</div>
			</form>
		</div>
	</div>
</template>
<style scoped>
.rotated {
	transform: rotate(180deg) !important;
	transition: transform 0.3s ease !important;
}

.bottom-options .switch-outer .switch .switch-slider {
	padding-bottom: unset;
}

</style>