import { defineStore } from 'pinia';
import _ from 'lodash';

export const usePromotionsStore = defineStore('promotionsStore', {
	state: () => ({
		promotions: {},
		promotion_categories: {},
		promotion: null,
		selected_promotion_category: 'all',
	}),
	getters: {
		getPromotions(state) {
			return state.promotions;
		},
		getPromotionsLanding(state) {
			// Get 2 promotions with is_landing = true
			var landing_promotions = _.chain(state.promotions).filter({ is_landing: true }).take(2).value();

			// If there are 2 promotions with is_landing = true, return them
			if (landing_promotions.length == 2) return landing_promotions;

			// If there are less than 2 promotions with is_landing = true, get 2 sportbook promotions
			return _.chain(state.promotions).filter((promo) => _.some(promo.categories, { slug: 'sportbook' })).take(2).value();
		},
		getPromotionsSidebar(state) {
			return _.chain(state.promotions).sortBy('sort').take(6).value();
		},
		getPromotion(state) {
			return state.promotion;
		},
		getPromotionCategories(state) {
			return state.promotion_categories;
		},
		getSelectedPromotionCategory(state) {
			return state.selected_promotion_category;
		},
	},
	actions: {
		fetchPromotionCategories() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/promotions').then((response) => {
				this.promotion_categories = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {

				return Promise.reject(error);
			})
		},
		fetchPromotion(promotion_slug) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/promotion/' + promotion_slug).then((response) => {
				this.promotion = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {

				return Promise.reject(error);
			})
		},
		selectPromotionCategory(promotion_category) {
			this.selected_promotion_category = promotion_category;

			return global.axios.get(process.env.VUE_APP_API_URL + '/promotions/' + promotion_category).then((response) => {
				this.promotions = response.data.data;

				return Promise.resolve(response.data);
			}).catch((error) => {

				return Promise.reject(error);
			})
		},

	},
})