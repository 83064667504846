<script setup>
import { onMounted } from 'vue';

onMounted(() => {
	if (window !== top) {
		var targetWindow = parent;

		var message = { status: 'closed', message: 'Game has been closed' };

		// The target origin. Use '*' if you don't know the origin of the target window,
		// but it's better to specify the exact target origin for security.
		var targetOrigin = '*';

		targetWindow.postMessage(message, targetOrigin);
	} else {
		location.href = '/casino';
	}
})
</script>
<template>
	<div class="close-main">
		<div class="content">
			<h1>{{ $t('general.thanks_for_playing') }}</h1>
			<p>{{ $t('general.we_hope_you_had_great_time') }}</p>
		</div>
	</div>
</template>

<style scoped>
.close-main {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100vh;
	margin: 0;
	padding: 0;
	background-color: black;
	z-index: 9999;
	position: relative;
}

.content {
	max-width: 600px;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-main h1 {
	font-size: 36px;
	margin-bottom: 30px;
	color: #fff;
}

.close-main p {
	font-size: 18px;
	color: #fff;
}
</style>
