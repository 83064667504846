<script setup>
/* eslint-disable */
import { onMounted, watch, ref } from "vue";
import { useRouter } from 'vue-router';

import { useCompetitionsStore } from '@/stores/competitions';
import { usePreSportsStore } from '@/stores/pre_sports';

const competitions_opened = ref(false);
const router = useRouter();
const competitionsStore = useCompetitionsStore();
const preSportsStore = usePreSportsStore();

const goToCompetition = (competition) => {
	router.push({ name: 'esports-competition', params: { sport_id: competition.sport_id, region_id: competition.region_id, competition_id: competition.id } } )
}

onMounted(() => {
	if (router.currentRoute.value.params.sport_id != '') {
		competitionsStore.fetchTopCompetitionsSport(router.currentRoute.value.params.sport_id);
	} else {
		competitionsStore.fetchTopCompetitionsSport(preSportsStore.esports[0].id);
	}
})

watch(() => preSportsStore.selected_esport_id, (newSportId, oldSportId) => {
	if (newSportId > 0) {
		// Fetch regions of this sport
		competitionsStore.fetchTopCompetitionsSport(newSportId);
	}
})

</script>
<template>
	<div class="accordion-item" :class="competitions_opened ? 'active': ''" v-if="Object.keys(competitionsStore.topCompetitionsSport).length > 0">
		<div class="accordion-header" @click="competitions_opened = !competitions_opened">{{$t('general.top_leagues')}}
			<div class="acoordion-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
					<g id="Group_776" data-name="Group 776" transform="translate(-272 -6)">
						<g id="minimize" transform="translate(272 10)">
							<rect id="Rectangle_408" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
						<g id="minimize-2" class="hide-i" data-name="minimize" transform="translate(277 6) rotate(90)">
							<rect id="Rectangle_408-2" data-name="Rectangle 408" width="1" height="9" rx="0.5" transform="translate(9) rotate(90)" fill="#fff" />
						</g>
					</g>
				</svg>

			</div>
		</div>
		<div class="accordion-content" v-if="competitions_opened">
			<div class="top-league-outer">
				<div style="cursor: pointer;">
					<div v-for="competition in competitionsStore.topCompetitionsSport" :key="competition.id" @click="goToCompetition(competition)" class="country-info-inner top-league" :class="{'active': competitionsStore.selectedCompetitionId == competition.id }">
						<div class="country-left">
							<div class="country-flag">
								<img :src="'/assets/' + competition.region_icon" alt="flag-country">
							</div>
							<div class="country-name text-capitalize">
								<h3>{{ competition.name }}</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>