import { defineStore } from "pinia";
import { useCustomerStore } from '@/stores/customer'

export const useAccountDetailsStore = defineStore("accountDetails", {
	state: () => ({
		verification_types: [],
		document_types: [],
		pending_documents: [],
		reviewed_documents: [],
	}),
	getters: {
		//
	},
	actions: {
		updateAccountDetails(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/details/update', payload).then((response) => {
				useCustomerStore().setCustomer(response.data.data)
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		changePassword(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/security/password/change', payload).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		updatePreferences(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/preferences/update', payload).then((response) => {
				useCustomerStore().setCustomer(response.data.data)
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		getVerificationIndex() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/verification/index').then((response) => {
				this.verification_types = response.data.data.verification_types;
				this.document_types = response.data.data.document_types;
				this.pending_documents = response.data.data.pending_documents;
				this.reviewed_documents = response.data.data.reviewed_documents;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		getDocumentTypes(verification_type_id) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/verification/types/' + verification_type_id).then((response) => {
				this.document_types = response.data.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		uploadDocument(payload) {
			const formData = new FormData();
			formData.append('file', payload.file);
			formData.append('document_type_id', payload.document_type_id);
			return global.axios.post(process.env.VUE_APP_API_URL +'/account/verification/document/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
				this.pending_documents = response.data.data;
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		deleteDocument(document_id) {
			return global.axios.delete(process.env.VUE_APP_API_URL + '/account/verification/document/delete/' + document_id).then((response) => {
			const updatedPendingDocuments = response.data.data;
			this.pending_documents = updatedPendingDocuments;
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		updateCustomerOdds(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/balance_changes/update', payload).then((response) => {
				useCustomerStore().setCustomer(response.data.data)
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		}
	}
});
