<script setup>
import { closeModal } from '@kolirt/vue-modal'
import { useRouter } from 'vue-router';
import { useCustomerStore } from '@/stores/customer';
import { useLoginStore } from '@/stores/login';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const customerStore = useCustomerStore();
const loginStore = useLoginStore();
const { t } = useI18n();

const time_value = customerStore.getCustomer.reality_check ? customerStore.getCustomer.reality_check.customer_protection_configuration_choice.value : '';

const timeTypeTranslation = computed(() => {
	let time_type = customerStore.getCustomer.reality_check ? customerStore.getCustomer.reality_check.customer_protection_configuration_choice.type : '';
	return time_type === 'minutes' ? t('account.customer_protection.minutes') : t('account.customer_protection.hours');
});

const goToSettings = () => {
	closeModal();
	router.push({ name: 'player-protection-reality-check' });
}

const logout = () => {
	loginStore.logout()
};

</script>
<template>
	<div id="customModal" class="custom-modal-detail">
		<div class="modal-content">

			<span id="closeModalBtn" class="close">
				<svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="13.414" viewBox="0 0 13.414 13.414" @click="closeModal()">
					<g id="close" transform="translate(0.707 0.707)">
						<line id="Line_2" data-name="Line 2" x2="12" y2="12" fill="none" stroke="#848484"
							stroke-linecap="round" stroke-width="1" />
						<line id="Line_3" data-name="Line 3" x1="12" y2="12" fill="none" stroke="#848484"
							stroke-linecap="round" stroke-width="1" />
					</g>
				</svg>
			</span>

			<div class="title">
				<p>{{ $t('account.customer_protection.reality_check_modal_title') }}</p>
			</div>

			<div class="content">
				<p>{{ $t('account.customer_protection.reality_check_modal_text') }}</p>
				<p v-if="customerStore.getCustomer.reality_check != null" style="font-size: 12px;">{{ $t('account.customer_protection.reality_check_time_message', { min: time_value, type: timeTypeTranslation}) }}</p>
			</div>

			<div class="button-container">
				<div class="btn-small btn-secondary" @click="closeModal()" style="width: 150px;">{{ $t('account.customer_protection.remain_logged_in') }}</div>
				<div class="btn-small" @click="logout()" style="width: 150px;">{{ $t('general.logout') }}</div>
			</div>

			<div class="settings-container">
				<p @click="goToSettings()">{{ $t('account.customer_protection.review_account_settings') }}</p>
			</div>

		</div>
	</div>
</template>
<style scoped>
.custom-modal-detail .modal-content {
	background-color: #0C131A;
	padding: 43px 0 35px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
	text-align: center;
	position: relative;
	height: max-content;
	width: 500px;
	margin: 0 auto;
	transform: translateY(-50%);
	top: 50%;
}

.custom-modal-detail .modal-content .close {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 20px;
	cursor: pointer;
}

.custom-modal-detail .modal-content .title {
	font-size: 20px;
	color: #F2F2F7;
	padding-bottom: 10px;
	font-weight: bold;
	margin-bottom: 10px;
}

.custom-modal-detail .modal-content .content {
	font-size: 14px;
	color: #F2F2F7;
	padding-bottom: 20px;
	margin-bottom: 10px;
	padding-left: 12px;
	padding-right: 12px;
}

.settings-container {
	margin-top: 10px;
	padding-left: 12px;
	padding-right: 12px;
}

.settings-container p {
	font-size: 12px;
	color: #F2F2F7;
	cursor: pointer;
}

.settings-container p:hover {
	color: #63A246;
}

.button-container {
	display: flex;
	gap: 10px;
	justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 500px)
{
	.custom-modal-detail .modal-content {
		width: 100%;
	}

	.button-container {
		display: flex;
		gap: 10px;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.btn-small {
		font-size: 13px;
		font-weight: 600;
	}
}
</style>