import { defineStore } from 'pinia';

export const useAccaBoostStore = defineStore('accaboost', {
    state: () => ({
		accaBoostScale: [],
        availableMarkets: []
	}),
	getters: {
        getAccaBoostScale(state){
            return state.accaBoostScale;
        },
        getAvailableMarkets(state){
            return state.availableMarkets;
        },
	},
    actions: {
        setAccaBoostScale(data){
            this.accaBoostScale = data;
        },
        setAvailableMarkets(data){
            this.availableMarkets = data;
        },
	}
}) 