<!-- eslint-disable -->
<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { openModal } from '@kolirt/vue-modal';
import { useCustomerStore } from '@/stores/customer';
import { useCurrenciesStore } from '@/stores/currencies';
import GameLoadingAndError from './GameLoadingAndError.vue';
import RealityCheckModal from '@/components/modals/PlayerProtection/RealityCheck.vue';
import CasinoLimitsModal from '@/components/modals/PlayerProtection/CasinoLimits.vue';
import moment from 'moment'
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n();
const customerStore = useCustomerStore();
const currenciesStore = useCurrenciesStore();

const props = defineProps({
	game_name: null,
	iframe: null,
	iframe_url: null,
	slot_id: null,
	error_message: null,
});

const loading = ref(true);
var reality_check_at = ref(null);
var reality_check_interval = ref(null);
var games_stats_interval = ref(null);
var fetch_balance_interval = ref(null);
var time_limit_check_at = ref(null);
var time_limit_interval = ref(null);

const showRealityCheckModal = () => {
	openModal(RealityCheckModal).catch(() => {
		if (customerStore.getCustomer.reality_check && customerStore.getCustomer.reality_check.customer_protection_configuration_choice && customerStore.getCustomer.reality_check.customer_protection_configuration_choice.value) {
			reality_check_at.value = moment().add(customerStore.getCustomer.reality_check.customer_protection_configuration_choice.value, customerStore.getCustomer.reality_check.customer_protection_configuration_choice.type).unix();;
			startRealityCheckTimer();
		}
	});
};

const startRealityCheckTimer = () => {
	// Check if there is an existing interval and clear it
	if (reality_check_interval.value) {
		clearInterval(reality_check_interval.value);
		reality_check_interval.value = null;
	}

	// Set up the interval to check every minute
	reality_check_interval.value = setInterval(() => {
		const currentTime = moment().unix();

		if (currentTime >= reality_check_at.value) {
			showRealityCheckModal();
			clearInterval(reality_check_interval.value);
		}
	}, 1000 * 60);
};

// Player Protection - Reality Check Watcher
watch(() => customerStore.customerRealityCheckTimestamp, () => {
	reality_check_at.value = customerStore.customerRealityCheckTimestamp;
	if (reality_check_at.value) {
		startRealityCheckTimer(); // Start the timer
	}
}, { immediate: true });

const showCasinoLimitsModal = (title, text, limit_type, remaining_amount) => {
	openModal(CasinoLimitsModal, { title: title, text: text }).catch(() => {
		let limits_array = ['max_daily_bet', 'loss_limit_monthly', 'loss_limit_weekly', 'loss_limit_daily', 'time_limits'];
		if (limits_array.includes(limit_type) && remaining_amount == 0) {
			router.push({ name: 'casino' });
		}
	});
};



// Player Protection - Time Limits
watch(() => customerStore.customerTimeLimitsStatus, (newValue, oldValue) => {
	if (!customerStore.customerTimeLimitsStatus) return;
	time_limit_check_at.value = customerStore.customerTimeLimitsStatus.timestamp;

	if (customerStore.customerTimeLimitsStatus.exceed == true) {
		let modal_title = null;
		let modal_text = null;

		modal_title = t('account.customer_protection.time_limits_modal_title');
		modal_text = t('account.customer_protection.' + customerStore.customerTimeLimitsStatus.type) + ' ' + t('account.customer_protection.time_limit_modal_text');

		showCasinoLimitsModal(modal_title, modal_text, 'time_limits', 0);
	} else {
		if (time_limit_check_at.value) {
			time_limit_interval.value = setInterval(() => {
				if (moment().unix() >= time_limit_check_at.value) {
					customerStore.fetchProtectionLimits().then((response) => {
						time_limit_check_at.value = customerStore.customerTimeLimitsStatus.timestamp;

						if (customerStore.customerTimeLimitsStatus.exceed == true) {
							clearInterval(time_limit_interval.value);

							let modal_title = null;
							let modal_text = null;

							modal_title = t('account.customer_protection.time_limits_modal_title');
							modal_text = t('account.customer_protection.' + customerStore.customerTimeLimitsStatus.type) + ' ' + t('account.customer_protection.time_limit_modal_text');

							showCasinoLimitsModal(modal_title, modal_text, 'time_limits', 0);
						}
					});
				}
			}, 5000);
		}
	}
}, { immediate: true });

onMounted(() => {
	// Display a loading screen for 1.5 seconds to prevent a brief
	// flash that occurs during the initial game loading process.
	setTimeout(() => {
		loading.value = false;
	}, 1500);

	// Fetch balance every 5 seconds
	fetch_balance_interval.value = setInterval(() => {
		customerStore.fetchBalance();
	}, 5000);

	// Player Protection - Bet Limit & Loss Limit
	games_stats_interval.value = setInterval(() => {
		customerStore.getSlotsPlayerStats().then((response) => {
			let modal_title = null;
			const modal_text = ref(null);
			let limit_type = null;
			let remaining_amount = null;

			let bet_limit = response['bet_limit'];
			let loss_limit = response['loss_limit'];

			if (bet_limit && bet_limit['limit_exceeded'] == true) {
				modal_title = t('account.customer_protection.bet_limit_modal_title');

				if (bet_limit['type'] == 'max_single_bet') {
					limit_type = 'max_single_bet';
					let limit_amount = bet_limit['amount'] ? currenciesStore.format_currency(bet_limit['amount'], customerStore.customer.currency.code) : null;

					modal_text.value = t('account.customer_protection.bet_limit_max_single_bet_modal_text', { limit_amount: limit_amount });
				} else if (bet_limit['type'] == 'max_daily_bet') {
					limit_type = 'max_daily_bet';
					let limit_amount = bet_limit['amount'] ? currenciesStore.format_currency(bet_limit['amount'], customerStore.customer.currency.code) : null;
					remaining_amount = bet_limit['remaining'] != null ? currenciesStore.format_currency(bet_limit['remaining'], customerStore.customer.currency.code) : null;

					modal_text.value = t('account.customer_protection.bet_limit_max_daily_bet_modal_text', { limit_amount: limit_amount, remaining_amount:remaining_amount  });

					// Stop timer
					clearInterval(games_stats_interval.value);
				}

				// Show modal
				showCasinoLimitsModal(modal_title, modal_text.value, limit_type, remaining_amount);
			} else if (loss_limit && loss_limit['limit_exceeded'] == true) {
				modal_title = t('account.customer_protection.loss_limit_modal_title');

				let limit_amount = loss_limit['amount'] ? currenciesStore.format_currency(loss_limit['amount'], customerStore.customer.currency.code) : null;
				remaining_amount = loss_limit['remaining'] != null ? currenciesStore.format_currency(loss_limit['remaining'], customerStore.customer.currency.code) : null;

				limit_type = loss_limit['type'];
				if (limit_type == 'loss_limit_monthly') modal_text.value = t('account.customer_protection.loss_limit_monthly_modal_text', { limit_amount: limit_amount, remaining_amount:remaining_amount  });
				else if (limit_type == 'loss_limit_weekly') modal_text.value = t('account.customer_protection.loss_limit_weekly_modal_text', { limit_amount: limit_amount, remaining_amount:remaining_amount  });
				else if (limit_type == 'loss_limit_daily') modal_text.value = t('account.customer_protection.loss_limit_daily_modal_text', { limit_amount: limit_amount, remaining_amount:remaining_amount  });

				// Stop timer
				if (loss_limit['remaining'] != null && loss_limit['remaining'] == 0) {
					clearInterval(games_stats_interval.value);
				}

				// Show modal
				showCasinoLimitsModal(modal_title, modal_text.value, limit_type, loss_limit['remaining']);
			}
		})
	}, 5000);

});

onUnmounted(() => {
	clearInterval(reality_check_interval.value);
	clearInterval(fetch_balance_interval.value);
	clearInterval(games_stats_interval.value);
	clearInterval(time_limit_interval.value);
});
</script>
<template>
	<div class="game-container">
		<template v-if="props.iframe == 1 && props.iframe_url != null && loading == false">
			<iframe class="game-iframe" id="frame" scrolling="no" frameborder="0" :src="iframe_url"></iframe>
		</template>
		<div v-else-if="iframe == 2 && iframe_url != null" class="divStyle">
			{{ iframe_url }}
		</div>
		<GameLoadingAndError v-else-if="iframe == 3" :error_message="props.error_message"></GameLoadingAndError>
		<GameLoadingAndError v-else></GameLoadingAndError>
	</div>
</template>
<style scoped>
.game-container {
	background-color: black;
	position: relative;
	width: 100%;
	height: 100%;
}

.game-iframe {
	height: 100%;
	width: 100%;
}
</style>