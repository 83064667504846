import { defineStore } from 'pinia';
import {applyPatch} from 'rfc6902'

export const useLiveHomeFixturesStore = defineStore('liveHomeFixtures', {
	state: () => ({
		fixtures: {},
	}),
	getters: {
		liveHomeFixtures(state) {
			return state.fixtures;
		}
	},
	actions: {
		setLiveHomeFixturesSnapshot(data) {
			this.fixtures = data;
		},
		updateLiveHomeFixtures(data) {
			applyPatch(this.fixtures, data.patch);
		},
	},
})