<script setup>
/* eslint-disable */
import { ref, watch } from "vue"
import { useOddsStore } from '@/stores/odds';
import { BetSlip } from '@/stores/BetSlip';
import { useFixtureStore } from '@/stores/fixture';
import menuIcons from '@/assets/svgs/NotificationMenu.json'
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svg_data = ref(commonSvgs);
const menu_icons = ref(menuIcons);

const oddsStore = useOddsStore();
const betslipStore = BetSlip();
const fixtureStore = useFixtureStore();

const price_up = ref(false);
const price_down = ref(false);

const props = defineProps({
	odd: {
		type: Object,
		required: true,
	}
})

const addToBetslip = () => {
	if (!props.odd || props.odd.status == 'suspended') return;

	betslipStore.toggleToBetslip({ odd_uuid: props.odd.uuid, fixture_id: fixtureStore.fixtureBook.id })
}

watch(() => props.overview_odd, (newOdd, oldOdd) => {
	if (newOdd == null || oldOdd == null) return;

	if (newOdd.status == 'suspended' || oldOdd.status == 'suspended') return;

	// If changed overview market from overview
	if (newOdd.uuid != oldOdd.uuid) return;

	if (newOdd.price > oldOdd.price) price_up.value = true;
	if (newOdd.price < oldOdd.price) price_down.value = true;

	setTimeout(() => {
		price_up.value = false;
		price_down.value = false;
	}, 5000);
})


</script>
<template>
	<div class="betting-info-inner" @click="addToBetslip(odd)" :class="{'odd-selected': oddsStore.isOddInBetslip(props.odd.uuid) }">
		<div class="betting-info-main bettig-info-dual" :class="{'bet-up': price_up , 'bet-down': price_down}">
			<h6>{{ odd.name }}</h6>

			<h6 v-if="odd.price == '1.00'" v-html="menu_icons.lock"></h6>
			<h6 v-else-if="odd.status=='suspended'" v-html="menu_icons.lock"></h6>
			<h6 v-else>{{ oddsStore.oddsFormat(odd.price) }}</h6>
		</div>
	</div>
</template>