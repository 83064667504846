<script setup>
/* eslint-disable */

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useRacingSportsStore } from '@/stores/racing_sports';
import { usePreSportsStore } from '@/stores/pre_sports';

const router = useRouter();
const racingSportsStore = useRacingSportsStore();
const preSportsStore = usePreSportsStore();

const selectSport = (sport_id) => {
	router.push({name: 'racing-coupons', params: {sport_id: sport_id}});
	racingSportsStore.setSelectedRacingSportId(sport_id);
}
</script>

<template>
	<div class="slider-tab-main">
		<div v-for="sport in racingSportsStore.racingSports" :key="sport.id" class="slider-item" :class="{'active': racingSportsStore.selectedRacingSportId == sport.id }">
			<div @click="selectSport(sport.id)" class="slider-content">
				<img :src="preSportsStore.preSportWhiteIcon(sport.id)" alt="">
				<p>{{ sport.name }}</p>
			</div>
		</div>
	</div>
</template>