<script setup>
import { openModal } from '@kolirt/vue-modal';
import Register from '@/components/modals/Register.vue';
import Login from '@/components/modals/Login.vue';
import { useI18n } from 'vue-i18n';
import TableImage from '@/components/live_casino/table/TableImage.vue';

const { t } = useI18n();

const props = defineProps({
	title: {
		type: String,
		required: true,
	},
	tables: {
		type: Object,
		required: true,
	},
	type: {
		type: String,
		required: true,
	}
});

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	})
};
</script>
<template>
	<div class="casino-game-section live_casino-game-section">
		<div class="container-lg casino-container-lg">
			<div class="casino-feature-games">
				<div >
					<div class="title">
						<h3>{{ t('general.last_played_games') }}</h3>
					</div>
					<div class="casino-feature-outer">
						<!-- live feed-grid start-->
						<div class="feature-games-main">
							<div v-for="table in props.tables" :key="table" class="featured-game-inner">
								<template v-if="table != null">
									<div class="feature-image">
										<TableImage :table="table" size="medium"/>
									</div>
									<div class="feature-title">
										<h4 class="ellipsis" :alt="table['name']">{{ table['name'] }}</h4>
									</div>
								</template>
							</div>
						</div>
						<!-- live feed-grid end-->
					</div>
				</div>

			</div>
		</div>
	</div>
</template>