<!-- eslint-disable -->

<script setup>
import Header from '@/components/Common/header.vue';
import { openModal } from '@kolirt/vue-modal';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useCustomerStore } from '../stores/customer';
import { useLoginStore } from '../stores/login';
import { useMessageStore } from '../stores/message'
import { useGeneralStore} from '@/stores/general';
import { useI18n } from 'vue-i18n';
import { useNavbarStore } from '@/stores/navbar_items';
import { useAccountStore } from '@/stores/account';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import MobileMenu from '@/components/Common/mobileMenu.vue';
import { useMobileDashboardPrevious } from "@/stores/mobile_dashboard_previous_route"
// import VerificationEmail from '@/components/modals/VerificationEmail.vue';
const accountStore = useAccountStore()
const mobileDashboardStore = useMobileDashboardPrevious();

const customerStore = useCustomerStore()
const router = useRouter();
const loginStore = useLoginStore();
const messageStore = useMessageStore();
const navbarStore = useNavbarStore();
const { t } = useI18n();
const mobile_tab_active = ref(false);
const accordion_visibility_mobile = ref({ account_details: false, bank: false, history: false, player_protection: false});
const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';

const logout = () => {
	loginStore.logout().then(() => {
		router.push({name: 'landing'})
	})
}

const showVerificationEmailModal = () => {
	openModal(VerificationEmail);
};

const goToRoute = (route_name) => {

if (!customerStore.hasVerifiedEmail) {
	showVerificationEmailModal();

	return;
}

router.push({ name: route_name });
mobile_tab_active.value = false;
}

const goBack = (route_path) => {
	if (route_path) router.push({ path: route_path });
	else router.push({ name: 'landing' })
}

const toggleAccordionMobile = (menu_slug) => {
	if (accordion_visibility_mobile.value[menu_slug] != true) accordion_visibility_mobile.value[menu_slug] = true;
	else accordion_visibility_mobile.value[menu_slug] = false;
}
const closeDashboard = () => {
	const previous_route_data_main = mobileDashboardStore.getMobileDashboardPrevious;

	if (previous_route_data_main && previous_route_data_main.path) {
		router.push(previous_route_data_main.path);
		mobileDashboardStore.setMobileDashboardPrevious(false)

	} else {
		router.push({ name: 'landing' })
	}
};
onMounted(() => {
	navbarStore.closeAll();
	document.title = useGeneralStore().getGeneral.site_name + ' - ' + t('routing.account');
	document.body.style.overflow = 'auto';
})

// Add watcher isLoggedIn
watch(() => customerStore.customer, (newValue) => {
	if (newValue == null) router.push({ name: 'landing' });
})

const goBackToDashboard = (keepOpen = true)=>{
	const previous_route_data =  mobileDashboardStore.getMobileDashboardPrevious
	if(previous_route_data?.name){
		if(keepOpen === false){
			mobileDashboardStore.setMobileDashboardPrevious(null)
		}
		router.push({ name: previous_route_data.name })
	}
	else{
		router.push({ name: 'landing' })
	}
}

</script>
<template>
	<div class="dashboard-layout">
		<Header></Header>
		<div class="dashboard-main">
			<div class="container-full">
				<div class="dashboard-outer">
					<div class="dashboard-left-sidebar">
						<div class="vertical-tabs">
							<div class="left-sidebar-dash" :class="router.currentRoute.value.path.includes('/account/account-details') ? 'active' : ''">
								<button @click="goToRoute('account-details')" class="dash-tab-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
										<path id="Path_384" data-name="Path 384"
											d="M19.843,17.544a11.01,11.01,0,1,0-.776.935A11.074,11.074,0,0,0,19.843,17.544Zm-.61-1.044a9.9,9.9,0,1,0-16.466,0A9.915,9.915,0,0,1,8.5,12.419a4.4,4.4,0,1,1,5.006,0A9.916,9.916,0,0,1,19.233,16.5Zm-.709.934a8.8,8.8,0,0,0-15.049,0,9.9,9.9,0,0,0,15.049,0ZM11,12.1A3.3,3.3,0,1,0,7.7,8.8,3.3,3.3,0,0,0,11,12.1Z"
											fill="#878a90" fill-rule="evenodd" />
									</svg>
									<span>{{ $t('account.account_details.title') }}</span>
								</button>

								<div class="dash-inner-tabs">
									<button @click="goToRoute('account-details')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details' ? 'active' : ''">{{ $t('account.account_details.title') }}</button>
									<button @click="goToRoute('account-details-password')" class="inner-tab-item " :class="router.currentRoute.value.name == 'account-details-password' ? 'active' : ''">{{ $t('account.account_password.title') }}</button>
									<button @click="goToRoute('account-details-preferences')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details-preferences' ? 'active' : ''">{{ $t('account.account_preferences.title') }}</button>
									<button v-if="!is_country_ghana" @click="goToRoute('account-details-verification-process')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details-verification-process' ? 'active' : ''">{{ $t('account.verification_process.title') }}</button>
								</div>

							</div>
							<div class="left-sidebar-dash" :class="router.currentRoute.value.path.includes('/bank') ? 'active' : ''">
								<button @click="goToRoute('bank-deposits')" class="dash-tab-item ">
									<svg id="bank" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
										viewBox="0 0 22 22">
										<path id="Path_385" data-name="Path 385"
											d="M23.763,10.91H2.507a.307.307,0,0,1-.314-.3V8.422a.3.3,0,0,1,.152-.257L12.97,2.043a.327.327,0,0,1,.325,0l10.63,6.122a.3.3,0,0,1,.152.257V10.61A.307.307,0,0,1,23.763,10.91Zm-20.941-.6H23.448V8.591L13.133,2.651,2.822,8.591Z"
											transform="translate(-2.135 -2)" fill="#878a90" />
										<path id="Path_386" data-name="Path 386"
											d="M32.813,15.94a1.794,1.794,0,1,1,1.794-1.794,1.794,1.794,0,0,1-1.794,1.794Zm0-2.989a1.194,1.194,0,1,0,1.194,1.194A1.194,1.194,0,0,0,32.813,12.951Z"
											transform="translate(-21.813 -9.246)" fill="#878a90" />
										<path id="Path_387" data-name="Path 387"
											d="M56.011,32.2H52.819a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,56.011,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048h3.192a.048.048,0,0,0,.048-.048V30.3Z"
											transform="translate(-36.12 -20.941)" fill="#878a90" />
										<path id="Path_388" data-name="Path 388"
											d="M57.186,42.676H54.538a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.648a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,57.186,42.676Zm-2.348-.6h2.048v-5.46H54.838Z"
											transform="translate(-37.567 -25.1)" fill="#878a90" />
										<path id="Path_389" data-name="Path 389"
											d="M56.359,58.712H52.471a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648h3.192a.649.649,0,0,1,.648.648v1.548A.3.3,0,0,1,56.359,58.712Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H52.819a.048.048,0,0,0-.048.048Z"
											transform="translate(-36.12 -38.951)" fill="#878a90" />
										<path id="Path_390" data-name="Path 390"
											d="M33.36,32.2H30.168a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,33.36,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048H33.36a.048.048,0,0,0,.048-.048V30.3Z"
											transform="translate(-20.764 -20.941)" fill="#878a90" />
										<path id="Path_391" data-name="Path 391"
											d="M34.54,42.676H31.9a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.64a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,34.54,42.676Zm-2.34-.6h2.04v-5.46H32.2Z"
											transform="translate(-22.22 -25.1)" fill="#878a90" />
										<path id="Path_392" data-name="Path 392"
											d="M33.708,58.712H29.82a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648H33.36a.649.649,0,0,1,.648.648v1.548A.3.3,0,0,1,33.708,58.712Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H30.168a.048.048,0,0,0-.048.048Z"
											transform="translate(-20.764 -38.951)" fill="#878a90" />
										<path id="Path_393" data-name="Path 393"
											d="M11.708,32.2H8.516a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,11.708,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048h3.192a.048.048,0,0,0,.048-.048V30.3Z"
											transform="translate(-6.108 -20.941)" fill="#878a90" />
										<path id="Path_394" data-name="Path 394"
											d="M12.87,42.676H10.2a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.67a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,12.87,42.676Zm-2.37-.6h2.07v-5.46H10.5Z"
											transform="translate(-7.53 -25.1)" fill="#878a90" />
										<path id="Path_395" data-name="Path 395"
											d="M12.056,58.712H8.168a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648h3.192a.649.649,0,0,1,.648.648v1.548a.3.3,0,0,1-.3.3Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H8.516a.048.048,0,0,0-.048.048Z"
											transform="translate(-6.108 -38.951)" fill="#878a90" />
										<path id="Path_396" data-name="Path 396"
											d="M23.686,65.375H2.314a.307.307,0,0,1-.314-.3V62.836a.307.307,0,0,1,.314-.3H23.686a.307.307,0,0,1,.314.3v2.239A.307.307,0,0,1,23.686,65.375Zm-21.057-.6H23.371V63.136H2.629Z"
											transform="translate(-2 -43.375)" fill="#878a90" />
									</svg>
									<span>{{ $t('general.bank') }}</span>
								</button>

								<div class="dash-inner-tabs">
									<button @click="goToRoute('bank-deposits')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('/account/bank/deposits') ? 'active' : ''">{{ $t('bank.deposit.deposit') }}</button>
									<button @click="goToRoute('bank-withdrawals')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('/account/bank/withdrawals') ? 'active' : ''">{{ $t('bank.withdraw.withdraw') }}</button>
									<button @click="goToRoute('bank-pending-transactions')" class="inner-tab-item" :class="router.currentRoute.value.name == 'bank-pending-transactions' ? 'active' : ''">{{ $t('bank.pending.pending') }}</button>
								</div>
							</div>

							<div class="left-sidebar-dash" :class="router.currentRoute.value.path.includes('/account/history') ? 'active' : ''">
								<button @click="goToRoute('history-settled-bets')" class="dash-tab-item">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
										width="21.206" height="21.48" viewBox="0 0 21.206 21.48">
										<defs>
											<linearGradient id="linear-gradient" y1="0.088" x2="1" y2="1"
												gradientUnits="objectBoundingBox">
												<stop offset="0" stop-color="#e382bc" />
												<stop offset="1" stop-color="#8d54eb" />
											</linearGradient>
										</defs>
										<path id="history"
											d="M22.472,12A10.6,10.6,0,0,1,1.543,14.394a.6.6,0,1,1,1.17-.27A9.405,9.405,0,1,0,3.36,8.015H6.927a.6.6,0,0,1,0,1.2H2.39a.6.6,0,0,1-.6-.6V4.078a.6.6,0,0,1,1.2,0V6.219A10.6,10.6,0,0,1,22.472,12ZM11.861,7.079a.6.6,0,0,0-.6.6v5.544a.6.6,0,0,0,.6.6H15.5a.6.6,0,1,0,0-1.2H12.461V7.679a.6.6,0,0,0-.6-.6Z"
											transform="translate(-1.391 -1.265)" stroke="#202833" stroke-width="0.25"
											fill="rgb(135, 138, 144)" />
									</svg>

									<span>{{ $t('general.history') }}</span>
								</button>

								<div class="dash-inner-tabs">
									<button @click="goToRoute('history-settled-bets')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-settled-bets' ? 'active' : ''">{{ $t('general.settled_bets') }}</button>
									<button @click="goToRoute('history-unsettled-bets')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-unsettled-bets' ? 'active' : ''">{{ $t('general.unsettled_bets') }}</button>
									<button @click="goToRoute('history-slots')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-slots' ? 'active' : ''">{{ $t('general.slots') }}</button>
									<button @click="goToRoute('history-live-casino')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-live-casino' ? 'active' : ''">{{ $t('general.live_casino') }}</button>
									<button @click="goToRoute('history-deposits')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-deposits' ? 'active' : ''">{{ $t('general.deposits') }}</button>
									<button @click="goToRoute('history-withdrawals')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-withdrawals' ? 'active' : ''">{{ $t('general.withdrawals') }}</button>
								</div>
							</div>
							<div class="left-sidebar-dash" :class="router.currentRoute.value.path.includes('/account/player-protection') ? 'active' : ''">
								<button @click="goToRoute('player-protection-login-limit')" class="dash-tab-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="17.131" height="22" viewBox="0 0 17.131 22">
										<g id="Group_159" data-name="Group 159" transform="translate(-179 -490)">
											<g id="protection" transform="translate(179 490)">
												<path id="Path_405" data-name="Path 405"
													d="M16.968,4.454,8.7.048a.411.411,0,0,0-.391,0L.268,4.457a.412.412,0,0,0-.214.361v5.755A11.917,11.917,0,0,0,7.2,21.479l1.116.486a.411.411,0,0,0,.328,0L9.9,21.423a11.893,11.893,0,0,0,7.288-10.964V4.818A.412.412,0,0,0,16.968,4.454Zm-.605,6A11.072,11.072,0,0,1,9.576,20.666h0l-1.1.473-.951-.415A11.092,11.092,0,0,1,.878,10.573V5.061L8.509.879l7.854,4.185Zm0,0"
													transform="translate(-0.055 0)" fill="#878a90" />
												<path id="Path_406" data-name="Path 406"
													d="M80.565,147.755a.411.411,0,0,0-.625.534l2.171,2.543a.411.411,0,0,0,.572.052l5.057-4.114a.411.411,0,0,0-.519-.638l-4.745,3.86Zm0,0"
													transform="translate(-75.143 -137.447)" fill="#878a90" />
											</g>
										</g>
									</svg>
									<span>{{ $t('account.customer_protection.customer_protection_title') }}</span>
								</button>

								<div class="dash-inner-tabs">
									<button @click="goToRoute('player-protection-login-limit')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('player-protection/limits') ? 'active' : ''">{{ $t('account.customer_protection.limits') }}</button>
									<button @click="goToRoute('player-protection-cancel-withdraw-request')" class="inner-tab-item " :class="router.currentRoute.value.name == 'player-protection-cancel-withdraw-request' ? 'active' : ''">{{ $t('account.customer_protection.cancel_withdraw_request_title') }}</button>
									<button @click="goToRoute('player-protection-reality-check')" class="inner-tab-item" :class="router.currentRoute.value.name == 'player-protection-reality-check' ? 'active' : ''">{{ $t('account.customer_protection.reality_check_title') }}</button>
									<button @click="goToRoute('player-protection-time-out')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('player-protection/time-limits') ? 'active' : ''">{{ $t('account.customer_protection.time_limits') }}</button>
									<button @click="goToRoute('player-protection-self-exclusion')" class="inner-tab-item" :class="router.currentRoute.value.name == 'player-protection-self-exclusion' ? 'active' : ''">{{ $t('account.customer_protection.self_exclusion_title') }}</button>
								</div>
							</div>
							<div class="left-sidebar-dash" :class="router.currentRoute.value.path.includes('/account/messages') ? 'active' : ''">
								<button @click="goToRoute('account-messages')" v-if="customerStore.getCustomer" class="dash-tab-item">
									<span v-if="customerStore.getCustomer.unread_messages_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_messages_count }}</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="22.25" height="16.25"
										viewBox="0 0 22.25 16.25">
										<g id="messages" transform="translate(0.125 0.125)">
											<g id="Group_156" data-name="Group 156" transform="translate(0 0)">
												<path id="Path_397" data-name="Path 397"
													d="M20,69.818H2a2,2,0,0,0-2,2v12a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2v-12A2,2,0,0,0,20,69.818Zm-18,1H20a1,1,0,0,1,.335.06L11.68,79.533a.955.955,0,0,1-1.351,0l0,0-8.66-8.655A1,1,0,0,1,2,70.818Zm-1,13v-12a1,1,0,0,1,0-.175l6.19,6.175L1,83.993A1,1,0,0,1,1,83.818Zm19,1H2a1,1,0,0,1-.335-.06L7.9,78.523l1.715,1.715a1.955,1.955,0,0,0,2.765.005l.005-.005L14.1,78.523l6.235,6.235A1,1,0,0,1,20,84.818Zm1-.825-6.19-6.175L21,71.643a1,1,0,0,1,0,.175v12A1,1,0,0,1,21,83.993Z"
													transform="translate(0 -69.818)" fill="#878a90" stroke="#202833"
													stroke-width="0.25" />
											</g>
										</g>
									</svg>
									<span>{{ $t('message.messages') }}</span>
								</button>
							</div>

							<div class="left-sidebar-dash" :class="router.currentRoute.value.path.includes('/account/bonus') ? 'active' : ''">
								<button @click="goToRoute('bonus-active-bonuses')" class="dash-tab-item">
									<span v-if="customerStore.unclaimed_bonuses_length > 0" class="notification-num">{{ customerStore.unclaimed_bonuses_length }}</span>
									<svg id="_30-Gift" data-name="30-Gift" xmlns="http://www.w3.org/2000/svg" width="22.036"
										height="22" viewBox="0 0 22.036 22">
										<path id="Path_398" data-name="Path 398"
											d="M23.543,36.61H4.355A.356.356,0,0,1,4,36.254v-13.9A.356.356,0,0,1,4.355,22H23.543a.356.356,0,0,1,.355.356v13.9A.356.356,0,0,1,23.543,36.61ZM4.711,35.9H23.188V22.713H4.711Z"
											transform="translate(-2.931 -14.61)" fill="#878a90" />
										<path id="Path_399" data-name="Path 399"
											d="M22.681,16.276H1.355A.356.356,0,0,1,1,15.92V12.356A.356.356,0,0,1,1.355,12H22.681a.356.356,0,0,1,.355.356V15.92A.356.356,0,0,1,22.681,16.276Zm-20.97-.713H22.326V12.713H1.711Z"
											transform="translate(-1 -8.129)" fill="#878a90" />
										<path id="Path_400" data-name="Path 400"
											d="M29.345,16.276H24.356A.356.356,0,0,1,24,15.92V12.356A.356.356,0,0,1,24.356,12h4.989a.356.356,0,0,1,.356.356V15.92A.356.356,0,0,1,29.345,16.276Zm-4.632-.713h4.276V12.713H24.713Z"
											transform="translate(-15.832 -8.129)" fill="#878a90" />
										<path id="Path_401" data-name="Path 401"
											d="M30.207,8.851H27.356A.356.356,0,0,1,27,8.494V7.02A1.021,1.021,0,0,1,28.02,6h1.523a1.021,1.021,0,0,1,1.02,1.02V8.494A.356.356,0,0,1,30.207,8.851Zm-2.494-.713h2.138V7.02a.308.308,0,0,0-.307-.307H28.02a.308.308,0,0,0-.307.307Z"
											transform="translate(-17.763 -4.255)" fill="#878a90" />
										<path id="Path_402" data-name="Path 402"
											d="M42.483,5.7H35.356A.356.356,0,0,1,35,5.342V4.594a.356.356,0,0,1,.173-.305l4.871-2.922a1.845,1.845,0,0,1,2.8,1.581V5.342A.356.356,0,0,1,42.483,5.7Zm-6.77-.713h6.414V2.948a1.132,1.132,0,0,0-1.716-.97L35.713,4.8Z"
											transform="translate(-22.941 -1.103)" fill="#878a90" />
										<path id="Path_403" data-name="Path 403"
											d="M14.483,5.7H7.356A.356.356,0,0,1,7,5.342V2.948A1.845,1.845,0,0,1,9.8,1.367l4.872,2.922a.356.356,0,0,1,.173.305v.748A.356.356,0,0,1,14.483,5.7Zm-6.77-.713h6.414V4.8l-4.7-2.818a1.132,1.132,0,0,0-1.716.97Z"
											transform="translate(-4.862 -1.103)" fill="#878a90" />
										<path id="Path_404" data-name="Path 404"
											d="M29.92,36.61H26.356A.356.356,0,0,1,26,36.254v-13.9A.356.356,0,0,1,26.356,22H29.92a.356.356,0,0,1,.356.356v13.9A.356.356,0,0,1,29.92,36.61ZM26.713,35.9h2.851V22.713H26.713Z"
											transform="translate(-17.12 -14.61)" fill="#878a90" />
									</svg>
									<span>{{ $t('general.bonus') }}</span>
								</button>

							</div>

							<div class="left-sidebar-dash" :class="router.currentRoute.value.path.includes('/account/notifications') ? 'active' : ''">
								<button @click="goToRoute('account-notifications')" v-if="customerStore.getCustomer" class="dash-tab-item">
									<span v-if="customerStore.getCustomer.unread_notifications_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_notifications_count }}</span>
									<svg id="_30-Gift" data-name="30-Gift" xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 28 23">
										<path d="M19.29 17.29L18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.9 0 1.34-1.08.71-1.71zM16 17H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zm-4 5c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z" fill="#878a90" stroke="#202833" stroke-width="0.25" class="color000000 svgShape">
										</path>
									</svg>
									<span>{{ $t('account.account_preferences.notifications') }}</span>
								</button>
							</div>
						</div>

						<div class="logout-btn" @click="logout">
							<button class="dash-tab-item">
								<svg xmlns="http://www.w3.org/2000/svg" width="20.392" height="22" viewBox="0 0 20.392 22">
									<g id="Group_161" data-name="Group 161" transform="translate(-56 -879)">
										<g id="logout" transform="translate(56 879)">
											<g id="Group_160" data-name="Group 160" transform="translate(0)">
												<path id="Path_407" data-name="Path 407"
													d="M27.033,20.739H21.3a2.827,2.827,0,0,1-2.822-2.825V4.085A2.827,2.827,0,0,1,21.3,1.261h5.826a.63.63,0,0,0,0-1.261H21.3a4.088,4.088,0,0,0-4.081,4.085V17.915A4.088,4.088,0,0,0,21.3,22h5.732a.63.63,0,0,0,0-1.261Z"
													transform="translate(-17.219)" fill="#878a90" />
												<path id="Path_408" data-name="Path 408"
													d="M135.888,140.512l-4-4a.63.63,0,0,0-.891.891l2.929,2.929H121.149a.63.63,0,0,0,0,1.259h12.776L131,144.519a.632.632,0,0,0,.443,1.077.614.614,0,0,0,.443-.187l4-4A.629.629,0,0,0,135.888,140.512Z"
													transform="translate(-115.681 -129.955)" fill="#878a90" />
											</g>
										</g>
									</g>
								</svg>
								<span>{{ $t('general.logout') }}</span>
							</button>

						</div>
					</div>


					<div class="dashboard-mobile-tab" id="tab-mobile-dashboard"  :class="mobile_tab_active == true ? 'active' : ''">
						<div class="container-lg">
							<div class="main-bar">
								<div class="icon" @click="mobile_tab_active = !mobile_tab_active">
									<svg class="ham" xmlns="http://www.w3.org/2000/svg" width="14" height="9"
										viewBox="0 0 14 9">
										<g id="Group_887" data-name="Group 887" transform="translate(-366 -856)">
											<rect class="ham-1" id="Rectangle_1066" data-name="Rectangle 1066" width="14"
												height="1" rx="0.5" transform="translate(366 860)" fill="#fff" />
											<rect class="ham-2" id="Rectangle_1067" data-name="Rectangle 1067" width="14"
												height="1" rx="0.5" transform="translate(366 856)" fill="#fff" />
											<rect class="ham-3" id="Rectangle_1068" data-name="Rectangle 1068" width="14"
												height="1" rx="0.5" transform="translate(366 864)" fill="#fff" />
										</g>
									</svg>
									<svg class="ham-close" xmlns="http://www.w3.org/2000/svg" width="10" height="14"
										viewBox="0 0 10 14">
										<g id="close" transform="translate(0.707 0.707)">
											<line id="Line_2" data-name="Line 2" x2="10" y2="10" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-width="1" />
											<line id="Line_3" data-name="Line 3" x1="10" y2="10" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-width="1" />
										</g>
									</svg>
								</div>
								<div class="close-title">
									<h6 v-if="router.currentRoute.value.name.includes('account-details')">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 22 22">
											<path id="Path_384" data-name="Path 384"
												d="M19.843,17.544a11.01,11.01,0,1,0-.776.935A11.074,11.074,0,0,0,19.843,17.544Zm-.61-1.044a9.9,9.9,0,1,0-16.466,0A9.915,9.915,0,0,1,8.5,12.419a4.4,4.4,0,1,1,5.006,0A9.916,9.916,0,0,1,19.233,16.5Zm-.709.934a8.8,8.8,0,0,0-15.049,0,9.9,9.9,0,0,0,15.049,0ZM11,12.1A3.3,3.3,0,1,0,7.7,8.8,3.3,3.3,0,0,0,11,12.1Z"
												fill="#878a90" fill-rule="evenodd" />
										</svg>
										{{ $t('account.account_details.title') }}</h6>
									<h6 v-else-if="router.currentRoute.value.name.includes('bank')">
										<svg id="bank" xmlns="http://www.w3.org/2000/svg" width="20" height="19"
											viewBox="0 0 22 22">
											<path id="Path_385" data-name="Path 385"
												d="M23.763,10.91H2.507a.307.307,0,0,1-.314-.3V8.422a.3.3,0,0,1,.152-.257L12.97,2.043a.327.327,0,0,1,.325,0l10.63,6.122a.3.3,0,0,1,.152.257V10.61A.307.307,0,0,1,23.763,10.91Zm-20.941-.6H23.448V8.591L13.133,2.651,2.822,8.591Z"
												transform="translate(-2.135 -2)" fill="#878a90" />
											<path id="Path_386" data-name="Path 386"
												d="M32.813,15.94a1.794,1.794,0,1,1,1.794-1.794,1.794,1.794,0,0,1-1.794,1.794Zm0-2.989a1.194,1.194,0,1,0,1.194,1.194A1.194,1.194,0,0,0,32.813,12.951Z"
												transform="translate(-21.813 -9.246)" fill="#878a90" />
											<path id="Path_387" data-name="Path 387"
												d="M56.011,32.2H52.819a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,56.011,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048h3.192a.048.048,0,0,0,.048-.048V30.3Z"
												transform="translate(-36.12 -20.941)" fill="#878a90" />
											<path id="Path_388" data-name="Path 388"
												d="M57.186,42.676H54.538a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.648a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,57.186,42.676Zm-2.348-.6h2.048v-5.46H54.838Z"
												transform="translate(-37.567 -25.1)" fill="#878a90" />
											<path id="Path_389" data-name="Path 389"
												d="M56.359,58.712H52.471a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648h3.192a.649.649,0,0,1,.648.648v1.548A.3.3,0,0,1,56.359,58.712Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H52.819a.048.048,0,0,0-.048.048Z"
												transform="translate(-36.12 -38.951)" fill="#878a90" />
											<path id="Path_390" data-name="Path 390"
												d="M33.36,32.2H30.168a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,33.36,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048H33.36a.048.048,0,0,0,.048-.048V30.3Z"
												transform="translate(-20.764 -20.941)" fill="#878a90" />
											<path id="Path_391" data-name="Path 391"
												d="M34.54,42.676H31.9a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.64a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,34.54,42.676Zm-2.34-.6h2.04v-5.46H32.2Z"
												transform="translate(-22.22 -25.1)" fill="#878a90" />
											<path id="Path_392" data-name="Path 392"
												d="M33.708,58.712H29.82a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648H33.36a.649.649,0,0,1,.648.648v1.548A.3.3,0,0,1,33.708,58.712Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H30.168a.048.048,0,0,0-.048.048Z"
												transform="translate(-20.764 -38.951)" fill="#878a90" />
											<path id="Path_393" data-name="Path 393"
												d="M11.708,32.2H8.516a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,11.708,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048h3.192a.048.048,0,0,0,.048-.048V30.3Z"
												transform="translate(-6.108 -20.941)" fill="#878a90" />
											<path id="Path_394" data-name="Path 394"
												d="M12.87,42.676H10.2a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.67a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,12.87,42.676Zm-2.37-.6h2.07v-5.46H10.5Z"
												transform="translate(-7.53 -25.1)" fill="#878a90" />
											<path id="Path_395" data-name="Path 395"
												d="M12.056,58.712H8.168a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648h3.192a.649.649,0,0,1,.648.648v1.548a.3.3,0,0,1-.3.3Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H8.516a.048.048,0,0,0-.048.048Z"
												transform="translate(-6.108 -38.951)" fill="#878a90" />
											<path id="Path_396" data-name="Path 396"
												d="M23.686,65.375H2.314a.307.307,0,0,1-.314-.3V62.836a.307.307,0,0,1,.314-.3H23.686a.307.307,0,0,1,.314.3v2.239A.307.307,0,0,1,23.686,65.375Zm-21.057-.6H23.371V63.136H2.629Z"
												transform="translate(-2 -43.375)" fill="#878a90" />
										</svg>
										{{ $t('general.bank') }}</h6>
									<h6 v-else-if="router.currentRoute.value.name.includes('history')">
										<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="19" viewBox="0 0 22 22">
											<defs>
												<linearGradient id="linear-gradient" y1="0.088" x2="1" y2="1"
													gradientUnits="objectBoundingBox">
													<stop offset="0" stop-color="#e382bc" />
													<stop offset="1" stop-color="#8d54eb" />
												</linearGradient>
											</defs>
											<path id="history"
												d="M22.472,12A10.6,10.6,0,0,1,1.543,14.394a.6.6,0,1,1,1.17-.27A9.405,9.405,0,1,0,3.36,8.015H6.927a.6.6,0,0,1,0,1.2H2.39a.6.6,0,0,1-.6-.6V4.078a.6.6,0,0,1,1.2,0V6.219A10.6,10.6,0,0,1,22.472,12ZM11.861,7.079a.6.6,0,0,0-.6.6v5.544a.6.6,0,0,0,.6.6H15.5a.6.6,0,1,0,0-1.2H12.461V7.679a.6.6,0,0,0-.6-.6Z"
												transform="translate(-1.391 -1.265)" stroke="#202833" stroke-width="0.25"
												fill="#878a90" />
										</svg>
										{{ $t('general.history') }}</h6>
									<h6 v-else-if="router.currentRoute.value.name.includes('player-protection')">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 22 22">
											<g id="Group_159" data-name="Group 159" transform="translate(-179 -490)">
												<g id="protection" transform="translate(179 490)">
													<path id="Path_405" data-name="Path 405"
														d="M16.968,4.454,8.7.048a.411.411,0,0,0-.391,0L.268,4.457a.412.412,0,0,0-.214.361v5.755A11.917,11.917,0,0,0,7.2,21.479l1.116.486a.411.411,0,0,0,.328,0L9.9,21.423a11.893,11.893,0,0,0,7.288-10.964V4.818A.412.412,0,0,0,16.968,4.454Zm-.605,6A11.072,11.072,0,0,1,9.576,20.666h0l-1.1.473-.951-.415A11.092,11.092,0,0,1,.878,10.573V5.061L8.509.879l7.854,4.185Zm0,0"
														transform="translate(-0.055 0)" fill="#878a90" />
													<path id="Path_406" data-name="Path 406"
														d="M80.565,147.755a.411.411,0,0,0-.625.534l2.171,2.543a.411.411,0,0,0,.572.052l5.057-4.114a.411.411,0,0,0-.519-.638l-4.745,3.86Zm0,0"
														transform="translate(-75.143 -137.447)" fill="#878a90" />
												</g>
											</g>
										</svg>
										{{ $t('account.customer_protection.customer_protection_title') }}</h6>
									<h6 v-else-if="router.currentRoute.value.name.includes('messages')">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 22 12">
											<g id="messages" transform="translate(0.125 0.125)">
												<g id="Group_156" data-name="Group 156" transform="translate(0 0)">
													<path id="Path_397" data-name="Path 397" d="M20,69.818H2a2,2,0,0,0-2,2v12a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2v-12A2,2,0,0,0,20,69.818Zm-18,1H20a1,1,0,0,1,.335.06L11.68,79.533a.955.955,0,0,1-1.351,0l0,0-8.66-8.655A1,1,0,0,1,2,70.818Zm-1,13v-12a1,1,0,0,1,0-.175l6.19,6.175L1,83.993A1,1,0,0,1,1,83.818Zm19,1H2a1,1,0,0,1-.335-.06L7.9,78.523l1.715,1.715a1.955,1.955,0,0,0,2.765.005l.005-.005L14.1,78.523l6.235,6.235A1,1,0,0,1,20,84.818Zm1-.825-6.19-6.175L21,71.643a1,1,0,0,1,0,.175v12A1,1,0,0,1,21,83.993Z" transform="translate(0 -69.818)" fill="#878a90" stroke="#202833" stroke-width="0.25"></path>
												</g>
											</g>
										</svg>
										{{$t('general.messages')}}</h6>
									<h6 v-else-if="router.currentRoute.value.name.includes('bonus')">
										<svg id="_30-Gift" data-name="30-Gift" xmlns="http://www.w3.org/2000/svg" width="20"
											height="19" viewBox="0 0 22 22">
											<path id="Path_398" data-name="Path 398"
												d="M23.543,36.61H4.355A.356.356,0,0,1,4,36.254v-13.9A.356.356,0,0,1,4.355,22H23.543a.356.356,0,0,1,.355.356v13.9A.356.356,0,0,1,23.543,36.61ZM4.711,35.9H23.188V22.713H4.711Z"
												transform="translate(-2.931 -14.61)" fill="#878a90" />
											<path id="Path_399" data-name="Path 399"
												d="M22.681,16.276H1.355A.356.356,0,0,1,1,15.92V12.356A.356.356,0,0,1,1.355,12H22.681a.356.356,0,0,1,.355.356V15.92A.356.356,0,0,1,22.681,16.276Zm-20.97-.713H22.326V12.713H1.711Z"
												transform="translate(-1 -8.129)" fill="#878a90" />
											<path id="Path_400" data-name="Path 400"
												d="M29.345,16.276H24.356A.356.356,0,0,1,24,15.92V12.356A.356.356,0,0,1,24.356,12h4.989a.356.356,0,0,1,.356.356V15.92A.356.356,0,0,1,29.345,16.276Zm-4.632-.713h4.276V12.713H24.713Z"
												transform="translate(-15.832 -8.129)" fill="#878a90" />
											<path id="Path_401" data-name="Path 401"
												d="M30.207,8.851H27.356A.356.356,0,0,1,27,8.494V7.02A1.021,1.021,0,0,1,28.02,6h1.523a1.021,1.021,0,0,1,1.02,1.02V8.494A.356.356,0,0,1,30.207,8.851Zm-2.494-.713h2.138V7.02a.308.308,0,0,0-.307-.307H28.02a.308.308,0,0,0-.307.307Z"
												transform="translate(-17.763 -4.255)" fill="#878a90" />
											<path id="Path_402" data-name="Path 402"
												d="M42.483,5.7H35.356A.356.356,0,0,1,35,5.342V4.594a.356.356,0,0,1,.173-.305l4.871-2.922a1.845,1.845,0,0,1,2.8,1.581V5.342A.356.356,0,0,1,42.483,5.7Zm-6.77-.713h6.414V2.948a1.132,1.132,0,0,0-1.716-.97L35.713,4.8Z"
												transform="translate(-22.941 -1.103)" fill="#878a90" />
											<path id="Path_403" data-name="Path 403"
												d="M14.483,5.7H7.356A.356.356,0,0,1,7,5.342V2.948A1.845,1.845,0,0,1,9.8,1.367l4.872,2.922a.356.356,0,0,1,.173.305v.748A.356.356,0,0,1,14.483,5.7Zm-6.77-.713h6.414V4.8l-4.7-2.818a1.132,1.132,0,0,0-1.716.97Z"
												transform="translate(-4.862 -1.103)" fill="#878a90" />
											<path id="Path_404" data-name="Path 404"
												d="M29.92,36.61H26.356A.356.356,0,0,1,26,36.254v-13.9A.356.356,0,0,1,26.356,22H29.92a.356.356,0,0,1,.356.356v13.9A.356.356,0,0,1,29.92,36.61ZM26.713,35.9h2.851V22.713H26.713Z"
												transform="translate(-17.12 -14.61)" fill="#878a90" />
										</svg>
										{{$t('general.bonus')}}</h6>
									<h6 v-else-if="router.currentRoute.value.name.includes('notifications')">
										<svg id="_30-Gift" data-name="30-Gift" xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 22 22">
											<path d="M19.29 17.29L18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.9 0 1.34-1.08.71-1.71zM16 17H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zm-4 5c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z" fill="#878a90" stroke="#202833" stroke-width="0.25" class="color000000 svgShape">
											</path>
										</svg>
										{{$t('account.account_preferences.notifications')}}</h6>
									<h6 v-else>{{ $t('general.my_account') }}</h6>
								</div>
								<div class="open-title">
									<h6>{{ $t('general.menu') }}</h6>
								</div>
								<div class="close-btn" @click="goBack(accountStore.getPreviousRoute)">
									<a href="#">
										<img style="width:16px" src="@/assets/images/back.png">
									</a>
								</div>

							</div>

							<div class="main-content-area">
								<div class="content-outer accordion-outer">
									<div class="accordion">
										<div class="accordion-item" :class="accordion_visibility_mobile['account_details'] == true ? 'active' : ''">
											<div class="accordion-header" @click="toggleAccordionMobile('account_details')">
												<button class="active">
													<div class="header-left">
														<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
															viewBox="0 0 22 22">
															<path id="Path_384" data-name="Path 384"
																d="M19.843,17.544a11.01,11.01,0,1,0-.776.935A11.074,11.074,0,0,0,19.843,17.544Zm-.61-1.044a9.9,9.9,0,1,0-16.466,0A9.915,9.915,0,0,1,8.5,12.419a4.4,4.4,0,1,1,5.006,0A9.916,9.916,0,0,1,19.233,16.5Zm-.709.934a8.8,8.8,0,0,0-15.049,0,9.9,9.9,0,0,0,15.049,0ZM11,12.1A3.3,3.3,0,1,0,7.7,8.8,3.3,3.3,0,0,0,11,12.1Z"
																fill="#878a90" fill-rule="evenodd" />
														</svg>
													</div>
													<span>{{ $t('account.account_details.title') }}</span>
												</button>
												<div class="acoordion-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
														viewBox="0 0 11.414 6.414">
														<g id="Group_897" data-name="Group 897"
															transform="translate(10.707 0.707) rotate(90)">
															<line id="Line_7" data-name="Line 7" y1="5" x2="5"
																transform="translate(0 5)" fill="none" stroke="#fff"
																stroke-linecap="round" stroke-width="1" />
															<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
																stroke="#fff" stroke-linecap="round" stroke-width="1" />
														</g>
													</svg>

												</div>
											</div>
											<div class="accordion-content">
												<div class="inner-tabing-list">
													<a @click="goToRoute('account-details')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details' ? 'active' : ''">{{ $t('account.account_details.title') }}</a>
													<a @click="goToRoute('account-details-preferences')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details-preferences' ? 'active' : ''">{{ $t('account.account_preferences.title') }}</a>
													<a @click="goToRoute('account-details-password')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details-password' ? 'active' : ''">{{ $t('account.account_password.title') }}</a>
													<a @click="goToRoute('account-details-verification-process')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details-verification-process' ? 'active' : ''">{{ $t('account.verification_process.title') }}</a>
												</div>

											</div>
										</div>

										<div class="accordion-item" :class="accordion_visibility_mobile['bank'] == true ? 'active' : ''">
											<div @click="toggleAccordionMobile('bank')" class="accordion-header">
												<button class="">
													<div class="header-left">

														<svg id="bank" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
															viewBox="0 0 22 22">
															<path id="Path_385" data-name="Path 385"
																d="M23.763,10.91H2.507a.307.307,0,0,1-.314-.3V8.422a.3.3,0,0,1,.152-.257L12.97,2.043a.327.327,0,0,1,.325,0l10.63,6.122a.3.3,0,0,1,.152.257V10.61A.307.307,0,0,1,23.763,10.91Zm-20.941-.6H23.448V8.591L13.133,2.651,2.822,8.591Z"
																transform="translate(-2.135 -2)" fill="#878a90" />
															<path id="Path_386" data-name="Path 386"
																d="M32.813,15.94a1.794,1.794,0,1,1,1.794-1.794,1.794,1.794,0,0,1-1.794,1.794Zm0-2.989a1.194,1.194,0,1,0,1.194,1.194A1.194,1.194,0,0,0,32.813,12.951Z"
																transform="translate(-21.813 -9.246)" fill="#878a90" />
															<path id="Path_387" data-name="Path 387"
																d="M56.011,32.2H52.819a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,56.011,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048h3.192a.048.048,0,0,0,.048-.048V30.3Z"
																transform="translate(-36.12 -20.941)" fill="#878a90" />
															<path id="Path_388" data-name="Path 388"
																d="M57.186,42.676H54.538a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.648a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,57.186,42.676Zm-2.348-.6h2.048v-5.46H54.838Z"
																transform="translate(-37.567 -25.1)" fill="#878a90" />
															<path id="Path_389" data-name="Path 389"
																d="M56.359,58.712H52.471a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648h3.192a.649.649,0,0,1,.648.648v1.548A.3.3,0,0,1,56.359,58.712Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H52.819a.048.048,0,0,0-.048.048Z"
																transform="translate(-36.12 -38.951)" fill="#878a90" />
															<path id="Path_390" data-name="Path 390"
																d="M33.36,32.2H30.168a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,33.36,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048H33.36a.048.048,0,0,0,.048-.048V30.3Z"
																transform="translate(-20.764 -20.941)" fill="#878a90" />
															<path id="Path_391" data-name="Path 391"
																d="M34.54,42.676H31.9a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.64a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,34.54,42.676Zm-2.34-.6h2.04v-5.46H32.2Z"
																transform="translate(-22.22 -25.1)" fill="#878a90" />
															<path id="Path_392" data-name="Path 392"
																d="M33.708,58.712H29.82a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648H33.36a.649.649,0,0,1,.648.648v1.548A.3.3,0,0,1,33.708,58.712Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H30.168a.048.048,0,0,0-.048.048Z"
																transform="translate(-20.764 -38.951)" fill="#878a90" />
															<path id="Path_393" data-name="Path 393"
																d="M11.708,32.2H8.516a.649.649,0,0,1-.648-.648V30a.3.3,0,0,1,.3-.3h3.888a.3.3,0,0,1,.3.3v1.548A.649.649,0,0,1,11.708,32.2Zm-3.24-1.9v1.248a.048.048,0,0,0,.048.048h3.192a.048.048,0,0,0,.048-.048V30.3Z"
																transform="translate(-6.108 -20.941)" fill="#878a90" />
															<path id="Path_394" data-name="Path 394"
																d="M12.87,42.676H10.2a.3.3,0,0,1-.3-.3v-6.06a.3.3,0,0,1,.3-.3h2.67a.3.3,0,0,1,.3.3v6.06A.3.3,0,0,1,12.87,42.676Zm-2.37-.6h2.07v-5.46H10.5Z"
																transform="translate(-7.53 -25.1)" fill="#878a90" />
															<path id="Path_395" data-name="Path 395"
																d="M12.056,58.712H8.168a.3.3,0,0,1-.3-.3V56.864a.649.649,0,0,1,.648-.648h3.192a.649.649,0,0,1,.648.648v1.548a.3.3,0,0,1-.3.3Zm-3.588-.6h3.288V56.864a.048.048,0,0,0-.048-.048H8.516a.048.048,0,0,0-.048.048Z"
																transform="translate(-6.108 -38.951)" fill="#878a90" />
															<path id="Path_396" data-name="Path 396"
																d="M23.686,65.375H2.314a.307.307,0,0,1-.314-.3V62.836a.307.307,0,0,1,.314-.3H23.686a.307.307,0,0,1,.314.3v2.239A.307.307,0,0,1,23.686,65.375Zm-21.057-.6H23.371V63.136H2.629Z"
																transform="translate(-2 -43.375)" fill="#878a90" />
														</svg>
													</div>
													<span>{{ $t('general.bank') }}</span>
												</button>
												<div class="acoordion-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
														viewBox="0 0 11.414 6.414">
														<g id="Group_897" data-name="Group 897"
															transform="translate(10.707 0.707) rotate(90)">
															<line id="Line_7" data-name="Line 7" y1="5" x2="5"
																transform="translate(0 5)" fill="none" stroke="#fff"
																stroke-linecap="round" stroke-width="1" />
															<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
																stroke="#fff" stroke-linecap="round" stroke-width="1" />
														</g>
													</svg>

												</div>
											</div>
											<div class="accordion-content">
												<div class="inner-tabing-list">
													<a @click="goToRoute('bank-deposits')" class="inner-tab-item" :class="router.currentRoute.value.path.startsWith('/account/bank/deposits') ? 'active' : ''">{{ $t('bank.deposit.deposit') }}</a>
													<a @click="goToRoute('bank-withdrawals')" class="inner-tab-item" :class="router.currentRoute.value.path.startsWith('/account/bank/withdrawals') ? 'active' : ''">{{ $t('bank.withdraw.withdraw') }}</a>
													<a @click="goToRoute('bank-pending-transactions')" class="inner-tab-item" :class="router.currentRoute.value.name == 'bank-pending-transaction' ? 'active' : ''">{{ $t('bank.pending.pending') }}</a>
												</div>

											</div>
										</div>

										<div class="accordion-item" :class="accordion_visibility_mobile['history'] == true ? 'active' : ''">
											<div class="accordion-header" @click="toggleAccordionMobile('history')">
												<button>
													<div class="header-left">
														<svg xmlns="http://www.w3.org/2000/svg" width="21.206" height="21.48"
															viewBox="0 0 21.206 21.48">
															<path id="history"
																d="M22.472,12A10.6,10.6,0,0,1,1.543,14.394a.6.6,0,1,1,1.17-.27A9.405,9.405,0,1,0,3.36,8.015H6.927a.6.6,0,0,1,0,1.2H2.39a.6.6,0,0,1-.6-.6V4.078a.6.6,0,0,1,1.2,0V6.219A10.6,10.6,0,0,1,22.472,12ZM11.861,7.079a.6.6,0,0,0-.6.6v5.544a.6.6,0,0,0,.6.6H15.5a.6.6,0,1,0,0-1.2H12.461V7.679a.6.6,0,0,0-.6-.6Z"
																transform="translate(-1.391 -1.265)" fill="#878a90"
																stroke="#000" stroke-width="0.25" />
														</svg>
													</div>
													<span>{{ $t('general.history') }}</span>
												</button>
												<div class="acoordion-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
														viewBox="0 0 11.414 6.414">
														<g id="Group_897" data-name="Group 897"
															transform="translate(10.707 0.707) rotate(90)">
															<line id="Line_7" data-name="Line 7" y1="5" x2="5"
																transform="translate(0 5)" fill="none" stroke="#fff"
																stroke-linecap="round" stroke-width="1" />
															<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
																stroke="#fff" stroke-linecap="round" stroke-width="1" />
														</g>
													</svg>

												</div>
											</div>
											<div class="accordion-content">
												<div class="inner-tabing-list">
													<a @click="goToRoute('history-settled-bets')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-settled-bets' ? 'active' : ''">{{ $t('general.settled_bets') }}</a>
													<a @click="goToRoute('history-unsettled-bets')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-unsettled-bets' ? 'active' : ''">{{ $t('general.unsettled_bets') }}</a>
													<a @click="goToRoute('history-slots')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-slots' ? 'active' : ''">{{ $t('general.slots') }}</a>
													<a @click="goToRoute('history-live-casino')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-live-casino' ? 'active' : ''">{{ $t('general.live_casino') }}</a>
													<a @click="goToRoute('history-deposits')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-deposits' ? 'active' : ''">{{ $t('general.deposits') }}</a>
													<a @click="goToRoute('history-withdrawals')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-withdrawals' ? 'active' : ''">{{ $t('general.withdrawals') }}</a>
												</div>

											</div>
										</div>
										<div class="accordion-item" :class="accordion_visibility_mobile['player_protection'] == true ? 'active' : ''">
											<div class="accordion-header" @click="toggleAccordionMobile('player_protection')">
												<button>
													<div class="header-left">
														<svg xmlns="http://www.w3.org/2000/svg" width="17.131" height="22"
															viewBox="0 0 17.131 22">
															<g id="Group_159" data-name="Group 159"
																transform="translate(-179 -490)">
																<g id="protection" transform="translate(179 490)">
																	<path id="Path_405" data-name="Path 405"
																		d="M16.968,4.454,8.7.048a.411.411,0,0,0-.391,0L.268,4.457a.412.412,0,0,0-.214.361v5.755A11.917,11.917,0,0,0,7.2,21.479l1.116.486a.411.411,0,0,0,.328,0L9.9,21.423a11.893,11.893,0,0,0,7.288-10.964V4.818A.412.412,0,0,0,16.968,4.454Zm-.605,6A11.072,11.072,0,0,1,9.576,20.666h0l-1.1.473-.951-.415A11.092,11.092,0,0,1,.878,10.573V5.061L8.509.879l7.854,4.185Zm0,0"
																		transform="translate(-0.055 0)" fill="#878a90" />
																	<path id="Path_406" data-name="Path 406"
																		d="M80.565,147.755a.411.411,0,0,0-.625.534l2.171,2.543a.411.411,0,0,0,.572.052l5.057-4.114a.411.411,0,0,0-.519-.638l-4.745,3.86Zm0,0"
																		transform="translate(-75.143 -137.447)"
																		fill="#878a90" />
																</g>
															</g>
														</svg>
													</div>
													<span>{{ $t('account.customer_protection.customer_protection_title') }}</span>
												</button>
												<div class="acoordion-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
														viewBox="0 0 11.414 6.414">
														<g id="Group_897" data-name="Group 897"
															transform="translate(10.707 0.707) rotate(90)">
															<line id="Line_7" data-name="Line 7" y1="5" x2="5"
																transform="translate(0 5)" fill="none" stroke="#fff"
																stroke-linecap="round" stroke-width="1" />
															<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
																stroke="#fff" stroke-linecap="round" stroke-width="1" />
														</g>
													</svg>

												</div>
											</div>
											<div class="accordion-content">
												<div class="inner-tabing-list">
													<a @click="goToRoute('player-protection-login-limit')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('player-protection/limits') ? 'active' : ''">{{ $t('account.customer_protection.limits') }}</a>
													<a @click="goToRoute('player-protection-cancel-withdraw-request')" class="inner-tab-item " :class="router.currentRoute.value.name == 'player-protection-cancel-withdraw-request' ? 'active' : ''">{{ $t('account.customer_protection.cancel_withdraw_request_title') }}</a>
													<a @click="goToRoute('player-protection-reality-check')" class="inner-tab-item" :class="router.currentRoute.value.name == 'player-protection-reality-check' ? 'active' : ''">{{ $t('account.customer_protection.reality_check_title') }}</a>
													<a @click="goToRoute('player-protection-time-out')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('player-protection/time-limits') ? 'active' : ''">{{ $t('account.customer_protection.time_limits') }}</a>
													<a @click="goToRoute('player-protection-self-exclusion')" class="inner-tab-item" :class="router.currentRoute.value.name == 'player-protection-self-exclusion' ? 'active' : ''">{{ $t('account.customer_protection.self_exclusion_title') }}</a>
												</div>

											</div>
										</div>


										<div class="accordion-item" :class="router.currentRoute.value.name == 'account-messages' ? 'active' : ''">
											<div class="accordion-header">
												<button @click="goToRoute('account-messages')" >
													<div class="header-left" v-if="customerStore.getCustomer">
														<span v-if="customerStore.getCustomer.unread_messages_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_messages_count }}</span>
														<svg xmlns="http://www.w3.org/2000/svg" width="22.25" height="16.25"
															viewBox="0 0 22.25 16.25">
															<g id="messages" transform="translate(0.125 0.125)">
																<g id="Group_156" data-name="Group 156"
																	transform="translate(0 0)">
																	<path id="Path_397" data-name="Path 397"
																		d="M20,69.818H2a2,2,0,0,0-2,2v12a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2v-12A2,2,0,0,0,20,69.818Zm-18,1H20a1,1,0,0,1,.335.06L11.68,79.533a.955.955,0,0,1-1.351,0l0,0-8.66-8.655A1,1,0,0,1,2,70.818Zm-1,13v-12a1,1,0,0,1,0-.175l6.19,6.175L1,83.993A1,1,0,0,1,1,83.818Zm19,1H2a1,1,0,0,1-.335-.06L7.9,78.523l1.715,1.715a1.955,1.955,0,0,0,2.765.005l.005-.005L14.1,78.523l6.235,6.235A1,1,0,0,1,20,84.818Zm1-.825-6.19-6.175L21,71.643a1,1,0,0,1,0,.175v12A1,1,0,0,1,21,83.993Z"
																		transform="translate(0 -69.818)" fill="#878a90"
																		stroke="#202833" stroke-width="0.25" />
																</g>
															</g>
														</svg>
													</div>
													<span>{{ $t('message.messages') }}</span>
												</button>
											</div>
										</div>

										<div class="accordion-item" :class="router.currentRoute.value.name == 'bonus-active-bonuses' ? 'active' : ''">
											<div class="accordion-header">
												<button @click="goToRoute('bonus-active-bonuses')">
													<div class="header-left">
														<span v-if="customerStore.unclaimed_bonuses_length > 0" class="notification-num">{{ customerStore.unclaimed_bonuses_length }}</span>
														<svg id="_30-Gift" data-name="30-Gift"
															xmlns="http://www.w3.org/2000/svg" width="22.036" height="22"
															viewBox="0 0 22.036 22">
															<path id="Path_398" data-name="Path 398"
																d="M23.543,36.61H4.355A.356.356,0,0,1,4,36.254v-13.9A.356.356,0,0,1,4.355,22H23.543a.356.356,0,0,1,.355.356v13.9A.356.356,0,0,1,23.543,36.61ZM4.711,35.9H23.188V22.713H4.711Z"
																transform="translate(-2.931 -14.61)" fill="#878a90" />
															<path id="Path_399" data-name="Path 399"
																d="M22.681,16.276H1.355A.356.356,0,0,1,1,15.92V12.356A.356.356,0,0,1,1.355,12H22.681a.356.356,0,0,1,.355.356V15.92A.356.356,0,0,1,22.681,16.276Zm-20.97-.713H22.326V12.713H1.711Z"
																transform="translate(-1 -8.129)" fill="#878a90" />
															<path id="Path_400" data-name="Path 400"
																d="M29.345,16.276H24.356A.356.356,0,0,1,24,15.92V12.356A.356.356,0,0,1,24.356,12h4.989a.356.356,0,0,1,.356.356V15.92A.356.356,0,0,1,29.345,16.276Zm-4.632-.713h4.276V12.713H24.713Z"
																transform="translate(-15.832 -8.129)" fill="#878a90" />
															<path id="Path_401" data-name="Path 401"
																d="M30.207,8.851H27.356A.356.356,0,0,1,27,8.494V7.02A1.021,1.021,0,0,1,28.02,6h1.523a1.021,1.021,0,0,1,1.02,1.02V8.494A.356.356,0,0,1,30.207,8.851Zm-2.494-.713h2.138V7.02a.308.308,0,0,0-.307-.307H28.02a.308.308,0,0,0-.307.307Z"
																transform="translate(-17.763 -4.255)" fill="#878a90" />
															<path id="Path_402" data-name="Path 402"
																d="M42.483,5.7H35.356A.356.356,0,0,1,35,5.342V4.594a.356.356,0,0,1,.173-.305l4.871-2.922a1.845,1.845,0,0,1,2.8,1.581V5.342A.356.356,0,0,1,42.483,5.7Zm-6.77-.713h6.414V2.948a1.132,1.132,0,0,0-1.716-.97L35.713,4.8Z"
																transform="translate(-22.941 -1.103)" fill="#878a90" />
															<path id="Path_403" data-name="Path 403"
																d="M14.483,5.7H7.356A.356.356,0,0,1,7,5.342V2.948A1.845,1.845,0,0,1,9.8,1.367l4.872,2.922a.356.356,0,0,1,.173.305v.748A.356.356,0,0,1,14.483,5.7Zm-6.77-.713h6.414V4.8l-4.7-2.818a1.132,1.132,0,0,0-1.716.97Z"
																transform="translate(-4.862 -1.103)" fill="#878a90" />
															<path id="Path_404" data-name="Path 404"
																d="M29.92,36.61H26.356A.356.356,0,0,1,26,36.254v-13.9A.356.356,0,0,1,26.356,22H29.92a.356.356,0,0,1,.356.356v13.9A.356.356,0,0,1,29.92,36.61ZM26.713,35.9h2.851V22.713H26.713Z"
																transform="translate(-17.12 -14.61)" fill="#878a90" />
														</svg>
													</div>
													<span>{{ $t('general.bonus') }}</span>
												</button >

											</div>
										</div>

										<div class="accordion-item" :class="router.currentRoute.value.name == 'account-notifications' ? 'active' : ''">
											<div class="accordion-header notification">
												<button @click="goToRoute('account-notifications')" >
													<div class="header-left" v-if="customerStore.getCustomer">
														<span v-if="customerStore.getCustomer.unread_notifications_count > 0" class="notification-num" style="right:5px">{{ customerStore.getCustomer.unread_notifications_count }}</span>
														<svg class="notification" id="_30-Gift" data-name="30-Gift" xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 28 23">
															<path d="M19.29 17.29L18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.9 0 1.34-1.08.71-1.71zM16 17H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zm-4 5c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z" fill="#878a90" stroke="#202833" stroke-width="0.25" class="color000000 svgShape">
															</path>
														</svg>
													</div>
													<span class="notification_span">{{ $t('account.account_preferences.notifications') }}</span>
												</button>
											</div>
										</div>

										<div @click="logout" class="accordion-item" style="margin-top: 50px;" >
											<div class="accordion-header">
												<button class="dash-tab-item">
													<svg xmlns="http://www.w3.org/2000/svg" width="20.392" height="22" viewBox="0 0 20.392 22">
														<g id="Group_161" data-name="Group 161" transform="translate(-56 -879)">
															<g id="logout" transform="translate(56 879)">
																<g id="Group_160" data-name="Group 160" transform="translate(0)">
																	<path id="Path_407" data-name="Path 407"
																		d="M27.033,20.739H21.3a2.827,2.827,0,0,1-2.822-2.825V4.085A2.827,2.827,0,0,1,21.3,1.261h5.826a.63.63,0,0,0,0-1.261H21.3a4.088,4.088,0,0,0-4.081,4.085V17.915A4.088,4.088,0,0,0,21.3,22h5.732a.63.63,0,0,0,0-1.261Z"
																		transform="translate(-17.219)" fill="#878a90" />
																	<path id="Path_408" data-name="Path 408"
																		d="M135.888,140.512l-4-4a.63.63,0,0,0-.891.891l2.929,2.929H121.149a.63.63,0,0,0,0,1.259h12.776L131,144.519a.632.632,0,0,0,.443,1.077.614.614,0,0,0,.443-.187l4-4A.629.629,0,0,0,135.888,140.512Z"
																		transform="translate(-115.681 -129.955)" fill="#878a90" />
																</g>
															</g>
														</g>
													</svg>
													<span class>{{ $t('general.logout') }}</span>
												</button>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>




					</div>

				<!--mobile dashboard top bar start  -->
					<div class="dashboard-mobile-top">
						<div class="back-btn">
							<div @click="goBackToDashboard" v-if="!['account-mobile-dashboard', 'new-message-mobile'].includes(router.currentRoute.value.name)">
								<div class="back-btn-inner">
									<object v-html="commonSvgs.arrow_left"></object>
									<p>Back</p>
								</div>
							</div>
							<router-link v-else-if="router.currentRoute.value.name == 'new-message-mobile' " to="/account/messages">
								<div class="back-btn-inner">
									<object v-html="commonSvgs.arrow_left"></object>
									<p>Back</p>
								</div>
							</router-link>

						</div>
						<div class="dashboard-title">
							<h6 v-if="router.currentRoute.value.name.includes('account-mobile-dashboard')"><img src="@/assets/images/user-ico.svg" alt="user icon">{{ customerStore.getCustomer.username }}</h6>
							<h6 v-if="router.currentRoute.value.path.startsWith('/account/account-details/details')">{{ $t('account.account_details.title') }}</h6>
							<h6 v-if="router.currentRoute.value.path.startsWith('/account/account-details/password')">{{$t('account.account_password.title')}}</h6>
							<h6 v-if="router.currentRoute.value.path.startsWith('/account/account-details/verification-process')">{{$t('account.verification_process.title')}}</h6>
							<h6 v-if="router.currentRoute.value.name.includes('preferences')">{{$t('account.account_preferences.title')}}</h6>
							<h6 v-else-if="router.currentRoute.value.name.includes('bank')"><object v-html="commonSvgs.bank_Green_ico"></object>{{ $t('general.bank') }}</h6>
							<h6 v-else-if="router.currentRoute.value.name.includes('history')"><object v-html="commonSvgs.history_Green_ico"></object>{{ $t('general.history') }}</h6>
							<h6 v-else-if="router.currentRoute.value.name.includes('player-protection')"><object v-html="commonSvgs.player_Green_ico"></object>{{ $t('account.customer_protection.customer_protection_title') }}</h6>
							<h6 v-else-if="router.currentRoute.value.path.startsWith('/account/message')"><object v-html="commonSvgs.message_Green_ico"></object>{{$t('general.messages')}}<span v-if="customerStore.getCustomer.unread_messages_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_messages_count }}</span></h6>
							<h6 v-else-if="router.currentRoute.value.name.includes('bonus')"><object v-html="commonSvgs.gift_Green_ico"></object>{{$t('general.bonus')}}<span v-if="customerStore.getCustomer.unread_messages_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_messages_count }}</span></h6>
							<h6 v-else-if="router.currentRoute.value.name.includes('notifications')">{{$t('account.account_preferences.notifications')}}</h6>
						</div>
						<div class="close-btn" @click="closeDashboard">
							<object v-html="commonSvgs.close"></object>
						</div>
					</div>



					<div class="inner-mobile_tab-main" v-if="router.currentRoute.value.name.includes('bank')">
						<div class="inner-mobile_tab-inner">
							<div class="inner-mobile_tab-item" @click="goToRoute('bank-deposits')" :class="router.currentRoute.value.path.startsWith('/account/bank/deposits') ? 'active' : ''">
								<p>{{ $t('bank.deposit.deposit') }}</p>
							</div>
							<div class="inner-mobile_tab-item" @click="goToRoute('bank-withdrawals')" :class="router.currentRoute.value.path.startsWith('/account/bank/withdrawals') ? 'active' : ''">
								<p>{{ $t('bank.withdraw.withdraw') }}</p>
							</div>
							<div class="inner-mobile_tab-item" @click="goToRoute('bank-pending-transactions')" :class="router.currentRoute.value.path.startsWith('/account/bank/pending-transactions') ? 'active' : ''">
								<p>{{ $t('bank.pending.pending') }}</p>
							</div>
						</div>
					</div>

					<div class="inner-mobile_tab-main" v-if="router.currentRoute.value.path.startsWith('/account/history')">
						<div class="inner-mobile_tab-inner">
							<div class="inner-mobile_tab-item" @click="goToRoute('history-settled-bets')" :class="router.currentRoute.value.name == 'history-settled-bets' ? 'active' : ''">
								<p>{{ $t('general.settled_bets') }}</p>
							</div>
							<div class="inner-mobile_tab-item" @click="goToRoute('history-unsettled-bets')" :class="router.currentRoute.value.name == 'history-unsettled-bets' ? 'active' : ''">
								<p>{{ $t('general.unsettled_bets') }}</p>
							</div>
							<div class="inner-mobile_tab-item" @click="goToRoute('history-slots')" :class="router.currentRoute.value.name == 'history-slots' ? 'active' : ''">
								<p>{{ $t('general.slots') }}</p>
							</div>
							<div class="inner-mobile_tab-item" @click="goToRoute('history-live-casino')" :class="router.currentRoute.value.name == 'history-live-casino' ? 'active' : ''">
								<p>{{ $t('general.live_casino') }}</p>
							</div>
							<div class="inner-mobile_tab-item" @click="goToRoute('history-deposits')" :class="router.currentRoute.value.name == 'history-deposits' ? 'active' : ''">
								<p>{{ $t('general.deposits') }}</p>
							</div>
							<div class="inner-mobile_tab-item" @click="goToRoute('history-withdrawals')" :class="router.currentRoute.value.name == 'history-withdrawals' ? 'active' : ''">
								<p>{{ $t('general.withdrawals') }}</p>
							</div>
						</div>
					</div>
				<!--mobile dashboard top bar end  -->


					<!-- <div class="dash-mobile-title">
						<svg id="Group_921" data-name="Group 921" xmlns="http://www.w3.org/2000/svg" width="18.102"
							height="18.102" viewBox="0 0 18.102 18.102">
							<g id="bank">
								<path id="Path_385" data-name="Path 385"
									d="M19.941,9.332H2.452a.253.253,0,0,1-.259-.247v-1.8a.245.245,0,0,1,.125-.211l8.743-5.037a.269.269,0,0,1,.267,0l8.747,5.037a.245.245,0,0,1,.125.211v1.8A.253.253,0,0,1,19.941,9.332ZM2.711,8.838H19.683V7.424L11.195,2.535,2.711,7.423Z"
									transform="translate(-2.145 -2)" fill="#63a246" />
								<path id="Path_386" data-name="Path 386"
									d="M32.5,15.3a1.476,1.476,0,1,1,1.476-1.476A1.476,1.476,0,0,1,32.5,15.3Zm0-2.459a.983.983,0,1,0,.983.983A.983.983,0,0,0,32.5,12.844Z"
									transform="translate(-23.444 -9.796)" fill="#63a246" />
								<path id="Path_387" data-name="Path 387"
									d="M55.331,31.753H52.7a.534.534,0,0,1-.533-.533V29.947a.247.247,0,0,1,.247-.247h3.2a.247.247,0,0,1,.247.247V31.22A.534.534,0,0,1,55.331,31.753Zm-2.666-1.56V31.22a.04.04,0,0,0,.039.04h2.626a.04.04,0,0,0,.039-.04V30.194Z"
									transform="translate(-38.963 -22.493)" fill="#63a246" />
								<path id="Path_388" data-name="Path 388"
									d="M56.664,41.5H54.485a.247.247,0,0,1-.247-.247V36.263a.247.247,0,0,1,.247-.247h2.179a.247.247,0,0,1,.247.247v4.986A.247.247,0,0,1,56.664,41.5ZM54.732,41h1.685V36.51H54.732Z"
									transform="translate(-40.52 -27.034)" fill="#63a246" />
								<path id="Path_389" data-name="Path 389"
									d="M55.617,58.27h-3.2a.247.247,0,0,1-.247-.247V56.749a.534.534,0,0,1,.533-.533h2.627a.534.534,0,0,1,.533.533v1.273A.247.247,0,0,1,55.617,58.27Zm-2.953-.494H55.37V56.749a.04.04,0,0,0-.039-.04H52.7a.04.04,0,0,0-.039.04Z"
									transform="translate(-38.963 -42.01)" fill="#63a246" />
								<path id="Path_390" data-name="Path 390"
									d="M32.68,31.753H30.053a.534.534,0,0,1-.533-.533V29.947a.247.247,0,0,1,.247-.247h3.2a.247.247,0,0,1,.247.247V31.22A.534.534,0,0,1,32.68,31.753Zm-2.666-1.559V31.22a.04.04,0,0,0,.039.04H32.68a.04.04,0,0,0,.039-.04V30.194Z"
									transform="translate(-22.315 -22.493)" fill="#63a246" />
								<path id="Path_391" data-name="Path 391"
									d="M34.019,41.5H31.847a.247.247,0,0,1-.247-.247V36.263a.247.247,0,0,1,.247-.247h2.172a.247.247,0,0,1,.247.247v4.986A.247.247,0,0,1,34.019,41.5ZM32.094,41h1.679V36.51H32.094Z"
									transform="translate(-23.882 -27.034)" fill="#63a246" />
								<path id="Path_392" data-name="Path 392"
									d="M32.966,58.27h-3.2a.247.247,0,0,1-.247-.247V56.749a.534.534,0,0,1,.533-.533H32.68a.534.534,0,0,1,.533.533v1.273A.247.247,0,0,1,32.966,58.27Zm-2.953-.494h2.706V56.749a.04.04,0,0,0-.04-.04H30.053a.04.04,0,0,0-.039.04Z"
									transform="translate(-22.315 -42.01)" fill="#63a246" />
								<path id="Path_393" data-name="Path 393"
									d="M11.028,31.753H8.4a.534.534,0,0,1-.533-.533V29.947a.247.247,0,0,1,.247-.247h3.2a.247.247,0,0,1,.247.247V31.22A.534.534,0,0,1,11.028,31.753ZM8.362,30.194V31.22a.04.04,0,0,0,.039.04h2.627a.04.04,0,0,0,.039-.04V30.194Z"
									transform="translate(-6.419 -22.493)" fill="#63a246" />
								<path id="Path_394" data-name="Path 394"
									d="M12.344,41.5h-2.2a.247.247,0,0,1-.247-.247V36.263a.247.247,0,0,1,.247-.247h2.2a.247.247,0,0,1,.247.247v4.986A.247.247,0,0,1,12.344,41.5ZM10.394,41h1.7V36.51h-1.7Z"
									transform="translate(-7.95 -27.034)" fill="#63a246" />
								<path id="Path_395" data-name="Path 395"
									d="M11.314,58.27h-3.2a.247.247,0,0,1-.247-.247V56.749a.534.534,0,0,1,.533-.533h2.627a.534.534,0,0,1,.533.533v1.273A.247.247,0,0,1,11.314,58.27Zm-2.953-.494h2.706V56.749a.04.04,0,0,0-.039-.04H8.4a.04.04,0,0,0-.039.04Z"
									transform="translate(-6.419 -42.01)" fill="#63a246" />
								<path id="Path_396" data-name="Path 396"
									d="M19.844,64.872H2.259A.253.253,0,0,1,2,64.625V62.783a.253.253,0,0,1,.259-.247H19.844a.253.253,0,0,1,.259.247v1.842A.253.253,0,0,1,19.844,64.872ZM2.517,64.378H19.585V63.03H2.517Z"
									transform="translate(-2 -46.77)" fill="#63a246" />
							</g>
						</svg>

						<h3>Settled Bets</h3>
					</div> -->


					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-left-sidebar .left-sidebar-dash .dash-inner-tabs {
	right: unset;
	left: -200px !important;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .accordion-header .acoordion-icon {
	position: absolute;
	right: unset;
	top: 35%;
	transform: translateY(-50%);
	left: 0;
}
.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer {
	border-radius:0px 0px 5px 5px;
}
.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer {
	padding: 27px 45px 98px 45px;
	overflow-x: hidden;
	height: 100%;
	min-height: 100vh;
	max-height: 200vh;
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	.dashboard-main .dashboard-outer .dashboard-left-sidebar {
		padding: 42px 20px 0;
		position: relative;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;
		padding-bottom: 50px;
	}
	.dashboard-main .dashboard-outer .dashboard-left-sidebar:after {
		content: "";
		width: 1px;
		height: 100%;
		background: #4F545C;
		position: absolute;
		right: 0;
		top: 0;
	}
	.dashboard-main .dashboard-outer .dashboard-tab-content .tab-content-outer .dash-inner-tabs:after {
		content: "";
		width: 1px;
		height: 100%!important;
		background: #4F545C;
		position: absolute;
		right: 0;
		top: 0;
	}
}
.close-btn{
	margin-left: auto;
	padding-top: 3px;
}
html[dir="rtl"] .close-btn{
	margin-right: auto;
	margin-left:unset;
}

.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .accordion-header button .notification {
	width: 36px!important;
}

.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .notification {
	margin-left: -3px !important;
}

.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .accordion-header button .notification_span {
	margin-left: -8px !important;

}

</style>

