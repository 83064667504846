<script setup>
/* eslint-disable */
import { ref, onMounted, onUnmounted, watch, nextTick, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSocketStore } from '@/stores/socket';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useI18n } from 'vue-i18n';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import _ from 'lodash';

const router = useRouter();
const socketStore = useSocketStore();
const liveSportsStore = useLiveSportsStore();
const { t } = useI18n();
const direction = ref(document.dir);
const splideRef = ref(null);
const options = ref({
	direction: direction.value,
	pagination: false,
	// arrows: false,
	// focus: 'center',
	autoWidth: true,
	// gap: 20,
	trimSpace: 'move'
});

socketStore.getSocket.on('liveSports', (data) => {
	liveSportsStore.setLiveSports(data);

	if (router.currentRoute.value.name != 'live-sports-overview') return;

	// Get first and set it as selected
	let first_sport = liveSportsStore.liveSports[0];
	liveSportsStore.setSelectedLiveSportId(first_sport.id);
})

socketStore.getSocket.on('liveSportsUpdate', (data) => {
	liveSportsStore.updateLiveSports(data);
})

const changeLiveSport = (live_sport_id) => {
	if (live_sport_id == 0) {
		selectFavourites();
		return;
	}

	window.scrollTo({ top: 0 });
	liveSportsStore.selectFavourites(false);
	liveSportsStore.setSelectedLiveSportId(live_sport_id);
}

const selectFavourites = () => {
	window.scrollTo({ top: 0 });
	changeLiveSport(null);
	liveSportsStore.selectFavourites(true);
}

const checkArrowsVisibility = () => {
	if (splideRef.value && splideRef.value.splide) {
		const slider = splideRef.value.splide;
		let totalWidth = 0;
		slider.Components.Elements.slides.forEach(slide => {
			const marginRight = parseFloat(window.getComputedStyle(slide).marginRight);
			totalWidth += slide.offsetWidth + marginRight;
		});

		const sliderWidth = slider.Components.Elements.track.clientWidth;

		// Update arrow visibility
		slider.options = {
			...slider.options,
			arrows: totalWidth > sliderWidth
		};

		slider.refresh();
	}
};

watch(() => liveSportsStore.liveSports, (newValue, oldValue) => {
	if (newValue.length > 0) {
		nextTick().then(() => {
			checkArrowsVisibility();
		});
	}
});

onMounted(() => {
	// Join to liveSports socket channel
	socketStore.getSocket.emit('join-liveSports');
	window.addEventListener('resize', checkArrowsVisibility);
});


onUnmounted( () => {
	socketStore.getSocket.emit('leave-liveSports');
	liveSportsStore.emptySelectedLiveSportId();
	window.removeEventListener('resize', checkArrowsVisibility);
});

const liveSports = computed(() => {
	let live_sports = _.cloneDeep(liveSportsStore.liveSports);
	if (router.currentRoute.value.name == 'live-sports-overview') {
		if (liveSportsStore.hasLiveFavouriteFixtures) {
			let favourite = { id: 0, name: t('general.favourites')}
			live_sports.unshift(favourite);
		}
	}

	return live_sports;
});

const isSportSelected = (sport_id) => {
	if (sport_id > 0 && sport_id == liveSportsStore.selected_live_sport_id) return true;
	if (sport_id == 0 && liveSportsStore.favouritesSelected) return true;

	return false
};

</script>

<template>
<div class="wrapper">
	<div id="game_slides" class="league_slides-inner">
		<!-- Slider start -->
		<Splide :options="options">
			<SplideSlide v-for="(sport, index) in liveSports" :key="index">
				<a @click="changeLiveSport(sport.id)" class="league-slide-item carousel__item" :class="isSportSelected(sport.id) ? 'active' : ''">
					<div class="coupon-slider-item">
						<div class="coupon-slider-content">
							<img v-if="sport.id > 0" :src="liveSportsStore.liveSportWhiteIcon(sport.id)" alt="">
							<img v-else src="/assets/white_sport_icons/Favorite Star.svg" alt="">
							<p>{{ sport.name }}</p>
						</div>
					</div>
				</a>
			</SplideSlide>
		</Splide>
		<!-- slider end -->
	</div>
</div>
</template>
<style scoped>
.league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow:disabled) {
	display: none !important;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
	rotate: 180deg;
	right: -10px;
	top: -3px;
	display: flex;
	left: unset;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
	rotate: 180deg;
	left: -10px;
	top: -3px;
	display: flex;
	right: unset;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px
	}
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px
	}
}
</style>