<script setup>
import { openModal } from '@kolirt/vue-modal';
import Register from '@/components/modals/Register.vue';
import Login from '@/components/modals/Login.vue';
import _ from 'lodash';
import TableImage from '@/components/live_casino/table/TableImage.vue';
import TableImageLiveSlotegrator from '@/components/live_casino/table/TableImageLiveSlotegrator.vue';
import TableImageLiveLuckyStreak from '@/components/live_casino/table/TableImageLiveLuckyStreak.vue';
import { useLiveCasinoStore } from '@/stores/live_casino';
import { ref } from 'vue';

const liveCasinoStore = useLiveCasinoStore();

const props = defineProps({
	title: {
		type: String,
		required: true,
	},
	tables: {
		type: Object,
		required: true,
	},
	live_tables: {
		type: Object,
		required: true,
	},
	type: {
		type: String,
		required: true,
	}
});

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	})
};

// Get Live Tables
const getLobbies = (category) => {
	if (category == undefined) return {};

	// Convert the string to lowercase
	let category_slug = category.toLowerCase();

	// Replace all spaces with underscores
	category_slug = category_slug.replace(/\s+/g, '_');

	let live_tables = _.filter(props.live_tables, (table) => {
		return table.category == category_slug;
	});

	return live_tables;
}

let show_more_loading = ref({});

// Toggle Show More
const showMoreToggle = (category_name) => {
	show_more_loading.value[category_name] = true;
	// Get all category_name's games length
	var all_tables_count = liveCasinoStore.getTables[category_name].length;

	// Toggle show more
	liveCasinoStore.showMoreToggle(category_name);

	// If category_name is expanded or all category_name's games are 13 or less games do not scroll to category_name's top
	if (liveCasinoStore.getShowMoreCategory.includes(category_name) || all_tables_count <= 10) {
		setTimeout(() => {
			show_more_loading.value[category_name] = false;
		}, 1000);

		return;
	}

	// Get element
	const provider_element = document.getElementById('category_' + category_name);

	// Get the element's position relative to the viewport
	const rect = provider_element.getBoundingClientRect();

	// Calculate the new scroll position with a 10-pixel offset at the top
	const scroll_top_offset = window.scrollY + rect.top - 30;

	// Scroll to the new position smoothly
	window.scrollTo({
		top: scroll_top_offset,
		behavior: "smooth"
	});

	setTimeout(() => {
		show_more_loading.value[category_name] = false;
	}, 1000);
};

const getTotalTablesPerCategory = (category) => {
	let category_id = null;
	if (category.length > 0 && category[0] != null) {
		category_id = category[0].live_casino_category_id;
	} else {
		// Return 11 as fallback to show the "More" button
		return 11;
	}

	return liveCasinoStore.getTotalTablesPerCategory[category_id];
};

const loadMoreVisibilityChanged = (is_visible) => {
	if (is_visible) {
		liveCasinoStore.loadMoreTables();
	}
};

</script>
<template>
	<div class="casino-game-section live_casino-game-section">
		<div class="container-lg casino-container-lg">
			<div v-if="Object.keys(props.tables).length == 0" class="casino-feature-games">
				<div class="title text-center mt-4 mb-10px">
					<h3>{{ $t('general.no_game_filter_result') }}</h3>
				</div>
			</div>

			<div :id="'category_' + category_name" v-else v-for="category, category_name in props.tables" :key="category_name" class="casino-feature-games">
				<div>
					<div class="title">
						<h3>{{ category_name }}</h3>
					</div>
					<div class="casino-feature-outer">
						<!-- live feed-grid start-->
						<div class="feature-games-main">
							<template v-for="live_tables in getLobbies(category_name)" :key="live_tables" >
								<template v-if="live_tables.aggregator_slug == 'slotegrator'">
									<TableImageLiveSlotegrator :tables="live_tables" size="medium"/>
								</template>
							</template>

							<div v-for="table in category" :key="table" class="featured-game-inner">
								<template v-if="table != null">
									<div class="feature-image">
										<template v-if="table.game_aggregator_slug == 'lucky_streak_aggregator'">
											<TableImageLiveLuckyStreak :table="table" size="medium"/>
										</template>
										<template v-else>
											<TableImage :table="table" size="medium"/>
										</template>

									</div>
									<div class="feature-title">
										<h4 class="ellipsis" :alt="table['name']">{{ table['name'] }}</h4>
									</div>
								</template>
							</div>

						</div>
						<!-- live feed-grid end-->
						<!-- Show More/Less Button Start -->
						<div class="view-all-btn" :class="getTotalTablesPerCategory(category) <= 10 ? 'view-all-btn-full-width' : ''">
							<button v-if="getTotalTablesPerCategory(category) > 10" @click="showMoreToggle(category_name)" class="btn-small view-all-button">
								<div v-if="show_more_loading[category_name] == true" class="loader-main"></div>
								<span v-else>{{ liveCasinoStore.getShowMoreCategory.includes(category_name) ?	$t('general.less') : $t('general.more') }}</span>
							</button>
						</div>
						<!-- Show More/Less Button End -->
					</div>
				</div>

			</div>
		</div>
		<div v-observe-visibility="loadMoreVisibilityChanged" style="height: 20px;"></div>
	</div>
</template>
<style scoped>
.text-center {
	text-align: center;
}

.mt-4 {
	margin-top: 4rem;
}

.mb-10px {
	margin-bottom: 10px;
}

@media screen and (min-width: 600px) and (max-width: 767px) {
	.feature-games-main {
		grid-template-columns: repeat(4, 1fr) !important;
	}
}

.casino-game-section.live_casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main .featured-game-inner .feature-image img {
	-o-object-fit: inherit;
	object-fit: inherit;
}

.casino-game-section.live_casino-game-section .casino-feature-games .casino-feature-outer {
	border-bottom: unset;
}

.view-all-btn-full-width:before {
	width: 100% !important;
}

.text-center {
	text-align: center;
}

.mt-4 {
	margin-top: 4rem;
}

.mb-10px {
	margin-bottom: 10px;
}

@media screen and (min-width: 600px) and (max-width: 767px) {
	.feature-games-main {
		grid-template-columns: repeat(4, 1fr) !important;
	}
}

.online-indicator::after {
	content: '';
	width: 12px;
	height: 12px;
	background-color: #00FF00;
	border-radius: 50%;
	position: absolute;
	top: 8px;
	right: 8px;
	border: 2px solid #131C27;
}

.offline-indicator::after {
	content: '';
	width: 12px;
	height: 12px;
	background-color: red;
	border-radius: 50%;
	position: absolute;
	top: 8px;
	right: 8px;
	border: 2px solid #131C27;
}

.game-limits {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	padding: 5px 10px;
	border-radius: 0 0 5px 5px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
}

.table-schedule {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	padding: 5px 10px;
	border-radius: 0 0 5px 5px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
}

.table-schedule-label {
	display: block;
	margin-top: 20%;
}

.table-schedule-time {
	display: block;
}

.feature-title {
	overflow: hidden;
}

.featured-game-inner {
	width: 100%;
}

.feature-title {
	width: 100%;
}

/* .feature-title h4 {
	min-width: -webkit-fill-available !important;
} */

@media screen and (min-width: 320px) and (max-width: 320px) {
	.feature-title h4 {
		width: 125px !important;
	}
}

@media screen and (min-width: 375px) and (max-width: 500px) {
	.feature-title h4 {
		width: 132px !important;
	}
}

@media screen and (min-width: 501px) and (max-width: 630px) {
	.feature-title h4 {
		width: 180px !important;
	}
}

@media screen and (min-width: 631px) and (max-width: 767px) {
	.feature-title h4 {
		width: 125px !important;
	}
}

@media screen and (min-width: 772px) and (max-width: 1100px) {
	.feature-title h4 {
		width: 255px !important;
	}
}

@media screen and (min-width: 1100px) and (max-width: 1190px) {
	.feature-title h4 {
		width: 215px !important;
	}
}

@media screen and (min-width: 1190px) and (max-width: 1499px) {
	.feature-title h4 {
		width: 215px !important;
	}
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
	.feature-title h4 {
		width: 185px !important;
	}
}

@media screen and (min-width: 1600px) and (max-width: 1700px) {
	.feature-title h4 {
		width: 254px !important;
	}
}

@media screen and (min-width: 1701px) {
	.feature-title h4 {
		width: 254px !important;
	}
}

.casino-game-section.live_casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main .featured-game-inner .feature-image img {
	-o-object-fit: inherit;
	object-fit: inherit;
}

.casino-game-section.live_casino-game-section .casino-feature-games .casino-feature-outer {
	border-bottom: unset;
}

.view-all-btn-full-width:before {
	width: 100% !important;
}
</style>