<script setup>
/* eslint-disable */
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useInitializeStore } from '@/stores/initialize';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

const router = useRouter();
const preSportsStore = usePreSportsStore();
const initializeStore = useInitializeStore();

const direction = ref(document.dir);
const splideRef = ref(null);
const options = ref({
	direction: direction.value,
	pagination: false,
	autoWidth: true,
	trimSpace: 'move'
});

const selectSport = (sport_id) => {
	if (sport_id === 'all') {
		preSportsStore.setSelectedEsportId(null);
		preSportsStore.selectedPreSportName = 'All';  // Set default name for 'All'
		preSportsStore.selectedPreSportIcon = '/path/to/all-icon.svg';  // Set default icon for 'All'
	} else {
		preSportsStore.setSelectedEsportId(sport_id);
		preSportsStore.setSelectedPreSportId(sport_id);  // This will set selected name and icon
	}
	
	if (!initializeStore.isMobile) {
		if (sport_id === 'all') {
			router.push({ name: 'esports-all-upcoming', params: { sport_id }});
		} else {
			router.push({ name: 'esports-upcoming', params: { sport_id }});
		}
	} else {
		router.push({ name: 'esports-live', params: { sport_id }});
	}
};

const checkArrowsVisibility = () => {
	if (splideRef.value && splideRef.value.splide) {
		const slider = splideRef.value.splide;
		let totalWidth = 0;
		slider.Components.Elements.slides.forEach(slide => {
			const marginRight = parseFloat(window.getComputedStyle(slide).marginRight);
			totalWidth += slide.offsetWidth + marginRight;
		});

		const sliderWidth = slider.Components.Elements.track.clientWidth;

		// Update arrow visibility
		slider.options = {
			...slider.options,
			arrows: totalWidth > sliderWidth
		};

		slider.refresh();
	}
};

watch(() => preSportsStore.esports, (newValue) => {
	if (newValue.length > 0) {
		nextTick().then(() => {
			checkArrowsVisibility();
		});
	}
});

onMounted(() => {
	// Set initial selected sport details on mount if no sport is selected yet
	if (!preSportsStore.selectedPreSportId && preSportsStore.esports.length > 0) {
		const firstSport = preSportsStore.esports[0];
		preSportsStore.setSelectedEsportId(firstSport.id);
		preSportsStore.setSelectedPreSportId(firstSport.id);
		preSportsStore.selectedPreSportName = firstSport.name;
		preSportsStore.selectedPreSportIcon = preSportsStore.preSportWhiteIcon(firstSport.id);
	}
	
	window.addEventListener('resize', checkArrowsVisibility);
});

onUnmounted(() => {
	window.removeEventListener('resize', checkArrowsVisibility);
});
</script>

<template>
<div class="wrapper">
	<div id="game_slides" class="league_slides-inner">
		<!-- Slider start -->
		<Splide ref="splideRef" :options="options">
	<!-- "All" option as a separate slide -->
	<!-- <SplideSlide>
		<a @click="selectSport('all')" class="league-slide-item carousel__item" :class="{ active: preSportsStore.selectedEsportId === 'all' }">
			<div class="coupon-slider-item">
				<div class="coupon-slider-content">
					<p>All</p>
				</div>
			</div>
		</a>
	</SplideSlide> -->

	<!-- Sports items -->
	<SplideSlide v-for="(sport, index) in preSportsStore.esports" :key="index">
		<a 
			@click="selectSport(sport.id)" 
			class="league-slide-item carousel__item" 
			:class="{ active: sport.id === preSportsStore.selectedEsportId }"
		>
			<div class="coupon-slider-item">
				<div class="coupon-slider-content">
					<img :src="preSportsStore.preSportWhiteIcon(sport.id)" alt="">
					<p>{{ sport.name }}</p>
				</div>
			</div>
		</a>
	</SplideSlide>
</Splide>

		<!-- slider end -->
	</div>
</div>
</template>
<style scoped>
.league-slider-main .wrapper .league_slides-inner :deep(.splide__arrow:disabled) {
	display: none !important;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
	rotate: 180deg;
	right: -10px;
	top: -3px;
	display: flex;
	left: unset;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
	rotate: 180deg;
	left: -10px;
	top: -3px;
	display: flex;
	right: unset;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px
	}
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px
	}
}
</style>