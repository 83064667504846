<!-- eslint-disable -->
<script setup>
import { ref, onMounted, watch } from 'vue';
import { useFixtureStore } from '@/stores/fixture';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useRouter } from 'vue-router';
import commonSvgs from '@/assets/svgs/commonSvgs.json';

const router = useRouter();
const fixtureStore = useFixtureStore();
const liveSportsStore = useLiveSportsStore();

const svg_data = ref(commonSvgs);
const current_set = ref('S1');
const selected_set = ref(1);
const user_selected = ref(null);

const selectSet = (set) => {
	user_selected.value = set;
	selected_set.value = null;
}

const getPerSet = (array) => {
	let sum = array.reduce((a, b) => a + b, 0);
	return sum;
}

const goToCompetition = (fixture) => {
	router.push({ name: 'pre-sports-competition', params: { sport_id: fixture.sport_id, region_id: Number(fixture.region_id), competition_id: fixture.competition_id } } )
}

watch(() => fixtureStore.fixtureBook.scoreboard, (newFixture, oldFixture) => {
	if (newFixture.set_1 && newFixture.set_1.length !== oldFixture.set_1.length) {
		current_set.value = 'S1';
		if (user_selected.value == null) selected_set.value = 1;
		if (current_set.value == 'S1' && user_selected.value == 1) {
			selected_set.value = 1;
			user_selected.value = null;
		}
	}
})

watch(() => fixtureStore.fixtureBook.scoreboard, (newFixture, oldFixture) => {
	if (newFixture.set_2 && newFixture.set_2.length !== oldFixture.set_2.length) {
		current_set.value = 'S2';
		if (user_selected.value == null) selected_set.value = 2;
		if (current_set.value == 'S2' && user_selected.value == 2) {
			selected_set.value = 2;
			user_selected.value = null;
		}
	}
})

watch(() => fixtureStore.fixtureBook.scoreboard, (newFixture, oldFixture) => {
	if (newFixture.set_3 && newFixture.set_3.length !== oldFixture.set_3.length) {
		current_set.value = 'S3';
		if (user_selected.value == null) selected_set.value = 3;
		if (current_set.value == 'S3' && user_selected.value == 3) {
			selected_set.value = 3;
			user_selected.value = null;
		}
	}
})

watch(() => fixtureStore.fixtureBook.scoreboard, (newFixture, oldFixture) => {
	if (newFixture.set_4 && newFixture.set_4.length !== oldFixture.set_4.length) {
		current_set.value = 'S4';
		if (user_selected.value == null) selected_set.value = 4;
		if (current_set.value == 'S4' && user_selected.value == 4) {
			selected_set.value = 4;
			user_selected.value = null;
		}
	}
})

watch(() => fixtureStore.fixtureBook.scoreboard, (newFixture, oldFixture) => {
	if (newFixture.set_5 && newFixture.set_5.length !== oldFixture.set_5.length) {
		current_set.value = 'S5';
		if (user_selected.value == null) selected_set.value = 5;
		if (current_set.value == 'S5' && user_selected.value == 5) {
			selected_set.value = 5;
			user_selected.value = null;
		}
	}
})

watch(() => fixtureStore.fixtureBook.scoreboard, (newFixture, oldFixture) => {
	if (newFixture.set_6 && newFixture.set_6.length !== oldFixture.set_6.length) {
		current_set.value = 'S6';
		if (user_selected.value == null) selected_set.value = 6;
		if (current_set.value == 'S6' && user_selected.value == 6) {
			selected_set.value = 6;
			user_selected.value = null;
		}
	}
})

onMounted(() => {
	if (fixtureStore.fixtureBook.scoreboard.set_6 && fixtureStore.fixtureBook.scoreboard.set_6 != null && fixtureStore.fixtureBook.scoreboard.set_6.length > 0) {
		selected_set.value = 6;
		current_set.value = 'S6';
	}else if (fixtureStore.fixtureBook.scoreboard.set_5 && fixtureStore.fixtureBook.scoreboard.set_5 != null && fixtureStore.fixtureBook.scoreboard.set_5.length > 0) {
		selected_set.value = 5;
		current_set.value = 'S5';
	}else if (fixtureStore.fixtureBook.scoreboard.set_4 && fixtureStore.fixtureBook.scoreboard.set_4 != null && fixtureStore.fixtureBook.scoreboard.set_4.length > 0) {
		selected_set.value = 4;
		current_set.value = 'S4';
	}else if (fixtureStore.fixtureBook.scoreboard.set_3 && fixtureStore.fixtureBook.scoreboard.set_3 != null && fixtureStore.fixtureBook.scoreboard.set_3.length > 0) {
		selected_set.value = 3;
		current_set.value = 'S3';
	} else if (fixtureStore.fixtureBook.scoreboard.set_2 && fixtureStore.fixtureBook.scoreboard.set_2 != null && fixtureStore.fixtureBook.scoreboard.set_2.length > 0) {
		selected_set.value = 2;
		current_set.value = 'S2';
	}
})
</script>

<template>
	<!-- archery shooting start -->
	<div class="eventview-game-banner eventview-set_game-banner eventview_shooting_game_banner">
		<div class="eventview-banner-image">
			<img :src="'/assets/scoreboards/' + liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) + '.png'" alt="">
		</div>
		<div class="live-game-outer">
			<div class="live_score_Box">
				<div class="league-title">
					<img :src="liveSportsStore.liveSportWhiteIcon(fixtureStore.fixtureBook.sport_id)" alt="">
					<h6 @click="goToCompetition(fixtureStore.fixtureBook)">{{ fixtureStore.fixtureBook.region_name + ' - ' + fixtureStore.fixtureBook.competition_name }}</h6>
				</div>
				<!-- score panel start-->
				<div class="league-score">
					<div class="set-top-info">
						<div class="live-badge" v-html="svg_data.live_badge"></div>
						<div class="playing-time" v-if="liveSportsStore.hasFixtureClock(fixtureStore.fixtureBook.sport_id)">
							<p>
								{{ fixtureStore.fixtureClockTime(fixtureStore.fixtureBook) }}
								<abbr>{{ fixtureStore.fixtureState(fixtureStore.fixtureBook) }}</abbr>
							</p>
							<span></span>
						</div>
						<div class="playing-time" v-else>
							<p> {{ current_set }}</p>
							<span></span>
						</div>
					</div>

					<div class="eventview-playing-info">
						<div class="playing-team-inner">
							<div class="playing-team">
								<h6>{{$t('scoreboard.total')}}:</h6>
							</div>
							<div class="playing-score">
								<h3>{{fixtureStore.fixtureBook.scoreboard.total}}</h3>
							</div>
						</div>
					</div>
					<div class="scorecard-outer">
						<div class="scorecard-inner">
							<div class="scorecard-left">
								<div class="score-title"></div>
								<div class="score-title">
									<p>{{$t('scoreboard.per_set')}}</p>
								</div>
								<div class="score-title">
									<p>{{$t('scoreboard.per_shot')}}</p>
								</div>
							</div>
							<div class="scorecard-right">
								<div class="score-right-top">
									<div class="score-tab-inner" :class="selected_set == '1' || user_selected == '1'? 'active' :''">
										<div class="score-tab-main"  @click="selectSet(1)" style="cursor: pointer;">
											<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
												<p>{{$t('scoreboard.set')}} 1</p>
													<template #content>
														<div class="tooltip"> {{ $t('scoreboard.set') }} 1 </div>
													</template>
											</Popper>
										</div>
										<div class="score-tab-main">
											<p>{{ getPerSet(fixtureStore.fixtureBook.scoreboard['set_1']) }}</p>
										</div>
									</div>
									<div class="score-tab-inner" :class="selected_set == '2' || user_selected == '2'? 'active' :''">
										<div class="score-tab-main" @click="selectSet(2)" style="cursor: pointer;">
											<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
												<p>{{$t('scoreboard.set')}} 2</p>
													<template #content>
														<div class="tooltip"> {{ $t('scoreboard.set') }} 2 </div>
													</template>
											</Popper>
										</div>
										<div class="score-tab-main">
											<p>{{ getPerSet(fixtureStore.fixtureBook.scoreboard['set_2']) }}</p>
										</div>
									</div>
									<div class="score-tab-inner" :class="selected_set == '3' || user_selected == '3' ? 'active' :''">
										<div class="score-tab-main" @click="selectSet(3)" style="cursor: pointer;">
											<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
												<p>{{$t('scoreboard.set')}} 3</p>
													<template #content>
														<div class="tooltip"> {{ $t('scoreboard.set') }} 3 </div>
													</template>
											</Popper>
										</div>
										<div class="score-tab-main">
											<p>{{ getPerSet(fixtureStore.fixtureBook.scoreboard['set_3']) }}</p>
										</div>
									</div>
									<div class="score-tab-inner" :class="selected_set == '4' || user_selected == '4' ? 'active' :''">
										<div class="score-tab-main" @click="selectSet(4)" style="cursor: pointer;">
											<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
												<p>{{$t('scoreboard.set')}} 4</p>
													<template #content>
														<div class="tooltip"> {{ $t('scoreboard.set') }} 4 </div>
													</template>
											</Popper>
										</div>
										<div class="score-tab-main">
											<p>{{ getPerSet(fixtureStore.fixtureBook.scoreboard['set_4']) }}</p>
										</div>
									</div>
									<div class="score-tab-inner" :class="selected_set == '5' || user_selected == '5' ? 'active' :''">
										<div class="score-tab-main" @click="selectSet(5)" style="cursor: pointer;">
											<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
												<p>{{$t('scoreboard.set')}} 5</p>
													<template #content>
														<div class="tooltip"> {{ $t('scoreboard.set') }} 5 </div>
													</template>
											</Popper>
										</div>
										<div class="score-tab-main">
											<p>{{ getPerSet(fixtureStore.fixtureBook.scoreboard['set_5']) }}</p>
										</div>
									</div>
									<div class="score-tab-inner" :class="selected_set == '6' || user_selected == '6'? 'active' :''">
										<div class="score-tab-main" @click="selectSet(6)" style="cursor: pointer;">
											<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
												<p>{{$t('scoreboard.set')}} 6</p>
													<template #content>
														<div class="tooltip"> {{ $t('scoreboard.set') }} 6 </div>
													</template>
											</Popper>
										</div>
										<div class="score-tab-main">
											<p>{{ getPerSet(fixtureStore.fixtureBook.scoreboard['set_6']) }}</p>
										</div>
									</div>
								</div>
								<div class="score-right-bottom">
									<div class="score-per-shot" v-show="selected_set === 1 || user_selected === 1" v-for="(score,index) in fixtureStore.fixtureBook.scoreboard.set_1" :key="index">
										<p :class="{ 'active': selected_set === 1 || user_selected === 1 }">{{score}}</p>
									</div>
									<div class="score-per-shot" v-show="selected_set === 2 || user_selected === 2" v-for="(score,index) in fixtureStore.fixtureBook.scoreboard.set_2" :key="index">
										<p :class="{ 'active': selected_set === 2 || user_selected === 2 }">{{score}}</p>
									</div>
									<div class="score-per-shot" v-show="selected_set === 3 || user_selected === 3" v-for="(score,index) in fixtureStore.fixtureBook.scoreboard.set_3" :key="index">
										<p :class="{ 'active': selected_set === 3 || user_selected === 3 }">{{score}}</p>
									</div>
									<div class="score-per-shot" v-show="selected_set === 4 || user_selected === 4" v-for="(score,index) in fixtureStore.fixtureBook.scoreboard.set_4" :key="index">
										<p :class="{ 'active': selected_set === 4 || user_selected === 4 }">{{score}}</p>
									</div>
									<div class="score-per-shot" v-show="selected_set === 5 || user_selected === 5" v-for="(score,index) in fixtureStore.fixtureBook.scoreboard.set_5" :key="index">
										<p :class="{ 'active': selected_set === 5 || user_selected === 5 }">{{score}}</p>
									</div>
									<div class="score-per-shot" v-show="selected_set === 6 || user_selected === 6" v-for="(score,index) in fixtureStore.fixtureBook.scoreboard.set_6" :key="index">
										<p :class="{ 'active': selected_set === 6 || user_selected === 6 }">{{score}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> <!-- score panel end-->
			</div>
		</div>
	</div>
	<!-- archery shooting end -->
</template>

<style scoped>
.active {
	color: #63A246 !important;
}
.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview_shooting_game_banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner .scorecard-right .score-right-top .score-tab-inner.active .score-tab-main {
	border-bottom: 1px solid #777 !important;
}
.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview_shooting_game_banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner .scorecard-right .score-right-bottom {
	display: flex;
	justify-content: flex-start;
}
html[dir="rtl"] .sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview_shooting_game_banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner .scorecard-left .score-title p {
	font-size: 12px;
	font-weight: 100;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-right: 28px;
	text-transform: capitalize;
}
.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview_shooting_game_banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner .scorecard-right .score-right-bottom {
	display: flex;
	justify-content: flex-end;
}
.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview_shooting_game_banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner .scorecard-right .score-right-bottom .score-per-shot {
	min-width: 45.2px;
}

.tooltip {
	width: 50px;
	background-color: #63A246;
	padding: 5px 0;
	text-align: center;
	border: 1px solid #63A246;
	border-radius: 50px !important;
	font-size: 10px;
	color: #ffffff;
}
:deep(.popper) {
	background-color: #63A246;
	padding: 20px;
	border-radius: 20px;
	font-weight: bold;
	margin-right: 30px;
}

:deep(.popper #arrow::before) {
	background: #63A246;
	left: 0px;
}
:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
	background: #63A246;
}
</style>