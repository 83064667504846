<script setup>
/* eslint-disable */
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useInitializeStore } from '@/stores/initialize';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

const router = useRouter();
const preSportsStore = usePreSportsStore();
const initializeStore = useInitializeStore();

const direction = ref(document.dir);
const splideRef = ref(null);
const options = ref({
	direction: direction.value,
	pagination: false,
	// arrows: false,
	// focus: 'center',
	autoWidth: true,
	// gap: 20,
	trimSpace: 'move'
});

const selectSport = (sport_id) => {
	window.scrollTo({ top: 0 });

	if (router.currentRoute.value.name == 'pre-sports-coupons-competitions')  {
		router.push({name: 'pre-sports-coupons', params: {sport_id: sport_id}})
	} else if (router.currentRoute.value.name == 'pre-sports-competition') {
		router.push({name: 'pre-sports-coupons', params: {sport_id: sport_id}})
	} else if (router.currentRoute.value.name == 'pre-sports-fixturebook') {
		let params = router.currentRoute.value.params;
		params.sport_id = sport_id;
		router.push({name: 'pre-sports-fixturebook', params: params})
	} else {
		router.push({name: router.currentRoute.value.name, params: {sport_id: sport_id}})
	}

	preSportsStore.setSelectedPreSportId(sport_id);
}

const checkArrowsVisibility = () => {
	if (splideRef.value && splideRef.value.splide) {
		const slider = splideRef.value.splide;
		let totalWidth = 0;
		slider.Components.Elements.slides.forEach(slide => {
			const marginRight = parseFloat(window.getComputedStyle(slide).marginRight);
			totalWidth += slide.offsetWidth + marginRight;
		});

		const sliderWidth = slider.Components.Elements.track.clientWidth;

		// Update arrow visibility
		slider.options = {
			...slider.options,
			arrows: totalWidth > sliderWidth
		};

		slider.refresh();
	}
};

watch(() => preSportsStore.preSports, (newValue, oldValue) => {
	if (newValue.length > 0) {
		nextTick().then(() => {
			checkArrowsVisibility();
		});
	}
});

onMounted(() => {
	window.addEventListener('resize', checkArrowsVisibility);
});


onUnmounted(() => {
	window.removeEventListener('resize', checkArrowsVisibility);
});

</script>

<template>
<div class="wrapper">
	<div id="game_slides" class="league_slides-inner">
		<!-- Slider start -->
		<Splide ref="splideRef" :options="options">
			<SplideSlide v-for="(sport, index) in preSportsStore.preSports" :key="index">
				<a @click="selectSport(sport.id)" class="league-slide-item carousel__item" :class="sport.id == preSportsStore.selectedPreSportId ? 'active' : ''">
					<div class="coupon-slider-item">
						<div class="coupon-slider-content">
							<img :src="preSportsStore.preSportWhiteIcon(sport.id)" alt="">
							<p>{{ sport.name }}</p>
						</div>
					</div>
				</a>
			</SplideSlide>
		</Splide>
		<!-- slider end -->
	</div>
</div>
</template>
<style scoped>
.league-slider-main .wrapper .league_slides-inner :deep(.splide__arrow:disabled) {
	display: none !important;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
	rotate: 180deg;
	right: -10px;
	top: -3px;
	display: flex;
	left: unset;
}

html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
	rotate: 180deg;
	left: -10px;
	top: -3px;
	display: flex;
	right: unset;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px
	}
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--next) {
		left: 0px;
	}

	html[dir="rtl"] .league-slider-main .wrapper .league_slides-inner :deep(.splide__arrows .splide__arrow--prev) {
		right: 0px
	}
}
</style>