<template>
	<div class="loading">
		<div class="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>
<style scoped>


.lds-ring {
	display: inline-block;
	position: relative;
	width: 25px;
	height: 25px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 50px;
	height: 50px;
	margin-top: 5px;
	margin-right: 10px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
.loading {
	padding-top: 170px;
	margin-left: 45%;
	height: 100%;
	max-height: 100%;
	min-height: 300px;
}

html[dir="rtl"] .loading {
	margin-left: unset;
	margin-right: 45%;
}

</style>