/* eslint-disable */
import { defineStore } from 'pinia';
import _ from 'lodash';
import { usePreSportsStore } from './pre_sports';
import { useFavouritesStore } from './favourites';
import { useTimezonesStore } from './timezones';
import { useCustomerStore } from './customer';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export const useRacingSportsStore = defineStore('racingSports', {
	 state: () => ({
		selected_racing_sport_id: null,
		next_3_hour_fixtures: {},
		coupon_fixtures: {},
		racing_sport_ids: [14, 20, 34]
	 }),
	 getters: {
		racingSports(state) {
			const preSportsStore = usePreSportsStore();
			return _.filter(preSportsStore.preSports, function (sport) {
				if (state.racing_sport_ids.includes(Number(sport.id))) return sport;
			});
		},
		selectedRacingSportId(state) {
			return state.selected_racing_sport_id
		},
		selectedSport(state) {
			return _.find(state.racingSports, { id: Number(state.selected_racing_sport_id)});
		},
		next3HourFixtures(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.next_3_hour_fixtures);
			for (const key in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, key)) {
					fixtures[key]['competition_region_name'] = fixtures[key].region_name + ' - ' + fixtures[key].competition_name;
					fixtures[key]['favourite'] = false;

					if (favouritesStore.favouriteFixtureIds.includes(fixtures[key].id)) {
						fixtures[key]['favourite'] = true;
					}
				}
			}

			return _.orderBy(fixtures, ['favourite', 'start_datetime'], ['desc', 'asc']);
		},
		next3HourFixturesRightSidebar(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.next_3_hour_fixtures);
			for (const key in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, key)) {
					fixtures[key]['competition_region_name'] = fixtures[key].region_name + ' - ' + fixtures[key].competition_name;
					fixtures[key]['favourite'] = false;

					if (favouritesStore.favouriteFixtureIds.includes(fixtures[key].id)) {
						fixtures[key]['favourite'] = true;
					}
				}
			}

			return _.chain(fixtures).orderBy(['start_datetime'], ['asc']).take(3).value();
		},
		couponsFixtures(state) {
			let fixtures = _.cloneDeep(state.coupon_fixtures);

			let grouped_by_region = _.chain(fixtures).orderBy(['region_name'], ['asc']).groupBy('region_name').value();

			for (const key in grouped_by_region) {
				if (Object.hasOwnProperty.call(grouped_by_region, key)) {
					grouped_by_region[key] = _.chain(grouped_by_region[key]).orderBy(['start_datetime', 'asc']).groupBy('competition_name').value()
				}
			}

			return grouped_by_region;
		},
		racingSportName: (state) => (sport_id) => {
			const { t } = useI18n();
			// If Greyhounds
			if (sport_id == 20) return t('racing.greyhound');
			else return t('racing.horse')
		}
	 },
	 actions: {
		setSelectedRacingSportId(pre_sport_id) {
			this.selected_racing_sport_id = Number(pre_sport_id);
		},
		fetchNext3HoursFixtures(sport_id) {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/sport/' + sport_id + '/fixtures-upcoming/3', { withCredentials: false }).then((response) => {
				this.next_3_hour_fixtures = response.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error)
			});
		},
		fetchCoupons(payload) {
			const timezonesStore = useTimezonesStore();
			let utc_offset = timezonesStore.selectedTimezone.utc_offset;
			const customerStore = useCustomerStore();

			let moment_new = new moment();
			moment_new.locale('en');
			if (!customerStore.isLoggedIn) {
				utc_offset = moment_new.format('Z');
			}

			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/fixtures/' + payload.sport_id + '/daterange/' + payload.date_range, {
				headers: {
					'utc-offset': utc_offset
				},
				withCredentials: false
			}).then((response) => {
				this.coupon_fixtures = response.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
		}
	 },
})