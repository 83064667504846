<script setup>
/* eslint-disable */
import { computed, ref, watch, onMounted } from "vue";
import { useFavouritesStore } from '@/stores/favourites';
import FixtureBookMarketOdd from '@/components/Common/FixtureBookMarketOdd.vue';
import accaBoostIcon from "@/assets/svgs/acca_boost.svg"
import { useAccaBoostStore } from "@/stores/acca_boost.js"

const props = defineProps({
	market: {
		type: Object,
		required: true,
	},
	markets_closed: {
		type: Boolean,
	},
});

const favouritesStore = useFavouritesStore();
const accaBoostStore = useAccaBoostStore();

const market_closed = ref(false);
const is_hidden = ref(false);

const visibilityChanged = (isVisible, entry) => {
	is_hidden.value = !isVisible;
}

const toggleFavouriteMarket = () => {
	favouritesStore.toggleMarket(props.market.id);
}

const showAccaBoost = (market_id)=>{
	return accaBoostStore.getAvailableMarkets.includes(market_id)
}

onMounted(() => {
	market_closed.value = props.markets_closed;
});

watch(() => props.markets_closed, (newValue, oldValue) => {
	market_closed.value = newValue;
})


</script>
<template>
	<div class="live-event-inner" :class="{'open': !market_closed}" v-observe-visibility="visibilityChanged" :style="is_hidden ? 'visibility:hidden;' : ''">
		<div class="info-heading">
			<div class="market-name">
				<h3 style="cursor:pointer;" @click="market_closed = !market_closed">{{ props.market.name }}</h3>
				<img v-if="showAccaBoost(props.market.external_id)" :src="accaBoostIcon" alt="acca-boost" class="acca-boost-icon">
			</div>
			<div class="live-fav-icon" @click="toggleFavouriteMarket" :class="{'active': props.market.favourite }">
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143"><path id="Icon_ionic-ios-star" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d"></path></svg>
			</div>
			<div @click="market_closed = !market_closed" class="market-arrow">
				<a :class="market_closed ? 'market-arrow-icon-closed' : 'market-arrow-icon'"></a>
			</div>

		</div>

		<div class="betting_info" v-if="!market_closed" :class="{'betting_info-single': market.odd_columns == 1}">
			<div class="live-event-fixture" v-for="(column, i) in market.odd_columns" :key="i">
				<FixtureBookMarketOdd v-for="odd in column" :key="odd.uuid" :odd="odd"/>
			</div>
		</div>
	</div>
</template>
<style scoped>
.live-event-inner {
	/* Force hardware acceleration */
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}

.market-arrow {
	display: grid;
	align-items: center;
	justify-content: center;
	height: 100%;
	cursor: pointer;
}

.market-arrow-icon {
	content:  url('@/assets/images/chevron-right.svg');;
	filter: invert(65%);
	transform: scale(.8);
	transform: rotate(270deg);
	width: 20px;
}
.market-arrow-icon-closed {
	content:  url('@/assets/images/chevron-right.svg');;
	filter: invert(65%);
	transform: scale(.8);
	transform: rotate(90deg);
	width: 20px;
}

.market-name{
	display: flex;
	align-items: center;
	justify-content: start;
}

.market-name .acca-boost-icon{
	margin-left: 0.5rem;
}

.sports-main .sports-center-content .eventview-center-panel .live-info-outer .live-event-inner .info-heading {
	grid-template-columns: 94% 3% 3%;
	gap: 0px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.sports-main .sports-center-content .eventview-center-panel .live-info-outer .live-event-inner .info-heading {
		grid-template-columns: 85% 7% 7%;
		gap: 0px;
	}
}

.sports-main .sports-center-content .eventview-center-panel .live-info-outer .live-event-inner .info-heading .live-fav-icon {
	text-align: right;
}

.open {
	margin-bottom: 20px;
}
</style>