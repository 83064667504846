<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';
import { useCountriesStore } from '../../stores/countries';
import { ref, computed } from 'vue';
import _ from 'lodash';

const { t } = useI18n();
const registerStore = useRegisterStore();
const countriesStore = useCountriesStore();

const send_sms_status = ref(null);
const send_sms_response = ref(null);

const sendSmsCode = () => {
	const country = countriesStore.findCountryById(registerStore.register_input_fields.mobile_country_id);

	if (!country) {
		send_sms_status.value = 'failed';
		send_sms_response.value = t('account.register.error_select_number_verification');
		return;
	}

	const mobile_prefix = country.mobile_prefix;
	const data = {
		mobile_prefix,
		mobile_number: registerStore.register_input_fields.mobile_number,
	};

	registerStore.sendSms(data).then((response) => {
		send_sms_status.value = response.status;
		if (response.status == 'success') {
			send_sms_response.value = t('account.register.verification_code_sent');
		} else if (response.status == 'failed') {
			if (response.message == 'phone_number_already_registered') send_sms_response.value = t('account.register.phone_number_already_exists');
			else send_sms_response.value = t('account.register.you_can_retry_after') + ' ' + response.retry_after_seconds + ' ' + t('account.register.seconds');
		} else if (response.status == 'rejected') {
			send_sms_response.value =  t('account.register.something_went_wrong');
		} else {
			send_sms_response.value = response.message;
		}
	}).catch((error) => {
		if (error.response.data.errors) {
			if (error.response.data.errors.mobile_number) {
				send_sms_status.value = 'failed';
				send_sms_response.value = error.response.data.errors.mobile_number[0];
			} else if (error.response.data.errors.mobile_prefix) {
				send_sms_status.value = 'failed';
				send_sms_response.value = error.response.data.errors.mobile_prefix[0];
			}
		} else {
			send_sms_status.value = 'failed';
			send_sms_response.value = error.message;
		}
	});
}

const isComponentVisible = computed(() => {
	if (registerStore.register_input_fields.verify_by == 'sms') {
		return true;
	}

	return false;
});
</script>

<template>
	<div v-if="isComponentVisible" class="register-form-field">
		<label>{{ $t('account.register.number_verification') }}</label>
		<input class="form-input" :placeholder="$t('account.register.number_verification')" v-model="registerStore.register_input_fields.number_verification" type="text"/>
		<button @click="sendSmsCode()" type="button" class="button-class">Send</button>
		<p class="status-label" :class="{'status-danger': send_sms_status == 'failed' || send_sms_status == 'rejected' }">{{ send_sms_response }}</p>

		<div class="register-form-list" v-if="registerStore.register_errors.number_verification.error">
			<div class="password-meter">
				<div class="password-meter-bar">
					<div class="password-meter-bar-inn"></div>
				</div>
			</div>
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.number_verification.error == 'error_select_number_verification'">
					{{ $t('account.register.error_select_number_verification') }}
				</span>
				<span v-else-if="registerStore.register_errors.hasOwnProperty('number_verification')">
					{{ registerStore.register_errors.number_verification.error }}
				</span>
			</p>
		</div>
	</div>
</template>

<style scoped>
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input {
	padding: 10px 15px 10px 15px;
}

.register-form-main .register-outer-form .register-inner-form .form-input {
	width:70%
}

button.button-class {
	text-align: center;
	cursor: pointer;
	color: #fff;
	background: #63A246;
	font-size: 14px;
	font-weight: 500;
	border: 1px solid #63A246;
	border-radius: 4px;
	padding: 7px;
	text-decoration: none;
	width: 70px;
	display: inline-block;
	text-align: center;
	margin-left: 10px
}

.status-label {
	color: #63A246;
	font-size: 13px;
	font-weight: 500;
}

.status-danger {
	color: red !important;
}
</style>
